/* istanbul ignore file */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface exportGroupDetailsByID {
  isLoading: boolean
  loadingError: string | null
  exportGroupDetailsById: any
}

// Initial state of the slice
const initialState: exportGroupDetailsByID = {
  isLoading: true,
  loadingError: null,
  exportGroupDetailsById: null
}

// Slice to return from action
export const exportGroupDetailsByIdSlice = createSlice({
  name: 'exportGroupDetailsById',
  initialState,
  reducers: {
    exportGroupDetailsByIdLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        exportGroupDetailsById: []
      }
    },
    exportGroupDetailsByIdUpdateAction: (state, action: PayloadAction< any >) => {
      return {
        isLoading: false,
        loadingError: null,
        exportGroupDetailsById: action.payload
      }
    },
    exportGroupDetailsByIdErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        exportGroupDetailsById: null
      }
    }
  }
})

// Actions to export
export const {
  exportGroupDetailsByIdLoadingAction,
  exportGroupDetailsByIdUpdateAction,
  exportGroupDetailsByIdErrorAction
} = exportGroupDetailsByIdSlice.actions

export default exportGroupDetailsByIdSlice.reducer
