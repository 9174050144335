// istanbul ignore file
export function exportCSV (data: string[][], filename: string): void {
  const csvContent = 'data:text/csv;charset=utf-8,' + data.map(row => row.join(',')).join('\n')
  const encodedUri = encodeURI(csvContent)
  const link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', `${filename}.csv`)
  document.body.appendChild(link)
  link.click()
}
