/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import * as HighCharts from 'highcharts'
import { Box, Card, CardContent, CircularProgress, Tab, Tabs, Typography, styled } from '@mui/material'
import DeviceTableRegReading from './dataSourceComponents/DeviceTableRegReading'
import DeviceTableReg from './dataSourceComponents/DeviceTableReg'
import HighchartsReact from 'highcharts-react-official'
import DarkUnica from 'highcharts/themes/dark-unica'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { type Dayjs } from 'dayjs'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { useAuth } from 'react-oidc-context'
import { DesktopDateTimePicker } from '@mui/x-date-pickers'
import { GenericDropdown, type Option } from '../../../components/GenericDropdown'
import { type ChannelDataState } from '../redux/channelDataSlice'
import { type ChannelDropdownData } from '../services/channelDropDownService'
import { type channelDropdownInterface } from '../redux/channelDropdownSlice'

function a11yProps (index: number): any {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

export interface DataSourceLabllUnit {
  label: string
  unit: string
}

const nintyDayDate = dayjs(new Date((new Date()).setMonth(new Date().getMonth() - 3)))

interface ParentProps {
  fetchChannelData: (assetId: string, fromDate: string, toDate: string, selectedChannel: string, selectedChannelUnit: string, tenantId: string, channelAttributes: ChannelDropdownData | undefined) => void
  chartData: ChannelDataState
  channelDropdownData: channelDropdownInterface
  fetchChannelDropdownData: (assetId: string, fromDate: string, toDate: string, selectedChannel: string, selectedChannelUnit: string, tenantId: string) => void
  fetchWaterKpiData: (assetId: string, fromDate: string, toDate: string, selectedChannelUnit: string, selectedKpi: string, tenantId: string, channelAttributes: ChannelDropdownData | undefined) => void
}

const DataSource = (props: ParentProps): JSX.Element => {
  const [dropdownOptions, setDropdownOptions] = useState<Option[]>([])
  const initialChannel = props.channelDropdownData?.channelDropdownOptionData && props.channelDropdownData?.channelDropdownOptionData.length > 0
    ? props.channelDropdownData.channelDropdownOptionData[0].id
    : ''
  const initialUnit = props.channelDropdownData?.channelDropdownOptionData && props.channelDropdownData?.channelDropdownOptionData.length > 0
    ? props.channelDropdownData.channelDropdownOptionData[0].unit
    : ''
  const initialName = props.channelDropdownData?.channelDropdownOptionData && props.channelDropdownData?.channelDropdownOptionData.length > 0
    ? props.channelDropdownData.channelDropdownOptionData[0].name
    : ''
  let paramAssetId = sessionStorage.getItem('paramAssetId')
  const auth = useAuth()
  // istanbul ignore next
  if (paramAssetId === null) {
    paramAssetId = ''
  }
  const defaultDate = {
    end: dayjs(new Date()),
    start: dayjs(new Date((new Date()).setMonth(new Date().getMonth() - 3))).clone().hour(0).minute(0).second(0)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value2, setValue2] = React.useState(1)
  const [start, setStart] = useState<Dayjs | null | undefined>(defaultDate.start)
  const [end, setEnd] = useState<Dayjs | null | undefined>(defaultDate.end)

  const [selectedChannelName, setSelectedChannelName] = useState(initialName)
  const [selectedChannel, setSelectedChannel] = useState(initialChannel)
  const [selectedChannelUnit, setSelectedChannelUnit] = useState(initialUnit)
  DarkUnica(HighCharts)
  const activeStyle = { fontSize: '12px', color: '#F0F0F0', borderBottom: '2px solid #1792E5' }
  const inActiveStyle = { fontSize: '12px', color: '#A0A0A0' }

  useEffect(() => {
    if (props.channelDropdownData.httpStatus === 200) {
      const initialChannel = props.channelDropdownData?.channelDropdownOptionData && props.channelDropdownData?.channelDropdownOptionData.length > 0
        ? props.channelDropdownData.channelDropdownOptionData[0].id
        : ''
      const initialUnit = props.channelDropdownData?.channelDropdownOptionData && props.channelDropdownData?.channelDropdownOptionData.length > 0
        ? props.channelDropdownData.channelDropdownOptionData[0].unit
        : ''
      const initialName = props.channelDropdownData?.channelDropdownOptionData && props.channelDropdownData?.channelDropdownOptionData.length > 0
        ? props.channelDropdownData.channelDropdownOptionData[0].name
        : ''
      setSelectedChannel(initialChannel)
      setSelectedChannelUnit(initialUnit)
      setSelectedChannelName(initialName)
    }
  }, [props.channelDropdownData])

  const fetchChannel = (): any => {
    // istanbul ignore else
    if (paramAssetId !== undefined && start !== (undefined) && start !== null && end !== undefined && end !== null && start <= end) {
      const startMoment = start
        .clone()
        .set('second', 0)
      const endMoment = end
        .clone()
        .set('second', 59)
      if (selectedChannelName.includes('Flow')) {
        props.fetchWaterKpiData(paramAssetId ?? '', startMoment.format('YYYY-MM-DDTHH:mm:ssZ'), endMoment.format('YYYY-MM-DDTHH:mm:ssZ'), selectedChannelUnit, selectedChannel, auth.user?.profile.customer as string, props.channelDropdownData.channelDropdownOptionData?.find(item => item.id === selectedChannel))
      } else {
        props.fetchChannelData(paramAssetId ?? '', startMoment.format('YYYY-MM-DD HH:mm:ss'), endMoment.format('YYYY-MM-DD HH:mm:ss'), selectedChannel, selectedChannelUnit, auth.user?.profile.customer as string, props.channelDropdownData.channelDropdownOptionData?.find(item => item.id === selectedChannel))
      }
    }
  }

  // istanbul ignore next
  const handleDateChange = (type: string, date: dayjs.Dayjs): void => {
    if (type === 'start') {
      if (date.isAfter(end) || date.isSame(end)) {
        setStart(date)
        setEnd(date)
      } else {
        setStart(date)
      }
    } else {
      if (date.isBefore(start) || date.isSame(start)) {
        setEnd(date)
        setStart(date)
      } else {
        setEnd(date)
      }
    }
  }

  useEffect(() => {
    fetchChannel()
  }, [start, end, selectedChannel])

  function getGraph (): JSX.Element {
    if (props.chartData.isLoading) {
      return <div style={{ textAlign: 'center' }}><CircularProgress /></div>
    } else if (props.chartData.httpStatus === 401) {
      return (
              <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (props.chartData.httpStatus === 403) {
      return (
            <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
      )
    } else if ((props.chartData.channelDataOptions === null && props.chartData.errorMessage !== null && props.chartData.httpStatus === 200)) {
      return (
        <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
      )
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    } else if (((props.chartData.errorMessage?.includes('ChannelId is invalid') || props.chartData.errorMessage?.includes('channelId cannot be empty')) || props.chartData.errorMessage === 'No data found')) {
      return (
        <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (
      props.chartData.channelDataOptions !== undefined &&
      props.chartData.channelDataOptions !== null
    ) {
      return (
        <HighchartsReact
          highcharts={HighCharts}
          options={props.chartData.channelDataOptions?.consumptionChartOptions}
        />
      )
    } else {
      return (
        <div className='authError'><AuthError errorMessage="cannotFetch" retry={fetchChannel}/></div>
      )
    }
  }

  const WhiteDatePicker = styled(DesktopDateTimePicker)({
    '& input': {
      color: '#D0D0D0',
      fontSize: '14px',
      paddingRight: 0
    }
  })

  const getChannelDropdown = (channelDropdownOptionData: ChannelDropdownData[]): void => {
    const tempDropdownOptions: Option[] = []
    channelDropdownOptionData.forEach((option: ChannelDropdownData) => {
      const labelValue = option.name
      const opt: Option = { label: labelValue, value: option.id }
      tempDropdownOptions.push(opt)
    })

    setDropdownOptions(tempDropdownOptions)
  }

  useEffect(() => {
    // Pass the channelDropdownOptionData array directly to the function
    if (props.channelDropdownData.channelDropdownOptionData) {
      getChannelDropdown(props.channelDropdownData.channelDropdownOptionData)
    }
  }, [props.channelDropdownData.channelDropdownOptionData])

  const CustomLocalizationProviderWrapper = styled('div')({
    backgroundColor: '#505050',
    border: 'none',
    borderRadius: '4px'
  })

  // istanbul ignore next
  const handleOnChange = (e: any): void => {
    setSelectedChannel(e.target.value)
    if (props.channelDropdownData.channelDropdownOptionData !== null && props.channelDropdownData.channelDropdownOptionData !== undefined) {
      const selectedChannelData = props.channelDropdownData.channelDropdownOptionData.find((item) => item.id === e.target.value)
      setSelectedChannelUnit(selectedChannelData?.unit ?? '')
      setSelectedChannelName(selectedChannelData?.name ?? '')
    }
  }
  return (

    <div className='devicedetails'>
      <Box sx={{ width: '100%', padding: '1em' }}>
        <Card sx={{ backgroundColor: '#313030', color: '#f0f0f0' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontSize: '16px', padding: '2em 2em' }}>DATA SOURCE</div>
          <div style={{ display: 'flex' }}>
            <span style={{ paddingRight: '1em', display: 'flex', alignItems: 'center' }}>
              <span style={{ paddingRight: '6px', fontSize: '14px' }}>
              Start Date
              </span>
              <span className='datePicker-deviceDetails'>
                <CustomLocalizationProviderWrapper>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <WhiteDatePicker minDate={nintyDayDate} disableFuture closeOnSelect={false} ampm={false} timeSteps={{ minutes: 1 }} slotProps={{ textField: { size: 'small' }, actionBar: { actions: ['cancel', 'accept'] }, field: { readOnly: true } }} defaultValue={defaultDate.start} value={start} onAccept={(val: any) => { handleDateChange('start', val) }} />
                  </LocalizationProvider>
                </CustomLocalizationProviderWrapper>
              </span>
            </span>
            <span style={{ paddingRight: '1em', display: 'flex', alignItems: 'center' }}>
              <span style={{ paddingRight: '6px', fontSize: '14px' }}>
              End Date
              </span>
              <span className='datePicker-deviceDetails'>
                <CustomLocalizationProviderWrapper>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <WhiteDatePicker minDate={nintyDayDate} disableFuture closeOnSelect={false} ampm={false} timeSteps={{ minutes: 1 }} slotProps={{ textField: { size: 'small' }, actionBar: { actions: ['cancel', 'accept'] }, field: { readOnly: true } }} defaultValue={defaultDate.end} value={end} onAccept={(val: any) => { handleDateChange('end', val) }} />
                  </LocalizationProvider>
                </CustomLocalizationProviderWrapper>
              </span>
            </span>
            <span style={{ paddingRight: '1em', display: 'flex', alignItems: 'center' }}>
              <span style={{ paddingRight: '6px', fontSize: '14px' }}>
                Data Source
              </span>
              <span style={{ paddingRight: '1em', fontSize: '14px' }}>
              <GenericDropdown dataTestId='channel-dropdown' width={250} options={dropdownOptions} value={selectedChannel} onChange={(e) => { handleOnChange(e) }} label={''} />
              </span>
            </span>
          </div>
        </div>
        <CardContent>
          {getGraph()}
          </CardContent>
        </Card>
        <Typography color='inherit' sx={{ fontSize: '20px', fontFamily: 'Honeywell Sans Web', paddingTop: '1em' }}>REGISTERS</Typography>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value2} aria-label='basic tabs example'>
            <Tab style={activeStyle} label='Register Readings' {...a11yProps(1)} />
            <Tab style={{ ...inActiveStyle, display: 'none' }} disabled label='Registers' {...a11yProps(0)} aria-label='basic tabs 1' />
          </Tabs>
        </Box><div>
      {value2 === 1
        ? (
          <DeviceTableRegReading />
          )
        : (
          <DeviceTableReg />
          )}
    </div>

      </Box>
    </div>
  )
}
export default DataSource
