/* eslint-disable */
import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZAssetProvisioningHistoryDetails = z.object({
  provisioningHistoryId: z.string(),
  uploadedBy: z.string(),
  uploadDate: z.string(),
  totalAsset: z.number(),
  failedAsset: z.number(),
  provisioningHistoryStatus: z.string()
})

const ZData = z.object({
  total_count: z.number(),
  provisioningHistory: ZAssetProvisioningHistoryDetails.array()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(ZData)
})

const ZApiFilterResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(z.array(z.string()))
})

export type AssetProvisioningHistoryDetails = z.infer<typeof ZAssetProvisioningHistoryDetails>

export async function getAssetProvisioningHistoryDetails (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  search: any,
  tenantId: string,
  onSuccess: (assetProvisioningHistoryData: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory?page=${page}&size=${size}&sort=${sortfield},${sortdir}&search=${search}&tenantId=${tenantId}`
  interceptedAxios.get(url, { timeout: 10000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        // istanbul ignore else
        if (parseResponse.success) {
          // istanbul ignore else
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.response !== undefined ? error.response.status : 404)
    })}

export async function getAssetProvisioningHistoryFilteredData (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  filterParams: any,
  tenantId: string,
  onSuccess: (assetProvisioningHistoryData: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/filter?page=${page}&size=${size}&sort=${sortfield},${sortdir}&tenantId=${tenantId}`
  interceptedAxios.post(url, filterParams)
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          // istanbul ignore else
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.response !== undefined ? error.response.status : 404)
    })}


export async function getAssetProvisioningHistoryFilterStatus (
  tenantId: string,
  onSuccess: (provisioningHistoryFilterStatus: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/status?tenantId=${tenantId}`
  interceptedAxios.get(url, { timeout: 10000} )
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiFilterResponse.safeParse(response.data)
        if (parseResponse.success) {
          // istanbul ignore else
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.status !== undefined ? error.status : 404)
    })}


export async function getAssetProvisioningHistoryFilterUploadedUsers (
  tenantId: string,
  onSuccess: (provisioningHistoryFilterUploadedUsers: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/users?tenantId=${tenantId}`
  interceptedAxios.get(url, { timeout: 10000} )
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiFilterResponse.safeParse(response.data)
        if (parseResponse.success) {
          // istanbul ignore else
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.status !== undefined ? error.status : 404)
    })}
