import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface resumeSchedulerTemplateState {
  isLoading: boolean
  error: boolean
  httpStatus: number
}

const initialState: resumeSchedulerTemplateState = {
  isLoading: false,
  error: false,
  httpStatus: 0
}

export const resumeSchedulerTemplateStateSlice = createSlice({
  name: 'resumeSchedulerTemplateState',
  initialState,
  reducers: {
    resumeSchedulerTemplateLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        httpStatus: 0
      }
    },
    resumeSchedulerTemplateUpdateAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: false,
        httpStatus: action.payload
      }
    },
    resumeSchedulerTemplateErrorAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: true,
        httpStatus: action.payload
      }
    }
  }
})

export const {
  resumeSchedulerTemplateLoadingAction,
  resumeSchedulerTemplateUpdateAction,
  resumeSchedulerTemplateErrorAction
} = resumeSchedulerTemplateStateSlice.actions

export default resumeSchedulerTemplateStateSlice.reducer
