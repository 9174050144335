import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Configuration } from '../services/singleConfigService'

export interface SingleConfigData {
  id: number
  description: string
  spec_version: string
  name: string
  public_key: string
  file_created: string
  encryption_enabled_state: boolean
  protocol: string
  sftp_host_name: string
  url: string
  username: string
  password: string
  sftp_port: number
  sftp_public_key: string
  sftp_encryption_string: string
  tenant_id: string
  file_format: string
  enabled: boolean
  minutes_between_publishing: number
  hour_of_day_to_start: number
  minute_of_day_to_start: number
  minutes_after_hour: number
  frequency_type: number
}

export interface SingleConfigState {
  isLoading: boolean
  error: string | null
  configData: Configuration | null
  httpStatus: number | null
}

const initialState: SingleConfigState = {
  isLoading: true,
  error: null,
  configData: null,
  httpStatus: null
}

const singleConfigSlice = createSlice({
  name: 'singleConfig',
  initialState,
  reducers: {
    singleConfigDataLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        configData: null,
        httpStatus: 0
      }
    },
    singleConfigDateUpdateAction: (state, action: PayloadAction<{ data: Configuration, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        configData: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    singleConfigDataErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        configData: null,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  singleConfigDataLoadingAction,
  singleConfigDateUpdateAction,
  singleConfigDataErrorAction
} = singleConfigSlice.actions

export default singleConfigSlice.reducer
