import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface AddFirmwareInfoState {
  isLoading: boolean
  message: string | null
  errorMessage: string | null
}

const initialState: AddFirmwareInfoState = {
  isLoading: false,
  message: null,
  errorMessage: null
}

export const addFirmwareInfoSlice = createSlice({
  name: 'addFirmwareInfoSlice',
  initialState,
  reducers: {
    resetAddFirmware: () => {
      return {
        isLoading: false,
        message: null,
        errorMessage: null
      }
    },
    addFirmwareInfoDataLoading: (state): AddFirmwareInfoState => {
      return {
        isLoading: true,
        message: null,
        errorMessage: null
      }
    },
    addFirmwareInfoDataError: (state, action: PayloadAction<string>): AddFirmwareInfoState => {
      return {
        isLoading: false,
        message: null,
        errorMessage: action.payload
      }
    },
    addFirmwareInfoData: (state, action: PayloadAction<string>): AddFirmwareInfoState => {
      return {
        isLoading: false,
        message: action.payload,
        errorMessage: null
      }
    }
  }
})

export const { addFirmwareInfoDataLoading, addFirmwareInfoDataError, addFirmwareInfoData, resetAddFirmware } = addFirmwareInfoSlice.actions
export default addFirmwareInfoSlice.reducer
