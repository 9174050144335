// istanbul ignore file
import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZFilterValues = z.object({
  allowed_operators: z.string(),
  parameter_data_type: z.string(),
  sample_value: z.string(),
  parameter_display_name: z.string()
})

const ZAdvancedFilterParameterRecord = z.record(z.string(), ZFilterValues)

const ZApiResponse = z.object({
  http_status: z.number(),
  data: ZAdvancedFilterParameterRecord,
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type ApiResponse = z.infer<typeof ZApiResponse>

export type AdvancedFilterParameter = z.infer<typeof ZAdvancedFilterParameterRecord>

export async function getAdvancedFilterOptions (
  searchKey: string,
  onSuccess: (advancedFilterOptions: AdvancedFilterParameter, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/search-service/api/v1/udp/search/searchParameter?searchKey=${searchKey}`

  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        // istanbul ignore else
        if (
          parseResponse.data.data !== null &&
          parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        } else {
          onFailure(
            parseResponse.data.error_message,
            parseResponse.data.http_status
          )
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        // istanbul ignore else
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.message, error.response.status)
        }
      } else {
        onFailure(error.message, error.status)
      }
    })
}
