import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
import { ZAssetGroup } from '../types'

const ZAssetGroupObj = z.object({
  total_count: z.number(),
  group_details: z.array(ZAssetGroup)
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZAssetGroupObj),
  error: z.boolean()
})

export type AssetGroupObj = z.infer<typeof ZAssetGroupObj>
export type ApiResponse = z.infer<typeof ZApiResponse>

export async function getAssetGroupList (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  input: string,
  filterParams: any,
  onSuccess: (assetGroupObj: AssetGroupObj, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  let field = sortfield
  // istanbul ignore else
  if (field === 'name') {
    field = 'groupName'
  }
  const url = `/assetrest/api/v1/udp/groupManagement/assetGroup/filter?page=${page}&input=${input}&size=${size}&sort=${field},${sortdir}`

  interceptedAxios
    .post(url, filterParams)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        // istanbul ignore else
        if (parseResponse.data.data !== undefined) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        } else {
          onFailure({ totalCount: 0, group_details: [] }, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore next
      if (error.response !== undefined) {
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.response.statusText, error.response.status)
        }
      } else {
        onFailure('Network Failure', error.status)
      }
    })
}
