import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZFilterForGroup = z.object({
  assetType: z.any(),
  categoryType: z.any(),
  status: z.any()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZFilterForGroup.nullable()),
  error: z.boolean(),
  error_message: z.optional(z.string())
})
export type FilterParameter = z.infer<typeof ZApiResponse>
export type ApiResponse = z.infer<typeof ZApiResponse>

export async function getAllGroupFilterParameter (
  onSuccess: (GroupFilterPara: ApiResponse, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/assetrest/api/v1/udp/groupManagement/assetGroup/filters'
  interceptedAxios.get(url, { timeout: 15000 })
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        // istanbul ignore else
        if (parseResponse.success) {
          const parsedData: ApiResponse = parseResponse.data
          onSuccess(parsedData, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.status)
    })
}
