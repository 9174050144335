import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ApiResponse } from '../services/associateAssetService'

export interface AssociateAssetState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null | undefined
}

const initialState: AssociateAssetState = {
  isLoading: true,
  error: null,
  httpStatus: null
}

const associateAssetsSlice = createSlice({
  name: 'associate-assets',
  initialState,
  reducers: {
    associateAssetsLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0
      }
    },
    associateAssetsUpdateAction: (state, action: PayloadAction<{ data: ApiResponse }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.data.http_status
      }
    },
    associateAssetsErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  associateAssetsLoadingAction,
  associateAssetsUpdateAction,
  associateAssetsErrorAction
} = associateAssetsSlice.actions

export default associateAssetsSlice.reducer
