import React from 'react'
import LanguageStrings from '../../../../i18n/locales'
import { type KpiKey } from '../../redux/changeKpiType'
import iButton from '../../../../assets/iButton.svg'

const KpiInfoStrings = LanguageStrings().AMIDataCollectionStrings.KpiInfo

function getItemsInList (kpiValue: string): JSX.Element {
  return <div>{kpiValue.split('|').map((item, index) => <p key={index}>{item}</p>)}</div>
}

export const getKpiInfo = (kpiName: KpiKey): JSX.Element => {
  const [isHovered, setIsHovered] = React.useState(false)
  return (
    <span onMouseEnter={() => { setIsHovered(true) }} onMouseLeave={() => { setIsHovered(false) }} style={{ position: 'relative' }}>
      <img className='elementHoverBlue' style={{ position: 'absolute', top: '3px', left: '7px' }} src={iButton} />
      { isHovered ? <span style={{ position: 'absolute', width: 250, backgroundColor: '#ffffff', padding: 8, borderRadius: 4, color: '#000000', fontSize: 13, zIndex: 100, right: '-150px', top: '120%', textTransform: 'initial' }}>{getItemsInList(KpiInfoStrings[kpiName])}</span> : <></> }
    </span>
  )
}
