import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type GroupAssignConfigObj } from '../services/configAssignGroupService'

export interface ConfigurationGroupAssignState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
  groupListData: GroupAssignConfigObj[] | undefined
}

export const initialState: ConfigurationGroupAssignState = {
  isLoading: false,
  error: undefined,
  httpStatus: undefined,
  groupListData: undefined
}

const configurationGroupAssignSlice = createSlice({
  name: 'configuration-group-assign',
  initialState,
  reducers: {
    configurationGroupAssignLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0,
        groupListData: undefined
      }
    },
    configurationGroupAssignUpdateAction: (
      state,
      action: PayloadAction<{ data: GroupAssignConfigObj[], httpStatus: number }>
    ) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload.httpStatus,
        groupListData: action.payload.data
      }
    },
    configurationGroupAssignErrorAction: (
      state,
      action: PayloadAction<{ errorMessage: string, httpStatus: number }>
    ) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        groupListData: undefined
      }
    }
  }
})

export const {
  configurationGroupAssignLoadingAction,
  configurationGroupAssignUpdateAction,
  configurationGroupAssignErrorAction
} = configurationGroupAssignSlice.actions

export default configurationGroupAssignSlice.reducer
