/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface firmwareListFilterFirmwareTypeState {
  isLoading: boolean
  loadingError: string | null
  firmwareListFilterFirmwareType: string[] | []
}

// Initial state of the slice
const initialState: firmwareListFilterFirmwareTypeState = {
  isLoading: true,
  loadingError: null,
  firmwareListFilterFirmwareType: [],
}

// Slice to return from action
export const firmwareListFilterFirmwareTypeSlice = createSlice({
  name: 'firmwareListFilterFirmwareType',
  initialState,
  reducers: {
    firmwareListFilterFirmwareTypeLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        firmwareListFilterFirmwareType: []
      }
    },
    firmwareListFilterFirmwareTypeUpdateAction: (state, action: PayloadAction<string[]|[]>) => {
      return {
        isLoading: false,
        loadingError: null,
        firmwareListFilterFirmwareType: action.payload
      }
    },
    firmwareListFilterFirmwareTypeErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        firmwareListFilterFirmwareType: []
      }
    }
  }
})

// Actions to export
export const {
  firmwareListFilterFirmwareTypeLoadingAction,
  firmwareListFilterFirmwareTypeUpdateAction,
  firmwareListFilterFirmwareTypeErrorAction
} = firmwareListFilterFirmwareTypeSlice.actions

export default firmwareListFilterFirmwareTypeSlice.reducer