/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import {
  Box,
  Breadcrumbs,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Radio,
  TextField,
  Typography
} from '@mui/material'
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import LanguageStrings from '../../../../i18n/locales'
import React, { type ChangeEvent, useEffect, useReducer, useState } from 'react'
import { GenXDropdown, type Option } from '../../../../components/GenXDropdown'
import TextareaAutosize from '@mui/material/TextareaAutosize'
import { Button } from '../../../../components/Button'
import { useNavigate } from 'react-router-dom'
import { updateConfigurationProtocolservice, getConfigurationDropdownMetadata, getPublicKeyData, getProtocolDetailsById, TestConnectionService } from '../../redux/actionCreators'
import { type PublicKeyDataState } from '../../redux/getPublicKeySlice'
import { type ProtocolsDetailsState } from '../../redux/getProtocolDetailsByIdSlice'
import { type updateConfigurationProtocolState } from '../../redux/updateConfigurationProtocolSlice'
import { type ConfigurationMetadataDropDownState } from '../../redux/configurationMetaDataSlice'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../../store'
import { type SFTPConfigurationData } from '../../types'
import Modal from '../../../../components/Modal'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import '../ReportSchedulerComponents/scheduler.css'
import { isValidName, isValidPort, isValidText, isValidFolderPath, isValidDesc, isValidNameEdit } from '../../../../utils/validator'
import { isValidIp } from '../../../data-publisher/components/addConfig/fileValidation'
import MoreInfo from '../../../../assets/More-Info.svg'
import { styled } from '@mui/material/styles'
import forge from 'node-forge'
import { GenericTextarea } from '../../../../components/GenericTextarea'
import { type testConnectionState } from '../../redux/testConnectionSlice'
import Notification from '../../../../components/Notification'

const CreateSFTPStrings = LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings.SchedulerStrings.CreateSFTPFormStrings

const CreateSFTPButtonStrings = LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings.SchedulerStrings.CreateSchedulerFormStrings

const style = {
  width: '99%',
  borderRadius: '10px',
  border: '1px solid #101010',
  background: '#272727',
  boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '16px',
  marginBottom: '16px',
  padding: '24px'
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 270,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    fontFamily: 'Honeywell Sans Web'
  }
}))

interface SftpStateType {
  connectionName: string
  description: string
  protocolTypeId: string
  protocolName: string
  ipAddress: string
  port: string
  userName: string
  password: string
  sshKey: string
  folderPath: string
  publicKey: string
  url: string
  apiToken: string
  fileType: string | null
}

const EditConfigurationProtocol = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const [protocolSpecificationName, setProtocolSpecificationName] = useState('')
  const [description, setDescription] = useState('')
  const [selectProtocol, setselectProtocol] = useState<Option | null>(null)
  const [selectProtocolOptions, setSelectProtocolOptions] = useState<Option[]>([])
  const [protocolName, setProtocolName] = useState('')
  const [ipAddress, setIpAddress] = useState('')
  const [port, setPort] = useState<any>(null)
  const [userName, setUserName] = useState('')
  const [userNamePlaceholder, setUserNamePlaceholder] = useState('')
  const [passwordPlaceholder, setPasswordPlaceholder] = useState('')
  const [sshKeyPlaceholder, setSshKeyPlaceholder] = useState('')
  const [password, setPassword] = useState('')
  const [SSHKey, setSSHKey] = useState('')
  const [PublicKeystate, setPublicHKeystate] = useState('')
  const [PublicKeyMaskValue, setPublicKeyMaskValue] = React.useState('')
  const [folderPath, setFolderPath] = useState('')
  const [confirm, setconfirm] = useState(false)
  const [sftpAuthentication, setSftpAuthentication] = useState<'ssh' | 'password'>('ssh')
  const [selectedFileType, setSelectedFileType] = useState<'Zipped' | 'Encrypted' | ''>('')
  const [url, setUrl] = useState('')
  const [apiToken, setApiToken] = useState('')
  const [urlPlaceholder, setUrlPlaceholder] = useState('')
  const [apiTokenPlaceholder, setApiTokenPlaceholder] = useState('')
  const [fileTypePlaceholder, setFileTypePlaceholder] = useState('')
  const [showSuccessPopUp, setshowSuccessPopUp] = useState(false)
  const [reqError, setReqError] = useState<boolean>(false)
  const [displayMaskValue, setDisplayMaskValue] = useState('')
  const [showNotification, setShowNotification] = useState<boolean>(false)
  const [apiTokenMaskValue, setApiTokenMaskValue] = React.useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [testRequired, setTestRequired] = useState(false)
  const [isSftpAuthenticationModified, setIsSftpAuthenticationModified] = useState(false)

  const DropDownMetaData = useSelector((state: RootState) => state?.ConfigurationMetadata?.configurationMetadata?.protocols)
  const publicKey = useSelector((state: RootState) => state.PublicKeyData.PublicKeyData)

  const UpateConfigurationResponce = useSelector(
    (state: RootState) => state.UpateProtocolConfigurationResponce
  )

  const ProtocolDataResponse = useSelector((state: RootState) => state.ProtocolDetailsData?.ProtocolsDetails)
  const ProtocolDataResponsestate = useSelector((state: RootState) => state.ProtocolDetailsData)
  const initialState: SftpStateType = {
    connectionName: '',
    description: '',
    protocolTypeId: '',
    protocolName: '',
    ipAddress: '',
    port: '',
    userName: '',
    password: '',
    sshKey: '',
    folderPath: '',
    publicKey: '',
    url: '',
    apiToken: '',
    fileType: null
  }

  const [addState, dispatch] = useReducer(
    (curVal: SftpStateType, newVal: SftpStateType) => ({ ...curVal, ...newVal }),
    initialState
  )

  const [oldInitialState, dispatchOldInitialState] = useReducer(
    (curVal: SftpStateType, newVal: SftpStateType) => ({ ...curVal, ...newVal }),
    initialState
  )
  const protocolIdData = sessionStorage.getItem('protocolId')

  useEffect(() => {
    props.getConfigurationDropdownMetadata()
  }, [])

  useEffect(() => {
    setProtocolSpecificationName(ProtocolDataResponse ? ProtocolDataResponse.connectionName : '')
    setDescription(ProtocolDataResponse?.description ? ProtocolDataResponse.description : '')
    setIpAddress(ProtocolDataResponse ? ProtocolDataResponse.ipAddress ?? '' : '')
    setProtocolName(ProtocolDataResponse ? ProtocolDataResponse.protocolName : '')
    setPort(ProtocolDataResponse ? ProtocolDataResponse.port ?? '' : '')
    setFolderPath(ProtocolDataResponse ? ProtocolDataResponse.folderPath ?? '' : '')
    setUserNamePlaceholder(ProtocolDataResponse?.userName ? '***********' : 'Enter Username')
    setPasswordPlaceholder(ProtocolDataResponse?.password ? '***********' : 'Enter Password')
    setSshKeyPlaceholder(ProtocolDataResponse?.sshKey ? '***********' : 'Enter SSH Key')
    setSelectedFileType(ProtocolDataResponse?.fileTypeName ? ProtocolDataResponse?.fileTypeName as 'Zipped' | 'Encrypted' : '')
    setSftpAuthentication(!ProtocolDataResponse?.sshKey ? 'password' : 'ssh')
    setselectProtocol(selectProtocolOptions.find((val) => val.value === ProtocolDataResponse?.protocolTypeId) ?? null)
    setApiTokenPlaceholder(ProtocolDataResponse?.apiToken ? '***********' : 'Enter API Token')
    setUrlPlaceholder(ProtocolDataResponse?.baseUrl ? '***********' : 'Enter URL')
    setFileTypePlaceholder(ProtocolDataResponse?.publicKey ? '***********' : 'Enter Public Key')
  }, [ProtocolDataResponse])

  useEffect(() => {
    if (ProtocolDataResponse !== undefined) {
      const savedData = {
        connectionName: ProtocolDataResponse !== undefined ? ProtocolDataResponse.connectionName : '',
        description: ProtocolDataResponse !== undefined ? (ProtocolDataResponse.description ?? '') : '',
        protocolTypeId: ProtocolDataResponse !== undefined ? ProtocolDataResponse.protocolTypeId : '',
        ipAddress: ProtocolDataResponse !== undefined ? ProtocolDataResponse.ipAddress ?? '' : '',
        protocolName: ProtocolDataResponse !== undefined ? ProtocolDataResponse.protocolName : '',
        port: (ProtocolDataResponse?.port != null) ? ProtocolDataResponse.port.toString() : '',
        userName: (ProtocolDataResponse?.userName !== null) ? ProtocolDataResponse.userName : '',
        folderPath: ProtocolDataResponse?.folderPath !== null ? ProtocolDataResponse.folderPath : '',
        publicKey: ProtocolDataResponse !== undefined ? (ProtocolDataResponse.publicKey ?? '') : '',
        sshKey: ProtocolDataResponse !== undefined ? (ProtocolDataResponse.sshKey ?? '') : '',
        password: ProtocolDataResponse !== undefined ? (ProtocolDataResponse.password ?? '') : '',
        url: ProtocolDataResponse !== undefined ? (!ProtocolDataResponse.baseUrl ? '' : ProtocolDataResponse.baseUrl) : '',
        apiToken: ProtocolDataResponse !== undefined ? (!ProtocolDataResponse.apiToken ? '' : ProtocolDataResponse.apiToken) : '',
        fileType: ProtocolDataResponse !== undefined ? (ProtocolDataResponse.fileTypeName ?? '') : ''
      }
      dispatch({ ...savedData })
      dispatchOldInitialState({ ...savedData })
    }
  }, [ProtocolDataResponse])

  useEffect(() => {
    if (DropDownMetaData !== undefined && DropDownMetaData?.length > 0) {
      const ProtocolOption: Option[] = []
      DropDownMetaData?.forEach((item) => {
        ProtocolOption.push({
          value: item.protocolTypeId,
          label: item.protocolTypeName
        })
      })
      setSelectProtocolOptions(ProtocolOption)
      props.getPublicKeyData()
      props.getProtocolDetailsById(protocolIdData !== null ? protocolIdData : '')
    }
  }, [DropDownMetaData])

  const handleConnectionName = (value: string): void => {
    setProtocolSpecificationName(value)
    dispatch({ ...addState, connectionName: value })
  }

  const isValidPasswordlength = (value: string): boolean => {
    return value.length >= 5 && value.length <= 15
  }

  const handleDescription = (value: string): void => {
    setDescription(value)
    dispatch({ ...addState, description: value })
  }

  const handleProtocol = (selectedValue: Option): void => {
    setTestRequired(true)
    setselectProtocol(selectedValue)
    dispatch({ ...addState, protocolTypeId: selectedValue.value })
  }

  const handleProtocolName = (value: string): void => {
    setProtocolName(value)
    dispatch({ ...addState, protocolName: value })
  }

  const handleIPAddress = (value: string): void => {
    setIpAddress(value)
    dispatch({ ...addState, ipAddress: value })
  }

  const handlePortNumber = (value: string): void => {
    setPort(value)
    dispatch({ ...addState, port: value })
  }

  const handleUserName = (value: string): void => {
    setUserNamePlaceholder('Enter Username')
    setUserName(value)
    const publicKeyObj = forge.pki.publicKeyFromPem(publicKey !== undefined ? publicKey : '')
    dispatch({
      ...addState,
      userName: forge.util.encode64(publicKeyObj.encrypt(value, 'RSA-OAEP', {
        md: forge.md.sha256.create()
      }))
    })
  }

  const handlePassword = (value: string): void => {
    setPasswordPlaceholder('Enter Password')
    setPassword(value)
    const publicKeyObj = forge.pki.publicKeyFromPem(publicKey !== undefined ? publicKey : '')
    dispatch({
      ...addState,
      password: forge.util.encode64(publicKeyObj.encrypt(value, 'RSA-OAEP', {
        md: forge.md.sha256.create()
      })),
      sshKey: ''
    })
  }

  const handleSSHkey = (value: string): void => {
    setSshKeyPlaceholder('Enter SSH Key')
    setDisplayMaskValue(value.replace(/./g, '*'))
    setSSHKey(value)
    dispatch({ ...addState, sshKey: btoa(value), password: '' })
  }

  const handlePublicKey = (value: string): void => {
    setFileTypePlaceholder('Enter Public Key')
    setPublicKeyMaskValue(value.replace(/./g, '*'))
    setPublicHKeystate(value)
    dispatch({ ...addState, publicKey: btoa(value) })
  }

  const handleUrl = (value: string): void => {
    setUrlPlaceholder('Enter URL')
    setUrl(value)
    const publicKeyObj = forge.pki.publicKeyFromPem(
      publicKey !== undefined ? publicKey : ''
    )
    dispatch({
      ...addState,
      url: forge.util.encode64(
        publicKeyObj.encrypt(value, 'RSA-OAEP', {
          md: forge.md.sha256.create()
        })
      )
    })
  }

  const handleFileTypeSelection = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTestRequired(true)
    setSelectedFileType(event.target.value as 'Zipped' | 'Encrypted')
    dispatch({ ...addState, fileType: event.target.value })
  }

  const handleFolderPath = (value: string): void => {
    setFolderPath(value)
    dispatch({ ...addState, folderPath: value })
  }

  const handleOkandClose = (): void => {
    if (UpateConfigurationResponce.httpStatus === 200) {
      setshowSuccessPopUp(false)
      setconfirm(false)
      navigate('/configuration-protocol')
      clearAllDetails()
    } else {
      setshowSuccessPopUp(false)
      setconfirm(false)
    }
  }

  const handleAuthenticationChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setTestRequired(true)
    dispatch({ ...addState, sshKey: '', password: '' })
    setSSHKey('')
    setPassword('')
    setDisplayMaskValue('')
    setSftpAuthentication(event.target.value as 'ssh' | 'password')
    setIsSftpAuthenticationModified(true)
  }

  const clearAllDetails = (): void => {
    setProtocolSpecificationName('')
    setDescription('')
    setselectProtocol(null)
    setProtocolName('')
    setIpAddress('')
    setPort(null)
    setUserName('')
    setPassword('')
  }

  const isValidlength = (value: string): boolean => {
    return value.length >= 5 && value.length <= 60
  }

  const isValidUserNameLength = (value: string): boolean => {
    return value.length >= 3 && value.length <= 60
  }

  const isValidDescLength = (value: string): boolean => {
    return value.length >= 5 && value.length <= 200
  }

  const handleTestConnection = (): void => {
    props.testConnectionService({
      connectionName: addState.connectionName,
      description: addState.description,
      protocolTypeId: addState.protocolTypeId,
      protocolName: addState.protocolName,
      ipAddress: selectProtocol?.label === 'SFTP' ? addState.ipAddress : '',
      port: selectProtocol?.label === 'SFTP' ? parseInt(addState.port) : 0,
      userName: selectProtocol?.label === 'SFTP' ? addState.userName : '',
      folderPath: selectProtocol?.label === 'SFTP' ? addState.folderPath : '',
      sshKey: selectProtocol?.label === 'SFTP' ? addState.sshKey : '',
      password: selectProtocol?.label === 'SFTP' ? addState.password : '',
      fileType: selectedFileType,
      fileEncryptFormat: selectedFileType === 'Encrypted' ? 'gpg' : '',
      publicKey: selectedFileType === 'Encrypted' ? addState.publicKey : '',
      baseUrl: selectProtocol?.label === 'HTTPS' ? addState.url : '',
      apiToken: selectProtocol?.label === 'HTTPS' ? addState.apiToken : '',
      authType: selectProtocol?.label === 'HTTPS' ? 'api_token' : ''
    })
  }

  const handleconfirmClick = (): void => {
    props.updateConfigurationProtocolservice(protocolIdData !== null ? protocolIdData : ProtocolDataResponse?.protocolId ?? '', {
      connectionName: addState.connectionName,
      description: addState.description,
      protocolTypeId: addState.protocolTypeId,
      protocolName: addState.protocolName,
      ipAddress: selectProtocol?.label === 'SFTP' ? addState.ipAddress : '',
      port: selectProtocol?.label === 'SFTP' ? parseInt(addState.port) : 0,
      userName: selectProtocol?.label === 'SFTP' ? addState.userName : '',
      folderPath: selectProtocol?.label === 'SFTP' ? addState.folderPath : '',
      sshKey: selectProtocol?.label === 'SFTP' ? addState.sshKey : '',
      password: selectProtocol?.label === 'SFTP' ? addState.password : '',
      fileType: selectedFileType,
      fileEncryptFormat: selectedFileType === 'Encrypted' ? 'gpg' : '',
      publicKey: selectedFileType === 'Encrypted' ? addState.publicKey : '',
      baseUrl: selectProtocol?.label === 'HTTPS' ? addState.url : '',
      apiToken: selectProtocol?.label === 'HTTPS' ? addState.apiToken : '',
      authType: selectProtocol?.label === 'HTTPS' ? 'api_token' : ''
    })
    setconfirm(false)
    setshowSuccessPopUp(true)
  }

  const handleCreateConfigurationWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (UpateConfigurationResponce.httpStatus === 200) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={CreateSFTPStrings.UpdateCreateTitle}
          LabelPositive={'Ok'}
          onClickPositive={() => {
            handleOkandClose()
          }}
          onClose={() => {
            handleOkandClose()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {UpateConfigurationResponce.httpStatus === 200
              ? CreateSFTPStrings.SucessfullyUpdated
              : UpateConfigurationResponce.error}
          </p>
        </Modal>
      )
    } else if (UpateConfigurationResponce.isLoading) {
      return (
        <Modal title={'Loading...'}>
          <div className="CircularprogressAmi">
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (UpateConfigurationResponce.httpStatus === 400) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={'Error'}
          LabelPositive={'Ok'}
          onClickPositive={() => {
            handleOkandClose()
          }}
          onClose={() => {
            handleOkandClose()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{UpateConfigurationResponce.error}</p>
        </Modal>
      )
    } else if (UpateConfigurationResponce.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (UpateConfigurationResponce.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={CreateSFTPStrings.UpdateCreateTitle}
          LabelPositive={'Ok'}
          onClickPositive={() => {
            setshowSuccessPopUp(false)
            setconfirm(false)
            navigate('/configuration-protocol')
          }}
          onClose={() => {
            setshowSuccessPopUp(false)
            setconfirm(false)
            navigate('/configuration-protocol')
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{UpateConfigurationResponce.error}</p>
        </Modal>
      )
    }
  }

  function isConnectionNameValid (): boolean {
    if (addState.connectionName === '' || !isValidNameEdit(addState.connectionName) || !isValidlength(addState.connectionName)) {
      return false
    }
    return true
  }

  function isDescriptionValid (): boolean {
    if (addState.description !== '' && (!isValidDesc(addState.description) || !isValidDescLength(addState.description))) {
      return false
    }
    return true
  }

  function isValidProtocolName (): boolean {
    if (addState.protocolName === '' || !isValidNameEdit(addState.protocolName) || !isValidlength(addState.protocolName)) {
      return false
    }
    return true
  }

  function handleTestConnectionClick (): void {
    if (validateFields() && isTestConnectionButtonEnabled()) {
      handleTestConnection()
      setShowNotification(true)
    }
  }

  function handleUpdateClick (): void {
    setconfirm(true)
  }

  const handleCloseNotification = (): void => {
    setShowNotification(false)
  }

  useEffect(() => {
    if (props.TestConnectionData.httpStatus === 200) {
      setTestRequired(false)
    }
  }, [props.TestConnectionData])

  const isUpdateButtonEnabled = (): boolean => {
    const fieldsModified = (
      addState.description !== oldInitialState.description ||
      addState.connectionName !== oldInitialState.connectionName ||
      addState.protocolName !== oldInitialState.protocolName
    )

    const validateUpdateFields = (
      isConnectionNameValid() &&
      isDescriptionValid() &&
      isValidProtocolName()
    )
    const isTestConnectionSuccessful = props.TestConnectionData.httpStatus === 200 && !isFieldsNotEdited() && !testRequired
    const directEdit = fieldsModified && !isTestConnectionButtonEnabled() && validateUpdateFields

    return (
      (
        directEdit || isTestConnectionSuccessful
      )
    )
  }

  const isTestConnectionButtonEnabled = (): boolean => {
    return (
      addState.protocolTypeId !== oldInitialState.protocolTypeId ||
      addState.url !== oldInitialState.url ||
      addState.fileType !== oldInitialState.fileType ||
      addState.ipAddress !== oldInitialState.ipAddress ||
      addState.port !== oldInitialState.port ||
      addState.userName !== oldInitialState.userName ||
      addState.folderPath !== oldInitialState.folderPath ||
      addState.sshKey !== oldInitialState.sshKey ||
      addState.password !== oldInitialState.password ||
      addState.publicKey !== oldInitialState.publicKey ||
      isSftpAuthenticationModified ||
      addState.apiToken !== oldInitialState.apiToken ||
      selectedFileType !== oldInitialState.fileType
    )
  }

  const validateFields = (): boolean => {
    const currentProtocol = selectProtocolOptions.find((val) => val.value === addState.protocolTypeId)

    if (!isConnectionNameValid()) {
      setReqError(true)
      return false
    }

    if (!isDescriptionValid()) {
      setReqError(true)
      return false
    }

    if (addState.protocolName === '' || !isValidNameEdit(addState.protocolName) || !isValidlength(addState.protocolName)) {
      setReqError(true)
      return false
    }

    if (addState.protocolTypeId === '') {
      setReqError(true)
      return false
    }

    if (selectedFileType === 'Encrypted') {
      if ((addState.publicKey !== oldInitialState.publicKey && PublicKeystate === '') || (addState.publicKey === oldInitialState.publicKey && PublicKeystate === '' && addState.publicKey === '')) {
        setReqError(true)
        return false
      }
    }

    if (currentProtocol?.label === 'SFTP') {
      if (addState.ipAddress === '' || !isValidIp(addState.ipAddress)) {
        setReqError(true)
        return false
      }
      if (addState.port === '' || !isValidPort(addState.port)) {
        setReqError(true)
        return false
      }
      if ((addState.userName !== oldInitialState.userName) && (userName === '' || !isValidName(userName) || !isValidUserNameLength(userName))) {
        setReqError(true)
        return false
      }
      if (addState.folderPath === '' || !isValidFolderPath(addState.folderPath)) {
        setReqError(true)
        return false
      }
      if (sftpAuthentication === 'password') {
        if (password === '') {
          setReqError(true)
          return false
        }
        if (addState.password !== oldInitialState.password && (password === '' || !isValidPasswordlength(password))) {
          setReqError(true)
          return false
        }
      }
      if (sftpAuthentication === 'ssh') {
        if (addState.sshKey !== oldInitialState.sshKey && addState.sshKey === '') {
          setReqError(true)
          return false
        }
      }
    }
    if (currentProtocol?.label === 'HTTPS') {
      if (((addState.url !== oldInitialState.url) && (!url?.includes('{token}'))) || (oldInitialState.url === addState.url && !addState.url)) {
        setReqError(true)
        return false
      }
      if (!addState.apiToken) {
        setReqError(true)
        return false
      }
    }
    setReqError(false)
    return true
  }

  const isValidPathlength = (value: string): boolean => {
    return value.length > 3 && value.length < 500
  }

  const isFieldsNotEdited = (): boolean => {
    return (JSON.stringify(oldInitialState) === JSON.stringify(addState))
  }

  const handleApiToken = (value: string): void => {
    setApiTokenPlaceholder('Enter API Token')
    setApiTokenMaskValue(value.replace(/./g, '*'))
    setApiToken(value)
    dispatch({ ...addState, apiToken: btoa(value) })
  }

  const handleFileTypeClick = (value: 'Zipped' | 'Encrypted'): void => {
    setTestRequired(true)
    if (value === 'Zipped' && selectedFileType === 'Zipped') {
      setSelectedFileType('')
    } else if (value === 'Encrypted' && selectedFileType === 'Encrypted') {
      setSelectedFileType('')
    }
    setPublicHKeystate('')
    setPublicKeyMaskValue('')
    dispatch({ ...addState, publicKey: '', fileType: '' })
  }

  const handleProtocolFields = (): JSX.Element => {
    if (selectProtocol?.label === 'SFTP') {
      return (
        <>
          <div className="connection-grid-field">
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.protocolName}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <TextField
              id="configuration-Pname"
              variant="outlined"
              data-testId="configuration-Pname"
              placeholder="Enter Protocol Name"
              InputLabelProps={{
                disabled: true
              }}
              value={protocolName}
              onChange={(e) => {
                handleProtocolName(e.target.value)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {addState.protocolName !== '' &&
              !isValidNameEdit(addState.protocolName) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPButtonStrings.enterValidText}
                  </span>
                </div>
            )}
            {reqError && !addState.protocolName && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {addState.protocolName !== '' &&
              !isValidlength(addState.protocolName) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPButtonStrings.enterMoreText}
                  </span>
                </div>
            )}
          </div>
          <div className="connection-grid-field">
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.SFTPIPAddress}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <TextField
              id="configuration-Ip"
              variant="outlined"
              data-testId="configuration-Ip"
              placeholder="Enter IP Address"
              InputLabelProps={{
                disabled: true
              }}
              value={ipAddress}
              onChange={(e) => {
                handleIPAddress(e.target.value)
                setTestRequired(true)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {reqError && !addState.ipAddress && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {addState.ipAddress !== '' &&
              !isValidIp(addState.ipAddress) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPStrings.ValidIP}
                  </span>
                </div>
            )}
          </div>
          <div className="connection-grid-field">
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.SFTPPort}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <TextField
              id="configuration-port"
              variant="outlined"
              data-testId="configuration-port"
              placeholder="Enter Port"
              InputLabelProps={{
                disabled: true
              }}
              inputProps={{ maxLength: 5 }}
              value={port}
              onChange={(e) => {
                handlePortNumber(e.target.value)
                setTestRequired(true)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {reqError && !addState.port && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {addState.port !== '' && !isValidPort(addState.port) && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPStrings.ValidPort}
                </span>
              </div>
            )}
          </div>
          <div className="connection-grid-field">
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.username}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <TextField
              id="configuration-username"
              variant="outlined"
              data-testId="configuration-username"
              placeholder={userNamePlaceholder}
              InputLabelProps={{
                disabled: true
              }}
              value={userName}
              onChange={(e) => {
                handleUserName(e.target.value)
                setTestRequired(true)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {reqError && !userName && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {userName !== '' && !isValidUserNameLength(userName) && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.username_validate_msg}
                </span>
              </div>
            )}
            {userName !== '' && !isValidName(userName) && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.enterValidText}
                </span>
              </div>
            )}
          </div>
          <div className="connection-grid-field">
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.folderPath}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <TextField
              id="configuration-Folder-Path"
              variant="outlined"
              data-testId="configuration-Folder-Path"
              placeholder="Enter Folder Path"
              InputLabelProps={{
                disabled: true
              }}
              value={folderPath}
              onChange={(e) => {
                handleFolderPath(e.target.value)
                setTestRequired(true)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {reqError && !addState.folderPath && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {addState.folderPath !== '' &&
              !isValidFolderPath(addState.folderPath) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPStrings.folderPath_error_msg}
                  </span>
                </div>
            )}
            {addState.folderPath !== '' &&
              !isValidPathlength(addState.folderPath) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPStrings.FolderPath_validate_msg}
                  </span>
                </div>
            )}
          </div>
        </>
      )
    } else if (selectProtocol?.label === 'HTTPS') {
      return (
        <>
          <div className="connection-grid-field">
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.protocolName}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <TextField
              id="configuration-Pname"
              variant="outlined"
              data-testId="configuration-Pname"
              placeholder="Enter Protocol Name"
              InputLabelProps={{
                disabled: true
              }}
              value={protocolName}
              onChange={(e) => {
                handleProtocolName(e.target.value)
              }}
              size="small"
              autoComplete="off"
              className="configuration-name-class"
              sx={{
                width: '270px',
                color: '#fffff !important'
              }}
            />
            {addState.protocolName !== '' &&
              !isValidNameEdit(addState.protocolName) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPButtonStrings.enterValidText}
                  </span>
                </div>
            )}
            {reqError && !addState.protocolName && (
              <div className="error-response">
                <span className="error-upload-message">
                  {CreateSFTPButtonStrings.required}
                </span>
              </div>
            )}
            {addState.protocolName !== '' &&
              !isValidlength(addState.protocolName) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPButtonStrings.enterMoreText}
                  </span>
                </div>
            )}
          </div>
        </>
      )
    } else {
      return <></>
    }
  }

  const handleURL = (): JSX.Element => {
    if (selectProtocol?.label === 'HTTPS') {
      return (
        <>
          <Grid>
            <span className='connection-setting-field-title'>
              URL<sup style={{ color: '#EE3426' }}>*</sup>
              <HtmlTooltip
                className="more-info-tooltip"
                title='Enter url with a placeholder for token as {token}, For Example: https://example.com/upload?token={token} or https://example.com/upload?auth={token}'
                placement="top"
                arrow
              >
                <IconButton>
                  <span className="more-info">
                    <img src={MoreInfo} height={20} width={20} />
                  </span>
                </IconButton>
              </HtmlTooltip>
            </span>
            <div>
              <GenericTextarea
                placeholder={urlPlaceholder}
                sx={{ width: 400, height: 60 }}
                value={url}
                onChange={(e) => {
                  handleUrl(e.target.value)
                  setTestRequired(true)
                }} />
              {
                reqError && selectProtocol?.label === 'HTTPS' && (!url || !addState.url) && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      {CreateSFTPButtonStrings.required}
                    </span>
                  </div>
                )
              }
              {
                selectProtocol?.label === 'HTTPS' && (oldInitialState.url !== addState.url && url !== '' && !url.includes('{token}')) && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      URL should contain {'{token}'}
                    </span>
                  </div>
                )
              }
            </div>
          </Grid>
        </>
      )
    } else {
      return <></>
    }
  }

  const handleAuthenticationFields = (): JSX.Element => {
    if (selectProtocol?.label === 'SFTP') {
      return (
        <>
          <Grid container spacing={1}>
            <div className="connection-grid-field">
              <span
                className='connection-setting-field-title'
              >
                {CreateSFTPStrings.Authentication}
                <sup style={{ color: '#EE3426' }}>*</sup>
              </span>
              <div className="radio-box">
                <Radio
                  checked={sftpAuthentication === 'ssh'}
                  onChange={handleAuthenticationChange}
                  value="ssh"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'A' }}
                  style={{ color: '#ffffff', paddingLeft: 0 }}
                />
              </div>
              <span
                style={{
                  fontSize: '15px',
                  padding: 10,
                  display: 'block',
                  fontStyle: 'italic'
                }}
              >
                {CreateSFTPStrings.SSH_Key_title}
              </span>
              <TextareaAutosize
                id="configuration-SSHkey"
                data-testId="configuration-SSHkey"
                placeholder={sshKeyPlaceholder}
                disabled={sftpAuthentication === 'password'}
                value={displayMaskValue}
                onChange={(e) => {
                  handleSSHkey(e.target.value)
                  setTestRequired(true)
                }}
                autoComplete="off"
                className="configuration-name-class"
                style={{
                  backgroundColor: '#272727',
                  color: '#ffffff',
                  border: '1px solid #ffffff54',
                  borderRadius: '4px',
                  padding: '8px',
                  width: '100%',
                  minHeight: '40px',
                  height: '40px'
                }}
              />
              {reqError && SSHKey === '' && sftpAuthentication === 'ssh' && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPButtonStrings.required}
                  </span>
                </div>
              )}
            </div>
            <div
              className="connection-grid-field"
              style={{ marginTop: '40px' }}
            >
              <div className="radio-box">
                <Radio
                  checked={sftpAuthentication === 'password'}
                  onChange={handleAuthenticationChange}
                  data-testid='sftp_auth'
                  value="password"
                  name="radio-buttons"
                  inputProps={{ 'aria-label': 'B' }}
                  style={{ color: '#ffffff', paddingLeft: 0 }}
                />
              </div>
              <span
                style={{
                  fontSize: '15px',
                  padding: 10,
                  display: 'block',
                  fontStyle: 'italic'
                }}
              >
                {CreateSFTPStrings.Password_title}
              </span>
              <TextField
                id="configuration-password"
                variant="outlined"
                data-testId="configuration-password"
                placeholder={passwordPlaceholder}
                type="password"
                disabled={sftpAuthentication === 'ssh'}
                InputLabelProps={{
                  disabled: true
                }}
                value={password}
                onChange={(e) => {
                  handlePassword(e.target.value)
                  setTestRequired(true)
                }}
                size="small"
                autoComplete="off"
                className="configuration-name-class"
                sx={{
                  width: '270px',
                  color: '#fffff !important'
                }}
              />
              {
                reqError && password === '' && sftpAuthentication === 'password' && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      {CreateSFTPButtonStrings.required}
                    </span>
                  </div>
                )
              }
              {password !== '' && !isValidPasswordlength(password) && (
                <div className="error-response">
                  <span className="error-upload-message">
                    {CreateSFTPStrings.PasswordError}
                  </span>
                </div>
              )}
            </div>
          </Grid>
        </>
      )
    } else if (selectProtocol?.label === 'HTTPS') {
      return (
        <>
          <Grid item md={3}>
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.Authentication}<sup style={{ color: '#EE3426' }}>*</sup>
            </span>
            <span className='connection-setting-field-title'>
              {CreateSFTPStrings.api_token}
            </span>
            <div>
              <GenericTextarea
                placeholder={apiTokenPlaceholder}
                sx={{ width: 400, height: 100 }}
                value={apiTokenMaskValue}
                onChange={(e) => {
                  handleApiToken(e.target.value)
                  setTestRequired(true)
                }} />
              {
                reqError && selectProtocol?.label === 'HTTPS' && !apiToken && (
                  <div className="error-response">
                    <span className="error-upload-message">
                      {CreateSFTPButtonStrings.required}
                    </span>
                  </div>
                )
              }
            </div>
          </Grid>
        </>
      )
    } else {
      return <></>
    }
  }

  const handleEditProtoCol = (): JSX.Element => {
    if (ProtocolDataResponsestate !== undefined && ProtocolDataResponsestate.httpStatus === 200) {
      return (
        <>
          <Box sx={style} className="add-configuration-container">
            <Typography
              color="inherit"
              className='connection-setting-card-heading'
              sx={{
                fontSize: '16px',
                textTransform: 'uppercase',
                fontFamily: 'Honeywell Sans Web'
              }}
            >
              {CreateSFTPStrings.configurationSpecifications}
            </Typography>
            <CardContent className='connection-setting-card-content'>
              <Grid container spacing={1}>
                <div className="connection-grid-field">
                  <span
                    className='connection-setting-field-title'
                  >
                    {CreateSFTPStrings.name}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <TextField
                    id="configuration-name"
                    variant="outlined"
                    data-testId="configuration-name"
                    placeholder="Enter Configuration Name"
                    InputLabelProps={{
                      disabled: true
                    }}
                    value={protocolSpecificationName}
                    onChange={(e) => {
                      handleConnectionName(e.target.value)
                    }}
                    size="small"
                    autoComplete="off"
                    className="configuration-name-class"
                    sx={{
                      width: '270px',
                      color: '#fffff !important'
                    }}
                  />
                  {addState.connectionName !== '' &&
                    !isValidNameEdit(addState.connectionName) && (
                      <div className="error-response">
                        <span className="error-upload-message">
                          {CreateSFTPButtonStrings.enterValidText}
                        </span>
                      </div>
                  )}
                  {reqError && !addState.connectionName && (
                    <div className="error-response">
                      <span className="error-upload-message">
                        {CreateSFTPButtonStrings.required}
                      </span>
                    </div>
                  )}
                  {addState.connectionName !== '' &&
                    !isValidlength(addState.connectionName) && (
                      <div className="error-response">
                        <span className="error-upload-message">
                          {CreateSFTPButtonStrings.enterMoreText}
                        </span>
                      </div>
                  )}
                </div>
                <div className="connection-grid-field">
                  <span
                    style={{ fontSize: '15px', padding: '10px 10px 10px 0px', display: 'block' }}
                  >
                    {CreateSFTPStrings.description}
                  </span>
                  <TextField
                    id="configuration-desc"
                    variant="outlined"
                    data-testid='description'
                    data-testId="configuration-desc"
                    placeholder="Enter Description"
                    InputLabelProps={{
                      disabled: true
                    }}
                    value={description}
                    onChange={(e) => {
                      handleDescription(e.target.value)
                    }}
                    size="small"
                    autoComplete="off"
                    className="configuration-name-class"
                    sx={{
                      width: '270px',
                      color: '#fffff !important'
                    }}
                  />
                  {addState.description !== '' &&
                    !isValidText(addState.description) && (
                      <div className="error-response">
                        <span className="error-upload-message">
                          {CreateSFTPButtonStrings.enterValidDescription}
                        </span>
                      </div>
                  )}
                  {addState.description !== '' &&
                    !isValidDescLength(addState.description) && (
                      <div className="error-response">
                        <span className="error-upload-message">
                          {CreateSFTPButtonStrings.descriptionError}
                        </span>
                      </div>
                  )}
                </div>
              </Grid>
            </CardContent>
          </Box>
          <Box sx={style} className="add-configuration-container">
            <Typography
              color="inherit"
              className='connection-setting-card-heading'
              sx={{
                fontSize: '16px',
                textTransform: 'uppercase',
                fontFamily: 'Honeywell Sans Web'
              }}
            >
              {CreateSFTPStrings.connectionSettings}
            </Typography>
            <CardContent className='connection-setting-card-content'>
              <Grid container spacing={1}>
                <div className="connection-grid-field">
                  <span
                    className='connection-setting-field-title'
                  >
                    {CreateSFTPStrings.protocol}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <GenXDropdown
                    placeholder="Select Protocol"
                    data-testid='protocol'
                    value={selectProtocol}
                    options={selectProtocolOptions}
                    onSelect={handleProtocol}
                    width={270}
                  />
                  {reqError && !addState.protocolTypeId && (
                    <div className="error-response">
                      <span className="error-upload-message">
                        {CreateSFTPButtonStrings.required}
                      </span>
                    </div>
                  )}
                </div>
                {handleProtocolFields()}
              </Grid>
              <Grid>
                {handleURL()}
              </Grid>
            </CardContent>
            <CardContent className='connection-setting-card-content'>
              {handleAuthenticationFields()}
            </CardContent>
            <CardContent className='connection-setting-card-content'>
              <Grid container spacing={1}>
                <div>
                  <div style={{ fontSize: '15px', padding: 10 }}>
                    {CreateSFTPStrings.file_type}
                    <span style={{ color: '#B0B0B0' }}>(Optional)</span>
                  </div>
                  <div className="radio-box">
                    <Radio
                      onClick={() => {
                        handleFileTypeClick('Zipped')
                      }}
                      checked={selectedFileType === 'Zipped'}
                      onChange={handleFileTypeSelection}
                      value="Zipped"
                      data-testid='zipped'
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'Zipped' }}
                      style={{ color: '#ffffff' }}
                      unselectable="on"
                    />
                  </div>
                  <span
                    style={{
                      fontSize: '15px',
                      padding: 10,
                      display: 'block',
                      fontStyle: 'italic'
                    }}
                  >
                    {CreateSFTPStrings.zipped}
                  </span>
                </div>
                <div style={{ marginTop: '40px' }}>
                  <div className="radio-box">
                    <Radio
                      checked={selectedFileType === 'Encrypted'}
                      data-testid='filetype'
                      onClick={() => {
                        handleFileTypeClick('Encrypted')
                      }}
                      onChange={handleFileTypeSelection}
                      value="Encrypted"
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'Encrypted' }}
                      style={{ color: '#ffffff' }}
                      unselectable="on"
                    />
                  </div>
                  <span
                    className='connection-setting-field-title'
                  >
                    {CreateSFTPStrings.encrypted}
                    <HtmlTooltip
                      className="more-info-tooltip"
                      title="Enter Public Key"
                      placement="top"
                      arrow
                    >
                      <IconButton>
                        <span className="more-info">
                          <img src={MoreInfo} height={20} width={20} />
                        </span>
                      </IconButton>
                    </HtmlTooltip>
                  </span>
                  <div style={{ paddingLeft: 40 }}>
                    <TextareaAutosize
                      style={{
                        backgroundColor: '#272727',
                        color: '#ffffff',
                        border: '1px solid #ffffff54',
                        borderRadius: '4px',
                        padding: '8px',
                        width: '100%',
                        minHeight: '90px',
                        height: '90px'
                      }}
                      data-testid='filetype_encrypted'
                      value={PublicKeyMaskValue}
                      disabled={selectedFileType !== 'Encrypted'}
                      placeholder={fileTypePlaceholder}
                      onChange={(e) => {
                        handlePublicKey(e.target.value)
                        setTestRequired(true)
                      }}
                    />
                    <div><span>gpg only</span></div>
                    {reqError && !addState.publicKey && selectedFileType === 'Encrypted' && (
                    <div className="error-response">
                      <span className="error-upload-message">
                        {CreateSFTPButtonStrings.required}
                      </span>
                    </div>
                    )}
                  </div>
                </div>
              </Grid>
            </CardContent>
          </Box>
          <Box sx={style} className="add-configuration-container">
            <Grid container sx={{ display: 'flex', alignItems: 'center' }} spacing={1}>
              <Grid style={{ textAlign: 'left' }} item md={6}>
                <Button
                  type="borderless"
                  data-testid="cancel-btn"
                  onClick={() => {
                    navigate('/configuration-protocol')
                    clearAllDetails()
                  }}
                >
                  {CreateSFTPButtonStrings.CancelBtn}
                </Button>
              </Grid>
              <Grid style={{ textAlign: 'right' }} item md={6}>
                <Button
                  type='hollow'
                  width='164px'
                  dataTestId='testconn_btn'
                  primaryColor={isTestConnectionButtonEnabled() ? undefined : '#B0B0B0'}
                  disabled={!isTestConnectionButtonEnabled()}
                  onClick={() => {
                    handleTestConnectionClick()
                  }}
                  >
                    {CreateSFTPStrings.test_connection}
                  </Button>
                  <Button
                    onClick={() => {
                      handleUpdateClick()
                    }}
                    dataTestId='updatebtn'
                    primaryColor={isUpdateButtonEnabled() ? undefined : '#B0B0B0'}
                    disabled={!isUpdateButtonEnabled()}
                  >
                    {CreateSFTPStrings.Update_btn}
                  </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )
    } else if (ProtocolDataResponsestate.isLoading) {
      return (
      <div className="CircularprogressAmi">
        <CircularProgress />
      </div>
      )
    } else if (ProtocolDataResponsestate.httpStatus === 401) {
      return (
        <div className='authError'><AuthError errorMessage="Unauthorized" /></div>
      )
    } else if (ProtocolDataResponsestate.httpStatus === 403) {
      return (
        <div className='authError'><AuthError errorMessage="accessForbidden" /></div>
      )
    } else {
      return (
        <div className='authError'><AuthError errorMessage="cannotFetch" retry={props.getConfigurationDropdownMetadata} /></div>
      )
    }
  }

  return (
    <>
      <div
        role="presentation"
        data-testid="breadcrumbs"
        style={{ width: '100%' }}
      >
        <Breadcrumbs
          aria-name="breadcrumb"
          sx={{
            color: '#f0f0f0',
            fontSize: '12px',
            marginBottom: '14px',
            fontFamily: 'Honeywell Sans Web',
            marginTop: '12px'
          }}
        >
          <Link
            underline="hover"
            color="inherit"
            data-testid="scheduler-breadcrumb"
            onClick={() => {
              navigate('/configuration-dashboard')
            }}
          >
            {CreateSFTPStrings.dataPublisherSettings}
          </Link>
          <Link
            underline="hover"
            color="inherit"
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
            onClick={() => {
              navigate('/configuration-protocol')
            }}
          >
            {CreateSFTPStrings.configurationProtocol}
          </Link>
          <Typography
            color="inherit"
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
          >
            {CreateSFTPStrings.UpdateCreateTitle}
          </Typography>
        </Breadcrumbs>
      </div>
      <div style={{ display: 'flex' }}>
        <ArrowBackIos
          sx={{ cursor: 'pointer' }}
          data-testid="back-arrow"
            onClick={() => {
              navigate('/configuration-protocol')
            }}
        />
        <div style={{ marginBottom: '32px' }}>
          <span
            data-testid="schedule-title"
            style={{
              color: '#f0f0f0',
              fontSize: '24px',
              fontWeight: '900',
              paddingRight: '16px',
              marginRight: '10px'
            }}
          >
           {CreateSFTPStrings.UpdateCreateTitle}
          </span>
        </div>
      </div>
      {handleEditProtoCol()}
      {confirm && (
        <Modal
          titleStyle={{ paddingRight: '2em', marginBottom: '20px' }}
          buttonTypeNegative="hollow"
          date-testid='edit_button'
          LabelPositive={CreateSFTPButtonStrings.ConfirmBtn}
          LabelNegative={CreateSFTPButtonStrings.CancelBtn}
          onClickPositive={() => {
            handleconfirmClick()
          }}
          onClose={() => {
            setconfirm(false)
          }}
          onClickNegative={() => {
            setconfirm(false)
          }}
          title={CreateSFTPStrings.Update_Configuration_Protocol}
        >
          <div className="confirm-msg" style={{ marginBottom: '30px' }}>
            <span>{'Do you want to update Connection Setting?'}</span>
          </div>
        </Modal>
      )}
      {showSuccessPopUp && handleCreateConfigurationWithFailure()}
      {showNotification && (
        <Notification data-testid='notification' containerStyle={{ position: 'fixed', bottom: '170px', right: '32px' }} httpStatus={props.TestConnectionData.httpStatus ?? 0} message={props.TestConnectionData.message ?? ''} loading={props.TestConnectionData.isLoading} onClose={handleCloseNotification} />
      )}
    </>
  )
}

interface DispatchToProps {
  updateConfigurationProtocolservice: (protocolId: string, UpdateConfigirationFetchRequest: SFTPConfigurationData) => void
  testConnectionService: (testConnectionPostRequest: SFTPConfigurationData) => void
  getConfigurationDropdownMetadata: () => void
  getPublicKeyData: () => void
  getProtocolDetailsById: (protocolId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  updateConfigurationProtocolservice: (protocolId: string, createReportSchedulerTemplatePostRequest: SFTPConfigurationData) => dispatch(updateConfigurationProtocolservice(protocolId, createReportSchedulerTemplatePostRequest)),
  testConnectionService: (testConnectionPostRequest: SFTPConfigurationData) => dispatch(TestConnectionService(testConnectionPostRequest)),
  getConfigurationDropdownMetadata: () => dispatch(getConfigurationDropdownMetadata()),
  getPublicKeyData: () => dispatch(getPublicKeyData()),
  getProtocolDetailsById: (protocolId: string) => dispatch(getProtocolDetailsById(protocolId))
})

interface StateToProps {
  ConfigurationUpdateData: updateConfigurationProtocolState
  TestConnectionData: testConnectionState
  ConfigurationMetadata: ConfigurationMetadataDropDownState
  PublicKeyData: PublicKeyDataState
  ProtocolDetailsData: ProtocolsDetailsState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  ConfigurationUpdateData: state.UpateProtocolConfigurationResponce,
  TestConnectionData: state.testConnectionData,
  ConfigurationMetadata: state.ConfigurationMetadata,
  PublicKeyData: state.PublicKeyData,
  ProtocolDetailsData: state.ProtocolDetailsData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EditConfigurationProtocol)
