import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface TemplateDetailsState {
  template_id: string
  template_name: string
}

const initialState: TemplateDetailsState = {
  template_id: '',
  template_name: ''
}

export const selectedTemplateDetailsSlice = createSlice({
  name: 'selectedTemplateDetailsSlice',
  initialState,
  reducers: {
    changeTemplate: (state, action: PayloadAction<TemplateDetailsState>) => {
      return action.payload
    }
  }
})

export const { changeTemplate } = selectedTemplateDetailsSlice.actions
export default selectedTemplateDetailsSlice.reducer
