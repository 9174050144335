import interceptedAxios from '../../../utils/interceptedAxios'
import { type KpiPreferenceObject, ZKpiPreferenceApiResponse } from '../types'

export async function getKpiUserPreferenceService (
  emailId: string,
  onSuccess: (dataObj: KpiPreferenceObject, httpStatus: number) => any,
  onFailure: (error_message: string, httpStatus: number) => any
): Promise<void> {
  const url = `/app-config-service/api/v1/udp/dashboard-preferences/get-preference?userId=${emailId}`

  interceptedAxios
    .post(url)
    .then((response) => {
      console.log('response', response)
      const parseResponse = ZKpiPreferenceApiResponse.safeParse(response.data)
      console.log('parseResponse', parseResponse)
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (parseResponse.success) {
        if (parseResponse.data.data !== undefined) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        }
      } else {
        onFailure('Parsing Error', response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        const parseResponse = ZKpiPreferenceApiResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
