import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
export interface updateConfigurationProtocolState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
}

const initialState: updateConfigurationProtocolState = {
  isLoading: false,
  error: undefined,
  httpStatus: undefined
}

const updateConfigurationProtocolSlice = createSlice({
  name: 'UpdateSFTPConfiguration-Create',
  initialState,
  reducers: {
    updateConfigurationProtocolLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0
      }
    },
    updateConfigurationProtocolUpdateAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload
      }
    },
    updateConfigurationProtocolErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  updateConfigurationProtocolLoadingAction,
  updateConfigurationProtocolUpdateAction,
  updateConfigurationProtocolErrorAction
} = updateConfigurationProtocolSlice.actions

export default updateConfigurationProtocolSlice.reducer
