import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type CreateSchedulerReportDropDownData } from '../services/getAllCreateSchdulerDropdown'

export interface CreateSchedulerDropDownState {
  isLoading: boolean
  loadingError: string | undefined
  schdulerDropDownListData: CreateSchedulerReportDropDownData | undefined
  httpStatus: number | undefined
}

// Initial state of the slice
const initialState: CreateSchedulerDropDownState = {
  isLoading: true,
  loadingError: undefined,
  schdulerDropDownListData: undefined,
  httpStatus: undefined
}

// Slice to return from action
export const SchdulerDropDownListingDataState = createSlice({
  name: 'SchdulerListData',
  initialState,
  reducers: {
    SchdulerDropDownLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: undefined,
        schdulerDropDownListData: undefined,
        httpStatus: 0
      }
    },
    SchdulerDropDownUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: undefined,
        schdulerDropDownListData: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    SchdulerDropDownErrorAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        httpStatus: action.payload.httpStatus,
        schdulerDropDownListData: undefined
      }
    }
  }
})

// Actions to export
export const {
  SchdulerDropDownLoadingAction,
  SchdulerDropDownUpdateAction,
  SchdulerDropDownErrorAction
} = SchdulerDropDownListingDataState.actions

export default SchdulerDropDownListingDataState.reducer
