import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import { Breadcrumbs, CircularProgress, Link, Typography } from '@mui/material'
import LanguageStrings from '../../../../i18n/locales'
import React from 'react'
import { connect, type ConnectedProps, useSelector } from 'react-redux'
import { getConfigurationListDetails, getProtocolDetailsById } from '../../redux/actionCreators'
import { useNavigate } from 'react-router-dom'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import ConfigurationDetails, { type Sort } from './ConfigurationProtocolItem'
import { Button } from '../../../../components/Button'
import { type ConfigurationListDataState } from '../../redux/getConfigurationProtocolListSlice'
import { type ProtocolsDetailsState } from '../../redux/getProtocolDetailsByIdSlice'
import { type RootState } from '../../../../store'

const CreateSFTPStrings = LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings.SchedulerStrings.CreateSFTPFormStrings

const style = {
  width: '100%',
  background: '#272727',
  boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '16px',
  marginBottom: '16px',
  padding: '24px'
}

function EnhancedTableToolbar (props: any): JSX.Element {
  const { numSelected } = props
  return (

        <div>
            {numSelected > 0
              ? (
                <Typography
                    sx={{ flex: '1 1 100%', paddingLeft: '16px', fontFamily: 'Honeywell Sans Web' }}
                    color='inherit'
                    variant='subtitle1'
                    component='div'
                >
                    {numSelected} {CreateSFTPStrings.selected}
                </Typography>
                )
              : (
                <div className='configurations-table-heading' style={{ width: '100%' }}>
                    <h5>{CreateSFTPStrings.connectionSettingList}<span className='config-list-box'>{props.deviceCount} {CreateSFTPStrings.connectionSetting}</span></h5>
                </div>
                )}
        </div>
  )
}

const ConfigurationProtocol = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'modifiedAt' })
  const [selected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState<number>(1)
  const [rowsPerPage] = React.useState(10)
  const configurationList = useSelector(
    (state: RootState) => state.ConfigurationListData.ConfigurationListData
  )
  const configurationListStatus = useSelector(
    (state: RootState) => state.ConfigurationListData
  )

  React.useEffect(() => {
    props.getConfigurationListDetails(page - 1, rowsPerPage, sort.order, sort.orderBy)
  }, [page, sort])

  const handleFetchSchedulerList = (): void => {
    props.getConfigurationListDetails(page - 1, rowsPerPage, sort.order, sort.orderBy)
  }

  const createConfiguration = (): void => {
    navigate('/create-sftp-configuration')
  }

  const handleEditClick = (protocolTypeName: string, protocolId: string): void => {
    sessionStorage.setItem('protocolId', protocolId)
    navigate('/edit-configuration-protocol')
  }

  const handleConfigurationDetails = (): JSX.Element => {
    if (configurationList !== undefined && configurationListStatus.httpStatus === 200) {
      return (
        <ConfigurationDetails
          style={style}
          configurationList={configurationList.map(item => ({
            modifiedAt: item.modifiedAt,
            connectionName: item.connectionName,
            description: item.description,
            protocolTypeName: item.protocolTypeName,
            protocolName: item.protocolName,
            protocolId: item.protocolId
          }))}
          count={props.ConfigurationListData.totalCount}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          sort={sort}
          setSort={setSort}
          handleEditClick={(protocolTypeName, protocolId) => { handleEditClick(protocolTypeName, protocolId) }} // Pass both arguments
        />
      )
    } else if (configurationListStatus.isLoading) {
      return (
        <div className="CircularprogressAmi">
          <CircularProgress />
        </div>
      )
    } else if (configurationListStatus.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (configurationListStatus.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else if (configurationListStatus.httpStatus === 404) {
      return (
        <div className="authError">
          <AuthError errorMessage="NoDataPresent" />
        </div>
      )
    } else {
      return (
        <div className="authError">
          <AuthError
            errorMessage="cannotFetch"
            retry={handleFetchSchedulerList}
          />
        </div>
      )
    }
  }

  return (
    <>
      <div
        role="presentation"
        data-testid="breadcrumbs"
        style={{ width: '100%' }}
      >
        <Breadcrumbs
          aria-name="breadcrumb"
          sx={{
            color: '#f0f0f0',
            fontSize: '12px',
            marginBottom: '14px',
            fontFamily: 'Honeywell Sans Web',
            marginTop: '12px'
          }}
        >
          <Link
            underline="hover"
            color="inherit"
            data-testid="scheduler-breadcrumb"
            onClick={() => {
              navigate('/configuration-dashboard')
            }}
          >
            {CreateSFTPStrings.dataPublisherSettings}
          </Link>
          <Typography
            color="inherit"
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
          >
            {CreateSFTPStrings.configurationProtocol}
          </Typography>
        </Breadcrumbs>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ArrowBackIos
            sx={{ cursor: 'pointer' }}
            data-testid="back-arrow"
            onClick={() => {
              navigate('/configuration-dashboard')
            }}
          />
          <span
            data-testid="schedule-title"
            style={{
              color: '#f0f0f0',
              fontSize: '24px',
              fontWeight: '900',
              paddingRight: '16px',
              marginRight: '10px'
            }}
          >
            {CreateSFTPStrings.configurationProtocol}
          </span>
        </div>
        <Button
          sx={{ marginRight: '1em' }}
          width='fit-content'
          onClick={createConfiguration}
          dataTestId='add-configuration-btn'
        >
          {CreateSFTPStrings.newConnection}
        </Button>
      </div>
      <div>
      <EnhancedTableToolbar numSelected={selected.length}
                          setSort={setSort}
                          deviceCount={props.ConfigurationListData.totalCount}
                      />
      </div>
      {handleConfigurationDetails()}
    </>
  )
}

interface DispatchToProps {
  getConfigurationListDetails: (page: number, size: number, sortdir: string, sortfield: string) => void
  getProtocolDetailsById: (protocolId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getConfigurationListDetails: (page: number, size: number, sortdir: string, sortfield: string) => dispatch(getConfigurationListDetails(page, size, sortdir, sortfield)),
  getProtocolDetailsById: (protocolId: string) => dispatch(getProtocolDetailsById(protocolId))
})

interface StateToProps {
  ConfigurationListData: ConfigurationListDataState
  ProtocolDetailsData: ProtocolsDetailsState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  ConfigurationListData: state.ConfigurationListData,
  ProtocolDetailsData: state.ProtocolDetailsData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ConfigurationProtocol)
