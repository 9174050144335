/* eslint-disable @typescript-eslint/no-floating-promises */
// istanbul ignore file
import { exportADSKpiDetailsErrorAction, exportADSKpiDetailsLoadingAction, exportADSKpiDetailsUpdateAction } from './exportADSKpiDetailsSlice'
import { exportARKpiDetailsErrorAction, exportARKpiDetailsLoadingAction, exportARKpiDetailsUpdateAction } from './exportARKpiDetailsSlice'
import { exportASKpiDetailsErrorAction, exportASKpiDetailsLoadingAction, exportASKpiDetailsUpdateAction } from './exportASKpiDetailsSlice'
import { exportDonutKpiDetailsErrorAction, exportDonutKpiDetailsLoadingAction, exportDonutKpiDetailsUpdateAction } from './exportDonutKpiDetailsSlice'
import * as KpiHistoryService from '../services/historyTableService'

export const exportARKpiHistoryTable = (
  kpiType: string,
  inputDateRead: string,
  page: number,
  size: number, service: string) => async (dispatch: any) => {
  dispatch(exportARKpiDetailsLoadingAction())
  KpiHistoryService.getHistoryARTable(
    kpiType,
    inputDateRead,
    page,
    size,
    service,
    true,
    (data, httpStatus) => {
      dispatch(exportARKpiDetailsUpdateAction({ data, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(exportARKpiDetailsErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const exportASKpiHistoryTable = (
  kpiType: string,
  inputDateRead: string,
  page: number,
  size: number, service: string) => async (dispatch: any) => {
  dispatch(exportASKpiDetailsLoadingAction())
  KpiHistoryService.getHistoryASTable(
    kpiType,
    inputDateRead,
    page,
    size,
    service,
    true,
    (data, httpStatus) => {
      dispatch(exportASKpiDetailsUpdateAction({ data, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(exportASKpiDetailsErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const exportADSKpiHistoryTable = (
  kpiType: string,
  inputDateRead: string,
  page: number,
  size: number, service: string) => async (dispatch: any) => {
  dispatch(exportADSKpiDetailsLoadingAction())
  KpiHistoryService.getHistoryADSTable(
    kpiType,
    inputDateRead,
    page,
    size,
    service,
    true,
    (data, httpStatus) => {
      dispatch(exportADSKpiDetailsUpdateAction({ data, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(exportADSKpiDetailsErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const exportDonutKpiHistoryTable = (
  kpiType: string,
  inputDateRead: string,
  donutType: string,
  page: number,
  size: number, service: string) => async (dispatch: any) => {
  dispatch(exportDonutKpiDetailsLoadingAction())
  KpiHistoryService.getHistoryDonutTable(
    kpiType,
    inputDateRead,
    donutType,
    page,
    size,
    service,
    true,
    (data, httpStatus) => {
      dispatch(exportDonutKpiDetailsUpdateAction({ data, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(exportDonutKpiDetailsErrorAction({ errorMessage, httpStatus }))
    }
  )
}
