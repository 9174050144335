import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
export interface FirmwareEditCampaignRequest {
  firmwareCampaignName: string
  firmwareCampaignId: string
  assetGroupDTOList: string[]
}

const ZFirmwareEditCampaignApiResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  data: z.string(),
  error_message: z.optional(z.string())
})

const ZFirmwareEditCampaignApiErrorResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  error_message: z.optional(z.string())
})

export type FirmwareEditCampaignApiResponse = z.infer<
  typeof ZFirmwareEditCampaignApiResponse
>

export async function putFirmwareEditCampaign (
  editCampaignBody: FirmwareEditCampaignRequest,
  onSuccess: (
    data: string,
    httpStatus: number
  ) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-config-service/api/v1/udp/firmwareCampaign/editFirmwareCampaign/${editCampaignBody.firmwareCampaignId}`
  interceptedAxios
    .put(url, { ...editCampaignBody, status: 'active' }, { timeout: 15000 })
    .then((response) => {
      const parseResponse = ZFirmwareEditCampaignApiResponse.safeParse(
        response.data
      )
      // istanbul ignore else
      if (parseResponse.success) {
        onSuccess(parseResponse.data.data, parseResponse.data.http_status)
      } else {
        onFailure('Error occurred while processing response', response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore next
      if (error.response !== undefined) {
        const parseResponse = ZFirmwareEditCampaignApiErrorResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
