// istanbul ignore file
import React from 'react'
import Modal from '../../../components/Modal'
import { type GroupParaObj } from '../services/firmwareCampaignListService'

export const getGroupTemplateModal = (groups: GroupParaObj[], handleModalClose: () => void): JSX.Element => {
  const tdrStyle: React.CSSProperties = {
    padding: '10px 0'
  }
  return (
    <Modal sx={{ paddingBottom: '10px' }} height='auto' width='600px' title={`Groups (${groups.length})`} onClose={handleModalClose}>
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <table style={{ width: '100%', padding: '10px 5px 0 0', marginTop: '40px' }}>
            <tr>
                <th style={{ margin: '1em 0 0 0', fontSize: '16px', ...tdrStyle, borderBottom: '1px solid #3d3d3d', textAlign: 'center' }} >Group Name</th>
                <th style={{ margin: '1em 0 0 0', fontSize: '16px', ...tdrStyle, borderBottom: '1px solid #3d3d3d', textAlign: 'center' }} >Status</th>
            </tr>
          <tbody>
            {
              groups.length > 0
                ? groups.map((row, index: number) => (
                    <tr key={index}>
                      <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #3d3d3d', textAlign: 'center' }}>{row.group_name}</td>
                      <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #3d3d3d', textAlign: 'center' }}>{row.status}</td>
                    </tr>
                ))
                : ''
            }
          </tbody>
        </table>
      </div>
    </Modal>
  )
}
