import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface EditReportTemplateState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
}

const initialState: EditReportTemplateState = {
  isLoading: false,
  error: undefined,
  httpStatus: undefined
}

const editReportTemplateSlice = createSlice({
  name: 'ReportTemplate-Edit',
  initialState,
  reducers: {
    editReportTemplateLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0
      }
    },
    editReportTemplateUpdateAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload
      }
    },
    editReportTemplateErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  editReportTemplateLoadingAction,
  editReportTemplateUpdateAction,
  editReportTemplateErrorAction
} = editReportTemplateSlice.actions

export default editReportTemplateSlice.reducer
