import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type DonutKpiDataObject, type DonutKpiState } from '../../types'

const initialState: DonutKpiState = {
  isLoading: false,
  error: false,
  data: undefined,
  httpStatus: 0
}

const ecModeDonutSlice = createSlice({
  name: 'ecModeDonut',
  initialState,
  reducers: {
    ecModeDonutLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        data: undefined,
        httpStatus: 0
      }
    },
    ecModeDonutUpdateAction: (state, action: PayloadAction<{ data: DonutKpiDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: false,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    ecModeDonutErrorAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: true,
        data: undefined,
        httpStatus: action.payload
      }
    }
  }
})

export const {
  ecModeDonutLoadingAction,
  ecModeDonutUpdateAction,
  ecModeDonutErrorAction
} = ecModeDonutSlice.actions

export default ecModeDonutSlice.reducer
