// istanbul ignore file
import { type ReportData } from '../features/data-publisher/types'
import './GenXList.css'
import React from 'react'

export interface GenXListItem {
  name: string
  value: string
}

interface GenXListProps {
  datalist: GenXListItem[]
  selected?: ReportData
  onClick?: (reportName: string) => void
  ulStyle?: React.CSSProperties
  liStyle?: React.CSSProperties
}

export const GenxList = (props: GenXListProps): JSX.Element => {
  return (
    <ul className='custom-list-ul' style={props.ulStyle !== undefined ? props.ulStyle : {}}>
      {props.datalist.map((item, index) => (
        <li onClick={() => {
          if (props.onClick !== undefined) {
            props.onClick(item.name)
          }
        }} style={props.liStyle !== undefined ? props.liStyle : { backgroundColor: (props.selected !== undefined && props.selected.reportName === item.name) ? '#5E5B5B' : '#272727' }} key={index}>{item.name}</li>
      ))}
    </ul>
  )
}
