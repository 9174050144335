import React, { useEffect } from 'react'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import UnassignedAssetListTable from './UnassignedAssetDetails'
import { type GroupsAssetObj } from '../../types'
import { type RootState } from '../../../../store'
import { useSelector } from 'react-redux'

interface ParentProps {
  assetTypeId: string
  onClose: () => void
  addedAssets: GroupsAssetObj[]
  setAddedAssets: React.Dispatch<React.SetStateAction<GroupsAssetObj[]>>
  setShowAddAssetWindow: React.Dispatch<React.SetStateAction<boolean>>
}

const UnassignAssetWindow = (props: ParentProps): JSX.Element => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])
  const numUnassignedAssets = useSelector((state: RootState) => state.UnassignedAssetList.unassignedAssetList !== null ? state.UnassignedAssetList.unassignedAssetList.total_count : 0)
  return (
    <div style={{ backgroundColor: 'rgb(0,0,0,0.4)', height: '100vh', width: '100vw', position: 'fixed', zIndex: 1000, top: 0, backdropFilter: 'blur(5px)', left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div id='modal-div-add-asset' style={{ width: '90vw', backgroundColor: '#272727', maxHeight: '90vh' }}>
        <div className='configurations-table-heading' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0.5em' }}>
          <span style={{ fontSize: 24, fontWeight: 500 }}>Assign Asset<span className='config-list-box' style={{ fontSize: '16px' }}>{numUnassignedAssets} Assets</span></span>
          <IconButton data-testid={'close-cross-btn'} onClick={props.onClose}>
            <CloseIcon />
        </IconButton>
        </div>
        <UnassignedAssetListTable setShowAddAssetWindow={props.setShowAddAssetWindow} addedAssets={props.addedAssets} setAddedAssets={props.setAddedAssets} assetTypeId={props.assetTypeId} />
      </div>
    </div>
  )
}

export default UnassignAssetWindow
