import React from 'react'

interface ButtonProps {
  onClick?: () => void
  children: React.ReactNode
  dataTestId?: string
  className?: string
  id?: string
  type?: 'filled' | 'hollow' | 'borderless'
  primaryColor?: string
  disabled?: boolean
  width?: string
  height?: string
  sx?: any
  icon?: React.ReactNode
}

const CommonStyle = {
  padding: '6px, 24px, 6px, 24px',
  borderRadius: '4px',
  margin: 10,
  cursor: 'pointer',
  fontWeight: 600
}

const HollowStyle = {
  backgroundColor: '#272727',
  borderWidth: '2px',
  borderType: 'solid',
  borderColor: '#64C3FF'
}

const FilledStyle = {
  backgroundColor: '#64C3FF',
  color: '#272727',
  border: 'none'
}

export const Button = (props: ButtonProps): JSX.Element => {
  const Borderless = {
    color: (props.disabled ?? false) ? '#C4C4C4' : '#64C3FF',
    cursor: 'pointer',
    padding: '20px'
  }
  const getStyle = (): any => {
    // istanbul ignore next
    if (props.type !== undefined && props.type === 'hollow') {
      return {
        ...CommonStyle,
        ...HollowStyle,
        color: props.primaryColor !== undefined ? props.primaryColor : '#64C3FF',
        borderColor: props.primaryColor !== undefined ? props.primaryColor : '#64C3FF',
        width: props.width !== undefined ? props.width : '108px',
        height: props.height !== undefined ? props.height : '32px',
        ...props.sx
      }
    } else if (props.type !== undefined && props.type === 'borderless') {
      return {
        ...Borderless,
        ...props.sx
      }
    } else {
      return {
        ...CommonStyle,
        ...FilledStyle,
        backgroundColor: props.primaryColor !== undefined ? props.primaryColor : '#64C3FF',
        width: props.width !== undefined ? props.width : '108px',
        height: props.height !== undefined ? props.height : '32px',
        ...props.sx
      }
    }
  }

  return (
    props.type !== undefined && props.type === 'borderless'
      ? <span id={props.id !== undefined ? props.id : ''}
          className={props.className !== undefined ? props.className : ''}
          data-testid={props.dataTestId !== undefined ? props.dataTestId : (props.id !== undefined ? props.id : '')}
          style={getStyle()}
          onClick={props.onClick}>
          {props.children}
          {props.icon !== undefined ? <span style={{ marginLeft: '20px', fontWeight: 600 }}>{props.icon}</span> : null}
        </span>
      : <button id={props.id !== undefined ? props.id : ''}
            className={props.className !== undefined ? props.className : ''}
            data-testid={props.dataTestId !== undefined ? props.dataTestId : (props.id !== undefined ? props.id : '')}
            style={getStyle()}
            onClick={props.onClick}
            disabled={props.disabled !== undefined ? props.disabled : false}
            >
          {props.children}
          {props.icon !== undefined ? <span style={{ marginLeft: '20px', fontWeight: 600 }}>{props.icon}</span> : null}
        </button>
  )
}
