import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export const language = createSlice({
  name: 'language',
  initialState: 'en',
  reducers: {
    changeLanguage: (state, action: PayloadAction<string>) => {
      return action.payload
    }
  }
})

export const { changeLanguage } = language.actions
export default language.reducer
