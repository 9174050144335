import React from 'react'
import ReactDOM from 'react-dom'
import 'leaflet/dist/leaflet.css'
import '@scuf/common/honeywell-compact-dark/theme.css'
import '@scuf/datatable/honeywell-compact-dark/theme.css'
import '@scuf/charts/honeywell-compact-dark/theme.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { oidcConfig } from './forgeiam/authConfig'
import { AuthProvider } from 'react-oidc-context'

ReactDOM.render(
    <Provider store={store}>
        <AuthProvider {...oidcConfig}>
            <App/>
        </AuthProvider>
    </Provider>,
    document.getElementById('root')
)
document.title = window._env_.REACT_APP_APPLICATION_TITLE ?? ''
reportWebVitals()
