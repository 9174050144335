// istanbul ignore file
import interceptedAxios from '../../../utils/interceptedAxios'
import { type KpiPreferenceRequest, ZSaveKpiPreferenceApiResponse } from '../types'

export async function saveKpiUserPreference (
  kpiPreferenceRequest: KpiPreferenceRequest,
  onSuccess: (data: boolean, httpStatus: number) => any,
  onFailure: (httpStatus: number) => any
): Promise<void> {
  const url = '/app-config-service/api/v1/udp/dashboard-preferences/save-preference'

  interceptedAxios
    .post(url, kpiPreferenceRequest)
    .then((response) => {
      console.log('response', response)
      const parseResponse = ZSaveKpiPreferenceApiResponse.safeParse(response.data)
      console.log('parseResponse', parseResponse)
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (parseResponse.success) {
        if (parseResponse.data.data !== undefined) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        }
      } else {
        onFailure(response.status)
      }
    })
    .catch((error) => {
      onFailure(error.status)
    })
}
