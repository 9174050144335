/* eslint-disable @typescript-eslint/no-unused-vars */
import BackButton from '../../../components/BackButton'
import LanguageStrings from '../../../i18n/locales'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type RootState } from '../../../store'
import FileStatusTable from './fileStatusTable'
import { type ConnectedProps, connect, useDispatch, useSelector } from 'react-redux'
import { createNewConfig, fetchAllConfigs, fetchAllFiles } from '../redux/actionCreators'
import { useAuth } from 'react-oidc-context'
import { Grid } from '@mui/material'
import { Button } from '../../../components/Button'
import { type ConfigState } from '../redux/allConfigSlice'
import { type FileStatusState } from '../redux/fileStatusSlice'

const FileStatus = (props: PropsFromRedux): JSX.Element => {
  const AMIMeterCollectionStrings = LanguageStrings().AMIDataCollectionStrings.LandingPage
  const DeviceDetailsStrings = LanguageStrings().DeviceDetailsStrings
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const ConfigurationListStrings = LanguageStrings().AssetConfigurationStrings.ConfigurationListString

  useEffect(() => {
    props.fetchAllFiles(0, 10, auth.user?.profile.customer as string)
  }, [])

  return (
        <div>
            <div className='topUtilityBar'>
                <BackButton/>
                <span id='componentTitle'>{'FILE STATUS TABLE'}</span>
            </div>
            <div>
              <div className='configurations-table-heading'>
                <h5>{'File Status List'} <span className='config-list-box'>{props.allStatusFiles.fileStatusData?.statusfiles?.length} {'Files'}</span></h5>
              </div>
                <FileStatusTable />
            </div>
            <br />
        </div>
  )
}

interface DispatchToProps {
  fetchAllConfigs: (page: number, size: number, tenantId: string) => void
  fetchAllFiles: (page: number, size: number, tenantId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAllConfigs: (page: number, size: number, tenantId: string) => dispatch(fetchAllConfigs(page, size, tenantId)),
  fetchAllFiles: (page: number, size: number, tenantId: string) => dispatch(fetchAllFiles(page, size, tenantId))
})
interface StateToProps {
  allConfigData: ConfigState
  allStatusFiles: FileStatusState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  allConfigData: state.allConfigData,
  allStatusFiles: state.allStatusFiles
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(FileStatus)
