/* istanbul ignore file */
import React from 'react'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import { CircularProgress } from '@mui/material'
import LanguageStrings from '../../../../i18n/locales'
import GenericModal from '../../../../components/Modal'

const TableStrings = LanguageStrings().AMIDataCollectionStrings.TableComponent

type SetShowStateUpdatePopupType = (value: boolean) => void
type DispatchStateType = (state: any) => void
type HandleSubmitType = (state: any) => void
type FetchAllAssetListType = () => void

export const stateUpdateModel = (
  props: any,
  setShowStateUpdatePopup: SetShowStateUpdatePopupType,
  addState: any,
  dispatchState: DispatchStateType,
  handleSubmit: HandleSubmitType,
  initialState: any,
  fetchAllAssetList: FetchAllAssetListType,
  setSelectedAsset: React.Dispatch<React.SetStateAction<readonly string[]>>
): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (props.updateAssetStateData.isLoading) {
    return (
        <GenericModal title='Modifying State'>
          <div className='CircularprogressAmi'>
            <CircularProgress data-testid="circular-progress"/>
          </div>
        </GenericModal>
    )
  } else if (props.updateAssetStateData.httpStatus === 412) {
    return (
          <GenericModal width={'40%'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative={TableStrings.Cancel} LabelPositive={TableStrings.Confirm}
              onClickNegative={() => {
                setShowStateUpdatePopup(false)
              }}
              onClose={() => { setShowStateUpdatePopup(false) }} title={TableStrings.AlertTitle}
              onClickPositive={() => {
                const newState = Object.assign({}, addState)
                newState.confirmedUpdate = true
                dispatchState(newState)
                handleSubmit(newState)
              }}>
              <div className='modal-content-message'>
                  {props.updateAssetStateData.message}
              </div>
        </GenericModal>
    )
  } else if (props.updateAssetStateData.httpStatus === 200) {
    return (
          <GenericModal data-testid="circular-progress" title={TableStrings.SuccessTitle} width={'fit-content'} height={'fit-content'} LabelPositive={TableStrings.Ok}
              onClose={() => {
                setShowStateUpdatePopup(false)
                dispatchState(initialState)
                fetchAllAssetList()
                setSelectedAsset([])
              }}
              onClickPositive={() => {
                setShowStateUpdatePopup(false)
                dispatchState(initialState)
                fetchAllAssetList()
                setSelectedAsset([])
              }}>
              <div style={{ paddingTop: 20 }}>
                  {props.updateAssetStateData.message}
              </div>
        </GenericModal>
    )
  } else if (props.updateAssetStateData.httpStatus === 401) {
    return (
              <GenericModal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                  onClickNegative={() => { setShowStateUpdatePopup(false) }}
                  onClose={() => { setShowStateUpdatePopup(false) }} title={TableStrings.ModifyingAssetState}>
                  <div className='authError'>
                      <AuthError className={'errorCompInsideModal'} errorMessage="Unauthorized"/>
                  </div>
              </GenericModal>
    )
  } else if (props.updateAssetStateData.httpStatus === 403) {
    return (
              <GenericModal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                  onClickNegative={() => { setShowStateUpdatePopup(false) }}
                  onClose={() => { setShowStateUpdatePopup(false) }} title={TableStrings.ModifyingAssetState}>
                  <div className='authError'>
                      <AuthError className={'errorCompInsideModal'} errorMessage="accessForbidden"/>
                  </div>
              </GenericModal>
    )
  } else {
    return (
      <GenericModal width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close' LabelPositive='Retry'
        onClickNegative={() => { setShowStateUpdatePopup(false) }}
        onClose={() => { setShowStateUpdatePopup(false) }} title={TableStrings.ModifyingAssetState}
        onClickPositive={() => {
          const newState = Object.assign({}, addState)
          newState.confirmedUpdate = true
          dispatchState(newState)
          handleSubmit(newState)
        }}>
        <div className='authError'>
          <AuthError className={'errorCompInsideModal'} customError={props.updateAssetStateData.message}/></div>
      </GenericModal>
    )
  }
}
