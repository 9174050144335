import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export const configIdSlice = createSlice({
  name: 'configIdSlice',
  initialState: '',
  reducers: {
    changeConfigId: (state, action: PayloadAction<string>) => {
      return action.payload
    }
  }
})

export const { changeConfigId } = configIdSlice.actions
export default configIdSlice.reducer
