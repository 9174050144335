import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type AssetAttribute } from '../services/assetAttributeService'

export interface AssetAttributeState {
  isLoading: boolean
  error: string | undefined
  assetAttribute: AssetAttribute[] | undefined
  httpStatus: number | undefined
}

export const initialState: AssetAttributeState = {
  isLoading: true,
  error: undefined,
  assetAttribute: undefined,
  httpStatus: 0
}

const assetAttribute = createSlice({
  name: 'assetAttributeSlice',
  initialState,
  reducers: {
    assetAttributeLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        assetAttribute: undefined,
        httpStatus: 0
      }
    },
    assetAttributeUpdateAction: (state, action: PayloadAction<{ data: AssetAttribute[], httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        assetAttribute: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    assetAttributeErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        assetAttribute: undefined,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  assetAttributeLoadingAction,
  assetAttributeUpdateAction,
  assetAttributeErrorAction
} = assetAttribute.actions

export default assetAttribute.reducer
