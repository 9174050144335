import { CardContent, FormHelperText, Grid, TextField } from '@mui/material'
import React from 'react'
import { GenXDropdown, type Option } from '../../../../components/GenXDropdown'
import { GenericTextarea } from '../../../../components/GenericTextarea'
import { type ConnectedProps, connect } from 'react-redux'
import { type AssetTypesState } from '../../../asset-configuration/redux/assetTypeSlice'
import { fetchAssetTypes } from '../../../asset-configuration/redux/actionCreator'
import { type RootState } from '../../../../store'
import { HandleError } from './utils/handleError'
import { isValidGroupNameText, isValidDesc, isValidGName } from '../../../../utils/validator'
import LanguageStrings from '../../../../i18n/locales'

const GroupInfoStrings = LanguageStrings().AssetGroupStrings.CreateGroupComponent.GroupInformation

const textStyle = {
  root: {
    backgroundColor: '#272727',
    '& .MuiInputBase-input': {
      color: '#F0F0F0',
      backgroundColor: '#404040'
    },
    '& .MuiInputLabel-root': {
      color: '#F0F0F0',
      '&.Mui-focused': {
        color: '#F0F0F0'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00000000'
      },
      '&:hover fieldset': {
        borderColor: '#00000000'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00000000'
      }
    }
  }
}

interface ParentProps {
  groupName: string
  setGroupName: React.Dispatch<React.SetStateAction<string>>
  category: Option | null
  setCategory: React.Dispatch<React.SetStateAction<Option | null>>
  assetType: Option | null
  setAssetType: React.Dispatch<React.SetStateAction<Option | null>>
  description: string
  setDescription: React.Dispatch<React.SetStateAction<string>>
  error: boolean
  setError: React.Dispatch<React.SetStateAction<boolean>>
  descError: boolean
  setDescError: React.Dispatch<React.SetStateAction<boolean>>
  nameErrorInit: boolean
  setNameErrorInit: React.Dispatch<React.SetStateAction<boolean>>
  categoryOptions: Option[]
  setCategoryOptions: React.Dispatch<React.SetStateAction<Option[]>>
  assetTypeOptions: Option[]
  setAssetTypeOptions: React.Dispatch<React.SetStateAction<Option[]>>
}

const GroupInformation = (props: AllProps): JSX.Element => {
  const [descErrorInit, setDescErrorInit] = React.useState(false)

  React.useEffect(() => {
    if (isValidGName(props.groupName)) {
      props.setError(false)
    } else {
      props.setError(true)
    }
  }, [props.groupName])

  React.useEffect(() => {
    if (isDescriptionNotValid()) {
      props.setDescError(true)
    } else {
      props.setDescError(false)
    }
  }, [props.description])

  React.useEffect(() => {
    if (!(props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0)) {
      props.fetchAssetTypes()
    }
  }, [])

  React.useEffect(() => {
    if (props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0) {
      const categories: Option[] = []
      const uniqueCategories: Record<string, boolean> = {}
      props.assetTypes.assetTypes.forEach((assetType) => {
        if (!uniqueCategories[assetType.category_type_id]) {
          uniqueCategories[assetType.category_type_id] = true
          categories.push({
            value: assetType.category_type_id,
            label: assetType.category_type_name
          })
        }
      })

      props.setCategoryOptions(categories)
    }
  }, [props.assetTypes.assetTypes])

  const handleGroupInformationWithFailure = (): JSX.Element => {
    if (props.assetTypes.isLoading) {
      return (
        HandleError({
          isLoading: true,
          unauthorized: false,
          forbidden: false,
          noDataPresent: false
        })
      )
    } else if (props.assetTypes.httpStatus === 401) {
      return (
        HandleError({
          isLoading: false,
          unauthorized: true,
          forbidden: false,
          noDataPresent: false
        })
      )
    } else if (props.assetTypes.httpStatus === 403) {
      return (
        HandleError({
          isLoading: false,
          unauthorized: true,
          forbidden: false,
          noDataPresent: false
        })
      )
    } else {
      return (
        HandleError({
          isLoading: false,
          unauthorized: false,
          forbidden: false,
          noDataPresent: false,
          retryHandler: props.fetchAssetTypes
        })
      )
    }
  }

  // istanbul ignore next
  const handleCategorySelect = (selectedValue: Option): void => {
    if (props.category !== null && selectedValue.value !== props.category.value) {
      props.setAssetType(null)
    }
    props.setCategory(selectedValue)
    selectedValue.label === props.category?.label ? props.setCategory(selectedValue) : props.setAssetTypeOptions([])
    const tempAssetTypeOptions: Option[] = []
    if (props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0) {
      props.assetTypes.assetTypes.forEach((assetType) => {
        if (assetType.category_type_id === selectedValue.value) {
          tempAssetTypeOptions.push({
            value: assetType.asset_type_id,
            label: assetType.asset_type_name
          })
        }
      })
      props.setAssetTypeOptions(tempAssetTypeOptions)
    }
  }

  const handleAssetTypeSelect = (selectedValue: Option): void => {
    props.setAssetType(selectedValue)
  }

  const isNameNotValid = (): boolean => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return (!isValidGroupNameText(props.groupName) || (props.groupName.length > 60))
  }

  const isDescriptionNotValid = (): boolean => {
    return ((!isValidDesc(props.description) || props.description.length > 200) && props.description.length !== 0)
  }

  if (props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0) {
    return (
      <>
        <CardContent>
          <p id='group-info-title'>{GroupInfoStrings.GROUP_INFORMATION}</p>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{GroupInfoStrings.Group_Name}<sup style={{ color: '#EE3426' }}>*</sup></span>
              <TextField
                id='custom-textfield'
                variant="outlined"
                data-testId='group-name'
                placeholder='Enter Name'
                InputLabelProps={{
                  disabled: true
                }}
                value={props.groupName}
                onChange={(e) => {
                  props.setGroupName(e.target.value)
                  if (!props.nameErrorInit) {
                    props.setNameErrorInit(true)
                  }
                }}
                size='small'
                autoComplete='off'
                className='custom-textfield'
                sx={{
                  ...textStyle.root,
                  width: '60%'
                  // border: (isParameterInvalid(row) && parameterListErrorInit.includes(row.parameterName)) ? '1px solid #EE3426' : 'none'
                }}
              />
              {(isNameNotValid() && props.nameErrorInit) && (
                // istanbul ignore next
                <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', width: '70%' }} className="errorColor">
                  {GroupInfoStrings.Name_Error}
                </FormHelperText>
              )}
            </Grid>
            <Grid item md={4}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{GroupInfoStrings.Category}<sup style={{ color: '#EE3426' }}>*</sup></span>
              <GenXDropdown placeholder='Select Category' value={props.category} options={props.categoryOptions} onSelect={handleCategorySelect} />
            </Grid>
            <Grid item md={4}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{GroupInfoStrings.Asset_Type}<sup style={{ color: '#EE3426' }}>*</sup></span>
              <GenXDropdown placeholder='Select Asset Type' value={props.assetType} options={props.assetTypeOptions} onSelect={handleAssetTypeSelect} />
            </Grid>
            <Grid item md={12}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{GroupInfoStrings.Description}</span>
              <GenericTextarea
                sx={{ width: 400, height: 100 }}
                value={props.description} onChange={(e) => {
                  props.setDescription(e.target.value)
                  setDescErrorInit(true)
                }} />
              {(isDescriptionNotValid() && descErrorInit) && (
                <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', width: '70%' }} className="errorColor">
                  {GroupInfoStrings.Desc_Error}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </>
    )
  } else {
    return handleGroupInformationWithFailure()
  }
}

interface DispatchToProps {
  fetchAssetTypes: () => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAssetTypes: () => dispatch(fetchAssetTypes())
})

interface StateToProps {
  assetTypes: AssetTypesState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assetTypes: state.assetTypes
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type AllProps = PropsFromRedux & ParentProps

export default connector(GroupInformation)
