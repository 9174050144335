import React, { type RefObject, useEffect } from 'react'
import { type ReportContent } from '../../types'
import LanguageStrings from '../../../../i18n/locales'

const ReportContentsTableStrings = LanguageStrings().DataPublisherStrings.ReportTemplateComponents.ReportContentsTable

interface ParentProps {
  reportContent: ReportContent[]
  reportTemplateDetailsRef: RefObject<HTMLDivElement>
}

const trimString = (str: string): string => {
  if (str.length > 20) {
    return `${str.substring(0, 20)}...`
  }
  return str
}

const ReportContentsTable = (props: ParentProps): JSX.Element => {
  const [width, setWidth] = React.useState<number>(0)
  useEffect(() => {
    if (props.reportTemplateDetailsRef.current !== null) {
      setWidth(props.reportTemplateDetailsRef.current.clientWidth)
    } else {
      setWidth(0)
    }
  }, [props.reportTemplateDetailsRef])

  return (
    <div style={{ border: '1px solid #101010', margin: '1rem', borderRadius: '10px' }}>
      <div style={{ padding: '10px 1em', backgroundColor: '#313030', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>{ReportContentsTableStrings.Report_Contents}</div>
      <table id='report-content-details-table' style={{ display: 'flex', flexDirection: 'column', maxWidth: `${width}px`, overflow: 'hidden', overflowX: 'auto', backgroundColor: '#272727', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', tableLayout: 'fixed' }}>
        <thead>
          <tr>
            {
              props.reportContent.map((reportContent: ReportContent, index: number) => {
                return (
                  <th key={index} style={{ color: '#f0f0f0', border: '1px solid #202020', backgroundColor: '#272727', whiteSpace: 'nowrap', width: '240px', minWidth: '240px', maxWidth: '240px', textAlign: 'left', padding: '8px 15px' }}><abbr style={{ textDecoration: 'none' }} title={reportContent.columnHeaderName}>{trimString(reportContent.columnHeaderName)}</abbr></th>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          <tr>
            {
              props.reportContent.map((reportContent: ReportContent, index: number) => {
                return (
                  <td key={index} style={{ color: '#f0f0f0', border: '1px solid #202020', whiteSpace: 'nowrap', width: '240px', minWidth: '240px', maxWidth: '240px', textAlign: 'left', padding: '8px 15px' }}><abbr style={{ textDecoration: 'none' }} title={reportContent.columnDisplayName}>{trimString(reportContent.columnDisplayName)}</abbr></td>
                )
              })
            }
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default ReportContentsTable
