import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface DeleteSchedulerTemplateState {
  isLoading: boolean
  error: boolean
  httpStatus: number
}

const initialState: DeleteSchedulerTemplateState = {
  isLoading: false,
  error: false,
  httpStatus: 0
}

export const deleteSchedulerTemplateSlice = createSlice({
  name: 'deleteSchedulerTemplateSlice',
  initialState,
  reducers: {
    deleteSchedulerTemplateLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        httpStatus: 0
      }
    },
    deleteSchedulerTemplateUpdateAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: false,
        httpStatus: action.payload
      }
    },
    deleteSchedulerTemplateErrorAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: true,
        httpStatus: action.payload
      }
    }
  }
})

export const {
  deleteSchedulerTemplateLoadingAction,
  deleteSchedulerTemplateUpdateAction,
  deleteSchedulerTemplateErrorAction
} = deleteSchedulerTemplateSlice.actions

export default deleteSchedulerTemplateSlice.reducer
