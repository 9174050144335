/* istanbul ignore file */
/* eslint-disable */

import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import { TextField } from '@mui/material'
import DropDownImage from '../assets/dropDown.svg'
import { Pointer } from 'highcharts'

export interface Option {
  value: string
  label: string
}
interface GenericDropdownProps {
  options: Option[]
  fontSize?: string
  menuItemStyle?: React.CSSProperties
  value: string
  onChange: (event: SelectChangeEvent<string>) => void
  label: string
  width: string | number | undefined
  dataTestId: string
  placeholder?: string
  menuStyle?: React.CSSProperties
  allowInput?: boolean
  handleSearchInput?: (searchVal: string) => Option[]
  setState?: React.Dispatch<React.SetStateAction<string>> | undefined
  disabled?: boolean
}

const injectLabel = (label: string): JSX.Element => {
  return <span style={{ fontSize: '15px', paddingBottom: 10 }}>{label}</span>
}

const truncateLabel = (label: string, maxLength: number) => {
  if (label.length > maxLength) {
    return label.slice(0, maxLength) + '...';
  }
  return label;
}

export const GenericDropdown: React.FC<GenericDropdownProps> = ({ placeholder, menuStyle, options, value, onChange, label, width, dataTestId, allowInput = false, setState, disabled, handleSearchInput, menuItemStyle, fontSize }) => {
  const [search, setSearch] = React.useState('')
  const [filteredOptions, setFilteredOptions] = React.useState<Option[]>(options)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (handleSearchInput !== undefined) {
      const searchBasedOptions: Option[] = handleSearchInput(event.target.value)
      setFilteredOptions(searchBasedOptions)
    } else {
      setSearch(event.target.value)
      setFilteredOptions(options.filter((option) => option.label.toLowerCase().includes(event.target.value.toLowerCase())))
    }
  }

  const handleReset = (event: SelectChangeEvent<string>): void => {
    setSearch('')
    setFilteredOptions(options)
    onChange(event)
  }

  const handleClose = (event: React.SyntheticEvent<Element, Event>): void => {
    setSearch('')
    setFilteredOptions(options)
  }

  React.useEffect(() => {
    setFilteredOptions(options.filter((option) => option.label?.toLowerCase().includes(search.toLowerCase())))
  }, [options, search])

  const textStyle = {
    root: {
      backgroundColor: '#272727',
      width: '100%',
      '& .MuiInputBase-input': {
        color: '#F0F0F0',
        height: '30px',
        paddingLeft: '10px',
        borderRadius: '0px'
      },
      '& .MuiInputLabel-root': {
        color: '#F0F0F0',
        boxShadow: 'none',
        '&.Mui-focused': {
          color: '#F0F0F0'
        }
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#00000000'
        },
        '&:hover fieldset': {
          borderColor: '#00000000'
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00000000'
        }
      }
    }
  }
  return (
    <div className='genericDropdownDiv'>
      <FormControl>
        {label !== '' && injectLabel(label)}
        <Select
          className='generic-dropdown'
          required
          onKeyDownCapture={(event) => { event.stopPropagation() }}
          value={value}
          displayEmpty
          data-testid={dataTestId}
          label={dataTestId}
          onChange={handleReset}
          IconComponent={() => <img src={DropDownImage} alt='dropdown' width={20} height={20} color='#ffffff' style={{ margin: '4px 4px 0 0', cursor: 'pointer', pointerEvents: 'none', position: 'absolute', right: 0 }}/>}
          onClose={handleClose}
          aria-label={dataTestId}
          MenuProps={{
            style: menuStyle
          }}
          renderValue={(selectedValue) =>
            truncateLabel(
              options.find((option) => option.value === selectedValue)?.label || (placeholder !== undefined ? placeholder : 'Select an option'),
              28
            )
          }
          input={<OutlinedInput id={value} />}
          disabled={disabled !== undefined ? disabled : false}
          sx={{
            background: '#00000000',
            width: width !== undefined ? width : '260px', height: '40px',
            boxShadow: 'none',
            color: '#f0f0f0', fontFamily: 'Honeywell Sans Web', fontSize: fontSize ?? '14px',
            borderRadius: '4px',
            '& .MuiInputBase-root':{
              marginTop:'10px'
            }
          }}
          disableUnderline
        >
          {allowInput && (<TextField
              variant="standard"
              id='standard-basic'
              placeholder='Search'
              value={search}
              onChange={handleSearch}
              InputLabelProps={{
                disabled: true
              }}
              size='small'
              className='custom-textfield'
              sx={
                textStyle.root
              }
              autoComplete='off'
            />)}
          {
            filteredOptions.length > 0 ? filteredOptions.map((option, index) => (
              <MenuItem sx={menuItemStyle} data-testid={`${dataTestId}-${index}`} key={index} value={option.value}>
                {option.label}
              </MenuItem>
            )) :
            <MenuItem data-testid={'no-options'}>
              No options
            </MenuItem>
          }
        </Select>
      </FormControl>
    </div>
  )
}
