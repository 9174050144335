/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface provisioningHistoryFilterStatusState {
  isLoading: boolean
  loadingError: string | null
  provisioningHistoryFilterStatus: string[] | []
  httpStatus: number | null
}

// Initial state of the slice
const initialState: provisioningHistoryFilterStatusState = {
  isLoading: true,
  loadingError: null,
  provisioningHistoryFilterStatus: [],
  httpStatus: null
}

// Slice to return from action
export const provisioningHistoryFilterStatusSlice = createSlice({
  name: 'provisioningHistoryFilterStatus',
  initialState,
  reducers: {
    provisioningHistoryFilterStatusLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        provisioningHistoryFilterStatus: [],
        httpStatus: 0
      }
    },
    provisioningHistoryFilterStatusUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: null,
        provisioningHistoryFilterStatus: action.payload.data,
        httpStatus: action.payload.httpStatus,
      }
    },
    provisioningHistoryFilterStatusErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        provisioningHistoryFilterStatus: []
      }
    }
  }
})

// Actions to export
export const {
  provisioningHistoryFilterStatusLoadingAction,
  provisioningHistoryFilterStatusUpdateAction,
  provisioningHistoryFilterStatusErrorAction
} = provisioningHistoryFilterStatusSlice.actions

export default provisioningHistoryFilterStatusSlice.reducer
