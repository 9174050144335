import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'
import { getGraphParameters, type AssetType } from '../../../utils/NetworkGraphUtils'

const ZKpi = z.object({
  kpi_definition_id: z.string(),
  timestamp: z.string(),
  kpi_value: z.number()
})

export type KpiData = z.infer<typeof ZKpi>

export async function getNetworkGraphData (
  tenantId: string,
  assetId: string,
  from: string,
  to: string,
  assetType: AssetType,
  onSuccess: (data: KpiData[], httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  // Move the KPIList out soon
  const encodedFrom = from.replace('+', '%2B')
  const encodedTo = to.replace('+', '%2B')
  const requiredKpiList = getGraphParameters(assetType).kpiDefinitionIds.join(',')
  const url = `/kpirest/api/v1/udp/kpis?kpiList=${requiredKpiList}&assetId=${assetId}&from=${encodedFrom}&to=${encodedTo}&tenantId=${tenantId}`
  interceptedAxios.get(url, { timeout: 10000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZKpi.array().safeParse(response.data.data)
        if (parseResponse.success) {
          onSuccess(parseResponse.data, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.response.status)
    })
}
