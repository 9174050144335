import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type FirmwareCampaignDetails } from '../services/firmwareCampaignListService'

export interface FirmwareCampaignListState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
  campaignListData: FirmwareCampaignDetails[] | undefined
  totalCount: number
}

export const initialState: FirmwareCampaignListState = {
  isLoading: false,
  error: undefined,
  httpStatus: undefined,
  campaignListData: undefined,
  totalCount: 0
}

const FirmwareCampaignListSlice = createSlice({
  name: 'firmware-campaign-list',
  initialState,
  reducers: {
    firmwareCampaignListLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0,
        campaignListData: undefined,
        totalCount: 0
      }
    },
    firmwareCampaignListUpdateAction: (
      state,
      action: PayloadAction<{ data: FirmwareCampaignDetails[], total_count: number, httpStatus: number }>
    ) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload.httpStatus,
        campaignListData: action.payload.data,
        totalCount: action.payload.total_count
      }
    },
    firmwareCampaignListErrorAction: (
      state,
      action: PayloadAction<{ errorMessage: string, httpStatus: number }>
    ) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        campaignListData: undefined,
        totalCount: 0
      }
    }
  }
})

export const {
  firmwareCampaignListLoadingAction,
  firmwareCampaignListUpdateAction,
  firmwareCampaignListErrorAction
} = FirmwareCampaignListSlice.actions

export default FirmwareCampaignListSlice.reducer
