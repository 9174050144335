import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
  CircularProgress
} from '@mui/material'

import React from 'react'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import Pagination from '../../../components/TablePagination'
import AuthError from '../../../components/ErrorComponents/AuthError'
import lowBadge from '../../../assets/lowBadge.svg'
import highBadge from '../../../assets/highBadge.svg'
import mediumBadge from '../../../assets/mediumBadge.svg'
import CriticalBadge from '../../../assets/criticalBadge.svg'
import { type IAlarmHistoryState } from '../redux/alarmHistorySlice'
import { fetchAlarmHistory } from '../redux/actionCreator'
import { type IAlarmHistoryList } from '../services/alarmHistoryService'
import { parseDateInAOHstandard } from '../../../customHooks/parseDate'

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0,
  overflow: 'hidden'
}

function EnhancedTableHead (props: any): any {
  return (
      <TableHead data-testid="table-header">
        <TableRow>
          {headCells.map((headCell: HeadCell) => (
            <StyledTableCell
              key={headCell.id}
              align={'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              <>{headCell.label.toUpperCase()}</>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#D0D0D0',
    fontWeight: '500',
    fontSize: '0.875rem',
    padding: '16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'activity',
    numeric: false,
    disablePadding: false,
    label: 'Alarm'
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'SEVERITY'
  },
  {
    id: 'end_date',
    numeric: true,
    disablePadding: false,
    label: 'End Date'
  }
]

export const getStatusIcons = (status: string): JSX.Element => {
  switch (status.toLowerCase()) {
    case 'low':
      return <img src={lowBadge} />
    case 'medium':
      return <img src={mediumBadge} />
    case 'high':
      return <img src={highBadge} />
    case 'critical':
      return <img src={CriticalBadge} />
    default:
      return <img src={lowBadge} />
  }
}

const AlarmHistory = (props: PropsFromRedux): JSX.Element => {
  const [page, setPage] = React.useState(1)
  const rowsPerPage = 10
  const tableData = useSelector((state: any) => state.alarmHistoryState?.alarmHistoryData?.alarmHistoryDetails)
  const totalCount = useSelector((state: any) => state.alarmHistoryState?.alarmHistoryData?.totalCount)

  const assetId = sessionStorage.getItem('paramAssetId')
  React.useEffect(() => {
    handleFetchSchedulerList()
  }, [])

  const handleFetchSchedulerList = (): void => {
    if (assetId !== null) {
      props.fetchAlarmHistory(assetId, page - 1, rowsPerPage)
    }
  }

  React.useEffect(() => {
    handleFetchSchedulerList()
  }, [page])

  const handleTable = (): JSX.Element => {
    if ((tableData !== null && tableData !== undefined && props.alarmHistoryState?.httpStatus === 200)) {
      return (
          <>
            <Box sx={style}>
              <Paper
                sx={{
                  width: '100%',
                  mb: 2,
                  backgroundColor: '#353535',
                  boxShadow: 'unset',
                  color: '#F0F0F0',
                  marginBottom: '0'
                }}
              >
                <div className='activeIssuesdiv'>
                    <h5>{'Alarm History List'}</h5>
                </div>
                <TableContainer className="device-table">
                  <Table
                    sx={{ minWidth: 750, color: '#f0f0f0', background: '#272727' }}
                    aria-labelledby="tableTitle"
                    size={'small'}
                  >
                    <EnhancedTableHead
                      rowCount={tableData?.length}
                    />
                    <TableBody>
                      {tableData?.length > 0 &&
                        tableData?.map((row: IAlarmHistoryList, index: number) => {
                          return (
                            <StyledTableRow
                              hover
                              role='checkbox'
                              tabIndex={-1}
                              key={row.alarmDefinitionId}
                              aria-label='alarm-history-row'
                              sx={{
                                cursor: 'pointer'
                              }}
                            >
                              <StyledTableCell>
                                {row.alarmActivity}
                              </StyledTableCell>
                              <StyledTableCell>
                                {getStatusIcons(row.severity)}
                              </StyledTableCell>
                              <StyledTableCell>
                                { row.alarmClearedAt !== null ? parseDateInAOHstandard(row.alarmClearedAt) : ''}
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {totalCount > 10 && (<Pagination
                  count={totalCount}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  siblingCount={1}
                  boundaryCount={1}
                />)}
              </Paper>
            </Box>
          </>
      )
    } else if (props.alarmHistoryState.isLoading) {
      return (
          <div style={{ height: '300px' }}className="deviceDetailsProgress" data-testid="circular-progress">
            <CircularProgress />
          </div>
      )
    } else if (props.alarmHistoryState?.httpStatus === 401) {
      return (
          <div className="authError">
            <AuthError errorMessage="Unauthorized" />
          </div>
      )
    } else if (props.alarmHistoryState?.httpStatus === 403) {
      return (
          <div className="authError">
            <AuthError errorMessage="accessForbidden" />
          </div>
      )
    } else if (props.alarmHistoryState?.httpStatus === 404) {
      return (
          <div className="authError">
            <AuthError errorMessage="NoDataPresent" />
          </div>
      )
    } else {
      return (
          <div className="authError">
            <AuthError
              errorMessage="cannotFetch"
              retry={handleFetchSchedulerList}
            />
          </div>
      )
    }
  }

  return (
      <>
        {handleTable()}
      </>
  )
}

interface DispatchToProps {
  fetchAlarmHistory: (assetId: string, page: number, size: number) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAlarmHistory: (assetId, page, size) =>
    dispatch(fetchAlarmHistory(assetId, page, size))
})

interface StateToProps {
  alarmHistoryState: IAlarmHistoryState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  alarmHistoryState: state.alarmHistoryState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
  type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AlarmHistory)
