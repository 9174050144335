import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Asset } from '../services/unAssignedAssetListService'

export interface AssetListState {
  isLoading: boolean
  loadingError: string | null
  assetList: Asset[] | null
  assetCount: number
  httpStatus: number | null
}

const initialState: AssetListState = {
  isLoading: true,
  loadingError: null,
  assetList: null,
  assetCount: 0,
  httpStatus: null
}

const assetListSlice = createSlice({
  name: 'Asset-List-By-AssetType',
  initialState,
  reducers: {
    assetListLoadingAction: (state) => {
      return {
        isLoading: true,
        loadingError: null,
        assetList: null,
        assetCount: 0,
        httpStatus: 0
      }
    },
    assetListUpdateAction: (state, action: PayloadAction<{ data: Asset[], count: number, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: null,
        assetList: action.payload.data,
        assetCount: action.payload.count,
        httpStatus: action.payload.httpStatus
      }
    },
    assetListErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: action.payload.errorMessage,
        assetList: null,
        assetCount: 0,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  assetListLoadingAction,
  assetListUpdateAction,
  assetListErrorAction
} = assetListSlice.actions

export default assetListSlice.reducer
