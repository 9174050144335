import { type KpiKeyDonut } from '../../redux/changeKpiType'
import { type DonutKpiBarGraphState } from '../../redux/historyDonutKpiBarGraphSlice'
import { type DonutKpiState, type BarChartState } from '../../types'
import { type seriesData } from './BarCharts'
import { kpiColorMapDonut } from './BuildKpiData'

export interface GeneratedData {
  seriesData: seriesData[]
  category: string[]
}

export interface GeneratedDonutData {
  name: string[]
  data: number[]
  color: string[]
}

export function buildTotalRegisteredHistoryData (kpiData: BarChartState): GeneratedData {
  if (kpiData.data !== undefined) {
    const categoryData: string[] = []
    const barOne: number[] = []
    const barTwo: number[] = []
    kpiData.data.liveData.forEach((element) => {
      categoryData.push(element.date)
      barOne.push(element.dataPoint[0].count)
      barTwo.push(element.dataPoint[1].count)
    })
    const seriesData = [
      {
        name: kpiData.data.liveData[0].dataPoint[0].name,
        data: barOne
      }, {
        name: kpiData.data.liveData[0].dataPoint[1].name,
        data: barTwo
      }
    ]
    return { seriesData, category: categoryData }
  }
  return { seriesData: [], category: [] }
}

export function buildTotalConnectedHistoryData (kpiData: BarChartState): GeneratedData {
  if (kpiData.data !== undefined) {
    const categoryData: string[] = []
    const barOne: number[] = []
    const barTwo: number[] = []
    kpiData.data.liveData.forEach((element) => {
      categoryData.push(element.date)
      barOne.push(element.dataPoint[0].count)
      barTwo.push(element.dataPoint[1].count)
    })
    const seriesData = [
      {
        name: kpiData.data.liveData[0].dataPoint[0].name,
        data: barOne
      }, {
        name: kpiData.data.liveData[0].dataPoint[1].name,
        data: barTwo
      }
    ]
    return { seriesData, category: categoryData }
  }
  return { seriesData: [], category: [] }
}

export function buildTotalShippedHistoryData (kpiData: BarChartState): GeneratedData {
  if (kpiData.data !== undefined) {
    const categoryData: string[] = []
    const barOne: number[] = []
    kpiData.data.liveData.forEach((element) => {
      categoryData.push(element.date)
      barOne.push(element.dataPoint[0].count)
    })
    const seriesData = [
      {
        name: kpiData.data.liveData[0].dataPoint[0].name,
        data: barOne
      }
    ]
    return { seriesData, category: categoryData }
  }
  return { seriesData: [], category: [] }
}

export function buildLastReadAlarmsHistoryData (kpiData: BarChartState): GeneratedData {
  if (kpiData.data !== undefined) {
    const categoryData: string[] = []
    const barOne: number[] = []
    kpiData.data.liveData.forEach((element) => {
      categoryData.push(element.date)
      barOne.push(element.dataPoint[0].count)
    })
    const seriesData = [
      {
        name: kpiData.data.liveData[0].dataPoint[0].name,
        data: barOne
      }
    ]
    return { seriesData, category: categoryData }
  }
  return { seriesData: [], category: [] }
}

export function buildTotalStaleHistoryData (kpiData: BarChartState): GeneratedData {
  if (kpiData.data !== undefined) {
    const categoryData: string[] = []
    const barOne: number[] = []
    kpiData.data.liveData.forEach((element) => {
      categoryData.push(element.date)
      barOne.push(element.dataPoint[0].count)
    })
    const seriesData = [
      {
        name: kpiData.data.liveData[0].dataPoint[0].name,
        data: barOne
      }
    ]
    return { seriesData, category: categoryData }
  }
  return { seriesData: [], category: [] }
}

export function buildDonutChartHistoryData (kpiName: KpiKeyDonut, kpiDataState: DonutKpiState): GeneratedDonutData {
  const name: string[] = []
  const data: number[] = []
  const color = kpiColorMapDonut[kpiName]
  if (kpiDataState.data !== undefined) {
    kpiDataState.data.liveData[0].dataPoint.forEach((element) => {
      name.push(element.name)
      data.push(element.percentage)
    })
  }
  return { name, data, color }
}

export function buildDonutKpiHistoryBarGraphData (kpiData: DonutKpiBarGraphState): GeneratedData {
  if (kpiData.data !== undefined) {
    const categoryData: string[] = []
    const barOne: number[] = []
    kpiData.data.liveData.forEach((element) => {
      categoryData.push(element.date)
      barOne.push(element.dataPoint[0].count)
    })
    const seriesData = [
      {
        name: kpiData.data.liveData[0].dataPoint[0].name,
        data: barOne
      }
    ]
    return { seriesData, category: categoryData }
  }
  return { seriesData: [], category: [] }
}
