import * as UploadFileService from '../services/uploadFile'
import { uploadFileInfoDataLoading, uploadFileInfoDataError, uploadFileInfoData, resetDeviceUpload } from './deviceInfoSlice'
export const uploadFile = (file: File, name: string, tenantID: string, uploadType: string) => async (dispatch: any) => {
  dispatch(uploadFileInfoDataLoading())
  try {
    await UploadFileService.uploadFile(file, name, tenantID, uploadType, (message) => {
      dispatch(uploadFileInfoData(message))
    },
    (errorMessage) => {
      dispatch(uploadFileInfoDataError(errorMessage))
    })
  } catch (e: any) {
    dispatch(uploadFileInfoDataError(e))
  }
}
export const resetUpload = () => async (dispatch: any) => {
  dispatch(resetDeviceUpload())
}
