import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type CreateGroupApiResponse } from '../services/createGroupService'

export interface CreateGroupState {
  isLoading: boolean
  error: string | undefined
  data: CreateGroupApiResponse | undefined
  httpStatus: number | undefined
}

const initialState: CreateGroupState = {
  isLoading: false,
  error: undefined,
  data: undefined,
  httpStatus: 0
}

const createGroupSlice = createSlice({
  name: 'createGroupSlice',
  initialState,
  reducers: {
    createGroupLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        data: undefined,
        httpStatus: 0
      }
    },
    createGroupUpdateAction: (state, action: PayloadAction<{ data: CreateGroupApiResponse, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    createGroupErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        data: undefined,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  createGroupLoadingAction,
  createGroupUpdateAction,
  createGroupErrorAction
} = createGroupSlice.actions

export default createGroupSlice.reducer
