import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface UpdateAssetStateInfo {
  isLoading: boolean
  message: string | null
  httpStatus: number
}

const initialState: UpdateAssetStateInfo = {
  isLoading: false,
  message: null,
  httpStatus: 0
}

export const updateAssetStateInfoSlice = createSlice({
  name: 'updateAssetStateInfoSlice',
  initialState,
  reducers: {
    resetUpdateStateParamsData: () => {
      return {
        isLoading: false,
        message: null,
        httpStatus: 0
      }
    },
    updateAssetStateInfoDataLoading: (state): UpdateAssetStateInfo => {
      return {
        isLoading: true,
        message: null,
        httpStatus: 0
      }
    },
    updateAssetStateInfoDataError: (state, action: PayloadAction<{ message: string, httpStatus: number }>): UpdateAssetStateInfo => {
      return {
        isLoading: false,
        message: action.payload.message,
        httpStatus: action.payload.httpStatus
      }
    },
    updateAssetStateInfoData: (state, action: PayloadAction<{ message: string, httpStatus: number }>): UpdateAssetStateInfo => {
      return {
        isLoading: false,
        message: action.payload.message,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const { updateAssetStateInfoDataLoading, updateAssetStateInfoDataError, updateAssetStateInfoData, resetUpdateStateParamsData } = updateAssetStateInfoSlice.actions
export default updateAssetStateInfoSlice.reducer
