import { type AppDispatch } from '../../../store'
import * as OperationPermissionService from '../services/operationPermissionService'
import { operationPermissionErrorAction, operationPermissionLoadingAction, operationPermissionUpdateAction } from '../redux/operationPermissionSlice'

export const getOperationPermissions = (subId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(operationPermissionLoadingAction())

  void OperationPermissionService.getPermissions(
    subId,
    (associateAssetsResponse) => {
      dispatch(operationPermissionUpdateAction({ data: associateAssetsResponse }))
    },
    (error, httpStatus) => {
      dispatch(operationPermissionErrorAction({ error, httpStatus }))
    }
  )
}
