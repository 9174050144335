import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface TopologyData {
  parent: any
  children: any[]
}

export interface topologyInterface {
  isLoading: boolean
  errorMessage: string | null
  topologyData: TopologyData | null
}

const initialState: topologyInterface = {
  isLoading: true,
  errorMessage: null,
  topologyData: null
}

export const topologyDataSlice = createSlice({
  name: 'topologyData',
  initialState,
  reducers: {
    addTopologyData: (state, action: PayloadAction<TopologyData>) => {
      return {
        isLoading: false,
        errorMessage: null,
        topologyData: action.payload
      }
    },
    topologyLoading: (state) => {
      return {
        isLoading: true,
        errorMessage: null,
        topologyData: null
      }
    },
    topologyError: (state, action: PayloadAction<string>) => {
      return {
        isLoading: false,
        errorMessage: action.payload,
        topologyData: null
      }
    }
  }
})

export const { addTopologyData, topologyLoading, topologyError } = topologyDataSlice.actions
export default topologyDataSlice.reducer
