import { createSlice } from '@reduxjs/toolkit'
import { type PayloadAction } from '@reduxjs/toolkit'
import { type AssetForGroup } from '../types'

export interface AssetsFoCreateGroupState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
  assetsForCreateGroup: AssetForGroup | undefined
}

const initialState: AssetsFoCreateGroupState = {
  isLoading: false,
  error: undefined,
  httpStatus: 0,
  assetsForCreateGroup: undefined
}

const assetsForCreateGroupSlice = createSlice({
  name: 'AssetsForCreateGroup',
  initialState,
  reducers: {
    assetsForCreateGroupLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0,
        assetsForCreateGroup: undefined
      }
    },
    assetsForCreateGroupUpdateAction: (state, action: PayloadAction<{ data: AssetForGroup, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload.httpStatus,
        assetsForCreateGroup: action.payload.data
      }
    },
    assetsForCreateGroupErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        assetsForCreateGroup: undefined
      }
    }
  }
})

export const {
  assetsForCreateGroupLoadingAction,
  assetsForCreateGroupUpdateAction,
  assetsForCreateGroupErrorAction
} = assetsForCreateGroupSlice.actions

export default assetsForCreateGroupSlice.reducer
