/* istanbul ignore file */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type HistoryADSDataObject } from '../types'

export interface ExportADSKpiDetailsState {
  isLoading: boolean
  error: string | undefined
  exportADSKpiDetails: HistoryADSDataObject | undefined
  httpStatus: number
}

// Initial state of the slice
const initialState: ExportADSKpiDetailsState = {
  isLoading: true,
  error: undefined,
  exportADSKpiDetails: undefined,
  httpStatus: 0
}

// Slice to return from action
export const exportADSKpiDetailsSlice = createSlice({
  name: 'exportADSKpiDetails',
  initialState,
  reducers: {
    exportADSKpiDetailsLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        exportADSKpiDetails: undefined,
        httpStatus: 0
      }
    },
    exportADSKpiDetailsUpdateAction: (state, action: PayloadAction<{ data: HistoryADSDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        exportADSKpiDetails: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    exportADSKpiDetailsErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        exportADSKpiDetails: undefined,
        httpStatus: action.payload.httpStatus
      }
    },
    // istanbul ignore next
    exportADSKpiDetailsResetAction: () => {
      return {
        isLoading: false,
        error: undefined,
        exportADSKpiDetails: undefined,
        httpStatus: 0
      }
    }
  }
})

// Actions to export
export const {
  exportADSKpiDetailsLoadingAction,
  exportADSKpiDetailsUpdateAction,
  exportADSKpiDetailsErrorAction,
  exportADSKpiDetailsResetAction
} = exportADSKpiDetailsSlice.actions

export default exportADSKpiDetailsSlice.reducer
