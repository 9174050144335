import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type AssetGroupObj } from '../services/assetGroupListService'

export interface AssetGroupsListState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null
  assetGroupsList: AssetGroupObj | null
}

const initialState: AssetGroupsListState = {
  isLoading: false,
  error: null,
  httpStatus: 0,
  assetGroupsList: null
}

const assetGroupsListSlice = createSlice({
  name: 'assetGroupsList',
  initialState,
  reducers: {
    assetGroupsListLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0,
        assetGroupsList: null
      }
    },
    assetGroupsListUpdateAction: (state, action: PayloadAction<{ data: AssetGroupObj, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.httpStatus,
        assetGroupsList: action.payload.data
      }
    },
    assetGroupsListErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        assetGroupsList: null
      }
    }
  }
})

export const {
  assetGroupsListLoadingAction,
  assetGroupsListUpdateAction,
  assetGroupsListErrorAction
} = assetGroupsListSlice.actions

export default assetGroupsListSlice.reducer
