import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type firmwareCampaignDetailsData } from '../services/firmwareUpgradeDetailsService'

export interface firmwareCampaignDetailsState {
  isLoading: boolean
  loadingError: string | null
  firmwareCampaignDetails: firmwareCampaignDetailsData | null
  httpStatus: number | null
}

// Initial state of the slice
const initialState: firmwareCampaignDetailsState = {
  isLoading: true,
  loadingError: null,
  firmwareCampaignDetails: null,
  httpStatus: null
}

// Slice to return from action
export const firmwareCampaignDetailsSlice = createSlice({
  name: 'firmwareListData',
  initialState,
  reducers: {
    firmwareCampaignDetailsLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        firmwareCampaignDetails: null,
        httpStatus: 0
      }
    },
    firmwareCampaignDetailsUpdateAction: (state, action: PayloadAction<{ data: firmwareCampaignDetailsData, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: null,
        firmwareCampaignDetails: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    firmwareCampaignDetailsErrorAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        firmwareCampaignDetails: null
      }
    }
  }
})

// Actions to export
export const {
  firmwareCampaignDetailsLoadingAction,
  firmwareCampaignDetailsUpdateAction,
  firmwareCampaignDetailsErrorAction
} = firmwareCampaignDetailsSlice.actions

export default firmwareCampaignDetailsSlice.reducer
