import React from 'react'
import warningParam from '../../../assets/warningParam.svg'
import selectCaution from '../../../assets/selectCaution.svg'
import LanguageStrings from '../../../i18n/locales'

const WarningString = LanguageStrings().AssetConfigurationStrings.components.warnings

export const ParameterWarning = (): JSX.Element => {
  return (
    <div style={{ display: 'flex', backgroundColor: '#404040', margin: '2em', padding: '0.005em 1em calc(0.005em + 20px) 3em', alignItems: 'flex-end', borderRadius: '10px' }}>
      <img src={warningParam} />
      <h3 style={{ color: 'white', paddingRight: '4px', paddingLeft: '20px', paddingBottom: '6px' }}>{WarningString.PLEASE_ADD_PARAMETERS}</h3>
    </div>
  )
}

export const CautionWarining = (props: { assetType: string, category: string }): JSX.Element => {
  return (
    <div style={{ display: 'flex', backgroundColor: '#404040', margin: '2em', padding: '2em', alignItems: 'flex-end', borderRadius: '10px', width: 'fit-content' }}>
      <img src={selectCaution} />
      <div style={{ margin: '3em' }}>
        <h3 style={{ color: 'white' }}>{WarningString.SELECT_CATEGORY_AND_ASSET_TYPE}</h3>
        <p>{WarningString.This_could_be_because}</p>
        <ul>
          { props.category === '' ? <li>{WarningString.You_have_not_selected_a_category}</li> : null }
          { props.assetType === '' ? <li>{WarningString.You_have_not_selected_an_asset_type}</li> : null }
        </ul>
      </div>
    </div>
  )
}
