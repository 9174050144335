import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ProtocolsDetails } from '../services/getProtocolsDetailsById'

export interface ProtocolsDetailsState {
  isLoading: boolean
  errorMessage: string | undefined
  ProtocolsDetails: ProtocolsDetails | undefined
  httpStatus: number | undefined
}

// Initial state of the slice
const initialState: ProtocolsDetailsState = {
  isLoading: true,
  errorMessage: undefined,
  ProtocolsDetails: undefined,
  httpStatus: undefined
}

// Slice to return from action
export const ProtocolsDetailsDataState = createSlice({
  name: 'SchdulerDetailsState',
  initialState,
  reducers: {
    ProtocolsDetailsLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        errorMessage: undefined,
        ProtocolsDetails: undefined,
        httpStatus: 0
      }
    },
    ProtocolsDetailsUpdateAction: (state, action: PayloadAction<{ data: ProtocolsDetails, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: undefined,
        ProtocolsDetails: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    ProtocolsDetailsErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        ProtocolsDetails: undefined
      }
    }
  }
})

// Actions to export
export const {
  ProtocolsDetailsLoadingAction,
  ProtocolsDetailsUpdateAction,
  ProtocolsDetailsErrorAction
} = ProtocolsDetailsDataState.actions

export default ProtocolsDetailsDataState.reducer
