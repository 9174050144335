// Notification.tsx
import React, { useEffect, useState } from 'react'
import './Notification.css'
import CloseIcon from '@mui/icons-material/Close'
import NotificationBadgeSuccessNew from '../assets/NotificationBadgeSuccessNew.svg'
import NotificationBadgeFailure from '../assets/NotificationBadgeFailure.svg'
import { CircularProgress } from '@mui/material'

interface NotificationProps {
  message: string
  classes?: string
  httpStatus: number
  loading: boolean
  containerStyle?: React.CSSProperties
  messageStyle?: React.CSSProperties
  closeIconStyle?: React.CSSProperties
  onClose?: () => void
}

const Notification = (props: NotificationProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState(true)

  const handleClose = (): void => {
    setIsVisible(false)
    if (props.onClose != null) {
      props.onClose()
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (props.message) {
      setIsVisible(true)
    }
  }, [props.message])

  if (!isVisible) return <></>

  const handleMessageIcon = (httpStatus: number): string => {
    if (httpStatus === 200) {
      return NotificationBadgeSuccessNew
    }
    return NotificationBadgeFailure
  }

  return (
    <div style={{ ...props.containerStyle }} className={`notification ${props.classes ?? ''}`}>
    {props.loading
      ? <div style={{ width: '100px' }}><CircularProgress sx={{ width: '5px', height: '5px' }}/></div>
      : <><img className="customNotificationImage" data-testid='customNotificationImage' src={handleMessageIcon(props.httpStatus)} /><span className="message" style={props.messageStyle}>{props.message}</span><span className="close-icon" style={props.closeIconStyle} onClick={handleClose}>
        <CloseIcon />
      </span></>}
    </div>
  )
}

export default Notification
