/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import Select, { type StylesConfig } from 'react-select'
import './GenXDropdown.css'

export interface Option {
  value: string
  label: string
}

const injectLabel = (label: string | undefined): JSX.Element => {
  return <span style={{ fontSize: '15px', paddingBottom: 10 }}>{label}</span>
}

interface GenXDropdownProps {
  options: Option[]
  value: Option | null
  onSelect: (selectedValue: Option, index?: number) => void
  placeholder?: string
  width?: number | string
  height?: number
  label?: string
  disabled?: boolean
}

export function GenXDropdown ({ options, onSelect, value, placeholder, width, label, height, disabled }: GenXDropdownProps): JSX.Element {
  const customStyles: StylesConfig = {
    control: (provided: any, { isFocused }) => ({
      ...provided,
      border: isFocused ? '2px solid #64C3FF' : '1px solid #404040',
      borderRadius: '4px',
      boxShadow: 'none',
      backgroundColor: '#272727',
      width: width !== undefined ? width : 260,
      cursor: 'pointer',
      height: 40,
      minHeight: 40,
      padding: 0,
      color: '#ffffff',
      opacity: disabled ? '0.5' : '1'
    }),
    option: (styles: any, { isSelected, isFocused }) => ({
      ...styles,
      color: isSelected ? '#64C3FF' : '#f0f0f0',
      backgroundColor: (isSelected) ? '#1792E51A' : (isFocused) ? '#303030' : '#404040',
      width: width !== undefined ? width : 260,
      overflow: 'ellipses',
      padding: '5px 8px'
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      width: width !== undefined ? width : 260,
      backgroundColor: '#404040'
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      height: 40,
      paddingLeft: '8px'
    }),
    indicatorsContainer: (styles: any) => ({
      ...styles,
      height: 40,
      paddingRight: '4px'
    })
  }

  const handleChange = (selectedOption: Option, index?: number): void => {
    if (selectedOption != null) {
      onSelect(selectedOption, index)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {label !== undefined && injectLabel(label)}
      <Select
        isDisabled={disabled}
        value={value}
        options={options}
        classNames={{
          option: (props) => {
            return (props.data !== null && props.data !== undefined && (props.data as { label?: string }).label !== undefined && (props.data as { label?: string }).label !== undefined) ? `${(props.data as { label?: string }).label}-option` : 'genx-dropdown-option'
          }
        }}
        isSearchable
        inputId={`genx-dropdown ${label}-genx-dropdown`}
        maxMenuHeight={height !== undefined ? height : 150}
        // @ts-expect-error - typescript is not able to determine the type
        onChange={handleChange}
        placeholder={placeholder !== undefined ? placeholder : 'Select an option'}
        unstyled
        styles={customStyles}
        theme={(theme: any) => ({
          ...theme,
          borderRadius: 0,
          height: 40,
          colors: {
            ...theme.colors,
            primary25: 'hotpink',
            neutral0: '#404040'
          }
        })}
      />
    </div>
  )
}
