import React, { useEffect, useState } from 'react'
import Modal from '../../../components/Modal'
import { CircularProgress, Stack } from '@mui/material'
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { useAuth } from 'react-oidc-context'
import LanguageStrings from '../../../i18n/locales'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import './CreateCampaignPopup.css'
import { isValidName } from '../../../utils/validator'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { getFirmwareUpgradeDetails, firmwareEditCampaign } from '../redux/actionCreators'
import { type firmwareCampaignDetailsState } from '../redux/firmwareUpgradeDetailsSlice'
import { type FirmwareEditCampaignState } from '../redux/firmwareEditCampaignSlice'
import { type FirmwareEditCampaignRequest } from '../services/firmwareEditCampaignService'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

interface Option {
  value: string
  label: string
  count: number
  countName: string
  checkBox: boolean
}

interface ISelectedGroups {
  group_id: string
  group_name: string
  status: number
}

// istanbul ignore next
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #E6E8ED'
    },
    color: 'white'
  }
}))

const EditCampaignPopupStrings = LanguageStrings().FirmwareCampaignPopupStrings
const EditCampaignPopup = (props: AllProps): JSX.Element => {
  const [groupOptions, setGroupOptions] = useState<Option[]>([])
  const [group, setGroup] = useState<string[]>([])
  const [created, setCreated] = useState(false)
  const [showSuccessPopUp, setshowSuccessPopUp] = useState(false)
  const [maximumAssetPopup, setMaximumAssetPopup] = useState(false)
  const [campaignTitle, setCampaignTitle] = useState('')
  const [titleError, setTitleError] = useState(false)
  const [lenghtError, setLenghtError] = useState(false)
  const auth = useAuth()
  const navigate = useNavigate()

  const rows = useSelector((state: any) => state.firmwareCampaignDetailsState.firmwareCampaignDetails)

  // istanbul ignore next
  useEffect(() => {
    if (rows !== undefined && rows !== null) {
      setCampaignTitle(rows.firmwareCampaignName)
      configureGroupOptions()
    }
  }, [rows])

  // istanbul ignore next
  useEffect(() => {
    apiCall()
  }, [props.selectedCampaignID])

  // istanbul ignore next
  const apiCall = (): void => {
    if (props.selectedCampaignID !== undefined) {
      props.getFirmwareUpgradeDetails(props?.selectedCampaignID, auth.user?.profile.customer as string)
    }
  }

  // istanbul ignore next
  const configureGroupOptions = (): void => {
    let transformedArray: React.SetStateAction<Option[]> = []
    let selectedArr = []
    if (
      rows.assetGroupDTOList !== null &&
      rows.assetGroupDTOList !== undefined
    ) {
      transformedArray = rows.assetGroupDTOList.map((item: ISelectedGroups) => ({
        value: item.group_id,
        label: item.group_name,
        status: item.status
      }))
      selectedArr = rows.assetGroupDTOList.map((item: ISelectedGroups) => (item.group_id))
    }
    setGroupOptions(transformedArray)
    setGroup(selectedArr)
  }

  // istanbul ignore next
  const handleChooseGroup = (event: SelectChangeEvent<string[]>): void => {
    const { target: { value } } = event
    if (getSumOfAssets(value, groupOptions) > 500000) {
      setMaximumAssetPopup(true)
    } else {
      setGroup(typeof value === 'string' ? value.split(',') : value)
    }
  }

  // istanbul ignore next
  const getSumOfAssets = (selectedGroup: string | string[], allGroup: Option[]): any => {
    let sum = 0
    allGroup.forEach((item) => {
      if (selectedGroup.includes(item.value)) {
        sum += item.count
      }
    })
    return sum
  }

  // istanbul ignore next
  const handleClickPositiveCreateAndUpdate = (): void => {
    props.firmwareEditCampaign({
      firmwareCampaignId: props.selectedCampaignID,
      firmwareCampaignName: campaignTitle,
      assetGroupDTOList: group
    })
  }

  // istanbul ignore next
  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value
    setCampaignTitle(value)
    setLenghtError(value.length < 5 || value.length > 20)
    if (isValidName(value)) {
      setTitleError(false)
    } else {
      setTitleError(true)
    }
  }

  // istanbul ignore next
  const isPositiveDisabled = (): boolean => {
    if (campaignTitle.length <= 0) {
      return true
    } else if (titleError || lenghtError || group.length === 0) {
      return true
    } else {
      return false
    }
  }

  // istanbul ignore next
  const editCampaignHandleSuccess = (): void => {
    setshowSuccessPopUp(false)
    setCreated(true)
    handleClickPositiveCreateAndUpdate()
  }

  // istanbul ignore next
  const handleGenericDropdownWithFailure = (): JSX.Element => {
    if (props.firmwareCampaignDetailsState?.isLoading) {
      return (
        <>
          <div className='CircularprogressAmi'>
            <CircularProgress />
          </div>
        </>
      )
    }
    if ((rows !== null && rows !== undefined && props.firmwareCampaignDetailsState?.httpStatus === 200) || props.firmwareCampaignDetailsState.httpStatus === 404) {
      return (
        <Stack spacing={3}>
          <Stack>
            <Typography
              variant='body2'
              sx={{ fontSize: '15px' }}
              display='block'
              gutterBottom
              data-testid='edit-campaign-title'
            >
              {EditCampaignPopupStrings.CampaignName}{' '}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </Typography>
            <TextField
              id='outlined-basic'
              value={campaignTitle}
              onChange={handleTitleChange}
              variant='outlined'
              className='campaign-popup-custom-textfield'
              data-testid='edit-campaign-textfield'
              inputProps={{ 'aria-label': 'campaignName' }}
            />
            <div>
              {
                // istanbul ignore next
                titleError && (
                  <p style={{ color: '#EE3426' }}>
                    {EditCampaignPopupStrings.invalid_text}
                  </p>
                )
              }
              {
                // istanbul ignore next
                lenghtError && (
                  <p style={{ color: '#EE3426' }}>
                    {EditCampaignPopupStrings.min_max_charecter}
                  </p>
                )
              }
            </div>
          </Stack>
          <div>
            <span style={{ fontSize: '15px', padding: '7px 0px', display: 'block' }} >
              {EditCampaignPopupStrings.Select_Group}
              <sup style={{ color: '#EE3426' }}> *</sup>
            </span>
            <FormControl style={{ width: '100%' }}>
              <Select
                labelId="choose-group-checkbox-label"
                multiple
                value={group}
                onChange={handleChooseGroup}
                sx={{ height: '40px', backgroundColor: '#5c5b5bdd', borderRadius: '4px', color: '#d0d0d0' }}
                // renderValue={(selected: any) => `${selected.length}  selected`}
                MenuProps={MenuProps}
                aria-label='user-change'
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>{EditCampaignPopupStrings.Select_an_option}</em>
                  }
                  return `${selected.length}  selected`
                }}
                displayEmpty
              >
                {groupOptions?.map((item, index) => (
                  <MenuItem key={item.value} value={item.value} disabled={true} >
                    <Checkbox
                      inputProps={{ id: `select-item-${index}` }}
                      checked={true}
                      sx={{ color: '#909090' }}
                    />
                    <LightTooltip
                      arrow
                      placement="top"
                      title={item.label.length > 15 ? `${item.label}` : ''}
                    >
                      <ListItemText
                        primary={item.label.length > 15 ? `${item.label.substring(0, 15)}..` : item.label}
                      />
                    </LightTooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p style={{ marginTop: '0.5rem' }} >{EditCampaignPopupStrings.max_50k_assets_allowed}</p>
          </div>
          <FormControl>
            <Typography
              variant='body2'
              sx={{ fontSize: '15px' }}
              display='block'
              gutterBottom
            >
              {EditCampaignPopupStrings.schedule}{' '}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </Typography>
            <RadioGroup
              defaultValue={EditCampaignPopupStrings.immediate}
              name='radio-buttons-group'
              data-testid='edit-campaign-schedule-radio-btn'
            >
              <FormControlLabel
                value={EditCampaignPopupStrings.immediate}
                control={<Radio />}
                label={EditCampaignPopupStrings.immediate}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      )
    } else if (props.firmwareCampaignDetailsState?.httpStatus === 401) {
      return (
      <div className='authError'><AuthError errorMessage='Unauthorized' /></div>
      )
    } else if (props.firmwareCampaignDetailsState?.httpStatus === 403) {
      return (
      <div className='authError'><AuthError errorMessage='accessForbidden' /></div>
      )
    } else {
      return (
      <div className='authError'>
        <AuthError errorMessage='cannotFetch' retry={apiCall} />
      </div>
      )
    }
  }

  // istanbul ignore next
  const handleSuccessCreate = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (props.firmwareEditCampaignState.isLoading) {
      return (
        <Modal title={EditCampaignPopupStrings.EditTitle}>
          <div className='CircularprogressAmi'>
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.firmwareEditCampaignState.httpStatus === 200) {
      return (
        <Modal
          buttonTypeNegative='hollow'
          title={EditCampaignPopupStrings.edit_sucess}
          LabelPositive='Ok'
          onClickPositive={() => {
            setCreated(false)
            setshowSuccessPopUp(false)
            props.onEditCampaignPopUpClose()
          }}
          onClose={() => {
            setCreated(false)
            setshowSuccessPopUp(false)
          }}
          onClickNegative={() => {
            navigate('/firmware-upgrade-status')
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {EditCampaignPopupStrings.campaign_has_been_updated_successfully}
          </p>
        </Modal>
      )
    } else if (props.firmwareEditCampaignState.httpStatus === 401) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative='hollow'
          LabelNegative='Close'
          onClickNegative={() => {
            props.onEditCampaignPopUpClose()
          }}
          onClose={() => {
            props.onEditCampaignPopUpClose()
          }}
          title={EditCampaignPopupStrings.create_campaign_unsuccessfull_title}
        >
          <div className='authError'>
            <AuthError
              className={'errorInsideGroupModal'}
              errorMessage='Unauthorized'
            />
          </div>
        </Modal>
      )
    } else if (props.firmwareEditCampaignState.httpStatus === 403) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative='hollow'
          LabelNegative='Close'
          onClickNegative={() => {
            props.onEditCampaignPopUpClose()
          }}
          onClose={() => {
            props.onEditCampaignPopUpClose()
          }}
          title={EditCampaignPopupStrings.create_campaign_unsuccessfull_title}
        >
          <div className='authError'>
            <AuthError
              className={'errorInsideGroupModal'}
              errorMessage='accessForbidden'
            />
          </div>
        </Modal>
      )
    } else if (props.firmwareEditCampaignState.httpStatus === 400) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          height={'fit-content'}
          buttonTypeNegative='hollow'
          LabelNegative='Close'
          onClickNegative={() => {
            props.onEditCampaignPopUpClose()
          }}
          onClose={() => {
            props.onEditCampaignPopUpClose()
          }}
          title={EditCampaignPopupStrings.create_campaign_unsuccessfull_title}
        >
          <div className='authError'>
            <AuthError
              className={'errorInsideGroupModal'}
              customError={props.firmwareEditCampaignState.error}
            />
          </div>
        </Modal>
      )
    } else {
      return (
        <Modal
          title={'Error'}
          onClose={() => {
            props.onEditCampaignPopUpClose()
          }}
        >
          <div className={'errorInsideGroupModal'} style={ { width: '100%', fontSize: '14px', padding: '0px 20px' } } >
            <p>{props.firmwareEditCampaignState.error}</p>
          </div>
        </Modal>
      )
    }
  }
  return (
    <div>
      {(!showSuccessPopUp || showSuccessPopUp) && (
        <Modal
          buttonTypeNegative='hollow'
          title={EditCampaignPopupStrings.EditTitle}
          LabelPositive={EditCampaignPopupStrings.edit}
          LabelNegative={EditCampaignPopupStrings.Cancel}
          positiveDisabled={isPositiveDisabled()}
          height={'fit-content'}
          onClickPositive={() => {
            // istanbul ignore next
            setshowSuccessPopUp(true)
          }}
          onClickNegative={() => {
            // istanbul ignore next
            props.onEditCampaignPopUpClose()
          }}
          onClose={() => {
            props.onEditCampaignPopUpClose()
          }}
        >
          <div style={{ marginTop: '3em' }} >
            {handleGenericDropdownWithFailure()}
          </div>
          <div
            style={{
              marginTop: '1.5em',
              display: 'flex',
              justifyContent: 'center'
            }}
          ></div>
        </Modal>
      )}
      {showSuccessPopUp && (
        // istanbul ignore next
        <Modal
          buttonTypeNegative='hollow'
          title={EditCampaignPopupStrings.EditTitle}
          LabelPositive='Confirm'
          LabelNegative='Cancel'
          onClickPositive={() => {
            editCampaignHandleSuccess()
          }}
          onClose={() => {
            setshowSuccessPopUp(false)
          }}
          onClickNegative={() => {
            setshowSuccessPopUp(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {
              EditCampaignPopupStrings.edit_confirmation
            }
          </p>
        </Modal>
      )}
      {maximumAssetPopup && (
        // istanbul ignore next
        <Modal
          buttonTypeNegative='hollow'
          title={EditCampaignPopupStrings.maximum_asset_title}
          LabelPositive='Ok'
          onClickPositive={() => {
            setMaximumAssetPopup(false)
          }}
          onClose={() => {
            setMaximumAssetPopup(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {
              EditCampaignPopupStrings.maximum_asset_body
            }
          </p>
        </Modal>
      )}
      {created && handleSuccessCreate()}
    </div>
  )
}

interface DispatchToProps {
  getFirmwareUpgradeDetails: (selectedCampaignID: string, tenantId: string) => void
  firmwareEditCampaign: (editCampaignBody: FirmwareEditCampaignRequest) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getFirmwareUpgradeDetails: (selectedCampaignID: string, tenantId: string) =>
    dispatch(getFirmwareUpgradeDetails(selectedCampaignID, tenantId)),
  firmwareEditCampaign: (editCampaignBody: FirmwareEditCampaignRequest) =>
    dispatch(firmwareEditCampaign(editCampaignBody))
})
interface StateToProps {
  firmwareCampaignDetailsState: firmwareCampaignDetailsState
  firmwareEditCampaignState: FirmwareEditCampaignState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  firmwareCampaignDetailsState: state.firmwareCampaignDetailsState,
  firmwareEditCampaignState: state.firmwareEditCampaignState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export interface ParentProps {
  onEditCampaignPopUpClose: () => void
  selectedCampaignID: string
  useCustomPositveFn?: boolean
  customPositiveFn?: () => void
  refreshFirmwareList?: () => void
}
type AllProps = PropsFromRedux & ParentProps

export default connector(EditCampaignPopup)
