// istanbul ignore file
import { useState, useEffect } from 'react'

export function usePieChartDim () {
  const [height, setHeight] = useState(getHeight())
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      setHeight(getHeight())
    }, 100) // 100ms
    window.addEventListener('resize', debouncedResizeHandler)
    return () => window.removeEventListener('resize', debouncedResizeHandler)
  }, [])
  return [height]
}

export function debounce (fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

function getHeight () {
  const width = window.innerWidth
  let height
  if (width >= 1896) {
    height = '280px'
  } else if (width >= 1532) {
    height = '250px'
  } else if (width >= 1368) {
    height = '200px'
  } else if (width >= 1100) {
    height = '190px'
  } else {
    height = '170px'
  }
  return height
}
