import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Configuration } from '../services/configurationServiceForStatus'

export interface ConfigurationsForStatusState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null
  configurationsWithStatus: Configuration | null
}

const initialState: ConfigurationsForStatusState = {
  isLoading: true,
  error: null,
  httpStatus: null,
  configurationsWithStatus: null
}

const configurationsForStatusSlice = createSlice({
  name: 'configurationsForStatus',
  initialState,
  reducers: {
    configurationsForStatusLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0,
        configurationsWithStatus: null
      }
    },
    configurationsForStatusUpdateAction: (state, action: PayloadAction<{ data: Configuration, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.httpStatus,
        configurationsWithStatus: action.payload.data
      }
    },
    configurationsForStatusErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        configurationsWithStatus: null
      }
    }
  }
})

export const {
  configurationsForStatusLoadingAction,
  configurationsForStatusUpdateAction,
  configurationsForStatusErrorAction
} = configurationsForStatusSlice.actions

export default configurationsForStatusSlice.reducer
