/* eslint-disable */
import * as DeviceDetailsService from '../services/getDeviceDetails'
import * as AssetProvisioningHistoryDetailsService from '../services/getAssetProvisioningHistory'
import * as DeviceProvisioningHistoryDetails from '../services/getDeviceProvisioningData'
import * as ExportProvisioningHistory from '../services/exportProvisioningHistory'
import * as ExportAssetProvisioningDetails from '../services/exportAssetProvisioningDetails'
import * as ExportProvisioningHistoryById from '../services/exportProvisioningHistoryById'
import { deviceDataLoadingAction, deviceDataUpdateAction, deviceDataErrorAction } from './ShowAssetDetailsSlice'
import { provisioningHistoryFilterStatusLoadingAction, provisioningHistoryFilterStatusUpdateAction, provisioningHistoryFilterStatusErrorAction } from './provisioningHistoryFilterStatusSlice'
import { provisioningHistoryFilterUploadedUsersLoadingAction, provisioningHistoryFilterUploadedUsersUpdateAction, provisioningHistoryFilterUploadedUsersErrorAction } from './provisioningHistoryFilterUploadedUsersSlice'
import { provisioningHistoryDetailsStateFilterLoadingAction, provisioningHistoryDetailsStateFilterUpdateAction, provisioningHistoryDetailsStateFilterErrorAction } from './provisioningHistoryDetailsFilterStateSlice'
import { provisioningHistoryDetailsFilterDeviceTypeLoadingAction, provisioningHistoryDetailsFilterDeviceTypeUpdateAction, provisioningHistoryDetailsFilterDeviceTypeErrorAction } from './provisioningHistoryDetailsFilterDeviceTypeSlice'
import { assetProvisioningHistoryDataLoadingAction, assetProvisioningHistoryDataUpdateAction, assetProvisioningHistoryDataErrorAction } from './assetProvisioingHistoryInfoSlice'
import { deviceProvisioningDataLoadingAction, deviceProvisioningDataUpdateAction, deviceProvisioningDataErrorAction } from './ProvisioningHistorySummaryDetailsInfoSlice'
import { exportAssetProvisioningDetailsLoadingAction, exportAssetProvisioningDetailsUpdateAction, exportAssetProvisioningDetailsErrorAction } from './exportAssetProvisioningDetailsSlice'
import { exportProvisioningHistoryLoadingAction, exportProvisioningHistoryUpdateAction, exportProvisioningHistoryErrorAction } from './exportProvisioningHistorySlice'
import { exportProvisioningHistoryByIdLoadingAction, exportProvisioningHistoryByIdUpdateAction, exportProvisioningHistoryByIdErrorAction } from './exportProvisioningHistoryByIdSlice'
import { allGroupDataErrorAction, allGroupDataLoadingAction, allGroupDataUpdateAction } from './groupListSlice'
import * as AllGroupService from '../services/groupListService'
import * as GroupCreationService from '../services/createAssetGroupService'
import * as GroupUpdationService from '../services/updateAssetGroupService'
import { type GroupCreationRequest } from '../services/createAssetGroupService'
import { type GroupUpdationRequest } from '../services/updateAssetGroupService'
import { AppDispatch } from '../../../store'
import { createAssetGroupLoadingAction, createAssetGroupUpdateAction, createAssetGroupErrorAction } from './createGroupSlice'
import { updateAssetGroupErrorAction, updateAssetGroupLoadingAction, updateAssetGroupUpdateAction } from './updateGroupSlice'
import { seedErrorAction, seedLoadingAction, seedSuccessUpdateAction } from './setC12.22SeedSlice'
import { SeedRequest } from '../services/setC12.22SeedService'
import * as SetC1222Seed from '../services/setC12.22SeedService'

export const getDeviceData = (page: number, size: number, sortdir: string, sortfield: string, fileId: number, search: any, tenantId: string) => async (dispatch: any) => {
  dispatch(deviceDataLoadingAction())
  try {
    await DeviceDetailsService.getDeviceDetails(page, size, sortdir, sortfield, fileId, search, tenantId, (deviceData, httpStatus) => {
      dispatch(deviceDataUpdateAction({ data: deviceData.provisioningDetails, total_count: deviceData.total_count, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(deviceDataErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(deviceDataErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getDeviceHistoryData = (page: number, size: number, sortdir: string, sortfield: string, search: any, tenantId: string) => async (dispatch: any) => {
  dispatch(assetProvisioningHistoryDataLoadingAction())
  try {
    await AssetProvisioningHistoryDetailsService.getAssetProvisioningHistoryDetails(page, size, sortdir, sortfield, search, tenantId, (assetProvisioningHistoryData, httpStatus) => {
      dispatch(assetProvisioningHistoryDataUpdateAction({ data: assetProvisioningHistoryData.provisioningHistory, total_count: assetProvisioningHistoryData.total_count, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(assetProvisioningHistoryDataErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(assetProvisioningHistoryDataErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getAssetProvisioningHistoryDetails = (page: number, size: number, sortdir: string, sortfield: string, provisioningHistoryId: number, search: any, tenantId: string) => async (dispatch: any) => {
  dispatch(deviceProvisioningDataLoadingAction())
  try {
    await DeviceProvisioningHistoryDetails.getDeviceProvisioningData(page, size, sortdir, sortfield, provisioningHistoryId, search, tenantId, (deviceProvisioningData, httpStatus) => {
      dispatch(deviceProvisioningDataUpdateAction({ data: deviceProvisioningData.provisioningDetails, total_count: deviceProvisioningData.total_count, httpStatus }))
    },
    (errorMessage, httpStatus ) => {
      dispatch(deviceProvisioningDataErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(deviceProvisioningDataErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const exportAssetProvisioningDetails = (fileId: string, tenantId: string) => async (dispatch: any) => {
  dispatch(exportAssetProvisioningDetailsLoadingAction())
  try {
    await ExportAssetProvisioningDetails.exportAssetProvisioningDetails(fileId, tenantId, (message: any) => {
      dispatch(exportAssetProvisioningDetailsUpdateAction(message))
    },
    (errorMessage: any) => {
      dispatch(exportAssetProvisioningDetailsErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(exportAssetProvisioningDetailsErrorAction(e))
  }
}

export const exportProvisioningHistory = (tenantId: string) => async (dispatch: any) => {
  dispatch(exportProvisioningHistoryLoadingAction())
  try {
    await ExportProvisioningHistory.exportProvisioningHistory(tenantId, (message: any) => {
      dispatch(exportProvisioningHistoryUpdateAction(message))
    },
    (errorMessage: any) => {
      dispatch(exportProvisioningHistoryErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(exportProvisioningHistoryErrorAction(e))
  }
}
export const exportProvisioningHistoryById = (fileId: string, tenantId: string) => async (dispatch: any) => {
  dispatch(exportProvisioningHistoryByIdLoadingAction())
  try {
    await ExportProvisioningHistoryById.exportProvisioningHistoryById(fileId, tenantId, (message: any) => {
      dispatch(exportProvisioningHistoryByIdUpdateAction(message))
    },
    (errorMessage: any) => {
      dispatch(exportProvisioningHistoryByIdErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(exportProvisioningHistoryByIdErrorAction(e))
  }
}

export const getProvisioningHistoryFilterStatus = (tenantId: string) => async (dispatch: any) => {
  dispatch(provisioningHistoryFilterStatusLoadingAction())
  try {
    await AssetProvisioningHistoryDetailsService.getAssetProvisioningHistoryFilterStatus(tenantId, (data, httpStatus) => {
      dispatch(provisioningHistoryFilterStatusUpdateAction({data, httpStatus}))
    },
    (errorMessage, httpStatus) => {
      dispatch(provisioningHistoryFilterStatusErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(provisioningHistoryFilterStatusErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}


export const getProvisioningHistoryFilterUploadedUsers = (tenantId: string) => async (dispatch: any) => {
  dispatch(provisioningHistoryFilterUploadedUsersLoadingAction())
  try {
    await AssetProvisioningHistoryDetailsService.getAssetProvisioningHistoryFilterUploadedUsers(tenantId, (data, httpStatus) => {
      dispatch(provisioningHistoryFilterUploadedUsersUpdateAction({data, httpStatus}))
    },
    (errorMessage, httpStatus) => {
      dispatch(provisioningHistoryFilterUploadedUsersErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(provisioningHistoryFilterUploadedUsersErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getAssetProvisioningFilterData = (page: number, size: number, sortdir: string, sortfield: string, filterParams: any, tenantId: string) => async (dispatch: any) => {
  dispatch(assetProvisioningHistoryDataLoadingAction())
  try {
    await AssetProvisioningHistoryDetailsService.getAssetProvisioningHistoryFilteredData(page, size, sortdir, sortfield, filterParams, tenantId, (assetProvisioningHistoryData, httpStatus) => {
      dispatch(assetProvisioningHistoryDataUpdateAction({ data: assetProvisioningHistoryData.provisioningHistory, total_count: assetProvisioningHistoryData.total_count, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(assetProvisioningHistoryDataErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(assetProvisioningHistoryDataErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getAssetProvisioningDetailsFilterDeviceType: any = (tenantId: string) => async (dispatch: any) => {
  dispatch(provisioningHistoryDetailsFilterDeviceTypeLoadingAction())
  try {
    await DeviceDetailsService.getAssetProvisioningHistoryDetailsFilterDeviceType(tenantId, (data, httpStatus) => {
      dispatch(provisioningHistoryDetailsFilterDeviceTypeUpdateAction({data, httpStatus}))
    },
    (errorMessage, httpStatus) => {
      dispatch(provisioningHistoryDetailsFilterDeviceTypeErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(provisioningHistoryDetailsFilterDeviceTypeErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getAssetProvisioningDetailsFilterState = (tenantId: string) => async (dispatch: any) => {
  dispatch(provisioningHistoryDetailsStateFilterLoadingAction())
  try {
    await DeviceDetailsService.getAssetProvisioningHistoryDetailsFilterState(tenantId, (data, httpStatus) => {
      dispatch(provisioningHistoryDetailsStateFilterUpdateAction({ data, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(provisioningHistoryDetailsStateFilterErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(provisioningHistoryDetailsStateFilterErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}


export const getAssetProvisioningDetailsFilterData = (page: number, size: number, sortdir: string, sortfield: string, fileId: number, filterParams: any, tenantId: string) => async (dispatch: any) => {
  dispatch(deviceDataLoadingAction())
  try {
    await DeviceDetailsService.getAssetProvisioningDetailsFilteredData(page, size, sortdir, sortfield, fileId, filterParams, tenantId, (deviceData, httpStatus) => {
      dispatch(deviceDataUpdateAction({ data: deviceData.provisioningDetails, total_count: deviceData.total_count, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(deviceDataErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(deviceDataErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getAssetProvisioningHistoryDetailsByIdFilteredData = (page: number, size: number, sortdir: string, sortfield: string, provisioningHistoryId: number, filterParams: any, tenantId: string) => async (dispatch: any) => {
  dispatch(deviceProvisioningDataLoadingAction())
  try {
    await DeviceProvisioningHistoryDetails.getAssetProvisioingByIdFilteredData(page, size, sortdir, sortfield, provisioningHistoryId, filterParams, tenantId, (deviceProvisioningData, httpStatus) => {
      dispatch(deviceProvisioningDataUpdateAction({ data: deviceProvisioningData.provisioningDetails, total_count: deviceProvisioningData.total_count, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(deviceProvisioningDataErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(deviceProvisioningDataErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const fetchGroupListData = (assetType: string, groupName: string, tenantId: string) => async (dispatch: any): Promise<void> => {
  dispatch(allGroupDataLoadingAction())

  void AllGroupService.getAllGroups(
    assetType, groupName, tenantId,
    (allGroupData, httpStatus) => {
      dispatch(
        allGroupDataUpdateAction({
          data: allGroupData,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(allGroupDataErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const postAssetGroup = ( GroupCreationRequestBody: GroupCreationRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(createAssetGroupLoadingAction())

  void GroupCreationService.createAssetGroup(
    GroupCreationRequestBody,
    (createAssetGroupResponse) => {
      dispatch(createAssetGroupUpdateAction({ data: createAssetGroupResponse }))
    },
    (errorMessage, httpStatus) => {
      dispatch(createAssetGroupErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const putAssetGroup = ( groupUpdationBody: GroupUpdationRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(updateAssetGroupLoadingAction())

  void GroupUpdationService.updateAssetGroup(
    groupUpdationBody,
    (updateAssetGroupResponse) => {
      dispatch(updateAssetGroupUpdateAction({ data: updateAssetGroupResponse }))
    },
    (errorMessage, httpStatus) => {
      dispatch(updateAssetGroupErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const postSeed = (SeedRequestBody: SeedRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(seedLoadingAction())

  void SetC1222Seed.postSeed(
    SeedRequestBody,
    (data, httpStatus) => {
      dispatch(seedSuccessUpdateAction({ data, httpStatus}))
    },
    (errorMessage, httpStatus) => {
      dispatch(seedErrorAction({ errorMessage, httpStatus }))
    }
  )
}
