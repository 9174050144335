import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface SaveKpiUserPreferenceState {
  isLoading: boolean
  data: boolean | undefined
  httpStatus: number
}

const initialState: SaveKpiUserPreferenceState = {
  isLoading: false,
  data: undefined,
  httpStatus: 0
}

const saveKpiUserPreferenceSlice = createSlice({
  name: 'saveKpiUserPreference',
  initialState,
  reducers: {
    saveKpiUserPreferenceLoadingAction: (state) => {
      return {
        isLoading: true,
        data: undefined,
        httpStatus: 0
      }
    },
    saveKpiUserPreferenceUpdateAction: (state, action: PayloadAction<{ data: boolean, httpStatus: number }>) => {
      return {
        isLoading: false,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    saveKpiUserPreferenceErrorAction: (state, action: PayloadAction<{ httpStatus: number }>) => {
      return {
        isLoading: false,
        data: undefined,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  saveKpiUserPreferenceLoadingAction,
  saveKpiUserPreferenceUpdateAction,
  saveKpiUserPreferenceErrorAction
} = saveKpiUserPreferenceSlice.actions

export default saveKpiUserPreferenceSlice.reducer
