import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface AllGroupData {
  asset_group_id: string
  group_name: string
}

export interface AssetGroupState {
  isLoading: boolean
  error: string | undefined
  groupListData: AllGroupData[] | undefined
  httpStatus: number
}

export const initialState: AssetGroupState = {
  isLoading: true,
  error: undefined,
  groupListData: undefined,
  httpStatus: 0
}

const allAlarmsSlice = createSlice({
  name: 'allGroups',
  initialState,
  reducers: {
    allGroupDataLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        groupListData: undefined,
        httpStatus: 0
      }
    },
    allGroupDataUpdateAction: (state, action: PayloadAction<{ data: any, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        groupListData: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    allGroupDataErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        groupListData: undefined,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  allGroupDataLoadingAction,
  allGroupDataUpdateAction,
  allGroupDataErrorAction
} = allAlarmsSlice.actions

export default allAlarmsSlice.reducer
