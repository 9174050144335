import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ParameterData } from '../services/parameterListService'

export interface ParameterListState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null
  parameterList: ParameterData[]
}

const initialState: ParameterListState = {
  isLoading: true,
  error: null,
  httpStatus: null,
  parameterList: []
}

const parameterListSlice = createSlice({
  name: 'config-parameters',
  initialState,
  reducers: {
    parameterListDataLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0,
        parameterList: []
      }
    },
    parameterListDataUpdateAction: (state, action: PayloadAction<{ data: ParameterData[], httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.httpStatus,
        parameterList: action.payload.data
      }
    },
    parameterListDataErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        parameterList: []
      }
    }
  }
})

export const {
  parameterListDataLoadingAction,
  parameterListDataUpdateAction,
  parameterListDataErrorAction
} = parameterListSlice.actions

export default parameterListSlice.reducer
