import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type DeviceHistory } from '../services/deviceLifeCycleHistoryService'

export interface DeviceHistoryState {
  isLoading: boolean
  loadingError: string | undefined
  deviceHistory: DeviceHistory[] | undefined
  deviceCount: number
}

const initialState: DeviceHistoryState = {
  isLoading: true,
  loadingError: undefined,
  deviceHistory: undefined,
  deviceCount: 0
}

const deviceHistorySlice = createSlice({
  name: 'deviceHistory',
  initialState,
  reducers: {
    deviceHistoryLoadingAction: (state) => {
      return {
        isLoading: true,
        loadingError: undefined,
        deviceHistory: undefined,
        deviceCount: 0
      }
    },
    deviceHistoryUpdateAction: (state, action: PayloadAction<{ data: DeviceHistory[], count: number }>) => {
      return {
        isLoading: false,
        loadingError: undefined,
        deviceHistory: action.payload.data,
        deviceCount: action.payload.count
      }
    },
    deviceHistoryErrorAction: (state, action: PayloadAction<string>) => {
      return {
        isLoading: false,
        loadingError: action.payload,
        deviceHistory: undefined,
        deviceCount: 0
      }
    }
  }
})

export const {
  deviceHistoryLoadingAction,
  deviceHistoryUpdateAction,
  deviceHistoryErrorAction
} = deviceHistorySlice.actions

export default deviceHistorySlice.reducer
