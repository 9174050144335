import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface CreateReportTemplateState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
}

const initialState: CreateReportTemplateState = {
  isLoading: false,
  error: undefined,
  httpStatus: undefined
}

const createReportTemplateSlice = createSlice({
  name: 'ReportTemplate-Create',
  initialState,
  reducers: {
    createReportTemplateLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0
      }
    },
    createReportTemplateUpdateAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload
      }
    },
    createReportTemplateErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  createReportTemplateLoadingAction,
  createReportTemplateUpdateAction,
  createReportTemplateErrorAction
} = createReportTemplateSlice.actions

export default createReportTemplateSlice.reducer
