import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type FilterParameter } from '../services/getAllGroupFilterParameter'

export interface AllGroupParamter {
  assetType: any
  categoryType: any
  status: any
}

export interface getAllGroupFilterParameterState {
  isLoading: boolean
  GroupFilterPara: FilterParameter | null
  error: string | null
  httpStatus: number | null
}

const initialState: getAllGroupFilterParameterState = {
  isLoading: false,
  GroupFilterPara: null,
  error: null,
  httpStatus: 0
}

export const getAllGroupFilterParameterSlice = createSlice({
  name: 'GroupFilterParamater',
  initialState,
  reducers: {
    getAllGroupFilterParameterDataLoading: (state): getAllGroupFilterParameterState => {
      return {
        isLoading: true,
        GroupFilterPara: null,
        error: null,
        httpStatus: 0
      }
    },
    getAllGroupFilterParameterDataError: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>): getAllGroupFilterParameterState => {
      return {
        isLoading: false,
        GroupFilterPara: null,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    },
    getAllGroupFilterParameterData: (state, action: PayloadAction<any>): getAllGroupFilterParameterState => {
      return {
        isLoading: false,
        GroupFilterPara: action.payload.data,
        error: null,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const { getAllGroupFilterParameterDataLoading, getAllGroupFilterParameterDataError, getAllGroupFilterParameterData } = getAllGroupFilterParameterSlice.actions
export default getAllGroupFilterParameterSlice.reducer
