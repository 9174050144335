import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type FirmwareCreateCampaignConfigObj } from '../services/firmwareCreateCampaignService'

export interface FirmwareCreateCampaignState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
  campaignData: FirmwareCreateCampaignConfigObj | undefined
}

export const initialState: FirmwareCreateCampaignState = {
  isLoading: false,
  error: undefined,
  httpStatus: undefined,
  campaignData: undefined
}

const FirmwareCreateCampaignSlice = createSlice({
  name: 'firmware-create-campaign',
  initialState,
  reducers: {
    createCampaignLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0,
        campaignData: undefined
      }
    },
    createCampaignUpdateAction: (
      state,
      action: PayloadAction<{ data: FirmwareCreateCampaignConfigObj, httpStatus: number }>
    ) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload.httpStatus,
        campaignData: action.payload.data
      }
    },
    createCampaignErrorAction: (
      state,
      action: PayloadAction<{ errorMessage: string, httpStatus: number }>
    ) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        campaignData: undefined
      }
    }
  }
})

export const {
  createCampaignLoadingAction,
  createCampaignUpdateAction,
  createCampaignErrorAction
} = FirmwareCreateCampaignSlice.actions

export default FirmwareCreateCampaignSlice.reducer
