import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'
import LanguageStrings from '../../../i18n/locales'

const ZUploadFile = z.object({
  message: z.string().nullable(),
  fileId: z.string().nullable(),
  fileName: z.string().nullable()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(ZUploadFile)
})

const APIErrorStrings = LanguageStrings().ErrorStrings

export type UploadFile = z.infer<typeof ZUploadFile>
export async function uploadFile (
  file: File,
  loggedInUser: string,
  tenantID: string,
  uploadType: string,
  onSuccess: (deviceInfo: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const objData: any = {
    uploadedBy: loggedInUser,
    uploadType,
    tenantId: tenantID
  }

  const formData = new FormData()
  formData.append('file', file)
  formData.append('fileUpload', '' + JSON.stringify(objData))
  const url = '/fileuploadservice/api/v1/udp/ami/devices/uploadFile'
  interceptedAxios.post(url, formData)
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          const parsedData = parseResponse.data
          onSuccess(parsedData, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch(
      // istanbul ignore next
      (error) => {
        if (error.response !== null && error.response !== undefined) {
          if (error.response.status === 502) {
            onFailure(APIErrorStrings.BadGateway, error.response.status)
          } else if (error.response.status === 500) {
            onFailure(APIErrorStrings.InternalServerError, error.response.status)
          } else if (error.response.status === 401) {
            onFailure(APIErrorStrings.Unauthorized, error.response.status)
          } else if (error.response.status === 403 && error.response.data.error_message === 'Upload a file with valid signature') {
            onFailure(APIErrorStrings.Upload_a_file_with_valid_signature, 403)
          } else if (error.response.status === 403) {
            onFailure(APIErrorStrings.AccessForbidden, error.response.status)
          } else if (error.response.status === 404) {
            onFailure(APIErrorStrings.PageNotFound, error.response.status)
          } else if (error.response.status === 400) {
            onFailure(error.response.data.error_message, error.response.status)
          } else {
            if (error.response.data !== null && Object.prototype.toString.call(error.response.data) === '[object String]') {
              onFailure(error.response.data, error.response.status)
            } else {
              const parseResponse = ZUploadFile.safeParse(error.response.data)
              if (parseResponse.success) {
                const parsedData = parseResponse.data.message
                onFailure(parsedData, error.response.status)
              } else {
                onFailure(parseResponse.error.message, error.response.status)
              }
            }
          }
        } else {
          onFailure(error.message, error.response.status)
        }
      })
}
