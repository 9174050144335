import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
// import { type AssetStateData } from '../services/changeAssetState'

// What makes up this slice
export interface AssetStatesTypeDataState {
  isLoading: boolean
  loadingError: string | null
  AssetStatesTypeData: any | null
}

// Initial state of the slice
const initialState: AssetStatesTypeDataState = {
  isLoading: true,
  loadingError: null,
  AssetStatesTypeData: null
}

// Slice to return from action
export const assetStatesSlice = createSlice({
  name: 'AssetStatesTypeData',
  initialState,
  reducers: {
    assetStatesDataLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        AssetStatesTypeData: null,
        httpStatus: 0
      }
    },
    assetStatesDataUpdateAction: (state, action: PayloadAction< any >) => {
      return {
        isLoading: false,
        loadingError: null,
        AssetStatesTypeData: action.payload
      }
    },
    assetStatesDataErrorAction: (state, action: PayloadAction<string>) => {
      return {
        isLoading: false,
        loadingError: action.payload,
        AssetStatesTypeData: null
      }
    }
  }
})

// Actions to export
export const {
  assetStatesDataLoadingAction,
  assetStatesDataUpdateAction,
  assetStatesDataErrorAction
} = assetStatesSlice.actions

export default assetStatesSlice.reducer
