/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface FirmwareTypeDataState {
  isLoading: boolean
  loadingError: string | null
  FirmwareTypeData: any | null
}

// Initial state of the slice
const initialState: FirmwareTypeDataState = {
  isLoading: true,
  loadingError: null,
  FirmwareTypeData: null
}

// Slice to return from action
export const FirmwareTypeDataSlice = createSlice({
  name: 'FirmwareTypeData',
  initialState,
  reducers: {
    FirmwareTypeDataLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        FirmwareTypeData: null
      }
    },
    FirmwareTypeDataUpdateAction: (state, action: PayloadAction< any >) => {
      return {
        isLoading: false,
        loadingError: null,
        FirmwareTypeData: action.payload
      }
    },
    FirmwareTypeDataErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        FirmwareTypeData: null
      }
    }
  }
})

// Actions to export
export const {
  FirmwareTypeDataLoadingAction,
  FirmwareTypeDataUpdateAction,
  FirmwareTypeDataErrorAction
} = FirmwareTypeDataSlice.actions

export default FirmwareTypeDataSlice.reducer
