/* istanbul ignore file */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type HistoryASDataObject } from '../types'

export interface ExportASKpiDetailsState {
  isLoading: boolean
  error: string | undefined
  exportASKpiDetails: HistoryASDataObject | undefined
  httpStatus: number
}

// Initial state of the slice
const initialState: ExportASKpiDetailsState = {
  isLoading: true,
  error: undefined,
  exportASKpiDetails: undefined,
  httpStatus: 0
}

// Slice to return from action
export const exportASKpiDetailsSlice = createSlice({
  name: 'exportASKpiDetails',
  initialState,
  reducers: {
    exportASKpiDetailsLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        exportASKpiDetails: undefined,
        httpStatus: 0
      }
    },
    exportASKpiDetailsUpdateAction: (state, action: PayloadAction<{ data: HistoryASDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        exportASKpiDetails: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    exportASKpiDetailsErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        exportASKpiDetails: undefined,
        httpStatus: action.payload.httpStatus
      }
    },
    // istanbul ignore next
    exportASKpiDetailsResetAction: () => {
      return {
        isLoading: false,
        error: undefined,
        exportASKpiDetails: undefined,
        httpStatus: 0
      }
    }
  }
})

// Actions to export
export const {
  exportASKpiDetailsLoadingAction,
  exportASKpiDetailsUpdateAction,
  exportASKpiDetailsErrorAction,
  exportASKpiDetailsResetAction
} = exportASKpiDetailsSlice.actions

export default exportASKpiDetailsSlice.reducer
