// istanbul ignore file
import { createSlice } from '@reduxjs/toolkit'
import { type PayloadAction } from '@reduxjs/toolkit'
import { type AdvancedFilterParameter } from '../services/advancedGroupFilterOptionsService'

export interface AdvancedFilterOptionState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null
  advancedFilterOption: AdvancedFilterParameter | null
}

const initialState: AdvancedFilterOptionState = {
  isLoading: false,
  error: null,
  httpStatus: 0,
  advancedFilterOption: null
}

const advancedFilterOptionSlice = createSlice({
  name: 'AdvancedFilterOptionSlice',
  initialState,
  reducers: {
    advancedFilterOptionLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0,
        advancedFilterOption: null
      }
    },
    advancedFilterOptionUpdateAction: (state, action: PayloadAction<{ data: AdvancedFilterParameter, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.httpStatus,
        advancedFilterOption: action.payload.data
      }
    },
    advancedFilterOptionErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        advancedFilterOption: null
      }
    }
  }
})

export const {
  advancedFilterOptionLoadingAction,
  advancedFilterOptionUpdateAction,
  advancedFilterOptionErrorAction
} = advancedFilterOptionSlice.actions

export default advancedFilterOptionSlice.reducer
