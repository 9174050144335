import React from 'react'
import './CardDashboard.css'
import {
  Card,
  CardContent,
  Toolbar,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import UploadFileComponent from '../../upload-file/components/uploadFile'
import LanguageStrings from '../../../i18n/locales'
import { type ConnectedProps, connect, useDispatch, useSelector } from 'react-redux'
import { changeGrandParentBreadcrumb, grandParentBreadcrumbState } from '../redux/grandParentBreadcrumbSlice'
import { type RootState } from '../../../store'
import { type OperationPermissionState } from '../redux/operationPermissionSlice'
import { getOperationPermissions } from '../redux/actionCreator'
import { useAuth } from 'react-oidc-context'
import ConfigSeed from '../../device-provisioning/components/SetC12.22Seed'

function CardDashboard (props: PropsFromRedux): any {
  const [open, setOpen] = React.useState(false)
  const [showConfigSeed, setShowConfigSeed] = React.useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const auth = useAuth()
  const operationPermissions: OperationPermissionState = useSelector((state: RootState) => state.operationPermissions)
  const CardDashboardStrings = LanguageStrings().CardDashboardStrings

  const handleOpen = (): void => {
    setOpen(true)
  }

  React.useEffect(() => {
    if (!operationPermissions.updated) {
      props.getOperationPermissions(auth.user?.profile.sub as string)
    }
  }, [])

  const handlefirmware = (): void => {
    navigate('/firmware-list')
    localStorage.removeItem('Search')
  }

  const handleHistory = (): void => {
    navigate('/device-history')
    localStorage.removeItem('Search')
  }

  return (
    <div className='card-body'>
      <div>
        <Toolbar className='dm-backButton'>
          <Typography
            className='header-title'
          >
            {CardDashboardStrings.title}
          </Typography>
        </Toolbar>
      </div>
      <div className="container">
        <Card className="card" aria-label='cardopen' style={{ height: '200px' }}>
          <CardContent className='cardcontent'>
            <Typography>
              <p className="cardMainTitle"
              >
                {CardDashboardStrings.card1.title}
              </p>
              <ul
                className='sub-titleList'
              >
                <li data-testid= 'asset-list-card' className='assetmgmtli' onClick={ () => {
                  navigate('/asset-list')
                  dispatch(changeGrandParentBreadcrumb(grandParentBreadcrumbState.assetMgmt))
                }}>{CardDashboardStrings.card1.subTitle1}</li>
                <li data-testid= 'asset-group-link' className='assetmgmtli' onClick={() => { navigate('/asset-group-list') }}>{CardDashboardStrings.card1.AssetGroup}</li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
        <Card className="card" style={{ height: '200px' }}>
          <CardContent className='cardcontent'>
            <Typography className='cardtitle'>
              <p
                className='cardMainTitle'
              >
                {CardDashboardStrings.card2.title}
              </p>
              <ul
                className='sub-titleList'
              >
                {
                  operationPermissions.permissions.can_add_asset
                    ? <li data-testid='device-provision' className='assetmgmtli' onClick={handleOpen}>
                    {CardDashboardStrings.card2.subTitle1}
                  </li>
                    : <></>
                }
                <li
                  data-testid='provisioning-history'
                  className='assetmgmtli'
                  onClick={() => { handleHistory() } }
                >
                  {CardDashboardStrings.card2.subTitle2}
                </li>
                <li
                  data-testid='set-seed-key'
                  className='assetmgmtli'
                  onClick={() => { setShowConfigSeed(true) } }
                >
                  {CardDashboardStrings.card2.subTitle3}
                </li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
        <Card className="card" style={{ height: '200px' }}>
          <CardContent className='cardcontent'>
            <Typography>
              <p className='cardMainTitle'>
              {CardDashboardStrings.card3.title}
              </p>
              <ul className='sub-titleList'>
                <li data-testid='assetconfiguration' className='assetmgmtli' onClick={() => { navigate('/configuration') }}>{CardDashboardStrings.card3.subTitle1}</li>
                <li data-testid='configurationuploadstatus' className='assetmgmtli' onClick={() => { navigate('/configuration-upload-status') }}>{CardDashboardStrings.card3.subTitle2}</li>
                <li data-testid='assetprofileupload' className='assetmgmtli' onClick={() => { navigate('/asset-profile-upload') }}>{CardDashboardStrings.card3.subTitle3}</li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
        <Card className="card" style={{ height: '200px' }}>
          <CardContent className='cardcontent '>
            <Typography>
              <p className="cardMainTitle">
                {CardDashboardStrings.card4.title}
              </p>
              <ul className='sub-titleList'>
                <li className='assetmgmtli' onClick={() => { handlefirmware() } }
                >{CardDashboardStrings.card4.subTitle1}</li>
                <li className='assetmgmtli' onClick={() => { navigate('/firmware-upgrade-status') } }
                >{CardDashboardStrings.card4.subTitle3}</li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
      </div>
      {
        showConfigSeed && <ConfigSeed setShowConfigSeed={ setShowConfigSeed } />
      }
      <UploadFileComponent open={open} handleClose={() => { setOpen(false) }} />
    </div>
  )
}

interface DispatchToProps {
  getOperationPermissions: (sub: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getOperationPermissions: (sub: string) => dispatch(getOperationPermissions(sub))
})

const connector = connect(null, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(CardDashboard)
