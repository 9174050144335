/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Modal from '../../../../components/Modal'
import SearchBox from '../../../../components/SearchBox'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import DoNotDisturb from '@mui/icons-material/DoNotDisturb'
import { CircularProgress, Stack, Toolbar } from '@mui/material'
import dayjs, { type Dayjs } from 'dayjs'
// import Stack from '@mui/material/Stack'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Grid from '@mui/material/Grid'
import LanguageStrings from '../../../../i18n/locales'
import { getSchdulerHistoryListDetails, getSchdulerHistoryListDetailsBySearch } from '../../redux/actionCreators'
import { type RootState } from '../../../../store'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type reportSchedulerDataObj } from '../../services/getSchedulerHistoryByIdService'
import { type SchdulerHistoryListDataState } from '../../redux/getSchedulerHistoryDetailsSlice'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import './scheduler.css'
import { parseDateInAOHstandard } from '../../../../customHooks/parseDate'

type Order = 'asc' | 'desc'

interface Data {
  scheduledDate: string
  schedulerStatus: string
  remarks: string
}

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => void
  order: Order
  orderBy: string
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
  sortable: boolean
}

interface Sort {
  order: Order
  orderBy: string
}

const viewHistoryTableString =
  LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings
    .SchedulerStrings.viewHistoryTableString

const defaultDate = {
  end: dayjs(new Date()),
  start: dayjs(new Date(new Date().setMonth(new Date().getMonth() - 6)))
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '1px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'scheduledDate',
    numeric: false,
    disablePadding: true,
    label: viewHistoryTableString.schedule,
    sortable: false
  },
  {
    id: 'schedulerStatus',
    numeric: false,
    disablePadding: false,
    label: viewHistoryTableString.scheduleStatus,
    sortable: false
  },
  {
    id: 'remarks',
    numeric: false,
    disablePadding: false,
    label: viewHistoryTableString.remark,
    sortable: false
  }
]

const EnhancedTableHead = (props: EnhancedTableProps): JSX.Element => {
  const { order, orderBy, onRequestSort } = props
  const createSortHandler =
    (newOrderBy: keyof Data) =>
      (event: React.MouseEvent<unknown>): void => {
        onRequestSort(event, newOrderBy)
      }
  return (
    <TableHead data-testid='device-table-header'>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon={!headCell.sortable}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              aria-label={headCell.label}
              data-testid='test-sort'
            >
              {headCell.label}
              {orderBy === headCell.id
                ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
                  )
                : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const ReportViewHistory = (props: AllProps): JSX.Element => {
  const [value, setValue] = React.useState(0)
  const [sort, setSort] = React.useState<Sort>({
    order: 'asc',
    orderBy: 'schedulerId'
  })
  const [searchValue, setSearchValue] = React.useState('')
  const [filterSuccessData, setfilterSuccessData] = React.useState('')
  const [filterErrorData, setfilterErrorData] = React.useState('')
  const [filterstatus, setfilterstatus] = React.useState('')
  const [showfilter, setshowfilter] = React.useState(false)
  const [start, setStart] = React.useState<Dayjs | null | undefined>(null)
  const [end, setEnd] = React.useState<Dayjs | null | undefined>(null)

  const [selecteStart, setSeletedStart] = React.useState('')
  const [selectedEnd, setSeletedEnd] = React.useState('')

  const tableData = useSelector(
    (state: RootState) => state.SchdulerHistoryListData?.SchdulerHistoryListData
  )

  const filterCountData = useSelector(
    (state: RootState) => state.SchdulerHistoryListData
  )

  const tableStatus = useSelector(
    (state: RootState) => state.SchdulerHistoryListData?.httpStatus
  )

  const schedulerId = sessionStorage.getItem('schedulerId')
  const schedulerName = sessionStorage.getItem('schedulerName')
  const selectedstartDate = start !== null && start !== undefined ? start.toISOString() : ''
  const selectedendDate = end !== null && end !== undefined ? end.toISOString() : ''

  React.useEffect(() => {
    props.getSchdulerHistoryListDetails(schedulerId !== null ? schedulerId : '', '', selectedstartDate, selectedendDate)
  }, [])

  const handleFetchSchedulerHistorList = (): void => {
    setfilterstatus('')
    props.getSchdulerHistoryListDetails(schedulerId !== null ? schedulerId : '', '', selectedstartDate, selectedendDate)
  }

  const fetchSchdulerHistoryListDetailsBySearch = (): void => {
    props.getSchdulerHistoryListDetailsBySearch(schedulerId !== null ? schedulerId : '', searchValue)
  }

  const handleSucessTab = (): void => {
    setfilterSuccessData('success')
    setshowfilter(true)
    setfilterstatus('success')
    props.getSchdulerHistoryListDetails(schedulerId !== null ? schedulerId : '', 'success', selectedstartDate, selectedendDate)
  }

  const handleErrorTab = (): void => {
    setfilterErrorData('error')
    setshowfilter(true)
    setfilterstatus('error')
    props.getSchdulerHistoryListDetails(schedulerId !== null ? schedulerId : '', 'error', selectedstartDate, selectedendDate)
  }

  const clearAllFilters = (): void => {
    setfilterSuccessData('')
    setfilterErrorData('')
    setfilterstatus('')
    setStart(null)
    setEnd(null)
    setshowfilter(false)
    setValue(0)
    setSeletedEnd('')
    setSeletedStart('')
    props.getSchdulerHistoryListDetails(schedulerId !== null ? schedulerId : '', '', '', '')
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ): void => {
    const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
    const toggledOrder = isAsc ? 'desc' : 'asc'
    setSort({ order: toggledOrder, orderBy: newOrderBy })
  }

  const CustomLocalizationProviderWrapper = styled('div')({
    backgroundColor: '#505050',
    border: 'none',
    borderRadius: '4px'
  })

  const handleTable = (): JSX.Element => {
    if (tableData !== undefined && tableStatus === 200) {
      return (
        <>
        <Paper
          sx={{
            width: '100%',
            mb: 2,
            backgroundColor: '#272727',
            boxShadow: 'unset',
            color: '#F0F0F0',
            marginBottom: '0',
            height: '250px',
            overflow: 'auto',
            marginTop: '10px'
          }}
        >
          <TableContainer className='device-table history-table'>
            <Table
              sx={{ minWidth: 750, color: '#f0f0f0' }}
              aria-labelledby='tableTitle'
              size={'small'}
            >
              <EnhancedTableHead
                order={sort.order}
                orderBy={sort.orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {tableData?.map((row: reportSchedulerDataObj) => {
                  return (
                    <StyledTableRow
                      hover
                      role='checkbox'
                      tabIndex={-1}
                      key={row.reportSchedulerId}
                      aria-label='devices-history-row'
                      sx={{ cursor: 'pointer' }}
                    >
                      <StyledTableCell>
                      {row.scheduledDate !== null
                        ? parseDateInAOHstandard(
                          row.scheduledDate
                        )
                        : ''}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div
                          className={
                            (
                              row.schedulerStatus.toLowerCase() === 'success' ||
                              row.schedulerStatus.toLowerCase() === 'progress'
                            )
                              ? 'success-provision-outlined'
                              : 'error-provision'
                          }
                          style={{
                            padding: '4px 8px',
                            height: '24px',
                            borderRadius: '16px'
                          }}
                        >
                          {(() => {
                            switch (row.schedulerStatus.toLowerCase()) {
                              case 'success':
                                return (
                                  <TaskAltIcon sx={{ fontSize: 'medium', paddingRight: '3px' }} />
                                )
                              case 'progress':
                                return (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <g clipPath="url(#clip0_11236_368)">
                                      <path
                                        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                                        stroke="#303030"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M4.0498 8.7998C4.46402 8.7998 4.7998 8.46402 4.7998 8.0498C4.7998 7.63559 4.46402 7.2998 4.0498 7.2998C3.63559 7.2998 3.2998 7.63559 3.2998 8.0498C3.2998 8.46402 3.63559 8.7998 4.0498 8.7998Z"
                                        stroke="#303030"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M7.94995 8.7998C8.36416 8.7998 8.69995 8.46402 8.69995 8.0498C8.69995 7.63559 8.36416 7.2998 7.94995 7.2998C7.53574 7.2998 7.19995 7.63559 7.19995 8.0498C7.19995 8.46402 7.53574 8.7998 7.94995 8.7998Z"
                                        stroke="#303030"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M11.8501 8.7998C12.2643 8.7998 12.6001 8.46402 12.6001 8.0498C12.6001 7.63559 12.2643 7.2998 11.8501 7.2998C11.4359 7.2998 11.1001 7.63559 11.1001 8.0498C11.1001 8.46402 11.4359 8.7998 11.8501 8.7998Z"
                                        stroke="#303030"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_11236_368">
                                        <rect width="16" height="16" fill="white" />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                )
                              default:
                                return (
                                  <DoNotDisturb sx={{ fontSize: 'medium', paddingRight: '3px' }} />
                                )
                            }
                          })()}
                          <span>
                            {row.schedulerStatus === 'progress'
                              ? 'In progress'
                              : row.schedulerStatus}
                          </span>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell>{row.remarks}</StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        </>
      )
    } else if (props.SchdulerHistoryListData.isLoading) {
      return (
        <div className="CircularprogressAmi" style={{ top: '70px' }} >
          <CircularProgress />
        </div>
      )
    } else if (tableStatus === 401) {
      return (
        <div className="apiErrWrapper">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (tableStatus === 403) {
      return (
        <div className="apiErrWrapper">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else if (tableStatus === 404) {
      return (
        <div className="apiErrWrapper">
          <AuthError errorMessage="NoDataPresent" />
        </div>
      )
    } else {
      return (
        <div className="apiErrWrapper">
          <AuthError
            errorMessage="cannotFetch"
            retry={handleFetchSchedulerHistorList}
          />
        </div>
      )
    }
  }

  const handleStarteDate = (val: Dayjs): void => {
    const sDate = val.format('YYYY-MM-DDTHH:mm:ss[Z]')
    setSeletedStart(val.format('YYYY-MM-DDTHH:mm:ss[Z]'))
    setStart(dayjs(val))
    setshowfilter(true)
    props.getSchdulerHistoryListDetails(schedulerId !== null ? schedulerId : '', filterstatus, sDate, selectedEnd)
  }

  const handleEndDate = (val: Dayjs): void => {
    const eDate = dayjs(val).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]')
    setSeletedEnd(dayjs(val).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'))
    setEnd(dayjs(val))
    setshowfilter(true)
    props.getSchdulerHistoryListDetails(schedulerId !== null ? schedulerId : '', filterstatus, selecteStart, eDate)
  }

  const WhiteDatePicker = styled(DatePicker)({
    '& input': {
      color: '#D0D0D0',
      fontSize: '14px',
      paddingRight: 0
    },
    '& label': {
      color: '#D0D0D0',
      fontSize: '14px'
    }
  })

  const BottomNavigationActionStyled = styled(BottomNavigationAction)`
  & .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 14px;
    font-family: Honeywell Sans Web;
  },
  .MuiBottomNavigationAction-label {
    color: white;
    font-size: 14px;
    font-family: Honeywell Sans Web;
  }`

  return (
    <Toolbar
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important'
      }}
    >
      <Modal
        title={viewHistoryTableString.scheduleHistory}
        height={'600px'}
        width='70%'
        onClose={() => {
          props.onScheduleHistoryPopUpClose()
        }}
        sx={{ backgroundColor: '#272727' }}
      >
        <div style={{ padding: '20px 0px' }}>
          <Typography variant='subtitle1' gutterBottom data-testid='schedule-name' >
            {viewHistoryTableString.scheduleName} : {schedulerName}
          </Typography>
        </div>
        <div className='history-box'>
        <div style={{ width: '100%' }}>
          <Typography
            sx={{
              flex: '1 1 100%',
              fontFamily: 'Honeywell Sans Web',
              fontSize: '16px',
              fontWeight: 600,
              padding: '12px 24px',
              borderBottom: '2px solid #202020',
              borderRadius: '4px',
              marginBottom: '25px'
            }}
            id='tableTitle'
            component='div'
          >
            <span
              data-testid='history-table-title'
              style={{
                fontSize: '16px',
                color: '#f0f0f0',
                paddingRight: '16px',
                marginRight: '10px'
              }}
            >
            {viewHistoryTableString.history}
            </span>
          </Typography>
        </div>
        <div className='history-table-box'>
        <Grid container rowSpacing={2} spacing={1} alignItems='end' >

          </Grid>
        <Grid container rowSpacing={2} spacing={1} alignItems='end' >
          <Grid item xs={2}>
          <span className='type-name' style={{ marginLeft: '0px', fontSize: '12px' }}>Start Date</span>
          <CustomLocalizationProviderWrapper data-testid='date-picker'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <WhiteDatePicker slotProps={{ textField: { size: 'small' }, actionBar: { actions: ['cancel', 'accept'] }, field: { readOnly: true } }}
                    value={start} onAccept={(val: any) => { handleStarteDate(dayjs(val)) }} />
                    </LocalizationProvider>
                  </CustomLocalizationProviderWrapper>
          </Grid>
          <Grid item xs={2}>
          <span className='type-name' style={{ marginLeft: '0px', fontSize: '12px' }}>End Date</span>
          <CustomLocalizationProviderWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <WhiteDatePicker slotProps={{ textField: { size: 'small' }, actionBar: { actions: ['cancel', 'accept'] }, field: { readOnly: true } }} defaultValue={defaultDate.end} value={end} onAccept={(val: any) => { handleEndDate(dayjs(val)) }} sx={{ color: '#D0D0D0' }} />
                    </LocalizationProvider>
            </CustomLocalizationProviderWrapper>
          </Grid>
          <Grid item xs={5}>
            <BottomNavigation
                showLabels
                data-testid='table-filter'
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue)
                }}
                sx={{
                  background: '#404040',
                  height: '37px',
                  borderRadius: '4px',
                  overflow: 'hidden',
                  border: 'solid 2px #404040'
                }}
              >
                <BottomNavigationActionStyled onClick={() => { handleFetchSchedulerHistorList() }}
                  label={`${viewHistoryTableString.all} (${filterCountData.totalCount})`}
                />
                <BottomNavigationActionStyled onClick={() => { handleSucessTab() }}
                  label={`${viewHistoryTableString.success} (${filterCountData.successCount})`}
                />
                <BottomNavigationActionStyled onClick={() => { handleErrorTab() }}
                  label={`${viewHistoryTableString.error} (${filterCountData.errorCount})`}
                />
            </BottomNavigation>
          </Grid>
          <Grid item xs={3}>
            <SearchBox
              immediate={true}
              debounce={true}
              placeholder={viewHistoryTableString.searchPlaceholder}
              sx={{ width: 'auto', margin: '-10px -17px', height: '37px', paddingRight: '10px' }}
              emptyEnterFallback={() => { handleFetchSchedulerHistorList() }}
              searchVal={searchValue}
              setSearchVal={setSearchValue}
              performAction={() => { fetchSchdulerHistoryListDetailsBySearch() }}
            />
          </Grid>
        </Grid>

        <Stack>
        {showfilter && (
          <div
            style={{
              padding: '12px 5px',
              borderTop: '2px solid #202020',
              color: '#d0d0d0',
              fontSize: '12px',
              marginBottom: '-20px'
            }}
          >
            { filterErrorData === '' &&
              filterSuccessData === '' &&
              start === null &&
              end === null
              ? (
                  'No filters applied'
                )
              : (
              <div className="classNAme">
                Results | Filters :
                <>
                  {start !== null && (
                    <div className="box">
                      {' '}
                      {'Start Date'} : { start !== null && start !== undefined ? new Date(start.toString()).toLocaleDateString('en-US') : '' }
                    </div>
                  )}
                  {end !== null && (
                    <div className="box">
                      {' '}
                      {'End Date'} :{' '}
                      {end !== null && end !== undefined ? new Date(end.toString()).toLocaleDateString('en-US') : ''}
                    </div>
                  )}
                  {filterstatus !== '' && (
                    <div className="box">
                      {' '}
                      {'Status'}: {filterstatus}
                    </div>
                  )}
                </>
                <em
                  onClick={() => {
                    clearAllFilters()
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Clear Filters{' '}
                </em>
              </div>
                )}
          </div>
        )}
        </Stack>
        </div>
        {handleTable()}
        </div>
      </Modal>
    </Toolbar>
  )
}

interface DispatchToProps {
  getSchdulerHistoryListDetails: (
    schedulerId: string,
    schedulerStatus: string,
    startDate: string,
    endDate: string,
  ) => void

  getSchdulerHistoryListDetailsBySearch: (
    schedulerId: string,
    searchValue: string
  ) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getSchdulerHistoryListDetails: (
    schedulerId: string,
    schedulerStatus: string,
    startDate: string,
    endDate: string
  ) =>
    dispatch(
      getSchdulerHistoryListDetails(schedulerId, schedulerStatus, startDate, endDate)
    ),
  getSchdulerHistoryListDetailsBySearch: (
    schedulerId: string,
    searchValue: string
  ) =>
    dispatch(
      getSchdulerHistoryListDetailsBySearch(schedulerId, searchValue)
    )
})

interface StateToProps {
  SchdulerHistoryListData: SchdulerHistoryListDataState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  SchdulerHistoryListData: state.SchdulerHistoryListData
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>
interface ParentProps {
  onScheduleHistoryPopUpClose: () => void
}
type AllProps = PropsFromRedux & ParentProps

export default connector(ReportViewHistory)
