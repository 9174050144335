import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import lowBadge from '../../../assets/lowBadge.svg'
import mediumBadge from '../../../assets/mediumBadge.svg'
import highBadge from '../../../assets/highBadge.svg'
import CriticalBadge from '../../../assets/criticalBadge.svg'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { type AlarmByIdState } from '../redux/alarmsByIdSlice'

const StyledTableCell = styled(TableCell)({
  paddingTop: 10,
  paddingBottom: 5
})

export const getStatusIcons = (status: string): JSX.Element => {
  switch (status.toLowerCase()) {
    case 'low':
      return <img src={lowBadge} />
    case 'medium':
      return <img src={mediumBadge} />
    case 'high':
      return <img src={highBadge} />
    case 'critical':
      return <img src={CriticalBadge} />
    default:
      return <img src={lowBadge} />
  }
}

interface alarmsData {
  alarmDefinitionId: string
  alarmName: string
  severity: string
  status: string
  readingTime: string
}

const ActiveIssuesTable = (props: {
  OverviewStrings: any
  alarmsById: AlarmByIdState
}): JSX.Element => {
  const alarmsInitialData: alarmsData[] = []
  const [rows, setRow] = useState<alarmsData[]>(alarmsInitialData)

  /* eslint-disable @typescript-eslint/no-unused-vars */
  function createData (
    alarmDefinitionId: string,
    alarmName: string,
    severity: string,
    status: string,
    readingTime: string
  ): alarmsData {
    return { alarmDefinitionId, alarmName, severity, status, readingTime }
  }

  function parseDate (date: string): string {
    return new Date(date).toUTCString()
  }

  useEffect(() => {
    // istanbul ignore else
    if (
      props.alarmsById.alarmData !== null &&
      props.alarmsById.alarmData !== undefined
    ) {
      const newRows = props.alarmsById.alarmData.map((alarm: any) =>
        createData(
          alarm.alarmDefinitionId,
          alarm.alarmName,
          alarm.severity,
          alarm.alarmStatus,
          alarm.readingTime
        )
      )
      setRow(newRows)
    } else {
      setRow(alarmsInitialData)
    }
  }, [props.alarmsById.alarmData])

  const grayRowStyle = {
    color: '#D0D0D0'
  }

  const blueRowStyle = {
    color: '#64C3FF'
  }

  return (
    <>
      <div className='activeIssuesdiv'>
        <h5>{props.OverviewStrings.Active_Issue_List}</h5>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: 400
        }}
      >
        <Table
          sx={{
            minWidth: 650,
            backgroundColor: '#272727',
            alignItems: 'center',
            height: 'max-content',
            position: 'relative'
          }}
          aria-label='simple table'
        >
          <TableHead>
            <TableRow
              sx={{
                position: 'sticky',
                top: 0,
                zIndex: 100,
                backgroundColor: '#272727'
              }}
            >
              <TableCell sx={grayRowStyle} align='left'>
                {props.OverviewStrings.ALARM}
              </TableCell>
              <TableCell sx={grayRowStyle} align='left'>
                {props.OverviewStrings.SEVERITY}
              </TableCell>
              <TableCell sx={grayRowStyle} align='left'>
                {props.OverviewStrings.READING_TIME}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ borderTop: 'none' }}>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: '1px solid black'
                  },
                  paddingTop: '0',
                  paddingBottom: '0'
                }}
              >
                <StyledTableCell
                  sx={blueRowStyle}
                  align='left'
                  component='th'
                  scope='row'
                >
                  {row.alarmName}
                </StyledTableCell>
                <StyledTableCell sx={grayRowStyle} align='left'>
                  {getStatusIcons(row.severity)}
                </StyledTableCell>
                <StyledTableCell sx={grayRowStyle} align='left'>
                  {parseDate(row.readingTime)}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ActiveIssuesTable
