import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZassetTypes = z.object({
  assetTypeTd: z.string(),
  assetType: z.string()
})

const ZfirmwareTypes = z.object({
  firmwareTypeId: z.string(),
  firmwareType: z.string()
})

const Zgroups = z.object({
  group_id: z.string(),
  group_name: z.string(),
  status: z.any()
})

const ZData = z.object({
  assetTypes: ZassetTypes.array(),
  firmwareTypes: ZfirmwareTypes.array(),
  version: z.string().array(),
  status: z.string().array(),
  groups: Zgroups.array()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(ZData)
})

const ZApiErrorResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  error_message: z.optional(z.string())
})

export type FirmwareCampaignFilterMetadataApiResponse = z.infer<typeof ZApiResponse>
export type FirmwareCampaignFilterMetadata = z.infer<typeof ZData>

export async function getFirmwareCampaignFilterMetadata (
  onSuccess: (firmwareListFilterAssetType: FirmwareCampaignFilterMetadata, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = 'asset-config-service/api/v1/udp/firmwareCampaign/filter-metadata'

  interceptedAxios.get(url, { timeout: 10000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
          parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, response.status)
          } else {
            onFailure('Error occurred while processing response', response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        const parseResponse = ZApiErrorResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
