import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type OperationPermission } from '../services/operationPermissionService'

export interface OperationPermissionState {
  isLoading: boolean
  error: boolean | null
  httpStatus: number | null | undefined
  permissions: OperationPermission
  updated: boolean
}

const initialState: OperationPermissionState = {
  isLoading: true,
  error: false,
  httpStatus: 0,
  permissions: {
    can_add_asset: false,
    can_add_asset_config: false,
    can_allow_access: false,
    can_authorize_user: false,
    can_create_honeywell_user: false,
    can_create_tenant: false,
    can_create_user: false,
    can_delete_honeywell_user: false,
    can_delete_tenant: false,
    can_delete_user: false,
    can_modify_asset: false,
    can_modify_asset_config: false,
    can_modify_file_provisioning: false,
    can_read_all_roles: false,
    can_read_all_users: false,
    can_read_asset: false,
    can_read_asset_alarm: false,
    can_read_asset_config: false,
    can_read_asset_data: false,
    can_read_file_provisioning: false,
    can_read_honeywell_user: false,
    can_read_kpis: false,
    can_read_tenant: false,
    can_read_tenant_logo: false,
    can_read_user: false,
    can_read_user_logo: false,
    can_update_honeywell_user: false,
    can_update_tenant: false,
    can_update_user: false,
    is_active_user: false
  },
  updated: false
}

const operationPermissionSlice = createSlice({
  name: 'policy-agent-permissions',
  initialState,
  reducers: {
    operationPermissionLoadingAction: (state) => {
      return {
        ...initialState
      }
    },
    operationPermissionUpdateAction: (state, action: PayloadAction<{ data: OperationPermission }>) => {
      return {
        isLoading: false,
        error: false,
        httpStatus: 200,
        permissions: action.payload.data,
        updated: true
      }
    },
    operationPermissionErrorAction: (state, action: PayloadAction<{ error: boolean, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.error,
        httpStatus: action.payload.httpStatus,
        permissions: initialState.permissions,
        updated: false
      }
    }
  }
})

export const {
  operationPermissionLoadingAction,
  operationPermissionUpdateAction,
  operationPermissionErrorAction
} = operationPermissionSlice.actions

export default operationPermissionSlice.reducer
