import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type DeviceDetails } from '../services/getDeviceDetails'

export interface assetDetail {
  provisioningDetailsId: string
  assetId: string
  name: string
  serialNumber: string
  configuration: string
  state: string
  assetGroup: string
  provisioningDetailsStatus: string
  provisioningErrorDescription: string
  deviceType: string
  provisioningHistoryId: number
}
export interface deviceDetailState {
  isLoading: boolean
  loadingError: string | null
  deviceData: DeviceDetails | null
  totalCount: number
  httpStatus: number | null
}

// Initial state of the slice
const initialState: deviceDetailState = {
  isLoading: true,
  loadingError: null,
  deviceData: null,
  totalCount: 0,
  httpStatus: null
}

// Slice to return from action
export const deviceDetailSlice = createSlice({
  name: 'deviceData',
  initialState,
  reducers: {
    deviceDataLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        deviceData: null,
        totalCount: 0,
        httpStatus: 0
      }
    },
    deviceDataUpdateAction: (state, action: PayloadAction< any >) => {
      return {
        isLoading: false,
        loadingError: null,
        deviceData: action.payload.data,
        totalCount: action.payload.total_count,
        httpStatus: action.payload.httpStatus
      }
    },
    deviceDataErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        deviceData: null,
        totalCount: 0
      }
    }
  }
})

// Actions to export
export const {
  deviceDataLoadingAction,
  deviceDataUpdateAction,
  deviceDataErrorAction
} = deviceDetailSlice.actions

export default deviceDetailSlice.reducer
