import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZschedulerAssignments = z.object({
  schedulerAssignmentId: z.string(),
  schedulerAssignment: z.string()
})

const ZreportTemplates = z.object({
  templateId: z.string(),
  templateName: z.string()
})

const ZData = z.object({
  schedulerNames: z.array(z.string()),
  reportTemplates: z.array(ZreportTemplates),
  schedulerAssignments: z.array(ZschedulerAssignments),
  lastScheduleStatus: z.array(z.string())
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: ZData
})

export type ReportGridFilterMasterDetails = z.infer<typeof ZApiResponse>
export type ReportGridFilterMasterData = z.infer<typeof ZData>

export async function getAllSchedulerFilterDropdown (
  tenantId: string,
  onSuccess: (SchedulerFilterDropDownListData: ReportGridFilterMasterData, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/report-service/api/v1/udp/scheduler/filter-metadata?tenantId=${tenantId}`
  interceptedAxios
    .get(url, { timeout: 10000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.status)
    })
}
