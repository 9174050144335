import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.boolean(),
  error: z.boolean()
})
export type ApiResponse = z.infer<typeof ZApiResponse>

export async function pauseSchedulerService (
  schedulerId: string,
  onSuccess: (httpStatus: number) => any,
  onFailure: (httpStatus: number) => any
): Promise<void> {
  const url = `/report-service/api/v1/udp/scheduler/job/pause/${schedulerId}`
  interceptedAxios
    .put(url, { timeout: 15000 })
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        if (
          parseResponse.data.data !== null &&
          parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.http_status)
        } else {
          onFailure(parseResponse.data.http_status)
        }
      } else {
        onFailure(response.status)
      }
    })
    .catch((error) => {
      onFailure(error.response !== undefined ? error.response.status : 502)
    })
}

export async function resumeSchedulerService (
  schedulerId: string,
  onSuccess: (httpStatus: number) => any,
  onFailure: (httpStatus: number) => any
): Promise<void> {
  const url = `/report-service/api/v1/udp/scheduler/job/resume/${schedulerId}`
  interceptedAxios
    .put(url, { timeout: 15000 })
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        if (
          parseResponse.data.data !== null &&
          parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.http_status)
        } else {
          onFailure(parseResponse.data.http_status)
        }
      } else {
        onFailure(response.status)
      }
    })
    .catch((error) => {
      onFailure(error.response !== undefined ? error.response.status : 502)
    })
}
