import interceptedAxios from '../../../utils/interceptedAxios'
import { handleExportByID } from './handleName/handleExportByID'

export async function exportProvisioningHistoryById (
  fileId: string,
  tenantId: string,
  onSuccess: (exportFile: any) => any,
  onFailure: (errorMessage: string) => any
): Promise<void> {
  const url = `/fileprocessingservice/api/v1/udp/provisioningHistory/details/${fileId}/download?tenantId=${tenantId}`
  interceptedAxios
    .get(url, { responseType: 'blob', timeout: 10000 })
    .then((response) => {
      handleExportByID(response)
      onSuccess(response)
    })
    .catch((error) => {
      onFailure(error.message)
    })
}
