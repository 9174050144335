/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react'
import {
  Box,
  Typography
} from '@mui/material'
import { useAuth } from 'react-oidc-context'
import { connect, type ConnectedProps } from 'react-redux'
import { type RootState } from '../../../store'
import { type UploadFileInfoState } from '../redux/deviceInfoSlice'
import Modal from '@mui/material/Modal'
import LinearProgress from '@mui/material/LinearProgress'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import WarningIcon from '@mui/icons-material/WarningAmber'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { uploadFile, resetUpload } from '../redux/actionCreators'
import { useSelector } from 'react-redux'
import LanguageStrings from '../../../i18n/locales'
import ShowDetailsComponent from '../../device-provisioning/components/ShowAssetDetails'
import { isValidName } from '../../../utils/validator'
import { GenXDropdown } from '../../../components/GenXDropdown'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 550,
  display: 'flex',
  justifyContent: 'center',
  bgcolor: '#302e2e',
  border: '1px #302e2e',
  boxShadow: '5px 5px 5px 5px #141111',
  p: 4,
  pl: '7%',
  pr: '7%',
  borderRadius: '6px'
}
const styleMinimize = {
  position: 'absolute',
  bottom: '97px',
  right: '32px',
  width: 461,
  height: 95,
  bgcolor: '#302e2e',
  p: 4,
  pl: '24px',
  pr: '25px',
  pt: '0%',
  boxShadow: '15px 30px 40px rgba(0, 0, 0, 0.4), 0px 11px 15px -2px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px'
}

const UploadFileComponent = (props: Props): any => {
  const [showProgressBar, setShowProgressBar] = React.useState(false)
  const [minimized, setMinimized] = useState(false)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [selectedUploadType, setSelectedUploadType] = useState<Option | null>(null)
  const [file, setFile] = React.useState<File | null>(null)
  const uploadFileResponse = useSelector((state: any) => state.deviceInfo)
  const UploadFileStrings = LanguageStrings().UploadFileStrings
  const auth = useAuth()

  interface Option {
    label: string
    value: string
  }

  const deviceTypeOptions: Option[] = [
    { label: 'Water', value: 'asset_provisioning' },
    { label: 'Gas', value: 'nxcm_asset_provisioning' }
  ]
  const handleUploadTypeChange = (selectedOption: Option | null): void => {
    setSelectedUploadType(selectedOption)
  }
  const isFileSizeLessThan10MB = (fileSize: number): boolean => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const maxSize = window._env_?.REACT_APP_MAX_FILE_SIZE !== undefined ? Number(window._env_.REACT_APP_MAX_FILE_SIZE) : 10
    return fileSize > 0 && (fileSize / (1024 * 1024)) < maxSize
  }
  const isValidFileUploaded = (file: any): boolean => {
    const validExtensions = ['xml']
    const fileExtension = file.type.split('/')[1]
    return validExtensions.includes(fileExtension)
  }
  React.useEffect(() => {
    if (uploadFileResponse.errorMessage !== null || uploadFileResponse.message !== null) {
      setShowProgressBar(false)
    }
  }, [uploadFileResponse])

  const handleSubmit = (): void => {
    if (file !== null && isFileSizeLessThan10MB(file.size) && isValidFileUploaded(file) && isValidName(file.name) && selectedUploadType !== null) {
      props.uploadFile(file, auth.user?.profile.name as string, auth.user?.profile.customer as string, selectedUploadType.value)
      setShowProgressBar(true)
    }
  }
  const [progress, setProgress] = React.useState(0)

  const onFileChange = (e: any): void => {
    props.resetUpload()
    setFile(e.target.files[0])
  }

  React.useEffect(() => {
    if (uploadFileResponse.isLoading === true) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0
          }
          const diff = Math.random() * 10
          return Math.min(oldProgress + diff, 100)
        })
      }, 50)
      return () => {
        clearInterval(timer)
      }
    }
  }, [uploadFileResponse])

  const onCloseModal = () => {
    setMinimized(false)
    onRemoveSelectedFile()
    props.resetUpload()
    props.handleClose()
    setSelectedUploadType(null)
    setFile(null)
  }
  const toggleMinimize = () => {
    setMinimized(!minimized)
  }
  const onRemoveSelectedFile = () => {
    setFile(null)
    setShowProgressBar(false)
    props.resetUpload()
  }
  const retryUpload = () => {
    setShowProgressBar(true)
    props.resetUpload()
    if (file !== null && selectedUploadType !== null) {
      props.uploadFile(file, auth.user?.profile.name as string, auth.user?.profile.customer as string, selectedUploadType.value)
    }
  }
  const handleCloseModal = () => {
    setShowDetailsModal(false)
    setFile(null)
    props.resetUpload()
    props.handleClose()
    setSelectedUploadType(null)
  }
  const handleDrag = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (e.dataTransfer?.files && e.dataTransfer?.files[0]) {
      setFile(e.dataTransfer.files[0])
    }
  }

  const handleDragOver = (e: any) => {
    const event = e as Event
    event.stopPropagation()
    event.preventDefault()
  }

  return (
    <div className='CardBody'>
      <Modal
        className='MainModal'
        open={props.open}
        aria-label='upload-popup'
        onClose={() => { onCloseModal() }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className='pop-up-modal' sx={minimized ? styleMinimize : style}>
          {minimized
            ? (
              <OpenInFullIcon
                onClick={() => { toggleMinimize() }}
                className='OpenFullscreenIcon'
                aria-label='minimize'
              />
              )
            : (
                showProgressBar && (
                <CloseFullscreenIcon
                  onClick={() => { toggleMinimize() }}
                  className='closeFullscreenIcon'
                  aria-label='minimize'
                />
                )
              )}
          <div>
            {minimized
              ? (
                <></>
                )
              : (
                <><Box className='modal-box' onDragEnter={handleDrag} onDragOver={handleDragOver} onDrop={handleDrop}>
                  <p>
                    <DescriptionOutlinedIcon
                      className='DescriptionOutlinedIcon' />
                  </p>
                  <Typography
                    data-testid='typo-text '
                    className='draganddroptext'
                  >
                    {UploadFileStrings.droptitle}
                    <br />
                    {UploadFileStrings.ortext}
                  </Typography>
                  <label className='file-label' htmlFor='file-input'>
                    <input
                      type='file'
                      id='file-input'
                      className='input-file'
                      data-testid='file-input'
                      onChange={(e: any) => {
                        onFileChange(e)
                      } }
                      onClick={(event: any) => {
                        event.target.value = null
                      } }
                      multiple />
                    {UploadFileStrings.browsefile}{' '}
                  </label>
                  <Typography
                    data-testid='typo-text2 '
                    className='subtext1'
                  >
                    {UploadFileStrings.acceptext}<br />{UploadFileStrings.errormsg}
                  </Typography>
                </Box>
                <div style={{ marginTop: '10px' }}><span>{UploadFileStrings.assetType}<sup style={{ color: '#EE3426', left: '0.3em' }}>*</sup> </span></div>
                <GenXDropdown
                  options={deviceTypeOptions}
                  value={selectedUploadType}
                  onSelect={handleUploadTypeChange}
                  label={''}
                  width={400}
                />
                </>
                )}
            {file !== null && <div className={minimized ? 'upload-file-name mn' : 'upload-file-name'}>{file.name} {file.size !== null && !isFileSizeLessThan10MB(file.size) ? (((file.size / 1024) / 1024).toFixed(4) + 'MB / 10MB') : (file.size / 1024).toFixed(4) + 'KB / 10MB' }  {!minimized && <CloseIcon
              className='upload-file-close'
              data-testid='close-selected-file'
              onClick={() => { onRemoveSelectedFile() }}
            />}</div>}
            {file !== null && !isFileSizeLessThan10MB(file.size) && <div className="failure-upload-response"><WarningIcon className='error-upload-icon' /><span className='error-upload-message'> {UploadFileStrings.errormsg}</span></div>}
            {file !== null && !isValidFileUploaded(file) && <div className="failure-upload-response"><WarningIcon className='error-upload-icon' /><span className='error-upload-message'>{UploadFileStrings.errormsgXML}</span></div>}
            {file !== null && !isValidName(file.name) && <div className="failure-upload-response"><WarningIcon className='error-upload-icon' /><span className='error-upload-message'>{UploadFileStrings.filenameError}</span></div>}
            {showProgressBar && (
              <LinearProgress
                variant='determinate'
                value={progress}
                className='linearprogressbar'
              />
            )}
            {file !== null && selectedUploadType !== null && uploadFileResponse.errorMessage !== null && <div className="failure-upload-response"><WarningIcon className='error-upload-icon' /><span className='error-upload-message'> {uploadFileResponse.errorMessage}</span>{minimized && <a className='retry-upload' aria-label='retry-min' onClick={() => { retryUpload() }}>Retry</a>}</div>} {uploadFileResponse.message !== null && <div className="success-upload-response"><TaskAltIcon className='success-upload-icon' /><span>{ UploadFileStrings.fileUploaded }</span></div>}
            {minimized
              ? (
                <></>
                )
              : (
                  uploadFileResponse.message == null && uploadFileResponse.errorMessage == null
                    ? <div className='buttonelement'>
                    <> <button
                      data-testid='cancel-btn'
                      className='Cancelbutton'
                      aria-label='CardCancelButton'
                      onClick={() => { onRemoveSelectedFile(); props.resetUpload(); props.handleClose(); setSelectedUploadType(null); setFile(null) }}
                    >
                      Cancel
                    </button>
                      <button
                        aria-label='CardConfirmButton'
                        onClick={() => { handleSubmit() }}
                        className={file !== null && selectedUploadType !== null && isFileSizeLessThan10MB(file.size) && isValidFileUploaded(file) && isValidName(file.name) ? 'Confirmbutton blue-bg' : 'Confirmbutton grey-bg'}
                      >
                        Confirm
                      </button></>
                  </div>
                    : uploadFileResponse.message !== null
                      ? <>
                      <button
                        className='Cancelbutton max-width device-list-button'
                        aria-label='CardCancelButton'
                        data-testid='Asset-list'
                        onClick={() => { props.resetUpload(); props.handleClose(); setSelectedUploadType(null); setFile(null) }}
                      >
                      Cancel
                      </button>
                      <button
                        aria-label='CardConfirmButton'
                        onClick={() => { setShowDetailsModal(true) }}
                        className={file !== null && selectedUploadType !== null ? 'Confirmbutton blue-bg max-width' : 'Confirmbutton grey-bg max-width'}
                      >
                        Show Details
                      </button></>
                      : <><button
                      className='Cancelbutton'
                      aria-label='CardCancelButton'
                      onClick={() => { props.resetUpload(); props.handleClose(); setSelectedUploadType(null); setFile(null) }}
                    >
                      Cancel
                    </button>
                      <button
                        aria-label='CardConfirmButton'
                        onClick={() => { retryUpload() }}
                        className={file !== null && selectedUploadType !== null ? 'Confirmbutton blue-bg' : 'Confirmbutton grey-bg'}
                      >
                        Retry
                      </button></>)}
          </div>
        </Box>
      </Modal>
      {showDetailsModal && <Modal open={showDetailsModal} onClose={handleCloseModal} ><ShowDetailsComponent handleClose={handleCloseModal}/></Modal>}
    </div>
  )
}
const mapDispatchToProps = (dispatch: any) => ({
  uploadFile: (file: File, name: string, tenantID: string, uploadType: string) => dispatch(uploadFile(file, name, tenantID, uploadType)),
  resetUpload: () => dispatch(resetUpload())
})

interface StateToProps {
  deviceInfo: UploadFileInfoState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  deviceInfo: state.deviceInfo
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends PropsFromRedux {
  open: boolean
  handleClose: any
}

export default connector(UploadFileComponent)
