import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface exportFirmwareUpgradeStatusListState {
  isLoading: boolean
  loadingError: string | null
  FirmwareUpgradeStatusList: any
}

// Initial state of the slice
const initialState: exportFirmwareUpgradeStatusListState = {
  isLoading: true,
  loadingError: null,
  FirmwareUpgradeStatusList: null
}

// Slice to return from action
export const exportFirmwareUpgradeStatusListSlice = createSlice({
  name: 'exportFirmwareUpgradeStatusList',
  initialState,
  reducers: {
    exportFirmwareUpgradeStatusLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        FirmwareUpgradeStatusList: []
      }
    },
    exportFirmwareUpgradeStatusUpdateAction: (state, action: PayloadAction< any >) => {
      return {
        isLoading: false,
        loadingError: null,
        FirmwareUpgradeStatusList: action.payload
      }
    },
    exportFirmwareUpgradeStatusErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        FirmwareUpgradeStatusList: null
      }
    }
  }
})

// Actions to export
export const {
  exportFirmwareUpgradeStatusLoadingAction,
  exportFirmwareUpgradeStatusUpdateAction,
  exportFirmwareUpgradeStatusErrorAction
} = exportFirmwareUpgradeStatusListSlice.actions

export default exportFirmwareUpgradeStatusListSlice.reducer
