import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZFailureMessage = z.object({
  failed_command_param: z.string(),
  failure_reason: z.string()
})

const ZConfiguration = z.object({
  asset_status_summary: z.string(),
  asset_serial_number: z.string(),
  asset_name: z.string(),
  command_failure_message: z.array(ZFailureMessage)
})

const ZConfigurationTemplate = z.object({
  total_count: z.number(),
  configuration_status: z.array(ZConfiguration)
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZConfigurationTemplate.nullable()),
  error: z.boolean(),
  error_message: z.optional(z.string().nullable())
})

export type ConfigurationDetails = z.infer<typeof ZConfigurationTemplate>
export type ConfigurationDetailsObj = z.infer<typeof ZConfiguration>
export type CommandFailureMessage = z.infer<typeof ZFailureMessage>

export async function getConfigurationList (
  templateId: string,
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  onSuccess: (configurationList: ConfigurationDetails, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  // istanbul ignore else
  if (sortfield === 'serial_number') {
    sortfield = 'assetSerialNo'
  } else if (sortfield === 'upload status') {
    sortfield = 'assetStatusSummary'
  } else if (sortfield === 'asset_name') {
    sortfield = 'assetName'
  }
  const url = `/asset-config-service/api/v1/udp/commands/configuration/status/${templateId}?page=${page - 1}&size=${size}&sort=${sortfield},${sortdir}`

  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          // istanbul ignore else
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, parseResponse.data.http_status)
          } else {
            onFailure(parseResponse.data.error_message, parseResponse.data.http_status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        onFailure(error.message, error.response.status)
      } else {
        onFailure('Network Error', 400)
      }
    })
}
