import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface PauseSchedulerTemplateState {
  isLoading: boolean
  error: boolean
  httpStatus: number
}

const initialState: PauseSchedulerTemplateState = {
  isLoading: false,
  error: false,
  httpStatus: 0
}

export const pauseSchedulerTemplateStateSlice = createSlice({
  name: 'PauseSchedulerTemplateState',
  initialState,
  reducers: {
    pauseSchedulerTemplateLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        httpStatus: 0
      }
    },
    pauseSchedulerTemplateUpdateAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: false,
        httpStatus: action.payload
      }
    },
    pauseSchedulerTemplateErrorAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: true,
        httpStatus: action.payload
      }
    }
  }
})

export const {
  pauseSchedulerTemplateLoadingAction,
  pauseSchedulerTemplateUpdateAction,
  pauseSchedulerTemplateErrorAction
} = pauseSchedulerTemplateStateSlice.actions

export default pauseSchedulerTemplateStateSlice.reducer
