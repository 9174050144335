/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type FirmwareListingDetails } from '../services/firmwareList'

export interface firmwareListingDetailsState {
    isLoading: boolean
    loadingError: string | null
    firmwareListData: FirmwareListingDetails[] | null
    httpStatus: number | null
    totalCount: number
}

// Initial state of the slice
const initialState: firmwareListingDetailsState = {
    isLoading: true,
    loadingError: null,
    firmwareListData: null,
    httpStatus: null,
    totalCount: 0
}

// Slice to return from action
export const firmwareListingDetailsSlice = createSlice({
    name: 'firmwareListData',
    initialState,
    reducers: {
        FirmwareListLoadingAction: (state) => {
            // clear out the data whenever we reload
            return {
                isLoading: true,
                loadingError: null,
                firmwareListData: null,
                httpStatus: 0,
                totalCount: 0
            }
        },
        FirmwareListUpdateAction: (state, action: PayloadAction<{ data: FirmwareListingDetails[], total_count: number, httpStatus: number }>) => {
            return {
                isLoading: false,
                loadingError: null,
                firmwareListData: action.payload.data,
                httpStatus: action.payload.httpStatus,
                totalCount: action.payload.total_count
            }
        },
        FirmwareListErrorAction: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                isLoading: false,
                loadingError: action.payload,
                httpStatus: action.payload.httpStatus,
                firmwareListData: null,
                totalCount: 0
            }
        }
    }
})

// Actions to export
export const {
    FirmwareListLoadingAction,
    FirmwareListUpdateAction,
    FirmwareListErrorAction
} = firmwareListingDetailsSlice.actions

export default firmwareListingDetailsSlice.reducer
