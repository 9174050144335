/* eslint-disable */
import * as React from 'react'
import './uplaodFirmware.css'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal';
import { fetchAssetTypes } from '../../asset-configuration/redux/actionCreator'
import { CircularProgress } from '@mui/material'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { useAuth } from 'react-oidc-context'
import OutlinedInput from '@mui/material/OutlinedInput';
import LinearProgress from '@mui/material/LinearProgress'
import FormHelperText from '@mui/material/FormHelperText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography'
import WarningIcon from '@mui/icons-material/WarningAmber'
import Tooltip from '@mui/material/Tooltip'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import { useNavigate } from 'react-router-dom'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close'
import LanguageStrings from '../../../i18n/locales'
import { GenericDropdown, type Option } from '../../../components/GenericDropdown'
import { Stack, TextField, Grid, Button } from '@mui/material'
import { type firmwareReq } from '../services/uploadFirmware'
import { addFirmware, getFirmwareTypeData, resetFirmwareParams } from '../redux/actionCreators'
import { connect, type ConnectedProps } from 'react-redux'
import { type RootState } from '../../../store'
import { type AddFirmwareInfoState } from '../redux/addFirmwareInfoSlice'
import { useSelector } from 'react-redux'
import { type AssetTypesState } from '../../asset-configuration/redux/assetTypeSlice'
import { isValidName } from '../../../utils/validator'
import { isValidVersion, isValidVersionCharacter } from '../../../utils/validator'

const AddFirmwareStrings = LanguageStrings().AddFirmwareStrings

const style = {
  width: '99%',
  borderRadius: '10px',
  border: '1px solid #101010',
  background: '#272727',
  boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '16px',
  marginBottom: '16px',
  padding: '24px'
}
const successModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 399,
  bgcolor: '#272727',
  fontFamily: 'Honeywell Sans Web',
  p: 4,
};
const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#272727',
  fontFamily: 'Honeywell Sans Web',
  p: 4,
};

const styles = {

  button: {
    padding: '10px 30px',
    cursor: 'pointer',
  },
  buttonDisabled: {
    padding: '10px 30px',
    cursor: 'not-allowed',
    background: '#625d5d',
  },
};

function AddFirmwareVersion(props: PropsFromRedux) {
  const navigate = useNavigate()
  const initialState = {
    assetType: null,
    firmwareType: '',
    version: '',
    imageIdentifier: '',
    file: null,
    minimumMeterFirmwareVersion: null,
    minimumCommunicationFirmwareVersion: null,
    assetCategoryId: '',
    assetTypeId: '',
    firmwareTypeId: '',
  };

  const [addState, dispatch] = React.useReducer((curVal: any, newVal: any) => ({ ...curVal, ...newVal }), initialState);


  function handleBreadCrumbsClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault()
  }
  const UploadFileStrings = LanguageStrings().UploadFileStrings
  const [file, setFile] = React.useState<File | null>(null)
  const [showProgressBar, setShowProgressBar] = React.useState(false)
  const [progress, setProgress] = React.useState(0)

  const isFileSizeLessThan10MB = (fileSize: number): boolean => {
    return fileSize > 0 && (fileSize / (1024 * 1024)) < 1
  }

  const isValidFileUploaded = (file: any): boolean => {
    const validExtensions = ['application/octet-stream', 'application/xml', 'text/xml', 'application/macbinary']
    const extension = file.type.split('.').pop();
    return validExtensions.includes(extension)
  }
  const onFileChange = (e: any): void => {
    dispatch({ file: e.target.files[0] })
    setFile(e.target.files[0])
  }

  const onRemoveSelectedFile = () => {
    setFile(null)
    setShowProgressBar(false)
    dispatch({ ...addState, 'file': null })
  }
  const handleDrag = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (e.dataTransfer?.files && e.dataTransfer?.files[0]) {
      setFile(e.dataTransfer.files[0])
      dispatch({ file: e.dataTransfer.files[0] })

    }
  }

  const handleChange = (name: string, value: string) => {

    let label = name + 'Options';
    // istanbul ignore else
    if (props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0) {
      let assetTypeName = props.assetTypes.assetTypes?.filter((a: any) => a.asset_type_id == value)[0].asset_type_name
      dispatch({ ...addState, 'assetTypeId': value, 'assetType': assetTypeName })
    }
  }

  const handleChangefirmware = (name: string, value: string) => {
    let label = name + 'Options';
    let firmwareTypeIdName = firmwareTypeState.find((a: { firmwareTypeId: string }) => a.firmwareTypeId == value).firmwareType
    dispatch({ ...addState, [name]: firmwareTypeIdName, 'firmwareTypeId': value })
  }

  const handleDragOver = (e: any) => {
    const event = e as Event
    event.stopPropagation()
    event.preventDefault()
  }

  const [reqError, setReqError] = React.useState<boolean>(false)
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false)
  const [showAddSuccessModal, setShowAddSuccessModal] = React.useState<boolean>(false)
  const auth = useAuth()
  const addFirmwareResponse = useSelector((state: RootState) => state.addFirmwareInfoSlice)
  const firmwareTypeState = useSelector((state: RootState) => state.firmwareTypeData.FirmwareTypeData)
  const [categoryOptions, setCategoryOptions] = React.useState<Option[]>([])
  const [assetTypeOptions, setAssetTypeOptions] = React.useState<Option[]>([])
  const [firmwareTypeOptions, setFirmwareTypeOptions] = React.useState<Option[]>([])
  const [category, setCategory] = React.useState('')
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [inputMeterMinVersion, setInputMinMeterVersion] = React.useState(true)
  const [inputMinVersion, setInputMinCommVersion] = React.useState(true)
  const [inputFocusedVersion, setInputFocusedVersion] = React.useState(false)
  const [inputFocusedMeterMinVersion, setInputFocusedMinMeterVersion] = React.useState(false)
  const [inputFocusedCommMinVersion, setInputFocusedMinCommVersion] = React.useState(false)

  const handleInputFocus = (isFocused: boolean | ((prevState: boolean) => boolean)) => {
    setInputFocusedVersion(isFocused);
  }

  const handleInputFocusMinMeterVersion = (isFocused: boolean | ((prevState: boolean) => boolean)) => {
    setInputFocusedMinMeterVersion(isFocused);
  }

  const handleInputFocusMinCommVersion = (isFocused: boolean | ((prevState: boolean) => boolean)) => {
    setInputFocusedMinCommVersion(isFocused);
  }


  const handleSubmit = () => {
    props.addFirmware(addState, auth.user?.profile.name as string, auth.user?.profile.customer as string);
    setIsDisabled(true);
  }

  const versionValidate = (e: any, ver: string) => {
    const res = isValidVersionCharacter(e.target.value)
    if ( ver === 'version') {
      setValidVersion(res)
    } else if (ver === 'meterVersion') {
      setInputMinMeterVersion(res)
    } else if (ver === 'commVersion') {
      setInputMinCommVersion(res)
    }

    dispatch({ [e.target.name]: e.target.value })
  }

  const handleChooseCategory = (e: any): void => {
    setCategory(e.target.value)
    setAssetTypeOptions([])
    const tempAssetTypeOptions: Option[] = []
    if (props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0) {
      props.assetTypes.assetTypes.forEach((assetType) => {
        if (assetType.category_type_id === e.target.value) {
          tempAssetTypeOptions.push({
            value: assetType.asset_type_id,
            label: assetType.asset_type_name
          })
        }
      })
      dispatch({ ...addState, 'assetCategoryId': e.target.value })
      setAssetTypeOptions(tempAssetTypeOptions)
    }
  }

  React.useEffect(() => {
    if (props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0) {
      const categories: Option[] = []
      const uniqueCategories: Record<string, boolean> = {}

      props.assetTypes.assetTypes.forEach((assetType) => {
        if (!uniqueCategories[assetType.category_type_id]) {
          uniqueCategories[assetType.category_type_id] = true
          categories.push({
            value: assetType.category_type_id,
            label: assetType.category_type_name
          })
        }
      })
      setCategoryOptions(categories)
    }
  }, [props.assetTypes.assetTypes])

  React.useEffect(() => {
    if (firmwareTypeState !== null && firmwareTypeState.length > 0) {
      const firmwareTypes: Option[] = []

      firmwareTypeState.filter((p:any) => p.assetTypeId===addState.assetTypeId).forEach((firmwareType: any) => {
        firmwareTypes.push({
          value: firmwareType.firmwareTypeId,
          label: firmwareType.firmwareType
        })

      })
      setFirmwareTypeOptions(firmwareTypes)
    }
  }, [firmwareTypeState, addState])
  const [validVersion, setValidVersion] = React.useState(false);

  const validateFields = () => {
    console.log(file, 'file')
    if (addState.assetType && addState.file && addState.file !== '' && isValidFileUploaded(file) == true && addState.firmwareType && addState.version !== '' && addState.minimumMeterFirmwareVersion !== '' && addState.minimumMeterFirmwareVersion !== null && validVersion && addState) {
      if (addState.minimumMeterFirmwareVersion) {
        if (!isValidVersion(addState.minimumMeterFirmwareVersion)) {
          setReqError(true)
          return
        }
      }
      if (addState.minimumCommunicationFirmwareVersion) {
        if (!isValidVersion(addState.minimumCommunicationFirmwareVersion)) {
          setReqError(true)
          return
        }
      }
      if (addState.imageIdentifier) {
        if (!isValidName(addState.imageIdentifier)) {
          setReqError(true)
          return
        }
      }
      if (addState.version) {
        const regexValidator = /^\d{1,3}\.\d{1,3}\.\d{1,3}$/
        if (!regexValidator.test(addState.version)) {
          setReqError(true)
          return
        }
      }
      if (addState.minimumMeterFirmwareVersion) {
        const regexValidator = /^\d{1,3}\.\d{1,3}\.\d{1,3}$/
        if (!regexValidator.test(addState.minimumMeterFirmwareVersion)) {
          setReqError(true)
          return
        }
      }

      setShowAddModal(true)
    }
    else {
      setReqError(true)
    }
  }
  React.useEffect(() => {
    if (addFirmwareResponse.errorMessage === null && addFirmwareResponse.message !== null) {
      setShowAddSuccessModal(true)
      props.resetFirmwareParams()
    }
  }, [addFirmwareResponse])

  React.useEffect(() => {
    props.fetchAssetTypes();
    props.getFirmwareTypeData();
  }, [])

  const closeAddModal = () => {
    setShowAddModal(false);
    setIsDisabled(false);
    props.resetFirmwareParams()
  }

  const closeAddSuccessModal = () => {
    setShowAddSuccessModal(false);
    setFile(null)
    setCategory('')
    dispatch({
      ...addState,
      'assetType': null,
      'firmwareType': '',
      'version': '',
      'imageIdentifier': '',
      'file': null,
      'minimumMeterFirmwareVersion': '',
      'minimumCommunicationFirmwareVersion': '',
      'assetCategoryId': '',
      'assetTypeId': '',
      'firmwareTypeId': ''
    })

  setShowAddModal(false)
  setIsDisabled(false);
}
  return (
    <>
      <div role='presentation' data-testid='breadcrumbs' onClick={handleBreadCrumbsClick} style={{ width: '100%' }} >
        <Breadcrumbs aria-name='breadcrumb' sx={{ color: '#f0f0f0', fontSize: '12px', marginBottom: '14px', fontFamily: 'Honeywell Sans Web', marginTop: '12px' }}>
          <Link underline='hover' color='inherit' data-testid='device-management-breadcrumb' onClick={() => { navigate('/card') }} >
            {LanguageStrings().DeviceProvisioningStrings.deviceManagement}
          </Link>
          <Link underline='hover' data-testid='asset-provisioning-history' color='inherit' onClick={() => { navigate('/firmware-list') }} >
            {LanguageStrings().AddFirmwareStrings.firmware}
          </Link>
          <Typography color='inherit' sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}>{LanguageStrings().AddFirmwareStrings.addFirmwareVersion}</Typography>
        </Breadcrumbs>
      </div>
      <div style={{ display: 'flex' }}>
        <ArrowBackIos sx={{ cursor: 'pointer' }} data-testid='back-arrow' onClick={() => { navigate('/firmware-list') }} />
        <div style={{ marginBottom: '32px' }}>
          <span data-testid='deviceHistoryDetails' style={{ color: '#f0f0f0', fontSize: '24px', fontWeight: '900', paddingRight: '16px', marginRight: '10px' }}>{LanguageStrings().AddFirmwareStrings.addFirmwareVersion}</span>
        </div>
      </div>
      <Box sx={style} className='add-firmware-container'>
        <div className='title'>{LanguageStrings().AddFirmwareStrings.addDetails}</div>
        <span>{LanguageStrings().AddFirmwareStrings.firmwareFile}</span><span className='required'> *</span>
        <div className='drag-drop-container'>
          <Box className='modal-box-firmware' onDragEnter={handleDrag} onDragOver={handleDragOver} onDrop={handleDrop}>
            <DescriptionOutlinedIcon
              className='DescriptionOutlinedIcon'
            />
            <Typography
              data-testid='typo-text '
              className='draganddroptext-firmware'
            >
              {UploadFileStrings.droptitle} {' '}
              {UploadFileStrings.ortext}
            </Typography>
            <label className='file-label-firmware' htmlFor='file-input'>
              <input
                type='file'
                id='file-input'
                className='input-file'
                data-testid='file-input'
                onChange={(e: any) => {
                  onFileChange(e)
                }}
                onClick={(event: any) => {
                  event.target.value = null
                }}
                multiple
              />
              {UploadFileStrings.browsefile}{' '}
            </label>
          </Box>
          {file !== null && <div className='upload-file-name'>{file.name} ({(file.size / (1024 * 1024)).toFixed(4)}MB / 1MB)  <CloseIcon
            className='upload-file-close'
            data-testid='close-selected-file'
            onClick={() => { onRemoveSelectedFile() }}
          /></div>}
          {file !== null && !isFileSizeLessThan10MB(file.size) && <div className='failure-upload-response'><WarningIcon className='error-upload-icon' /><span className='error-upload-message'> {UploadFileStrings.errormsg1MB}</span></div>}
          {file !== null && !isValidFileUploaded(file) && <div className='failure-upload-response'><WarningIcon className='error-upload-icon' /><span className='error-upload-message'>{UploadFileStrings.errormsgBIN}</span></div>}
          {file !== null && !isValidName(file.name) && <div className='failure-upload-response'><WarningIcon className='error-upload-icon' /><span className='error-upload-message'>{UploadFileStrings.filenameError}</span></div>}
          {showProgressBar && (
            <LinearProgress
              variant='determinate'
              value={progress}
              className='linearprogressbar'
            />
          )}
        </div>
        <Typography
          data-testid='typo-text2 '
          className='subtext-firmware'
        >
          {UploadFileStrings.acceptBin}{' '}{UploadFileStrings.errormsg1MB}
        </Typography>
        {reqError && !addState.file && <FormHelperText style={{ marginTop: '-30px' }} className='errorColor' >{LanguageStrings().AddFirmwareStrings.required}</FormHelperText>}

        <Box>
          <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 2, sm: 4, md: 8 }}>
            <Grid item >
              <span className='requiredDropDown'>*</span>
              <GenericDropdown dataTestId='category' width={250} options={categoryOptions} value={category} onChange={handleChooseCategory} label={'Category'} />
              {reqError && !addState.assetCategoryId && <FormHelperText className='errorColor' >{LanguageStrings().AddFirmwareStrings.required}</FormHelperText>}

            </Grid>
            <Grid item >
              <span className='requiredAssetType'>*</span>
              <GenericDropdown dataTestId='assettype' width={250} options={assetTypeOptions} value={addState.assetTypeId} onChange={(e) => handleChange('assetType', e.target.value)} label={LanguageStrings().AddFirmwareStrings.assetType} />
              {reqError && !addState.assetType && <FormHelperText className='errorColor'>{LanguageStrings().AddFirmwareStrings.required}</FormHelperText>}
            </Grid>
            <Grid item >
              <span className='requiredFmwType'>*</span>
              <GenericDropdown dataTestId='firmwaretype' width={250} options={firmwareTypeOptions} value={addState.firmwareTypeId} onChange={(e) => handleChangefirmware('firmwareType', e.target.value)} label={LanguageStrings().AddFirmwareStrings.firmwareType} />
              {reqError && !addState.firmwareType && <FormHelperText className='errorColor'>{LanguageStrings().AddFirmwareStrings.required}</FormHelperText>}

            </Grid>

            <Grid item >
              <div style={{ marginTop: '10px' }}><span>{LanguageStrings().AddFirmwareStrings.version} </span><span className='required'>*</span></div>

              <TextField id='filled-basic' inputProps={{ maxLength: 11 }} required name='version' value={addState.version} onChange={(e) => versionValidate(e, 'version')} onFocus={() => handleInputFocus(true)} onBlur={() => handleInputFocus(false)}
                placeholder={LanguageStrings().AddFirmwareStrings.format} variant='filled' />
              {reqError && !addState.version && <FormHelperText className='errorColor'>{LanguageStrings().AddFirmwareStrings.required}</FormHelperText>}
              { addState.version !== '' &&  !validVersion && inputFocusedVersion && <FormHelperText style={{ maxWidth: 269 }} className='errorColor'>{LanguageStrings().AddFirmwareStrings.invalidVersion} {'('}{LanguageStrings().AddFirmwareStrings.errorFormat}{')'}</FormHelperText>}
              { addState.version !== '' &&  !isValidVersion(addState.version) && !inputFocusedVersion && <FormHelperText style={{ maxWidth: 269 }} className='errorColor'>{LanguageStrings().AddFirmwareStrings.invalidVersion} {'('}{LanguageStrings().AddFirmwareStrings.errorFormat}{')'}</FormHelperText>}

            </Grid>
            <Grid item >
              <div style={{ marginTop: '10px' }}><span>{LanguageStrings().AddFirmwareStrings.imageIdentifier} </span></div>

              <TextField id='filled-basic' inputProps={{ maxLength: 30 }} name='imageIdentifier' value={addState.imageIdentifier} onChange={(e) => { dispatch({ [e.target.name]: e.target.value }) }}
                placeholder={LanguageStrings().AddFirmwareStrings.enter} variant='filled' />
              {addState.imageIdentifier !== '' && !isValidName(addState.imageIdentifier) && <FormHelperText style={{ maxWidth: 269 }} className='errorColor'>{UploadFileStrings.nameError} </FormHelperText>}

            </Grid>

            <Grid item >
              <div style={{ marginTop: '10px' }}><span>{LanguageStrings().AddFirmwareStrings.minimumMeterFirmwareVersion} </span><span className='required'>*</span></div>

              <TextField id='filled-basic' inputProps={{ maxLength: 30 }} required name='minimumMeterFirmwareVersion' value={addState.minimumMeterFirmwareVersion} onChange={(e) => versionValidate(e, 'meterVersion')}  onFocus={() => handleInputFocusMinMeterVersion(true)} onBlur={() => handleInputFocusMinMeterVersion(false)}
                placeholder={LanguageStrings().AddFirmwareStrings.format} variant='filled' />
                {reqError && !addState.minimumMeterFirmwareVersion && <FormHelperText className='errorColor'>{LanguageStrings().AddFirmwareStrings.required}</FormHelperText>}
              {addState.minimumMeterFirmwareVersion !== '' && !inputMeterMinVersion && inputFocusedMeterMinVersion && <FormHelperText style={{ maxWidth: 269 }} className='errorColor'>{LanguageStrings().AddFirmwareStrings.invalidVersion} {' (' + LanguageStrings().AddFirmwareStrings.errorFormat + ')'} </FormHelperText>}
              {addState.minimumMeterFirmwareVersion !== '' && !isValidVersion(addState.minimumMeterFirmwareVersion) && !inputFocusedMeterMinVersion && <FormHelperText style={{ maxWidth: 269 }} className='errorColor'>{LanguageStrings().AddFirmwareStrings.invalidVersion} {' (' + LanguageStrings().AddFirmwareStrings.errorFormat + ')'} </FormHelperText>}

            </Grid>
            <Grid item >
              <div style={{ marginTop: '10px' }}><span>{LanguageStrings().AddFirmwareStrings.minimumCommunicationFirmwareVersion} </span></div>
              <TextField id='filled-basic' inputProps={{ maxLength: 30 }} required name='minimumCommunicationFirmwareVersion' value={addState.minimumCommunicationFirmwareVersion} onChange={(e) => versionValidate(e, 'commVersion')} onFocus={() => handleInputFocusMinCommVersion(true)} onBlur={() => handleInputFocusMinCommVersion(false)}
                placeholder={LanguageStrings().AddFirmwareStrings.enter} variant='filled' />
              {addState.minimumCommunicationFirmwareVersion !== '' && !inputMinVersion && inputFocusedCommMinVersion && <FormHelperText style={{ maxWidth: 269 }} className='errorColor'>{LanguageStrings().AddFirmwareStrings.invalidVersion} {' (' + LanguageStrings().AddFirmwareStrings.errorFormat + ')'} </FormHelperText>}
              {addState.minimumMeterFirmwareVersion !== '' && !isValidVersion(addState.minimumCommunicationFirmwareVersion) && !inputFocusedCommMinVersion && <FormHelperText style={{ maxWidth: 269 }} className='errorColor'>{LanguageStrings().AddFirmwareStrings.invalidVersion} {' (' + LanguageStrings().AddFirmwareStrings.errorFormat + ')'} </FormHelperText>}

            </Grid>
          </Grid>
        </Box>
      </Box>
      <div className='footer'><Button onClick={() => { navigate('/firmware-list') }}>Cancel</Button><Button data-testid='validate-req' onClick={() => { validateFields() }}>Add</Button></div>
      <Modal className='add-firmware-modal' open={showAddSuccessModal} onClose={() => { closeAddSuccessModal() }}><Box sx={successModalStyle}>
        <div className='title'>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {LanguageStrings().AddFirmwareStrings.successfullyAdded}
          </Typography>
          <CloseIcon style={{ cursor: 'pointer' }} data-testid='close-button' onClick={() => { closeAddSuccessModal() }} />
        </div>
        <Typography id='modal-modal-description' sx={{ mt: 2 }}>
          {LanguageStrings().AddFirmwareStrings.versionhasbeenaddedsuccessfully}
        </Typography>
        <div className='add-modal-button-container'>
          <Button style={{ visibility: 'hidden' }}></Button><Button onClick={() => { closeAddSuccessModal(); navigate('/firmware-list') }}>Ok</Button></div>
      </Box>
      </Modal>


      <Modal className='add-firmware-modal' open={showAddModal} onClose={() => { closeAddModal() }}><Box sx={modalStyle}>
        <div className='title'>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {LanguageStrings().AddFirmwareStrings.addFirmwareVersion}
          </Typography>
          <CloseIcon data-testid='close-button' style={{ cursor: 'pointer' }} onClick={() => { closeAddModal() }} />
        </div>
        <Typography id='modal-modal-description' sx={{ mt: 2 }}>
          {LanguageStrings().AddFirmwareStrings.doYouWantadd}
        </Typography>
        {addFirmwareResponse.errorMessage !== null && <div className='failure-upload-response'><WarningIcon className='error-upload-icon' /><span className='error-upload-message'> {addFirmwareResponse.errorMessage}</span></div>}
        <div className='add-modal-button-container'>
          <Button data-testid='cancel-add-firmware' onClick={() => { closeAddModal() }}>Cancel</Button><Button data-testid='add-firmware' disabled={isDisabled} style={isDisabled ? styles.buttonDisabled : styles.button} onClick={() => { handleSubmit() }}>Confirm</Button></div>
      </Box>
      </Modal>
      {props.addFirmwareInfoSlice?.isLoading && <div className='CircularprogressAmi'>
        <CircularProgress />
      </div>}
    </>
  )
}


const mapDispatchToProps = (dispatch: any) => ({
  addFirmware: (firmwareRequestParams: firmwareReq, name: string, tenantId: string) => dispatch(addFirmware(firmwareRequestParams, name, tenantId)),
  resetFirmwareParams: () => dispatch(resetFirmwareParams()),
  getFirmwareTypeData: () => dispatch(getFirmwareTypeData()),
  fetchAssetTypes: () => dispatch(fetchAssetTypes()),
})

interface StateToProps {
  addFirmwareInfoSlice: AddFirmwareInfoState
  assetTypes: AssetTypesState
  tenant: string
}

const mapStateToProps = (state: RootState): StateToProps => ({
  addFirmwareInfoSlice: state.addFirmwareInfoSlice,
  assetTypes: state.assetTypes,
  tenant: state.tenant
})
type PropsFromRedux = ConnectedProps<typeof connector>

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(AddFirmwareVersion)
