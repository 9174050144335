import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import LanguageStrings from '../../../i18n/locales'
const DashboardStrings = LanguageStrings().Dashboard

export const grandParentBreadcrumbState = {
  honHeading: DashboardStrings.Honeywell_Heading_Breadcrumb,
  assetMgmt: DashboardStrings.Asset_Management
}

export const grandParentBreadcrumbSlice = createSlice({
  name: 'GrandParentBreadcrumbSlice',
  initialState: grandParentBreadcrumbState.honHeading,
  reducers: {
    changeGrandParentBreadcrumb: (state, action: PayloadAction<string>) => {
      return action.payload
    }
  }
})

export const { changeGrandParentBreadcrumb } = grandParentBreadcrumbSlice.actions
export default grandParentBreadcrumbSlice.reducer
