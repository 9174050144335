// istanbul ignore file
import { useState, useEffect } from 'react'

export function useCircleGaugeParams () {
  const [height, setHeight] = useState(getHeight())
  const [centerFont, setCenterFont] = useState(getCenterFont())
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      const width = window.innerWidth
      if (width >= 1896) {
        setHeight('350px')
        setCenterFont('30px')
      } else if (width >= 1532) {
        setHeight('280px')
        setCenterFont('25px')
      } else if (width >= 1368) {
        setHeight('250px')
        setCenterFont('20px')
      } else if (width >= 1300) {
        setCenterFont('20px')
        setHeight('250px')
      } else {
        setCenterFont('12px')
        setHeight('200px')
      }
    }, 100) // 100ms
    window.addEventListener('resize', debouncedResizeHandler)
    return () => window.removeEventListener('resize', debouncedResizeHandler)
  }, [])
  return [height, centerFont]
}

export function debounce (fn, ms) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

function getHeight () {
  const width = window.innerWidth
  let height
  if (width >= 1896) {
    height = '350px'
  } else if (width >= 1532) {
    height = '280px'
  } else if (width >= 1368) {
    height = '220px'
  } else if (width >= 1300) {
    height = '250px'
  } else {
    height = '200px'
  }
  return height
}

function getCenterFont () {
  const width = window.innerWidth
  let centerFont
  if (width >= 1896) {
    centerFont = '30px'
  } else if (width >= 1532) {
    centerFont = '25px'
  } else if (width >= 1368) {
    centerFont = '20px'
  } else if (width >= 1300) {
    centerFont = '20px'
  } else {
    centerFont = '15px'
  }
  return centerFont
}
