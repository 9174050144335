/* eslint-disable */
import * as React from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Pagination } from "@mui/material";

export default function TablePagination(props: any) {
  const { page, rowsPerPage, setPage } = props;
  const totalAssets = props.count;
  const pageCount = Math.ceil(totalAssets / rowsPerPage)
  const [textFieldValue, setTextFieldValue] = React.useState(page.toString())

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  }
  const handleKeyEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // istanbul ignore else
    if (event.key === 'Enter') {
      let value = textFieldValue || 1
      // istanbul ignore else
      if (value === "" || (Number(value) >= 1 && Number(value) <= pageCount)) {
        handlePageChange(event, Number(value))
      }
      else {
        if (isNaN(value)) {
          value = '1'
        }
        value = (Math.min(Math.max(Number(value), 1), pageCount)).toString()
        handlePageChange(event, Number(value))
        setTextFieldValue(value.toString())
      }
    }
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(parseInt(event.target.value))) {
      setTextFieldValue(parseInt(event.target.value))
    } else if (event.target.value === '') {
      setTextFieldValue(event.target.value)
    }
  }

  return (
    <Stack
      aria-label="pagination-component"
      className="device-pagination"
      direction={{ xs: "column", sm: "row" }}
      spacing={{ xs: 1, sm: 2, md: 4 }}
      justifyContent="space-between"
      alignItems="center"
      sx={{ mt: 2 }}
    >
      <div className="total-items">
        {(page >= 1 && totalAssets != 0) ? (
          <>
            {(page - 1) * rowsPerPage + 1}-
            {Math.min(page * rowsPerPage, totalAssets)} of {totalAssets} items
          </>
        ) : (
          ""
        )}
      </div>
      <Pagination
        count={pageCount}
        page={page}
        onChange={handlePageChange}
        sx={{ ml: 2, mr: 2 }}
      />
      <React.StrictMode>
        <Stack direction="row" alignItems="center">
          <div className="gotoAndTextfield">
            <div className="goTo" data-testid="goto">
              {" "}
              Go to page{" "}
            </div>
            <TextField
              size="small"
              className="AmiTableGoto"
              variant="outlined"
              inputProps={{
                min: 1,
                max: pageCount,
                role: "goto-page",
                color: "white",
                sx: {
                  color: "white",
                },
              }}
              value={textFieldValue}
              onChange={handleChange}
              onKeyDown={handleKeyEnter}
            />
          </div>
        </Stack>
      </React.StrictMode>
    </Stack>
  );
}
