import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface exportSchedulerDataState {
  isLoading: boolean
  loadingError: string | null
  exportSchedulerData: any
}

// Initial state of the slice
const initialState: exportSchedulerDataState = {
  isLoading: true,
  loadingError: null,
  exportSchedulerData: null
}

// Slice to return from action
export const exportSchedulerDataSlice = createSlice({
  name: 'exportProvisioningHistory',
  initialState,
  reducers: {
    exportSchedulerDataLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        exportSchedulerData: []
      }
    },
    exportSchedulerDataUpdateAction: (state, action: PayloadAction< any >) => {
      return {
        isLoading: false,
        loadingError: null,
        exportSchedulerData: action.payload
      }
    },
    exportSchedulerDataErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        exportSchedulerData: null
      }
    }
  }
})

// Actions to export
export const {
  exportSchedulerDataLoadingAction,
  exportSchedulerDataUpdateAction,
  exportSchedulerDataErrorAction
} = exportSchedulerDataSlice.actions

export default exportSchedulerDataSlice.reducer
