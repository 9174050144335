export function escapeHTML (str: string): string {
  let out = ''
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '<') {
      out += '&lt;'
    } else if (str[i] === '>') {
      out += '&gt;'
    } else if (str[i] === "'") {
      out += '&#x27;'
    } else if (str[i] === '"') {
      out += '&quot;'
    } else if (str[i] === '\\') {
      out += '&#92;'
    } else {
      out += str[i]
    }
  }
  return out
}
