/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect } from 'react'
import { Card } from '@scuf/common'
import { Box, CircularProgress, styled } from '@mui/material'
import LanguageStrings from '../../../i18n/locales'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import type { topologyInterface } from '../redux/topologyDataSlice'

const Topology = (props: { fetchTopology: (assetId: string, tenantId: string) => void, tenant: string, topologyData: topologyInterface, assetId: string }): JSX.Element => {
  const hamburgerState = useSelector((state: RootState) => state.hamburgerState)
  const TopologyStrings = LanguageStrings().DeviceDetailsStrings.Topology

  let paramAssetId = sessionStorage.getItem('paramAssetId')

  // istanbul ignore next
  if (paramAssetId === null) {
    paramAssetId = ''
  }

  const fetchTopologyData = (): any => {
    // istanbul ignore next
    if (paramAssetId !== undefined) {
      props.fetchTopology(props.assetId, props.tenant)
    }
  }
  useEffect(() => {
    fetchTopologyData()
  }, [hamburgerState])
  styled('div')({
    backgroundColor: '#505050',
    border: 'none',
    borderRadius: '4px'
  })

  function renderDevice (asset: any, index: number): JSX.Element {
    return (
            <dd key={index} style={{ cursor: 'pointer' }}>
                <strong style={{ color: '#64C3FF !important' }}>{asset.name}</strong>&nbsp;({asset.serial_number})
            </dd>
    )
  }

  function renderTopology (topo: any): JSX.Element {
    return (
            <div>
                {topo.parent !== undefined && topo.parent !== null && (
                    <dl>
                        <dt data-testid='topologyParent'>{TopologyStrings.parent}</dt>
                        {renderDevice(topo.parent, 0)}
                    </dl>
                )}
                {topo.children !== undefined && topo.children !== null && topo.children.length > 0 && (
                    <dl>
                        <dt data-testid='topologyChild'>{TopologyStrings.children}</dt>
                        {topo.children.map((child: { name: string }, index: number) => (
                          renderDevice(child, index)
                        ))}
                    </dl>
                )}
            </div>
    )
  }

  function getTable (): JSX.Element {
    if (props.topologyData === null) {
      return (
              <div className='authError'><AuthError errorMessage="cannotFetch" retry={fetchTopologyData}/></div>
      )
    }
    if (props.topologyData.isLoading) {
      return <CircularProgress/>
    } else
    if (props.topologyData.errorMessage !== null) {
      return (
                <div className='authError'><AuthError errorMessage={props.topologyData.errorMessage}/></div>
      )
    } else
    if (props.topologyData.topologyData !== null && props.topologyData.topologyData !== undefined) {
      return (
        renderTopology(props.topologyData.topologyData)
      )
    } else {
      return (
                <div className='authError'><AuthError errorMessage="cannotFetch" retry={fetchTopologyData}/></div>
      )
    }
  }

  return (
        <div className='deviceTopology' data-testid='deviceTopology'>
            <Box sx={{ width: '100%', padding: '1em' }}>
                <Card>
                    <Card.Header title={TopologyStrings.title} style={{ fontSize: '14px' }}/>
                    <Card.Content>
                        {
                            getTable()
                        }
                    </Card.Content>
                </Card>
            </Box>
        </div>
  )
}
export default Topology
