import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type BarKpiDataObject } from '../types'

export interface DonutKpiBarGraphState {
  isLoading: boolean
  error: boolean
  data: BarKpiDataObject | undefined
  httpStatus: number
}

const initialState: DonutKpiBarGraphState = {
  isLoading: false,
  error: false,
  data: undefined,
  httpStatus: 0
}

const donutKpiBarGraphSlice = createSlice({
  name: 'donutKpiBarGraph',
  initialState,
  reducers: {
    donutKpiBarGraphLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        data: undefined,
        httpStatus: 0
      }
    },
    donutKpiBarGraphUpdateAction: (state, action: PayloadAction<{ data: BarKpiDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: false,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    donutKpiBarGraphErrorAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: true,
        data: undefined,
        httpStatus: action.payload
      }
    },
    donutKpiBarGraphResetAction: () => {
      return initialState
    }
  }
})

export const {
  donutKpiBarGraphLoadingAction,
  donutKpiBarGraphUpdateAction,
  donutKpiBarGraphErrorAction,
  donutKpiBarGraphResetAction
} = donutKpiBarGraphSlice.actions

export default donutKpiBarGraphSlice.reducer
