/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Box, Button, Checkbox, CircularProgress, Drawer, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, styled, tableCellClasses } from '@mui/material'
import Pagination from '../../../components/TablePagination'
import React, { useEffect } from 'react'
import { Button as GenericButton } from '../../../components/Button'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import { visuallyHidden } from '@mui/utils'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { type AssetGroup } from '../types'
import { type AssetGroupsListState } from '../redux/AssetGroupsListSlice'
import { type getAllGroupFilterParameterState } from '../redux/getAllGroupFilterParameterSlice'
import { type AssetTypesState } from '../../asset-configuration/redux/assetTypeSlice'
import LanguageStrings from '../../../i18n/locales'
import { deleteGroup, fetchAssetGroupList, getAllGroupFilterParameterInfo, getAssetGroupListByFilterParamterInfo } from '../redux/actionCreator'
import { fetchAssetTypes } from '../../asset-configuration/redux/actionCreator'
import { useNavigate } from 'react-router-dom'
import SearchBox from '../../../components/SearchBox'
import CloseIcon from '@mui/icons-material/Close'
import ReplayIcon from '@mui/icons-material/Replay'
import FilterIcon from '../../../assets/filterIcon.png'
import { GenXDropdown, type Option } from '../../../components/GenXDropdown'
import { type DeleteGroupState } from '../redux/DeleteGroupSlice'
import Modal from '../../../components/Modal'
import { getTemplateModal } from './createGroupComponents/utils/getTemplateModal'

const AssetGroupStrings = LanguageStrings().AssetGroupStrings.GroupsTableComponent

type Order = 'asc' | 'desc'

interface Sort {
  order: Order
  orderBy: string
}

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  dataList: AssetGroup[]
  order: Order
  orderBy: string
  onRequestSort: (event: React.MouseEvent<unknown>, newOrderBy: keyof AssetGroup) => void
}

function EnhancedTableHead (props: EnhancedTableProps): any {
  const { onSelectAllClick, order, orderBy, onRequestSort, numSelected, rowCount, dataList } = props
  // istanbul ignore next
  const createSortHandler =
    (newOrderBy: keyof AssetGroup) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy)
    }
  return (dataList !== null && dataList !== undefined && <>
  <TableHead data-testid='table-header'>
        <TableRow>
          <StyledTableCell padding="checkbox">
            <Checkbox
              sx={{ color: '#909090' }}
              data-testid="device-table-header-checkbox"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'Select all Assets',
                id: 'select-all-items'
              }}
            />
          </StyledTableCell>
          {headCells.map((headCell: HeadCell) => (
            <StyledTableCell
              key={headCell.id}
              align='left'
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                hideSortIcon={!headCell.sortable}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
                aria-label={headCell.label}
                data-testid="test-sort"
                sx={{ cursor: headCell.sortable ? 'pointer' : 'text' }}
              >
                {headCell.label}
                {orderBy === headCell.id
                  ? (
                    // istanbul ignore next
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                    )
                  : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
  </>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
  sortable: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web',
    lineHeight: '20px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web',
    whiteSpace: 'nowrap'
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: AssetGroupStrings.NAME,
    sortable: true
  },
  {
    id: 'asset_category',
    numeric: false,
    disablePadding: false,
    label: AssetGroupStrings.ASSET_CATEGORY,
    sortable: true
  },
  {
    id: 'asset_type',
    numeric: false,
    disablePadding: false,
    label: AssetGroupStrings.ASSET_TYPE,
    sortable: true
  },
  {
    id: 'total_asset',
    numeric: true,
    disablePadding: false,
    label: AssetGroupStrings.TOTAL_ASSET,
    sortable: false
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: AssetGroupStrings.DESCRIPTION,
    sortable: true
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: AssetGroupStrings.STATUS,
    sortable: false
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: AssetGroupStrings.ACTION,
    sortable: false
  }
]

interface FilterState {
  category: Option | null
  assetType: Option | null
  status: Option | null
}

const GroupsTableComponent = (props: PropsFromRedux): JSX.Element => {
  const [assetGroupsList, setAssetGroupsList] = React.useState<AssetGroup[]>([])
  const [selected, setSelected] = React.useState<string[]>([])
  const [checkedGroups, setCheckedGroups] = React.useState<AssetGroup[]>([])
  const [selectedGroup, setSelectedGroup] = React.useState<string>('')
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'assetGroupId' })
  const [searchValue, setSearchValue] = React.useState<string | null>('')
  const rowsPerPage = 10
  const navigate = useNavigate()
  const deleteGroupResponse = useSelector((state: any) => state.deleteGroup)
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = React.useState<boolean>(false)
  const [deleteGroup, setDeleteGroup] = React.useState(false)
  const numAssignedAssets = useSelector((state: RootState) => state.assetGroupList.assetGroupsList?.total_count)
  const [showTemplates, setShowTemplates] = React.useState<string[]>([])

  // Filter state
  const [openFilter, setOpenFilter] = React.useState(false)
  const [showfilter, setShowFilter] = React.useState(false)
  const [categoryOptions, setCategoryOptions] = React.useState<Option[]>([])
  const [assetTypeOptions, setAssetTypeOptions] = React.useState<Option[]>([])
  const [groupStatusoption, setGroupStatusOption] = React.useState<Option[]>([])
  const [filterState, setFilterState] = React.useState<FilterState>({ category: null, assetType: null, status: null })
  const [appliedFilters, setAppliedFilters] = React.useState<FilterState>({ category: null, assetType: null, status: null })
  const GroupFilterParameter = useSelector((state: RootState) => state.getAllGroupFilterParamter.GroupFilterPara?.data?.status)

  useEffect(() => {
    handleFetchAssetGroupList()
    props.getAllGroupFilterParameterInfo()
    props.fetchAssetTypes()
  }, [])

  /* istanbul ignore next */
  const handleChooseCategory = (selectedValue: Option): void => {
    setFilterState({ ...filterState, category: selectedValue, assetType: null })
    const tempAssetTypeOptions: Option[] = []
    if (props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0 && props.assetTypes.assetTypes !== undefined) {
      props.assetTypes.assetTypes.forEach((assetType) => {
        if (assetType.category_type_id === selectedValue.value) {
          tempAssetTypeOptions.push({
            value: assetType.asset_type_id,
            label: assetType.asset_type_name
          })
        }
      })
      setAssetTypeOptions(tempAssetTypeOptions)
    }
  }

  /* istanbul ignore next */
  const handleAssetTypeSelect = (selectedValue: Option): void => {
    setFilterState({ ...filterState, assetType: selectedValue })
  }

  const handleStatusChange = (selectedValue: Option): void => {
    setFilterState({ ...filterState, status: selectedValue })
  }

  // istanbul ignore next
  const resetFilterState = (): void => {
    setAssetTypeOptions([])
    setFilterState(appliedFilters)
    setOpenFilter(false)
  }

  // istanbul ignore next
  const resetAppliedFilters = (): void => {
    setAssetTypeOptions([])
    setAppliedFilters({ category: null, assetType: null, status: null })
  }

  const handleGroupStatus = (configStatus: string | null, firmwareStatus: string | null): string[] => {
    const NOT_STARTED = 'Not Started'
    const IN_PROGRESS = 'In Progress'
    const COMPLETED = 'Completed'
    const result = []
    switch (firmwareStatus) {
      case NOT_STARTED:
        result.push(AssetGroupStrings.FirmwareUpgradeNotStarted)
        break
      case IN_PROGRESS:
        result.push(AssetGroupStrings.FirmwareUpgradeInProgress)
        break
      case COMPLETED:
        result.push(AssetGroupStrings.FirmwareUpgradeCompleted)
        break
      default:
        result.push('')
        break
    }
    switch (configStatus) {
      case NOT_STARTED:
        result.push(AssetGroupStrings.ConfigurationNotStarted)
        break
      case IN_PROGRESS:
        result.push(AssetGroupStrings.ConfigurationInProgress)
        break
      case COMPLETED:
        result.push(AssetGroupStrings.ConfigurationCompleted)
        break
      default:
        result.push('')
        break
    }
    return result
  }

  const handleStatus = (configStatus: string | null, firmwareStatus: string | null): JSX.Element => {
    if (configStatus === null && firmwareStatus === null) {
      return <></>
    } else if (configStatus === null) {
      return <>{handleGroupStatus(configStatus, firmwareStatus)[0]}</>
    } else if (firmwareStatus === null) {
      return <>{handleGroupStatus(configStatus, firmwareStatus)[1]}</>
    } else {
      const [firmwareStatusString, configStatusString] = handleGroupStatus(configStatus, firmwareStatus)
      return (
        <>
          <span onClick={() => { setShowTemplates([firmwareStatusString, configStatusString]) }} style={{ color: '#64C3FF' }}>{firmwareStatusString}+1</span>
        </>
      )
    }
  }

  const isDeleteDisabled = (configStatus: string | null, firmwareStatus: string | null): boolean => {
    return configStatus !== null || firmwareStatus !== null
  }

  /* istanbul ignore next */
  const isMutipleDeleteDisabled = (): boolean => {
    for (const row of checkedGroups) {
      if (row.firmware_status !== null || row.config_status !== null) return true
    }
    return false
  }

  React.useEffect(() => {
    if (GroupFilterParameter !== null && GroupFilterParameter?.length > 0) {
      const groupStatusoption: Option[] = []
      GroupFilterParameter?.forEach((item: any) => {
        groupStatusoption.push({
          value: item,
          label: item
        })
      })
      setGroupStatusOption(groupStatusoption)
    }
  }, [GroupFilterParameter])

  React.useEffect(() => {
    if (props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0) {
      const categories: Option[] = []
      const uniqueCategories: Record<string, boolean> = {}
      props.assetTypes.assetTypes.forEach((assetType) => {
        if (!uniqueCategories[assetType.category_type_id]) {
          uniqueCategories[assetType.category_type_id] = true
          categories.push({
            value: assetType.category_type_id,
            label: assetType.category_type_name
          })
        }
      })
      setCategoryOptions(categories)
    }
  }, [props.assetTypes.assetTypes])

  /* istanbul ignore next */
  const showFilterDetails = (pageNum: number): void => {
    props.getAssetGroupListByFilterParamterInfo(
      pageNum,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      searchValue ?? '',
      {
        categoryTypeIds: filterState.category === null ? [] : [filterState.category.value],
        assetTypeIds: filterState.assetType !== null ? [filterState.assetType.value] : [],
        status: filterState.status === null ? null : filterState.status.value
      })
  }

  const filtersUpdated = (): boolean => {
    return (filterState.category !== appliedFilters.category || filterState.assetType !== appliedFilters.assetType || filterState.status !== appliedFilters.status)
  }

  /* istanbul ignore next */
  const handleRowClick = (id: string): void => {
    const isIdSelected = selected.includes(id)
    if (!isIdSelected) {
      setCheckedGroups([...checkedGroups, assetGroupsList.filter((row: AssetGroup) => row.group_id === id)[0]])
      setSelected([...selected, id])
    } else {
      const temp = [...checkedGroups].filter((row: AssetGroup) => row.group_id !== id)
      setCheckedGroups(temp)
      setSelected(selected.filter((selectedId) => selectedId !== id))
    }
  }

  const handleGroupClick = (groupId: string, groupName: string): void => {
    sessionStorage.setItem('selectedGroupId', groupId)
    sessionStorage.setItem('selectedGroupName', groupName)
    navigate('/asset-group-details')
  }

  /* istanbul ignore next */
  const handleFetchAssetGroupList = (): void => {
    page >= 1 && props.fetchAssetGroupList(page - 1, rowsPerPage, sort.order, sort.orderBy, searchValue ?? '')
  }

  /* istanbul ignore next */
  const handleSearch = (): void => {
    if (page >= 1) {
      setPage(1)
      setFilterState({ category: null, assetType: null, status: null })
      setAppliedFilters({ category: null, assetType: null, status: null })
      props.fetchAssetGroupList(0, rowsPerPage, sort.order, sort.orderBy, searchValue ?? '')
    }
  }

  useEffect(() => {
    if (appliedFilters.category === null && appliedFilters.assetType === null && appliedFilters.status === null) {
      if (props.assetGroupsListState.assetGroupsList !== null && props.assetGroupsListState.httpStatus === 200 && (props.assetGroupsListState.assetGroupsList.total_count / rowsPerPage) >= page - 1) {
        handleFetchAssetGroupList()
      } else {
        setPage(1)
      }
    } else {
      // istanbul ignore else
      if (props.assetGroupsListState.assetGroupsList !== null && props.assetGroupsListState.httpStatus === 200 && (props.assetGroupsListState.assetGroupsList.total_count / rowsPerPage) >= page - 1) {
        showFilterDetails(page - 1)
      } else {
        setPage(1)
      }
    }
  }, [page, sort])

  /* istanbul ignore next */
  const handleRequestSort =
    (event: React.MouseEvent<unknown>, newOrderBy: keyof AssetGroup): void => {
      const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
      const toggledOrder = isAsc ? 'desc' : 'asc'
      setSort({ order: toggledOrder, orderBy: newOrderBy })
    }

  useEffect(() => {
    if (props.assetGroupsListState.assetGroupsList !== null && props.assetGroupsListState.httpStatus === 200) {
      setAssetGroupsList(props.assetGroupsListState.assetGroupsList.group_details)
    }
  }, [props.assetGroupsListState.assetGroupsList])

  // istanbul ignore next
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const currentPageGroups = assetGroupsList.map((group: AssetGroup) => group.group_id)
    if (event.target.checked) {
      const newSelected: string[] = []
      const newSelectedGroups: AssetGroup[] = []
      currentPageGroups.forEach((id) => {
        if (!selected.includes(id)) {
          newSelected.push(id)
        }
      })
      assetGroupsList.forEach((row: AssetGroup) => {
        if (newSelected.includes(row.group_id)) {
          newSelectedGroups.push(row)
        }
      })
      setSelected([...selected, ...newSelected])
      setCheckedGroups([...checkedGroups, ...newSelectedGroups])
    } else {
      const newSelected: string[] = []
      selected.forEach((id) => {
        if (!currentPageGroups.includes(id)) {
          newSelected.push(id)
        }
      })
      const newSelectedGroups: AssetGroup[] = [...checkedGroups].filter((row: AssetGroup) => !currentPageGroups.includes(row.group_id))
      setSelected(newSelected)
      setCheckedGroups(newSelectedGroups)
    }
  }

  React.useEffect(() => {
    // istanbul ignore next
    if (deleteGroupResponse.deleteGroup === 'Success') {
      setShowDeleteSuccessModal(true)
    }
  }, [deleteGroupResponse])

  const handleDeleteGroupWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.deleteGroupState.success) {
      return (
        <Modal buttonTypeNegative='hollow' title='Successfully Deleted' LabelPositive='Ok'
          onClickPositive={() => {
            handleFetchAssetGroupList()
            setDeleteGroup(false)
          }}
          onClose={() => {
            handleFetchAssetGroupList()
            setDeleteGroup(false)
          }}>
          <p style={{ paddingTop: '1.2em' }}>Group has been deleted successfully</p>
        </Modal>
      )
    } else if (props.deleteGroupState.isLoading) {
      return (
        <Modal title='Deleting Group'>
          <div className='CircularprogressAmi'>
            <CircularProgress />
          </div>
        </Modal>
      )
    } else {
      return (
        <Modal width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close' LabelPositive='Retry'
          onClickNegative={() => { setDeleteGroup(false) }}
          onClose={() => { setDeleteGroup(false) }} title='Deleting Group'
        // onClickPositive={handleModifyGroup}
        >
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} errorMessage="cannotUpdateGroup" /></div>
        </Modal>
      )
    }
  }

  const isSelected = (id: string): boolean => selected.includes(id)

  /* istanbul ignore next */
  const handleDelete = (): void => {
    setShowDeleteSuccessModal(false)
  }

  const handleTable = (): JSX.Element => {
    // istanbul ignore else
    if (props.assetGroupsListState.assetGroupsList !== null && props.assetGroupsListState.assetGroupsList.group_details.length > 0 && props.assetGroupsListState.httpStatus === 200) {
      return (
        <>
          <Paper
            sx={{
              width: '100%',
              mb: 2,
              backgroundColor: '#272727',
              boxShadow: 'unset',
              color: '#F0F0F0',
              marginBottom: '0'
            }}
          >
            <TableContainer className="device-table">
              <Table
                sx={{ minWidth: 750, color: '#f0f0f0' }}
                aria-labelledby="tableTitle"
                size={'small'}
              >
                <EnhancedTableHead
                  numSelected={selected.filter((id) => assetGroupsList.map((group: AssetGroup) => group.group_id).includes(id)).length}
                  order={sort.order}
                  orderBy={sort.orderBy}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={assetGroupsList.length}
                  dataList={assetGroupsList}
                />
                <TableBody>
                  {assetGroupsList.length > 0 &&
                    assetGroupsList.map((row: AssetGroup, index: number) => {
                      const isItemSelected = isSelected(row.group_id)
                      const labelId = `enhanced-table-checkbox-${row.group_name}`

                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.group_id}
                          aria-label="groups-list-row"
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <StyledTableCell padding="checkbox">
                            <Checkbox
                              data-testid={`checkbox-${index}`}
                              sx={{ color: '#909090' }}
                              checked={isItemSelected}
                              onChange={
                                /* istanbul ignore next */
                                () => {
                                  handleRowClick(row.group_id)
                                }
                              }
                              inputProps={{
                                'aria-labelledby': labelId,
                                'aria-label': 'test-checkbox',
                                id: `select-item-${index}`
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            onClick={() => { handleGroupClick(row.group_id, row.group_name) }}
                            scope="row"
                            padding="none"
                            aria-label="group-name"
                            data-testid="group-name"
                            sx={{
                              color: '#64C3FF !important',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '15vw'
                            }}
                          >
                            {
                              (row.group_name != null) && row.group_name.length > 12
                                ? <abbr style={{ textDecoration: 'none' }} title={row.group_name}>{row.group_name}</abbr>
                                : row.group_name
                            }
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.category_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.asset_type_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.no_of_assets}
                          </StyledTableCell>
                          <StyledTableCell
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '15vw',
                            whiteSpace: 'nowrap'
                          }}
                          >
                            {
                              (row.description != null) && row.description.length > 12
                                ? <abbr style={{ textDecoration: 'none' }} title={row.description}>{row.description}</abbr>
                                : row.description
                            }
                          </StyledTableCell>
                          <StyledTableCell>
                            <span data-testid={`config-${index}`}>
                              {handleStatus(row.config_status, row.firmware_status)}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell>
                            {!isDeleteDisabled(row.config_status, row.firmware_status) && (
                              <span
                                data-testid={`delete-${index}`}
                                onClick={() => {
                                  setSelectedGroup(row.group_id)
                                  setShowDeleteSuccessModal(true)
                                }}
                                style={{
                                  color: '#64c3ff',
                                  textDecoration: 'none'
                                }}
                              >
                                {AssetGroupStrings.Delete}
                              </span>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {props.assetGroupsListState.assetGroupsList.total_count > 10 && (<Pagination count={props.assetGroupsListState.assetGroupsList.total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} siblingCount={1}
              boundaryCount={1} />)}
          </Paper>
          {
            selected.length === 0
              ? <></>
              : <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#272727', borderTop: '2px solid #202020' }}>
                <GenericButton type='borderless' dataTestId='cancel-btn' onClick={() => {
                  setSelected([])
                }}>Cancel</GenericButton>
                <GenericButton disabled={isMutipleDeleteDisabled()} primaryColor={isMutipleDeleteDisabled() ? '#B0B0B0' : undefined} dataTestId='delete-btn' onClick={() => {
                  setShowDeleteSuccessModal(true)
                }}>Delete</GenericButton>
              </div>
          }
          {
          showTemplates.length > 0
            ? getTemplateModal(showTemplates, () => { setShowTemplates([]) }, 'Status')
            : <></>
          }
          <>
            {
              deleteGroup
                ? handleDeleteGroupWithFailure()
                : <></>
            }
            {showDeleteSuccessModal &&
              <Modal buttonTypeNegative='hollow' title='Deleting Group' LabelNegative='Cancel' LabelPositive='Delete' onClickPositive={() => {
                setShowDeleteSuccessModal(false)
                setDeleteGroup(true)
                props.deleteGroup(selectedGroup !== '' ? selectedGroup : selected)
              }}
              onClose={handleDelete}
              onClickNegative={handleDelete}>
                <p style={{ paddingTop: '1.2em' }}>Do you want to Delete Group ?</p>
              </Modal>
            }
          </>
        </>
      )
    } else if (props.assetGroupsListState.isLoading) {
      return (
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
      )
    } else if (props.assetGroupsListState.httpStatus === 401) {
      return (
        <div className='authError'><AuthError errorMessage="Unauthorized" /></div>
      )
    } else if (props.assetGroupsListState.httpStatus === 403) {
      return (
        <div className='authError'><AuthError errorMessage="accessForbidden" /></div>
      )
    } else if (props.assetGroupsListState.httpStatus === 404) {
      return (
        <div className='authError'><AuthError errorMessage="NoDataPresent" /></div>
      )
    } else if (((props.assetGroupsListState.assetGroupsList === null || props.assetGroupsListState.assetGroupsList.group_details.length === 0) && props.assetGroupsListState.httpStatus === 200) || (props.assetGroupsListState.httpStatus === 404 && props.assetGroupsListState.error === 'No Groups are present')) {
      return (
        <div className='authError'><AuthError errorMessage="NoDataPresent" /></div>
      )
    } else if (props.assetGroupsListState.httpStatus === 400) {
      return <div className="authError"><AuthError errorMessage="invalidSearchInput" /></div>
    } else {
      return (
        <div className='authError'><AuthError errorMessage="cannotFetch" retry={handleFetchAssetGroupList} /></div>
      )
    }
  }

  return (
    <>
      <Box sx={style} className="devices">
        <div className='searchSection' style={{ width: '100%', backgroundColor: '#272727' }}>
          <Paper className='searchBoxComponent'
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
          >
            <Button data-testid='filter-data' className="filter-btn" sx={{ background: '#707070', color: '#D0D0D0' }} onClick={() => { setOpenFilter(true) }}><img src={FilterIcon} height={16} width={16} style={{ marginLeft: '7px' }} />Filter</Button>
            <SearchBox immediate={true} debounce={true} placeholder='Search using Name, Category, Asset Type, Description' sx={{ width: '450px' }} emptyEnterFallback={() => {
              page >= 1 && props.fetchAssetGroupList(page - 1, rowsPerPage, sort.order, sort.orderBy, '')
            }} searchVal={searchValue} setSearchVal={setSearchValue}
              performAction={() => { handleSearch() }} />
          </Paper>
        </div>
        <Drawer
            anchor="right"
            open={openFilter}
            className='filter-drawer'
            variant='temporary'
            onClose={() => {
              setOpenFilter(false)
              resetFilterState()
            }}
          >
            <div className='filter-headar'>
              <div className='title' style={{ marginTop: '15px' }}>Filter</div>
              <div><Button aria-label='clear-all-filter' className='cancel' style={{ marginTop: '-2px', marginLeft: '80px' }} onClick={() => {
                setFilterState({ category: null, assetType: null, status: null })
                setAssetTypeOptions([])
              }}><ReplayIcon sx={{ width: '16px', height: '16px', marginRight: '8px' }} />Clear All</Button></div>
              <div><CloseIcon aria-label='close-drawer' sx={{ color: '#f0f0f0' }}
                onClick={() => {
                  setOpenFilter(false)
                  resetFilterState()
                }} />
              </div>
            </div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                <div className='type-name' style={{ marginTop: '40px' }}>Category Type</div>
                <GenXDropdown placeholder='Select Category' value={filterState.category} options={categoryOptions} onSelect={handleChooseCategory} />
              </Grid>
              <Grid item xs={12}>
                <div className='type-name'>Asset Type</div>
                <GenXDropdown placeholder='Select Asset Type' value={filterState.assetType} options={assetTypeOptions} onSelect={handleAssetTypeSelect} />
              </Grid>
              <Grid item xs={12}>
                <div className='type-name'>Status</div>
                <GenXDropdown placeholder='Select Status' value={filterState.status} options={groupStatusoption} onSelect={handleStatusChange} />
              </Grid>
            </Grid>
            <div className='divider' />
            <div className='buttons-container'>
              <Button className='cancel' aria-label='cancel-drawer' onClick={() => {
                resetFilterState()
                setOpenFilter(false)
              }}>Cancel</Button>
              <GenericButton aria-label='confirm-filter' data-testid='show-results' primaryColor={!filtersUpdated() ? '#B0B0B0' : undefined}
              disabled={!filtersUpdated()}
              onClick={() => {
                setAppliedFilters(filterState)
                setShowFilter(true)
                setOpenFilter(false)
                setPage(1)
                showFilterDetails(0)
                setSearchValue(null)
              }}>Show Details</GenericButton>
            </div>
          </Drawer>
        {showfilter &&
          <div
            style={{
              padding: '12px 24px',
              borderTop: '2px solid #202020',
              color: '#d0d0d0'
            }}
          >
            { appliedFilters.category === null && appliedFilters.assetType === null && appliedFilters.status === null
              ? (
                  'No filters applied'
                )
              : (
                <div className="classNAme">
                  {numAssignedAssets} Results | Filters :
                  <>
                    {appliedFilters.category !== null &&
                      <div className="box">
                        {' '}
                        Category : {' '}
                        {appliedFilters.category.label}
                      </div>
                    }
                    {appliedFilters.assetType !== null &&
                      <div className="box">
                        {' '}
                        Asset Type : {' '}
                        {appliedFilters.assetType.label}
                      </div>
                    }
                    {appliedFilters.status !== null &&
                      <div className="box">
                        {' '}
                        Status:{' '}
                        {appliedFilters.status.label}
                      </div>
                    }
                  </>
                  <em
                    onClick={() => {
                      resetAppliedFilters()
                      setFilterState({ category: null, assetType: null, status: null })
                      handleFetchAssetGroupList()
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Clear Filters{' '}
                  </em>
                </div>
                )}
          </div>
        }
        {handleTable()}
      </Box>
    </>
  )
}

interface DispatchToProps {
  fetchAssetGroupList: (page: number, size: number, sortdir: string, sortfield: string, input: string) => void
  getAssetGroupListByFilterParamterInfo: (page: number, size: number, sortdir: string, sortfield: string, input: string, filterParams: any) => void
  getAllGroupFilterParameterInfo: () => void
  fetchAssetTypes: () => void
  deleteGroup: (groupIds: string | string[]) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getAllGroupFilterParameterInfo: () => dispatch(getAllGroupFilterParameterInfo()),
  getAssetGroupListByFilterParamterInfo: (page: number, size: number, sortdir: string, sortfield: string, input: string, filterParams: any) => dispatch(getAssetGroupListByFilterParamterInfo(page, size, sortdir, sortfield, input, filterParams)),
  fetchAssetTypes: () => dispatch(fetchAssetTypes()),
  fetchAssetGroupList: (page: number, size: number, sortdir: string, sortfield: string, input: string) => dispatch(fetchAssetGroupList(page, size, sortdir, sortfield, input)),
  deleteGroup: (groupIds: string | string[]) => dispatch(deleteGroup(groupIds))
})

interface StateToProps {
  assetGroupsListState: AssetGroupsListState
  groupFilterParamterState: getAllGroupFilterParameterState
  assetTypes: AssetTypesState
  deleteGroupState: DeleteGroupState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assetGroupsListState: state.assetGroupList,
  groupFilterParamterState: state.getAllGroupFilterParamter,
  assetTypes: state.assetTypes,
  deleteGroupState: state.deleteGroup
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(GroupsTableComponent)
