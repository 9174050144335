import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type DonutKpiDataObject, type DonutKpiState } from '../../types'

const initialState: DonutKpiState = {
  isLoading: false,
  error: false,
  data: undefined,
  httpStatus: 0
}

const batteryBreakdownDonutSlice = createSlice({
  name: 'batteryBreakdownDonut',
  initialState,
  reducers: {
    batteryBreakdownDonutLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        data: undefined,
        httpStatus: 0
      }
    },
    batteryBreakdownDonutUpdateAction: (state, action: PayloadAction<{ data: DonutKpiDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: false,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    batteryBreakdownDonutErrorAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: true,
        data: undefined,
        httpStatus: action.payload
      }
    }
  }
})

export const {
  batteryBreakdownDonutLoadingAction,
  batteryBreakdownDonutUpdateAction,
  batteryBreakdownDonutErrorAction
} = batteryBreakdownDonutSlice.actions

export default batteryBreakdownDonutSlice.reducer
