// istanbul ignore file
import Language from './en-US.json'
import LanguageES from './es-ES.json'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const LanguageStrings = () => {
  const language = navigator.language
  let Strings
  if (language === 'es') {
    Strings = LanguageES
  } else {
    Strings = Language
  }
  return (Strings)
}

export default LanguageStrings
