import { type ToggleOption } from '../../../../components/GenericToggleButton'
import { type KpiKeyDonut, type KpiKey, type KpiKeyWithDynamicColumn } from '../../redux/changeKpiType'
import { type DonutKpiState } from '../../types'

export function getKpiTitle (kpiName: KpiKey): string {
  if (kpiName === 'Total Shipped') {
    return 'Shipped'
  }
  return kpiName
}

export function getDynamicColumnForDonutTable (kpiName: KpiKeyWithDynamicColumn): string {
  switch (kpiName) {
    case 'Total Reads':
      return 'Interval Length'
    case 'Extended Coverage Level':
      return 'Extended Coverage Level'
    case 'Signal Breakdown':
      return 'Signal Strength'
    case 'Alarm Breakdown':
      return 'Severity'
    case 'Firmware Breakdown':
      return 'Firmware Version'
    case 'Battery Breakdown':
      return 'Battery Percentage'
    case 'Lifecycle Breakdown':
      return 'LC Transition Date'
    case 'Last Read Alarms':
      return 'Alarm Raised At'
    default:
      return kpiName
  }
}

export function getDonutKpiService (kpiName: KpiKeyDonut): string {
  switch (kpiName) {
    case 'Total Reads':
      return 'asset-data-service'
    case 'Extended Coverage Level':
      return 'kpirest'
    case 'Signal Breakdown':
      return 'kpirest'
    case 'Alarm Breakdown':
      return 'alarm-service'
    case 'Firmware Breakdown':
      return 'assetrest'
    case 'Battery Breakdown':
      return 'kpirest'
    case 'Lifecycle Breakdown':
      return 'assetrest'
    default:
      return 'assetrest'
  }
}

export function getKpiService (kpiName: KpiKey): string {
  switch (kpiName) {
    case 'Total Registered':
      return 'assetrest'
    case 'Total Connected':
      return 'asset-data-service'
    case 'Total Shipped':
      return 'assetrest'
    case 'Total Stale':
      return 'assetrest'
    case 'Total Reads':
      return 'asset-data-service'
    case 'Extended Coverage Level':
      return 'kpirest'
    case 'Signal Breakdown':
      return 'kpirest'
    case 'Alarm Breakdown':
      return 'alarm-service'
    case 'Firmware Breakdown':
      return 'assetrest'
    case 'Last Read Alarms':
      return 'alarm-service'
    case 'Battery Breakdown':
      return 'kpirest'
    case 'Lifecycle Breakdown':
      return 'assetrest'
    default:
      return 'assetrest'
  }
}

export function getDetailKeyword (kpiName: KpiKey, barItem: string): string[] {
  if (kpiName === 'Total Registered') {
    if (barItem === 'Shipped') {
      return ['total-registered-shipped-details', 'assetrest']
    }
    return ['total-registered-communicated-details', 'assetrest']
  } else if (kpiName === 'Total Connected') {
    if (barItem === 'Shipped') {
      return ['total-registered-communicated-details', 'assetrest']
    }
    return ['total-connected-details', 'asset-data-service']
  } else if (kpiName === 'Total Shipped') {
    return ['total-shipped-details', 'assetrest']
  } else if (kpiName === 'Total Stale') {
    return ['total-stale-details', 'assetrest']
  } else if (kpiName === 'Total Reads') {
    return ['total-reads-details-in-range', 'asset-data-service']
  } else if (kpiName === 'Extended Coverage Level') {
    return ['ec-mode-details', 'kpirest']
  } else if (kpiName === 'Signal Breakdown') {
    return ['signal-strength-details', 'kpirest']
  } else if (kpiName === 'Alarm Breakdown') {
    return ['alarms-breakdown-details', 'alarm-service']
  } else if (kpiName === 'Firmware Breakdown') {
    return ['firmware-details', 'assetrest']
  } else if (kpiName === 'Last Read Alarms') {
    return ['last-reads-alarms-details', 'alarm-service']
  } else if (kpiName === 'Battery Breakdown') {
    return ['battery-percent-details', 'kpirest']
  } else if (kpiName === 'Lifecycle Breakdown') {
    return ['asset-details-with-state', 'assetrest']
  }
  return ['', '']
}

export function getToggleOptions (kpiName: KpiKey): ToggleOption[] {
  if (kpiName === 'Total Registered') {
    return [
      { label: 'Total Shipped', value: 'Shipped' },
      { label: 'Communicated Once', value: 'Active' }
    ]
  } else if (kpiName === 'Total Connected') {
    return [
      { label: 'Total Communicated Once', value: 'Shipped' },
      { label: 'Communicated in last 7 days', value: 'Active' }
    ]
  } else if (kpiName === 'Total Shipped') {
    return [
      { label: 'Total Shipped', value: 'Shipped' }
    ]
  } else if (kpiName === 'Total Stale') {
    return [
      { label: 'Total Stale', value: 'Shipped' }
    ]
  } else if (kpiName === 'Last Read Alarms') {
    return [
      { label: 'Last Read Alarms', value: 'Last Read Alarms' }
    ]
  }
  return [{ label: 'Total Shipped', value: 'Shipped' }]
}

export const KPI_NAME_ID_MAPPING: Record<string, KpiKey> = {
  '54c0f6dd-3460-433c-b808-cad310fce27c': 'Data Completeness',
  'd80ed664-1b24-4d58-a445-ebc2b1361847': '30 Days Trend',
  'b5820516-7388-4511-950f-10a9271aa44e': 'Total Shipped',
  '8dfc4b26-43e3-4660-bd5e-bc8b13d8c82b': 'Total Registered',
  'f7b2fe73-83ad-4710-9232-fcad8e644746': 'Total Connected',
  '250d8072-0aff-4f62-93c0-d4696e14285b': 'Total Stale',
  '1b40a027-676e-47fe-ab41-2fd31910ab0b': 'Total Reads',
  '7f354fc3-c943-47a5-831f-d26f55517bd0': 'Extended Coverage Level',
  'f08b9d34-afa3-428f-aeac-c2ff563c8cfa': 'Signal Breakdown',
  '8be54d76-2128-4257-a54f-1fb5ff1473a8': 'Alarm Breakdown',
  '5049cdfb-7943-44ba-a600-34cdcde4b6ef': 'Firmware Breakdown',
  '9327b025-d6da-42a0-83da-af2856b48ab1': 'Last Read Alarms',
  'b9a0aa20-0d73-4319-acb5-2245a7ae76f2': 'Battery Breakdown',
  '013dc232-f475-4869-bf30-eeba83b40bd8': 'Lifecycle Breakdown'
}

export const KPI_NAME_ID_MAPPING_REVERSE: Record<KpiKey, string> = {
  'Data Completeness': '54c0f6dd-3460-433c-b808-cad310fce27c',
  '30 Days Trend': 'd80ed664-1b24-4d58-a445-ebc2b1361847',
  'Total Shipped': 'b5820516-7388-4511-950f-10a9271aa44e',
  'Total Registered': '8dfc4b26-43e3-4660-bd5e-bc8b13d8c82b',
  'Total Connected': 'f7b2fe73-83ad-4710-9232-fcad8e644746',
  'Total Stale': '250d8072-0aff-4f62-93c0-d4696e14285b',
  'Total Reads': '1b40a027-676e-47fe-ab41-2fd31910ab0b',
  'Extended Coverage Level': '7f354fc3-c943-47a5-831f-d26f55517bd0',
  'Signal Breakdown': 'f08b9d34-afa3-428f-aeac-c2ff563c8cfa',
  'Alarm Breakdown': '8be54d76-2128-4257-a54f-1fb5ff1473a8',
  'Firmware Breakdown': '5049cdfb-7943-44ba-a600-34cdcde4b6ef',
  'Last Read Alarms': '9327b025-d6da-42a0-83da-af2856b48ab1',
  'Battery Breakdown': 'b9a0aa20-0d73-4319-acb5-2245a7ae76f2',
  'Lifecycle Breakdown': '013dc232-f475-4869-bf30-eeba83b40bd8'
}

export const getKpiNameFromId = (id: string): KpiKey | undefined => {
  // check if given id is a valid key in KPI_NAME_ID_MAPPING then return the value
  if (id in KPI_NAME_ID_MAPPING) {
    return KPI_NAME_ID_MAPPING[id]
  }
}

export const getKpiIdFromName = (name: KpiKey): string | undefined => {
  // check if given name is a valid key in KPI_NAME_ID_MAPPING_REVERSE then return the value
  if (name in KPI_NAME_ID_MAPPING_REVERSE) {
    return KPI_NAME_ID_MAPPING_REVERSE[name]
  }
}

const donutTypeMap = ['Strong', 'Median', 'Weak']
const lifecycleDonutTypeMap = ['Removed', 'Active', 'Shipped', 'Inactive']
const totalReadsDonutTypeMap = [15, 30, 60]
const alarmDonutTypeMap = ['critical', 'High', 'Medium', 'Low']

export const getDonutType = (kpiType: KpiKeyDonut, index: number, state: DonutKpiState): string => {
  if (kpiType === 'Extended Coverage Level') {
    return (index).toString()
  } else if (kpiType === 'Signal Breakdown') {
    return donutTypeMap[index]
  } else if (kpiType === 'Total Reads') {
    return totalReadsDonutTypeMap[index].toString()
  } else if (kpiType === 'Alarm Breakdown') {
    return state.data !== undefined ? state.data.liveData[0].dataPoint[index].name.toLocaleLowerCase() : alarmDonutTypeMap[index]
  } else if (kpiType === 'Firmware Breakdown') {
    return state.data !== undefined ? state.data.liveData[0].dataPoint[index].name : (index + 1).toString()
  } else if (kpiType === 'Battery Breakdown') {
    return donutTypeMap[index]
  } else if (kpiType === 'Lifecycle Breakdown') {
    return state.data !== undefined ? state.data.liveData[0].dataPoint[index].name : lifecycleDonutTypeMap[index]
  } else {
    return 'key-not-present'
  }
}
