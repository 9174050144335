import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
export interface EditReportSchedulerTemplateState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
}

const initialState: EditReportSchedulerTemplateState = {
  isLoading: false,
  error: undefined,
  httpStatus: undefined
}

const createReportSchedulrTemplateSlice = createSlice({
  name: 'ReportSchedulerTemplate-Edit',
  initialState,
  reducers: {
    editReportSchedulerTemplateLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0
      }
    },
    editReportSchedulerTemplateUpdateAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload
      }
    },
    editReportSchedulerTemplateErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  editReportSchedulerTemplateLoadingAction,
  editReportSchedulerTemplateUpdateAction,
  editReportSchedulerTemplateErrorAction
} = createReportSchedulrTemplateSlice.actions

export default createReportSchedulrTemplateSlice.reducer
