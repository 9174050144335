import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface exportFirmwareCampaignDetailsListState {
  isLoading: boolean
  loadingError: string | null
  FirmwareCampaignDetailsList: any
}

// Initial state of the slice
const initialState: exportFirmwareCampaignDetailsListState = {
  isLoading: true,
  loadingError: null,
  FirmwareCampaignDetailsList: null
}

// Slice to return from action
export const exportFirmwareCampaignDetailsListSlice = createSlice({
  name: 'exportFirmwareCampaignDetailsList',
  initialState,
  reducers: {
    exportFirmwareCampaignDetailsLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        FirmwareCampaignDetailsList: []
      }
    },
    exportFirmwareCampaignDetailsUpdateAction: (state, action: PayloadAction< any >) => {
      return {
        isLoading: false,
        loadingError: null,
        FirmwareCampaignDetailsList: action.payload
      }
    },
    exportFirmwareCampaignDetailsErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        FirmwareCampaignDetailsList: null
      }
    }
  }
})

// Actions to export
export const {
  exportFirmwareCampaignDetailsLoadingAction,
  exportFirmwareCampaignDetailsUpdateAction,
  exportFirmwareCampaignDetailsErrorAction
} = exportFirmwareCampaignDetailsListSlice.actions

export default exportFirmwareCampaignDetailsListSlice.reducer
