import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZGroupWithCountData = z.object({
  assetGroupId: z.string(),
  groupName: z.string(),
  assetCount: z.number(),
  assetCountDisplayName: z.string(),
  groupMapped: z.boolean()
})

const ZGroupWithCountApiResponse = z.object({
  http_status: z.number(),
  data: z.array(ZGroupWithCountData).optional(),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

const ZGroupWithCountErrorApiResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  error_message: z.optional(z.string())
})

export type GroupWithCountData = z.infer<typeof ZGroupWithCountData>
export type GroupWithCountApiResponse = z.infer<typeof ZGroupWithCountApiResponse>
export type GroupWithCountErrorApiResponse = z.infer<typeof ZGroupWithCountErrorApiResponse>

export async function getAllGroupsWithCount (
  assetType: string,
  groupName: string,
  tenantId: string,
  onSuccess: (groupCountDataList: GroupWithCountData[], httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  let url = ''
  url = `/assetrest/api/v1/udp/groupManagement/asset-group/assetCount?assetType=${assetType}&tenantId=${tenantId}`
  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZGroupWithCountApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, response.status)
          } else {
            onFailure('Error occurred while processing response', response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        const parseResponse = ZGroupWithCountErrorApiResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
