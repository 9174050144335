import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ApiResponse } from '../services/updateAssetGroupService'

export interface UpdationState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null | undefined
}

export const initialState: UpdationState = {
  isLoading: false,
  error: null,
  httpStatus: null
}

const updateAssetGroupSlice = createSlice({
  name: 'update-asset-group',
  initialState,
  reducers: {
    updateAssetGroupLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0
      }
    },
    updateAssetGroupUpdateAction: (state, action: PayloadAction<{ data: ApiResponse }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.data.http_status
      }
    },
    updateAssetGroupErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  updateAssetGroupLoadingAction,
  updateAssetGroupUpdateAction,
  updateAssetGroupErrorAction
} = updateAssetGroupSlice.actions

export default updateAssetGroupSlice.reducer
