/* eslint-disable */
import * as React from 'react'
import { alpha } from '@mui/material/styles'
import InputLabel from '@mui/material/InputLabel'
import './assetHistory.css'
import Button from '@mui/material/Button'
import { CircularProgress } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import { useAuth } from 'react-oidc-context'
import FilterIcon from '../../../assets/filterIcon.png'
import Autocomplete from '@mui/material/Autocomplete'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import DoNotDisturb from '@mui/icons-material/DoNotDisturb'
import Search from '@mui/icons-material/Search'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import ReplayIcon from '@mui/icons-material/Replay'

import Drawer from '@mui/material/Drawer'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { visuallyHidden } from '@mui/utils'
import Pagination from '../../../components/TablePagination'
import { connect, type ConnectedProps } from 'react-redux'
import { type RootState } from '../../../store'
import { type deviceProvisioningDataState } from '../redux/ProvisioningHistorySummaryDetailsInfoSlice'
import {
  getAssetProvisioningHistoryDetails,
  getAssetProvisioningDetailsFilterDeviceType,
  getAssetProvisioningDetailsFilterState,
  getProvisioningHistoryFilterStatus,
  getAssetProvisioningHistoryDetailsByIdFilteredData,
} from '../redux/actionCreators'
import { useSelector } from 'react-redux'
import LanguageStrings from '../../../i18n/locales'
import UploadFile from '../../upload-file/components/uploadFile'
import { useNavigate } from 'react-router-dom'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import AuthError from '../../../components/ErrorComponents/AuthError'
import successBadge from '../../../assets/successBadge.svg'
import progressBadge from '../../../assets/progressBadge.svg'
import errorBadge from '../../../assets/errorBadge.svg'
import notApplicableBadge from '../../../assets/notApplicableBadge.svg'

const provisioningDetailStrings =
  LanguageStrings().ProvisioningDetailStrings.ProvisioningDetailTable
let total_Asset_count: number = 0
let searchValue: any

interface Data {
  provisioningDetailsId: string
  assetId: string
  name: string
  serialNumber: string
  configuration: string
  state: string
  assetGroup: string
  provisioningDetailsStatus: string
  provisioningErrorDescription: string
  deviceType: string
  provisioningHistoryId: string
}

const style = {
  bgcolor: '#313030',
  borderRadius: '10px',
  padding: 0,
}

interface HeadCell {
  disablePadding: boolean
  id: keyof Data
  label: string
  numeric: boolean
}

type Order = 'asc' | 'desc'

const headCells: readonly HeadCell[] = [
  {
    id: 'assetId',
    numeric: false,
    disablePadding: true,
    label: provisioningDetailStrings.deviceId,
  },
  {
    id: 'serialNumber',
    numeric: false,
    disablePadding: false,
    label: provisioningDetailStrings.serialNumber,
  },
  {
    id: 'deviceType',
    numeric: false,
    disablePadding: false,
    label: provisioningDetailStrings.deviceType,
  },
  {
    id: 'provisioningDetailsStatus',
    numeric: false,
    disablePadding: false,
    label: provisioningDetailStrings.provisioningDetailsStatus,
  },
  {
    id: 'provisioningErrorDescription',
    numeric: false,
    disablePadding: false,
    label: provisioningDetailStrings.provisioningErrorDescription,
  }
]

const DEFAULT_ORDER = 'asc'
const DEFAULT_ORDER_BY = 'deviceId'
const DEFAULT_ROWS_PER_PAGE = 5

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => void
  order: Order
  orderBy: string
  rowCount: number
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, rowCount } = props
  const createSortHandler =
    (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy)
    }
  return (
    <TableHead data-testid="device-table-header">
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              aria-label={headCell.label}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

interface EnhancedTableToolbarProps {
  checkName: string[]
  setCheckName: (val: any) => any
  rows: Data[] | []
  inputSearchFunction: any
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web',
  },
}))
function EnhancedTableToolbar(props: any) {
  const {
    checkName,
    setCheckName,
    clearAllClicked,
    setClearAllClicked,
    rows,
    filterParams,
    filterCalled,
    status,
    statusState,
    setStatusState,
    assetType,
    assetTypeState,
    setAssetTypeState,
    detailState,
    state,
    setState,
    filterData,
    clearAllFilters,
  } = props
  const [value, setValue] = React.useState('')
  const [searched, setSearched] = React.useState('')
  React.useEffect(() => {
    searchValue = searched
  }, [searched])

  const onchangehandleSearch = (e: any) => {
    setValue(e.target.value)
    localStorage.setItem('Search', e.target.value)
  }

  const handleCloseSearch = () => {
    localStorage.removeItem('Search')
    props.inputSearchFunction('')
    setValue('')
  }

  React.useEffect(() => {
    const value = localStorage.getItem('Search')
    if (value) {
      setValue(value)
    }
  }, [])

  const [openFilter, setOpenFilter] = React.useState(false)
  const handleStatusChange = (e: any) => {
    setStatusState({ ...statusState, [e.target.name]: e.target.checked })
  }
  const handleAssetTypeChange = (e: any) => {
    setAssetTypeState({ ...assetTypeState, [e.target.name]: e.target.checked })
  }
  const handleStateChange = (e: any) => {
    setState({ ...state, [e.target.name]: e.target.checked })
  }
  const populateValues = () => {
    const tmp: any = {}
    const tmp1: any = {}
    const tmp2: any = {}

    for (let i = 0; i < status.length; i++) {
      if (
        filterParams.filter(
          (par: any) => par.field === 'provisioningDetailsStatus'
        ).length &&
        filterParams
          .filter((par: any) => par.field === 'provisioningDetailsStatus')[0]
          .values.includes(status[i])
      ) {
        tmp[status[i]] = true
      } else {
        tmp[status[i]] = false
      }
    }
    for (let i = 0; i < assetType.length; i++) {
      if (
        filterParams.filter((par: any) => par.field === 'deviceType').length &&
        filterParams
          .filter((par: any) => par.field === 'deviceType')[0]
          .values.includes(assetType[i])
      ) {
        tmp1[assetType[i]] = true
      } else {
        tmp1[assetType[i]] = false
      }
    }
    for (let i = 0; i < detailState.length; i++) {
      if (
        filterParams.filter((par: any) => par.field === 'state').length &&
        filterParams
          .filter((par: any) => par.field === 'state')[0]
          .values.includes(detailState[i])
      ) {
        tmp2[detailState[i]] = true
      } else {
        tmp2[detailState[i]] = false
      }
    }
    setStatusState(tmp)
    setAssetTypeState(tmp1)
    setState(tmp2)
  }
  return (
    <Toolbar
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      }}
    >
      <div style={{ width: '100%' }}>
        <Typography
          sx={{
            flex: '1 1 100%',
            fontFamily: 'Honeywell Sans Web',
            fontSize: '16px',
            fontWeight: 600,
            padding: '12px 24px',
            borderBottom: '2px solid #202020',
            borderRadius: '4px',
          }}
          id="tableTitle"
          component="div"
        >
          <span
            data-testid="provision-History-Details"
            style={{
              fontSize: '16px',
              color: '#f0f0f0',
              borderRight: '2px solid #e0e0e0',
              paddingRight: '16px',
              marginRight: '10px',
            }}
          >
            {provisioningDetailStrings.tableTitle}
          </span>
          <span style={{ fontSize: '16px', color: '#d0d0d0' }}>
            {total_Asset_count} {provisioningDetailStrings.assetCount}
          </span>
        </Typography>
        <div className="searchSection" style={{ width: '100%' }}>
          <Paper
            className="searchBoxComponent"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'left',
              width: 375,
            }}
          >
            {!assetType.length && !status.length && !detailState.length ? (
              <Tooltip title={provisioningDetailStrings.noDataFilter}>
                <Button
                  data-testid="filter-data"
                  className="filter-btn"
                  sx={{ background: '#707070', cursor: 'not-allowed' }}
                >
                  <img
                    src={FilterIcon}
                    height={16}
                    width={16}
                    style={{ marginLeft: '7px' }}
                  />
                  {provisioningDetailStrings.filterTitle}
                </Button>
              </Tooltip>
            ) : (
              <Button
                data-testid="filter-data"
                className="filter-btn"
                sx={{ background: '#707070', color:'#D0D0D0' }}
                onClick={() => {
                  setOpenFilter(true)
                  populateValues()
                }}
              >
                <img
                  src={FilterIcon}
                  height={16}
                  width={16}
                  style={{ marginLeft: '7px' }}
                />
                {provisioningDetailStrings.filterTitle}
              </Button>
            )}

            <InputBase
              className="searchBox"
              sx={{ ml: 1, p: 1 }}
              placeholder="Search the (placeholder)"
              inputProps={{ 'aria-label': 'searchInput' }}
              value={value}
              onChange={onchangehandleSearch}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSearched(value), props.inputSearchFunction(value)
                }
              }}
            />
            {value != '' ? (
              <CloseIcon
                onClick={handleCloseSearch}
                className="search-close"
                aria-label="close-drawer"
              />
            ) : (
              ''
            )}
            <IconButton
              onClick={() => {
                setSearched(value), props.inputSearchFunction(value)
              }}
              className="searchBtn"
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <Drawer
            anchor="right"
            open={openFilter}
            className="filter-drawer"
            onClose={() => {
              setOpenFilter(false)
              if (filterCalled !== 'end') {
                clearAllFilters()
              }
            }}
          >
            <div className="filter-headar">
              <div className="title">Filter</div>
              <div>
                <Button
                  aria-label="clear-all-filter"
                  className="cancel"
                  style={{ marginTop: '-2px', marginLeft: '80px' }}
                  onClick={() => {
                    clearAllFilters()
                    setClearAllClicked(true)
                  }}
                >
                  <ReplayIcon
                    sx={{ width: '16px', height: '16px', marginRight: '8px' }}
                  />
                  Clear All
                </Button>
              </div>
              <div>
                <CloseIcon
                  aria-label="close-drawer"
                  sx={{ color: '#f0f0f0' }}
                  onClick={() => setOpenFilter(false)}
                />
              </div>
            </div>

            {status && status.length !== 0 && (
              <>
                <div className="type-name status">Status</div>
                <FormGroup>
                  {status.map((val: string) => (
                    <FormControlLabel
                      aria-label="status-change"
                      name={val}
                      onChange={(e) => {
                        handleStatusChange(e)
                      }}
                      checked={statusState[val]}
                      control={<Checkbox inputProps={{ id: 'select-item' }} />}
                      label={val}
                    />
                  ))}
                </FormGroup>
              </>
            )}
            <div className="divider" />
            {assetType && assetType.length !== 0 && (
              <>
                <div className="type-name">Asset Type</div>
                <FormGroup>
                  {assetType.map((val: string) => (
                    <FormControlLabel
                      aria-label="asset-type-change"
                      name={val}
                      onChange={(e) => {
                        handleAssetTypeChange(e)
                      }}
                      checked={assetTypeState[val]}
                      control={<Checkbox inputProps={{ id: 'select-item' }} />}
                      label={val}
                    />
                  ))}
                </FormGroup>
                <div className="divider" />
              </>
            )}
            {detailState && detailState.length !== 0 && (
              <>
                <div className="type-name">State</div>
                <FormGroup>
                  {detailState.map((val: string) => (
                    <FormControlLabel
                      aria-label="state-change"
                      name={val}
                      onChange={(e) => {
                        handleStateChange(e)
                      }}
                      checked={state[val]}
                      control={<Checkbox inputProps={{ id: 'select-item' }} />}
                      label={val}
                    />
                  ))}
                </FormGroup>
                <div className="divider" />
              </>
            )}

            <div className="buttons-container">
              <Button
                className="cancel"
                aria-label="cancel-drawer"
                onClick={() => {
                  setOpenFilter(false)
                  if (filterCalled !== 'end') {
                    clearAllFilters()
                  }
                }}
              >
                {provisioningDetailStrings.cancelButton}
              </Button>
              <Button
                className="confirm"
                aria-label="confirm-filter"
                onClick={() => {
                  filterData()
                  setOpenFilter(false)
                }}
              >
                {provisioningDetailStrings.showResultButton}
              </Button>
            </div>
          </Drawer>
        </div>
      </div>
    </Toolbar>
  )
}

function DeviceData(props: any) {
  const navigate = useNavigate()
  const rowsPerPage = 10
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({
    order: 'asc',
    orderBy: 'assetId',
  })
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [showUploadFileModal, setShowUploadModal] =
    React.useState<boolean>(false)
  const count = (total_Asset_count = useSelector(
    (state: any) => state.deviceData.totalCount
  ))
  const rows = useSelector(
    (state: any) => state.deviceData.deviceProvisioningData
  )
  const httpStatus = useSelector((state: any) => state.deviceData.httpStatus)
  const provisioningDetailsId = props.fileId
  const [checkName, setCheckName] = React.useState<string[]>([])
  const [statusState, setStatusState] = React.useState<any>({})
  const [assetTypeState, setAssetTypeState] = React.useState<any>({})
  const [state, setState] = React.useState<any>({})

  const [filterCalled, setFilterCalled] = React.useState<string>('')

  const status = useSelector(
    (state: any) =>
      state.provisioningHistoryFilterStatus.provisioningHistoryFilterStatus
  )
  const detailState = useSelector(
    (state: any) =>
      state.provisioningHistoryDetailsStateFilter
        .provisioningHistoryDetailsStateFilter
  )
  const assetType = useSelector(
    (state: any) =>
      state.provisioningHistoryDetailsFilterDeviceType
        .provisioningHistoryDetailsFilterDeviceType
  )

  let search: any = ''
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& th': {
      border: '1px solid #202020',
    },
  }))
  React.useEffect(() => {
    resetStatus()
  }, [status])
  React.useEffect(() => {
    resetAssetType()
  }, [assetType])
  React.useEffect(() => {
    resetState()
  }, [detailState])
  const resetStatus = () => {
    const tmp: any = {}
    for (const item of status) {
      tmp[item] = false
    }
    setStatusState(tmp)
  }
  const resetAssetType = () => {
    const tmp: any = {}
    for (const item of assetType) {
      tmp[item] = false
    }
    setAssetTypeState(tmp)
  }
  const resetState = () => {
    const tmp: any = {}
    for (const item of detailState) {
      tmp[item] = false
    }
    setState(tmp)
  }
  const clearAllFilters = () => {
    resetAssetType()
    resetState()
    resetStatus()
  }

  const [filterParams, setFilterParams] = React.useState<any>([])
  const [selectedStatus, setSelectedStatus] = React.useState<any>([])
  const [selectedAssetType, setSelectedAssetType] = React.useState<any>([])
  const [selectedState, setSelectedState] = React.useState<any>([])
  const [clearAllClicked, setClearAllClicked] = React.useState(false)

  const auth = useAuth()
  React.useEffect(() => {
    const extractedTrueValues = Object.keys(statusState).filter(
      (key) => statusState[key] === true
    )
    setSelectedStatus(extractedTrueValues)
  }, [statusState])
  React.useEffect(() => {
    const extractedTrueValues = Object.keys(assetTypeState).filter(
      (key) => assetTypeState[key] === true
    )
    setSelectedAssetType(extractedTrueValues)
  }, [assetTypeState])
  React.useEffect(() => {
    const extractedTrueValues = Object.keys(state).filter(
      (key) => state[key] === true
    )
    setSelectedState(extractedTrueValues)
  }, [state])
  React.useEffect(() => {
    if (filterCalled === 'start') {
      const arr = []
      if (selectedStatus.length) {
        arr.push({
          field: 'provisioningDetailsStatus',
          operator: 'IN',
          values: selectedStatus,
        })
      }

      if (selectedAssetType.length) {
        arr.push({
          field: 'deviceType',
          operator: 'IN',
          values: selectedAssetType,
        })
      }
      if (selectedState.length) {
        arr.push({
          field: 'state',
          operator: 'IN',
          values: selectedState,
        })
      }
      if (!arr.length && clearAllClicked) {
        props.getAssetProvisioningHistoryDetails(
          page,
          rowsPerPage,
          sort.order,
          sort.orderBy,
          provisioningDetailsId,
          search,
          auth.user?.profile.customer as string
        )
      }
      setFilterParams(arr)
    }
  }, [filterCalled])

  const callSearchValue = (e: any) => {
    search = e
    setPage(1)
    props.getAssetProvisioningHistoryDetails(
      1,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      provisioningDetailsId,
      search,
      auth.user?.profile.customer as string
    )
  }

  // istanbul ignore next
  const getCompletionStatusBadge = (status: string): JSX.Element => {
    switch (status) {
      case 'SUCCESS':
        return <img data-testid="successBadge" src={successBadge} />
      case 'PROGRESS':
        return <img data-testid="progressBadge" src={progressBadge} />
      case 'ERROR':
        return <img data-testid="errorBadge" src={errorBadge} />
      default:
        return <img data-testid="notApplicableBadge" src={notApplicableBadge} />
    }
  }

  React.useEffect(() => {
    if (filterParams.length !== 0) {
      props.getAssetProvisioningHistoryDetailsByIdFilteredData(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        provisioningDetailsId,
        filterParams,
        auth.user?.profile.customer as string
      )
    } else {
      props.getAssetProvisioningHistoryDetails(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        provisioningDetailsId,
        search != ''
          ? search
          : localStorage.getItem('Search') == null
            ? search
            : localStorage.getItem('Search'),
        auth.user?.profile.customer as string
      )
    }
  }, [page, sort])

  const handleRetry = () => {
    props.getAssetProvisioningHistoryDetailsByIdFilteredData(
      page,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      provisioningDetailsId,
      filterParams
    )
  }

  const clickCancleBtn = () => {
    props.handleClose()
    navigate('/device-history')
    localStorage.removeItem('Search')
  }

  React.useEffect(() => {
    props.getAssetProvisioningDetailsFilterDeviceType(
      auth.user?.profile.customer as string
    )
    props.getAssetProvisioningDetailsFilterState(
      auth.user?.profile.customer as string
    )
    props.getProvisioningHistoryFilterStatus(
      auth.user?.profile.customer as string
    )
  }, [])

  const filterData = () => {
    setFilterCalled('start')
  }

  React.useEffect(() => {
    setPage(1)
    if (filterParams.length !== 0) {
      props.getAssetProvisioningHistoryDetailsByIdFilteredData(
        1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        provisioningDetailsId,
        filterParams,
        auth.user?.profile.customer as string
      )
      setFilterCalled('end')
    } else {
      if (filterCalled === 'start') {
        props.getAssetProvisioningHistoryDetails(
          1,
          rowsPerPage,
          sort.order,
          sort.orderBy,
          provisioningDetailsId,
          search != ''
            ? search
            : localStorage.getItem('Search') == null
              ? search
              : localStorage.getItem('Search'),
          auth.user?.profile.customer as string
        )
        setFilterCalled('end')
      }
    }
  }, [filterParams])
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => {
    const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
    const toggledOrder = isAsc ? 'desc' : 'asc'
    setSort({ order: toggledOrder, orderBy: newOrderBy })
  }

  const getPagination = (): JSX.Element => {
    if (count > rowsPerPage) {
      return (
        <Pagination
          count={count}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          siblingCount={1}
          boundaryCount={1}
        />
      )
    } else {
      return <></>
    }
  }

  interface Sort {
    order: Order
    orderBy: string
  }
  if (rows !== null && rows !== undefined && httpStatus === 200) {
    return (
      <>
        <Box sx={style} className="devices">
          <Paper
            sx={{ width: '100%', backgroundColor: '#272727', color: '#F0F0F0' }}
          >
            <EnhancedTableToolbar
              checkName={checkName}
              setCheckName={setCheckName}
              rows={rows}
              inputSearchFunction={callSearchValue}
              clearAllClicked={clearAllClicked}
              setClearAllClicked={setClearAllClicked}
              filterParams={filterParams}
              filterCalled={filterCalled}
              status={status}
              statusState={statusState}
              setStatusState={setStatusState}
              assetType={assetType}
              assetTypeState={assetTypeState}
              setAssetTypeState={setAssetTypeState}
              detailState={detailState}
              state={state}
              setState={setState}
              filterData={filterData}
              clearAllFilters={clearAllFilters}
            />
            {filterParams.length !== 0 && (
              <div
                style={{
                  padding: '12px 24px',
                  borderTop: '2px solid #202020',
                  color: '#d0d0d0',
                }}
              >
                {filterParams.length === 0 ? (
                  'No filters applied'
                ) : (
                  <div className="classNAme">
                    {count} Results | Filters :
                    <>
                      {filterParams.filter(
                        (par: any) => par.field === 'provisioningDetailsStatus'
                      ).length !== 0 &&
                        filterParams.filter(
                          (par: any) =>
                            par.field === 'provisioningDetailsStatus'
                        )[0].values.length !== 0 && (
                          <div className="box">
                            {' '}
                            Status:{' '}
                            {filterParams.filter(
                              (par: any) =>
                                par.field === 'provisioningDetailsStatus'
                            )[0].values.length + ' selected'}
                          </div>
                        )}
                      {filterParams.filter(
                        (par: any) => par.field === 'deviceType'
                      ).length !== 0 &&
                        filterParams.filter(
                          (par: any) => par.field === 'deviceType'
                        )[0].values.length !== 0 && (
                          <div className="box">
                            {' '}
                            AssetType:{' '}
                            {filterParams.filter(
                              (par: any) => par.field === 'deviceType'
                            )[0].values.length + ' selected'}
                          </div>
                        )}
                      {filterParams.filter((par: any) => par.field === 'state')
                        .length !== 0 &&
                        filterParams.filter(
                          (par: any) => par.field === 'state'
                        )[0].values.length !== 0 && (
                          <div className="box">
                            {' '}
                            State:{' '}
                            {filterParams.filter(
                              (par: any) => par.field === 'state'
                            )[0].values.length + ' selected'}
                          </div>
                        )}
                    </>
                    <em
                      onClick={() => {
                        clearAllFilters()
                        setFilterParams([])
                        props.getAssetProvisioningHistoryDetails(
                          page,
                          rowsPerPage,
                          sort.order,
                          sort.orderBy,
                          provisioningDetailsId,
                          search,
                          auth.user?.profile.customer as string
                        )
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      Clear Filters{' '}
                    </em>
                  </div>
                )}
              </div>
            )}
            {rows.length !== 0 ? (
              <>
                <TableContainer className="device-table">
                  <Table
                    sx={{ minWidth: 750, color: '#f0f0f0' }}
                    aria-labelledby="tableTitle"
                    size={'small'}
                  >
                    <EnhancedTableHead
                      order={sort.order}
                      orderBy={sort.orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={10}
                    />
                    <TableBody>
                      {rows &&
                        rows.map((row: any, index: any) => {
                          const labelId = `enhanced-table-checkbox-${index}`
                          return (
                            <StyledTableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.provisioningDetailsId}
                              aria-label="devices-list-row"
                              sx={{ cursor: 'pointer' }}
                            >
                              <StyledTableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.assetId}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.serialNumber}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.deviceType}
                              </StyledTableCell>
                              <StyledTableCell>
                                <div style={{ height:'20px' }}>
                                  {getCompletionStatusBadge(row.provisioningDetailsStatus)}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.provisioningErrorDescription}
                              </StyledTableCell>
                            </StyledTableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {getPagination()}
              </>
            ) : (
              <div className="authError">
                <AuthError errorMessage="NoDataPresent" />
              </div>
            )}
          </Paper>
          <div className="button-container details-data">
            <button
              aria-label="retry-devices"
              onClick={clickCancleBtn}
            >
              {provisioningDetailStrings.cancelButton}
            </button>
            {rows?.length !=
              rows?.filter((a: any) => a.provisioningDetailsStatus == 'SUCCESS')
                .length ? (
              <button
                aria-label="upload-popup"
                onClick={() => {
                  setShowUploadModal(true)
                }}
              >
                {provisioningDetailStrings.retryButton}
              </button>
            ) : (
              ''
            )}
          </div>
        </Box>
        {showUploadFileModal && (
          <UploadFile
            open={showUploadFileModal}
            handleClose={() => setShowUploadModal(false)}
          />
        )}
      </>
    )
  } else if (props.deviceProvisioningData.isLoading) {
    return (
      <div className="CircularprogressAmi">
        <CircularProgress />
      </div>
    )
  } else if (httpStatus === 401) {
    return (
      <div className="authError">
        <AuthError errorMessage="Unauthorized" />
      </div>
    )
  } else if (httpStatus === 403) {
    return (
      <div className="authError">
        <AuthError errorMessage="accessForbidden" />
      </div>
    )
  } else {
    return (
      <div className="authError">
        <AuthError errorMessage="cannotFetch" retry={handleRetry} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  getAssetProvisioningHistoryDetails: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    provisioningDetailsId: number,
    search: any,
    tenantId: string
  ) =>
    dispatch(
      getAssetProvisioningHistoryDetails(
        page,
        size,
        sortdir,
        sortfield,
        provisioningDetailsId,
        search,
        tenantId
      )
    ),
  getAssetProvisioningHistoryDetailsByIdFilteredData: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    provisioningDetailsId: number,
    filterParams: any,
    tenantId: string
  ) =>
    dispatch(
      getAssetProvisioningHistoryDetailsByIdFilteredData(
        page,
        size,
        sortdir,
        sortfield,
        provisioningDetailsId,
        filterParams,
        tenantId
      )
    ),
  getAssetProvisioningDetailsFilterDeviceType: (tenantId: string) =>
    dispatch(getAssetProvisioningDetailsFilterDeviceType(tenantId)),
  getAssetProvisioningDetailsFilterState: (tenantId: string) =>
    dispatch(getAssetProvisioningDetailsFilterState(tenantId)),
  getProvisioningHistoryFilterStatus: (tenantId: string) =>
    dispatch(getProvisioningHistoryFilterStatus(tenantId)),
})

interface StateToProps {
  deviceProvisioningData: deviceProvisioningDataState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  deviceProvisioningData: state.deviceData,
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(DeviceData)
