import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface MapData {
  assetId: string
  assetName: string
  readingTime: string
  alarmDefinitionId: string
  alarmName: string
  severity: string
  alarmStatus: string
  longitude: number
  latitude: number
}

export interface MapDetails {
  totalCount: number
  alarms: MapData[]
}

export interface MapDataState {
  isLoading: boolean
  error: string | null
  alarmData: MapData[] | null
  count: number
  httpStatus: number | null
}

const initialState: MapDataState = {
  isLoading: true,
  error: null,
  alarmData: null,
  count: 0,
  httpStatus: null
}

const MapDataSlice = createSlice({
  name: 'MapDataSlice',
  initialState,
  reducers: {
    MapDataLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        alarmData: null,
        count: 0,
        httpStatus: 0
      }
    },
    MapDataUpdateAction: (state, action: PayloadAction<{ data: MapData[], count: number, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        alarmData: action.payload.data,
        count: action.payload.count,
        httpStatus: action.payload.httpStatus
      }
    },
    MapDataErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        alarmData: null,
        count: 0,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  MapDataLoadingAction,
  MapDataUpdateAction,
  MapDataErrorAction
} = MapDataSlice.actions

export default MapDataSlice.reducer
