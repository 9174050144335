import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type AssetAttrParameterData } from '../services/assetParameterService'

export interface AssetAttrParameterState {
  isLoading: boolean
  error: string | undefined
  assetAttrParameter: AssetAttrParameterData | undefined
  httpStatus: number | undefined
}

export const initialState: AssetAttrParameterState = {
  isLoading: true,
  error: undefined,
  assetAttrParameter: undefined,
  httpStatus: 0
}

const assetAttrParameter = createSlice({
  name: 'assetAttrParameterSlice',
  initialState,
  reducers: {
    assetAttrParameterLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        assetAttrParameter: undefined,
        httpStatus: 0
      }
    },
    assetAttrParameterUpdateAction: (state, action: PayloadAction<{ data: AssetAttrParameterData, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        assetAttrParameter: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    assetAttrParameterErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        assetAttrParameter: undefined,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  assetAttrParameterLoadingAction,
  assetAttrParameterUpdateAction,
  assetAttrParameterErrorAction
} = assetAttrParameter.actions

export default assetAttrParameter.reducer
