import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type BarKpiDataObject } from '../../types'

export interface LastReadAlarmsState {
  isLoading: boolean
  error: boolean
  data: BarKpiDataObject | undefined
  httpStatus: number
}

const initialState: LastReadAlarmsState = {
  isLoading: false,
  error: false,
  data: undefined,
  httpStatus: 0
}

const lastReadAlarmsSlice = createSlice({
  name: 'lastReadAlarms',
  initialState,
  reducers: {
    lastReadAlarmsLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        data: undefined,
        httpStatus: 0
      }
    },
    lastReadAlarmsUpdateAction: (state, action: PayloadAction<{ data: BarKpiDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: false,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    lastReadAlarmsErrorAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: true,
        data: undefined,
        httpStatus: action.payload
      }
    }
  }
})

export const {
  lastReadAlarmsLoadingAction,
  lastReadAlarmsUpdateAction,
  lastReadAlarmsErrorAction
} = lastReadAlarmsSlice.actions

export default lastReadAlarmsSlice.reducer
