// istanbul ignore file
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export type status = 'open' | 'close'

export const hamburgerSlice = createSlice({
  name: 'hamburgerStateSlice',
  initialState: 'close',
  reducers: {
    changeHamburgerState: (state, action: PayloadAction<status>) => {
      return action.payload
    }
  }
})

export const { changeHamburgerState } = hamburgerSlice.actions
export default hamburgerSlice.reducer
