import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface AllTags {
  name: string
}

export interface getAllTagsByIdState {
  isLoading: boolean
  tags: AllTags | null
  errorMessage: string | null
  httpStatus: number | null
}

const initialState: getAllTagsByIdState = {
  isLoading: false,
  tags: null,
  errorMessage: null,
  httpStatus: null
}

export const getAllTagsByIdSlice = createSlice({
  name: 'TagsById',
  initialState,
  reducers: {
    getAllTagsByIdDataLoading: (state): getAllTagsByIdState => {
      return {
        isLoading: true,
        tags: null,
        errorMessage: null,
        httpStatus: 0
      }
    },
    getAllTagsByIdDataError: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>): getAllTagsByIdState => {
      return {
        isLoading: false,
        tags: null,
        errorMessage: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    },
    getAllTagsByIdData: (state, action: PayloadAction<any>): getAllTagsByIdState => {
      return {
        isLoading: false,
        tags: action.payload.data,
        errorMessage: null,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const { getAllTagsByIdDataLoading, getAllTagsByIdDataError, getAllTagsByIdData } = getAllTagsByIdSlice.actions
export default getAllTagsByIdSlice.reducer
