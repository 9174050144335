import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.string()
})

export async function getPublicKey (
  onSuccess: (publickeyData: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = '/app-config-service/api/v1/udp/configure-protocol/encryption-key'
  interceptedAxios
    .get(url, { timeout: 10000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.response.statusText, error.response.status)
        }
      } else {
        onFailure('Network Failure', error.status)
      }
    })
}
