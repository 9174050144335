import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export const assetIdSlice = createSlice({
  name: 'assetIdSlice',
  initialState: '',
  reducers: {
    changeAssetId: (state, action: PayloadAction<string>) => {
      return action.payload
    }
  }
})

export const { changeAssetId } = assetIdSlice.actions
export default assetIdSlice.reducer
