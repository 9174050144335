import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
import { type ReportSchedulerData } from '../types'

const ZCreateReportSchedulerApiResponse = z.object({
  reportSchedulerId: z.string()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: ZCreateReportSchedulerApiResponse,
  error: z.boolean()
})

export type CreateReportSchedulerApiResponse = z.infer<typeof ZCreateReportSchedulerApiResponse>
export type ApiResponse = z.infer<typeof ZApiResponse>

export async function createReportScheduler (
  createReportSchedulerTemplatePostRequest: ReportSchedulerData,
  onSuccess: (httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/report-service/api/v1/udp/scheduler/createReportScheduler'

  interceptedAxios
    .post(url, createReportSchedulerTemplatePostRequest, { timeout: 15000 })
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        onSuccess(parseResponse.data.http_status)
      } else {
        onFailure('Error occurred while processing response', response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        // istanbul ignore else
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.data.http_status)
        } else {
          onFailure(error.response.statusText, error.response.status)
        }
      } else {
        onFailure('Network Failure', error.status)
      }
    })
}
