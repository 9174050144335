import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ReportGridFilterMasterData } from '../services/reportSchedulerGridFilterServices'

export interface CreateSchedulerFilterDropDownState {
  isLoading: boolean
  loadingError: string | undefined
  SchedulerFilterDropDownListData: ReportGridFilterMasterData | undefined
  httpStatus: number | undefined
}

// Initial state of the slice
const initialState: CreateSchedulerFilterDropDownState = {
  isLoading: true,
  loadingError: undefined,
  SchedulerFilterDropDownListData: undefined,
  httpStatus: undefined
}

// Slice to return from action
export const CreateSchedulerFilterState = createSlice({
  name: 'SchedulerFilterDropDownListData',
  initialState,
  reducers: {
    SchedulerFilterDropDownLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: undefined,
        SchedulerFilterDropDownListData: undefined,
        httpStatus: 0
      }
    },
    SchedulerFilterDropDownUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: undefined,
        SchedulerFilterDropDownListData: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    SchedulerFilterDropDownErrorAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        httpStatus: action.payload.httpStatus,
        SchedulerFilterDropDownListData: undefined
      }
    }
  }
})

// Actions to export
export const {
  SchedulerFilterDropDownLoadingAction,
  SchedulerFilterDropDownUpdateAction,
  SchedulerFilterDropDownErrorAction
} = CreateSchedulerFilterState.actions

export default CreateSchedulerFilterState.reducer
