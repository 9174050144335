import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface EditAssetSummaryInfo {
  isLoading: boolean
  message: string | undefined
  httpStatus: number
}

const initialState: EditAssetSummaryInfo = {
  isLoading: false,
  message: undefined,
  httpStatus: 0
}

export const EditAssetSummaryInfoSlice = createSlice({
  name: 'EditAssetSummaryInfo',
  initialState,
  reducers: {
    ResetEditAssetSummaryParamsData: () => {
      return {
        isLoading: false,
        message: undefined,
        httpStatus: 0
      }
    },
    EditAssetSummaryInfoDataLoading: (state): EditAssetSummaryInfo => {
      return {
        isLoading: true,
        message: undefined,
        httpStatus: 0
      }
    },
    EditAssetSummaryInfoDataError: (state, action: PayloadAction<{ message: string, httpStatus: number }>): EditAssetSummaryInfo => {
      return {
        isLoading: false,
        message: action.payload.message,
        httpStatus: action.payload.httpStatus
      }
    },
    EditAssetSummaryInfoData: (state, action: PayloadAction<{ message: string, httpStatus: number }>): EditAssetSummaryInfo => {
      return {
        isLoading: false,
        message: action.payload.message,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const { EditAssetSummaryInfoDataLoading, EditAssetSummaryInfoDataError, EditAssetSummaryInfoData, ResetEditAssetSummaryParamsData } = EditAssetSummaryInfoSlice.actions
export default EditAssetSummaryInfoSlice.reducer
