import { createSlice } from '@reduxjs/toolkit'
import { type PayloadAction } from '@reduxjs/toolkit'
import { type GroupsAsset } from '../types'

export interface UnassignedAssetListState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null
  unassignedAssetList: GroupsAsset | null
}

const initialState: UnassignedAssetListState = {
  isLoading: false,
  error: null,
  httpStatus: 0,
  unassignedAssetList: null
}

const UnssignedAssetListSlice = createSlice({
  name: 'UnssignedAssetListSlice',
  initialState,
  reducers: {
    unassignedAssetListLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0,
        unassignedAssetList: null
      }
    },
    unassignedAssetListUpdateAction: (state, action: PayloadAction<{ data: GroupsAsset, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.httpStatus,
        unassignedAssetList: action.payload.data
      }
    },
    unassignedAssetListErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        unassignedAssetList: null
      }
    }
  }
})

export const {
  unassignedAssetListLoadingAction,
  unassignedAssetListUpdateAction,
  unassignedAssetListErrorAction
} = UnssignedAssetListSlice.actions

export default UnssignedAssetListSlice.reducer
