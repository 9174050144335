/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import { Box, Button, Paper, Table, TableBody, TableContainer, TableHead, TableRow, styled } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import Pagination from '../../../../components/TablePagination'
import { parseDateInAOHstandard } from '../../../../customHooks/parseDate'

// Define the HeadCell interface
interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
  sortable: boolean
}

// Styled TableCell for consistent design
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web',
    lineHeight: '20px'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web',
    whiteSpace: 'nowrap'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'connectionName',
    numeric: false,
    disablePadding: false,
    label: 'Connection Name',
    sortable: true
  },
  {
    id: 'protocolTypeName',
    numeric: false,
    disablePadding: false,
    label: 'Protocol Type Name',
    sortable: true
  },
  {
    id: 'protocolName',
    numeric: false,
    disablePadding: false,
    label: 'Protocol Name',
    sortable: true
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    sortable: true
  },
  {
    id: 'modifiedAt',
    numeric: false,
    disablePadding: false,
    label: 'Modified At',
    sortable: true
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    sortable: false
  }
]

interface Data {
  modifiedAt: string | null
  connectionName: string
  description: string | null
  protocolTypeName: string
  protocolName: string
  protocolId: string
}
interface ConfigurationDetailsProps {
  style: React.CSSProperties
  configurationList: Data[]
  handleEditClick: (protocolTypeName: string, protocolId: string) => void
  count: number
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  rowsPerPage: number
  setSort: React.Dispatch<React.SetStateAction<Sort>>
  sort: Sort
}

export type Order = 'asc' | 'desc'
export interface Sort {
  order: Order
  orderBy: keyof Data
}

interface EnhancedTableHeadProps {
  order: 'asc' | 'desc'
  orderBy: keyof ConfigurationDetailsProps['configurationList'][0]
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof ConfigurationDetailsProps['configurationList'][0]
  ) => void
}

function EnhancedTableHead (props: EnhancedTableHeadProps) {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler =
    (newOrderBy: keyof ConfigurationDetailsProps['configurationList'][0]) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, newOrderBy)
      }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable
              ? (
              <TableSortLabel
                className='custom-tablesort-label'
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{ cursor: headCell.sortable ? 'pointer' : 'text', color: '#F0F0F0' }}
              >
                {headCell.label}
                {orderBy === headCell.id
                  ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                    )
                  : null}
              </TableSortLabel>
                )
              : (
                  headCell.label
                )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

function truncateValue (text: string | null): JSX.Element {
  return ((text != null) && text.length > 12
    ? <abbr style={{ textDecoration: 'none' }} title={text}>{text.slice(0, 12)}...</abbr>
    : <span>{text}</span>)
}
const ConfigurationDetails = (props: ConfigurationDetailsProps): JSX.Element => {
  const handleRequestSort =
    (event: React.MouseEvent<unknown>, newOrderBy: keyof Data) => {
      const isAsc = props.sort.orderBy === newOrderBy && props.sort.order === 'asc'
      const toggledOrder = isAsc ? 'desc' : 'asc'
      props.setSort({ order: toggledOrder, orderBy: newOrderBy })
    }

  return (
    <Box sx={{ ...props.style, overflowX: 'auto', padding: '0px' }}>
      <Paper sx={{ width: '100%', mb: 2, backgroundColor: '#272727', boxShadow: 'unset', color: '#F0F0F0' }}>
        <TableContainer>
          <Table sx={{ minWidth: 750, color: '#f0f0f0' }} aria-labelledby="tableTitle" size={'small'}>
            <EnhancedTableHead order={props.sort.order} orderBy={props.sort.orderBy} onRequestSort={handleRequestSort} />
            <TableBody>
              {props.configurationList.map((row, index) => (
                <TableRow hover key={index}>
                  <StyledTableCell>{truncateValue(row.connectionName)}</StyledTableCell>
                  <StyledTableCell>{row.protocolTypeName}</StyledTableCell>
                  <StyledTableCell>{truncateValue(row.protocolName)}</StyledTableCell>
                  <StyledTableCell>{truncateValue(row.description)}</StyledTableCell>
                  <StyledTableCell>{row.modifiedAt !== null ? parseDateInAOHstandard(row.modifiedAt) : ''}</StyledTableCell>
                  <StyledTableCell>
                    <Button
                      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
                      onClick={() => props.handleEditClick(row.protocolTypeName, row.protocolId)}
                      sx={{
                        color: '#64c3ff',
                        textTransform: 'none',
                        padding: 0,
                        minWidth: 0
                      }}
                    >
                      Edit
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {props.count > 10 && (<Pagination count={props.count} page={props.page} setPage={props.setPage} rowsPerPage={props.rowsPerPage} siblingCount={1}
                            boundaryCount={1}></Pagination>)}
      </Paper>
    </Box>
  )
}

export default ConfigurationDetails
