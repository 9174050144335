import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface IMeterImageState {
  isLoading: boolean
  error: string | undefined
  meterImageData: string | undefined
  httpStatus: number | undefined
}

export const initialState: IMeterImageState = {
  isLoading: false,
  error: undefined,
  meterImageData: undefined,
  httpStatus: 0
}

const meterImageSlice = createSlice({
  name: 'meterImageSlice',
  initialState,
  reducers: {
    meterImageLoadingAction: (state) => {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        meterImageData: undefined,
        httpStatus: 0
      }
    },
    meterImageUpdateAction: (state, action: PayloadAction<{ data: string, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        meterImageData: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    meterImageErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload.errorMessage,
        meterImageData: undefined,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

// Export actions
export const {
  meterImageLoadingAction,
  meterImageUpdateAction,
  meterImageErrorAction
} = meterImageSlice.actions

// Export reducer
export default meterImageSlice.reducer
