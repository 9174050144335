import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ConfigurationMetadata } from '../services/ConfigurationMetadataService'

export interface ConfigurationMetadataDropDownState {
  isLoading: boolean
  loadingError: string | undefined
  configurationMetadata: ConfigurationMetadata | undefined
  httpStatus: number | undefined
}

// Initial state of the slice
const initialState: ConfigurationMetadataDropDownState = {
  isLoading: true,
  loadingError: undefined,
  configurationMetadata: undefined,
  httpStatus: undefined
}

// Slice to return from action
export const ConfigurationMetadataState = createSlice({
  name: 'SchdulerListData',
  initialState,
  reducers: {
    ConfigurationMetadataLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: undefined,
        configurationMetadata: undefined,
        httpStatus: 0
      }
    },
    ConfigurationMetadataUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: undefined,
        configurationMetadata: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    ConfigurationMetadataErrorAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        httpStatus: action.payload.httpStatus,
        configurationMetadata: undefined
      }
    }
  }
})

// Actions to export
export const {
  ConfigurationMetadataLoadingAction,
  ConfigurationMetadataUpdateAction,
  ConfigurationMetadataErrorAction
} = ConfigurationMetadataState.actions

export default ConfigurationMetadataState.reducer
