/* eslint-disable */
/* istanbul ignore file */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface exportProvisioningHistoryByIdState {
  isLoading: boolean
  loadingError: string | null
  exportProvisioningHistoryById: any
}

// Initial state of the slice
const initialState: exportProvisioningHistoryByIdState = {
  isLoading: true,
  loadingError: null,
  exportProvisioningHistoryById: null
}

// Slice to return from action
export const exportProvisioningHistoryByIdSlice = createSlice({
  name: 'exportProvisioningHistoryById',
  initialState,
  reducers: {
    exportProvisioningHistoryByIdLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        exportProvisioningHistoryById: []
      }
    },
    exportProvisioningHistoryByIdUpdateAction: (state, action: PayloadAction< any >) => {
      return {
        isLoading: false,
        loadingError: null,
        exportProvisioningHistoryById: action.payload
      }
    },
    exportProvisioningHistoryByIdErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        exportProvisioningHistoryById: null
      }
    }
  }
})

// Actions to export
export const {
  exportProvisioningHistoryByIdLoadingAction,
  exportProvisioningHistoryByIdUpdateAction,
  exportProvisioningHistoryByIdErrorAction
} = exportProvisioningHistoryByIdSlice.actions

export default exportProvisioningHistoryByIdSlice.reducer
