import React from 'react'
import LanguageStrings from '../../../i18n/locales'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../../components/BackButton'
import { Breadcrumbs, Grid, Link, Stack, Typography, CircularProgress } from '@mui/material'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import DoNotDisturb from '@mui/icons-material/DoNotDisturb'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined'
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import { getFirmwareUpgradeDetails, exportFirmwareCampaignDetails } from '../redux/actionCreators'
import { type firmwareCampaignDetailsState } from '../redux/firmwareUpgradeDetailsSlice'
import { useAuth } from 'react-oidc-context'
import AuthError from '../../../components/ErrorComponents/AuthError'
import Tooltip, {
  type TooltipProps,
  tooltipClasses
} from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { parseDateInAOHstandard } from '../../../customHooks/parseDate'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  },
  [`& .${tooltipClasses.arrow}`]: {
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.87)',
    '&::before': {
      backgroundColor: theme.palette.common.white
    }
  }
}))

const FirmwareUpgradeDetailsStrings =
  LanguageStrings().FirmwareUpgradeDetailsStrings

const FirmwareUpgradeDetails = (props: PropsFromRedux): JSX.Element => {
  const rows = useSelector((state: any) => state.firmwareCampaignDetailsState.firmwareCampaignDetails)
  const navigate = useNavigate()
  const auth = useAuth()
  const [selectedCampaignId, setSelectedCampaignId] = React.useState('')
  const [selectedCampaignName, setSelectedCampaignName] = React.useState('')

  React.useEffect(() => {
    callApi()
  }, [])

  React.useEffect(() => {
    // istanbul ignore next
    if (selectedCampaignId !== '') {
      props.getFirmwareUpgradeDetails(selectedCampaignId, auth.user?.profile.customer as string)
    }
  }, [selectedCampaignId])

  // istanbul ignore next
  const callApi = (): void => {
    const id = sessionStorage.getItem('selectedCampaignId')
    const name = sessionStorage.getItem('selectedCampaignName')
    setSelectedCampaignId(id != null ? id : '')
    setSelectedCampaignName(name != null ? name : '')
  }

  // istanbul ignore next
  const retryApi = (): void => {
    props.getFirmwareUpgradeDetails(selectedCampaignId, auth.user?.profile.customer as string)
  }

  const exportData = (): void => {
    props.exportFirmwareCampaignDetails(selectedCampaignId)
  }

  const tooltipWithCount = (title: string, count: number): string => {
    return title + ' ' + count.toString()
  }

  // istanbul ignore next
  if ((rows !== null && rows !== undefined && props.firmwareCampaignDetailsState?.httpStatus === 200) || props.firmwareCampaignDetailsState.httpStatus === 404) {
    return (
      <div style={{ width: 'calc(100vw - 3.5rem)', paddingRight: '2rem' }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            color: '#f0f0f0',
            fontSize: '12px',
            marginBottom: '14px',
            fontFamily: 'Honeywell Sans Web',
            marginTop: '12px'
          }}
        >
          <Link
            aria-label="navBreadcrumb"
            className="assetmgmtli"
            underline="none"
            color="inherit"
            onClick={() => {
              navigate('/firmware-upgrade-status')
            }}
            data-testid="nav-breadcrumb-link"
          >
            {FirmwareUpgradeDetailsStrings.deviceManagement}
          </Link>

          <Typography
            color="inherit"
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
            onClick={() => {
              navigate('/firmware-upgrade-status')
            }}
            className="assetmgmtli"
            data-testid="firmware-upgrade-stat-link"
          >
            {FirmwareUpgradeDetailsStrings.firmwareUpgradeStatus}
          </Typography>
          <Typography
            color="inherit"
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
          >
            {selectedCampaignName}
          </Typography>
        </Breadcrumbs>
        <div
          className="topUtilityBar"
          style={{ marginBottom: '30px', marginRight: '0px' }}
        >
          <BackButton
            dataTestId="back-arrow"
            onclick={() => {
              navigate('/firmware-upgrade-status')
            }}
          />
          <span id="componentTitle" data-testid="firmware-upgrade-details-title">
            {selectedCampaignName}
          </span>
        </div>
        <Grid>
          <Stack>
            <h3
              style={{
                textTransform: 'uppercase',
                marginLeft: '10px',
                fontSize: '1rem'
              }}
              data-testid="box-heading"
            >
              {FirmwareUpgradeDetailsStrings.sectionTitle}
            </h3>
          </Stack>
        </Grid>
        <div
          className="edit-container"
          style={{
            backgroundColor: '#303030',
            border: '1px solid #000',
            margin: '20px 10px',
            padding: '20px',
            borderRadius: '4px'
          }}
        >

          {rows !== null &&
          <Grid container>
            <Stack sx={{ paddingBottom: '10px' }}>
              <h4 data-testid="box-heading" style={{ textTransform: 'uppercase' }} >{'Firmware upgrade Details'}</h4>
            </Stack>
            <Grid container mt={3} >
              <Grid item xs={12} sm={3}>
                <Stack className='firmware-details-heading' >{FirmwareUpgradeDetailsStrings.gridTitles.name}</Stack>
                <Stack>{rows.firmwareCampaignName}</Stack>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Stack className='firmware-details-heading' >
                  {FirmwareUpgradeDetailsStrings.gridTitles.assetType}
                </Stack>
                <Stack>{rows.assetType}</Stack>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Stack className='firmware-details-heading' >
                  {FirmwareUpgradeDetailsStrings.gridTitles.firmwareType}
                </Stack>
                <Stack>{rows.firmwareType}</Stack>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Stack className='firmware-details-heading' >
                  {FirmwareUpgradeDetailsStrings.gridTitles.firmwareFile}
                </Stack>
                <Stack>
                    {
                      // istanbul ignore next
                      rows.firmwareFile != null ? rows.firmwareFile : '--'
                    }
                </Stack>
              </Grid>
            </Grid>
            <Grid container mt={5}>
              <Grid item xs={12} sm={3}>
                <Stack className='firmware-details-heading' >
                  {FirmwareUpgradeDetailsStrings.gridTitles.scheduledFrom}
                </Stack>
                <Stack>{rows.scheduledFrome !== null
                  ? parseDateInAOHstandard(
                    rows.scheduledFrom
                  )
                  : ''}</Stack>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Stack className='firmware-details-heading' >
                  {FirmwareUpgradeDetailsStrings.gridTitles.finishedOn}
                </Stack>
                <Stack>{rows.finishedOn !== null
                  ? parseDateInAOHstandard(
                    rows.finishedOn
                  )
                  : '--'}</Stack>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Stack className='firmware-details-heading' >{FirmwareUpgradeDetailsStrings.gridTitles.status}</Stack>
                <Stack>{rows.status}</Stack>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                sx={{ rowGap: '7px', display: 'flex', flexDirection: 'column' }}
              >
                <Stack className='firmware-details-heading'>
                  {FirmwareUpgradeDetailsStrings.gridTitles.assetsUpgradeStatus}
                </Stack>
                <Grid direction="row" spacing={1} display={'flex'} rowGap={1} columnGap={1} flexWrap={'wrap'}>
                  <LightTooltip
                    title={ tooltipWithCount(FirmwareUpgradeDetailsStrings.tooltip.success, rows.assetUpgradeStatus.success) }
                    placement='top'
                    arrow
                  >
                  <div
                    className={'success-provision'}
                    style={{
                      padding: '4px 8px',
                      height: '24px',
                      borderRadius: '16px'
                    }}
                  >
                    <TaskAltIcon
                      sx={{
                        fontSize: 'medium',
                        paddingRight: '3px'
                      }}
                    />
                    <span>{rows.assetUpgradeStatus.success}</span>
                  </div>
                  </LightTooltip>
                  <LightTooltip
                    title={ tooltipWithCount(FirmwareUpgradeDetailsStrings.tooltip.failure, rows.assetUpgradeStatus.failure) }
                    placement='top'
                    arrow
                  >
                  <div
                    className={'error-provision'}
                    style={{
                      padding: '4px 8px',
                      height: '24px',
                      borderRadius: '16px',
                      display: 'flex'
                    }}
                  >
                    <DoNotDisturb
                      sx={{
                        fontSize: 'medium',
                        paddingRight: '3px'
                      }}
                    />
                    <span>{rows.assetUpgradeStatus.failure}</span>
                  </div>
                  </LightTooltip>
                  <LightTooltip
                    title={ tooltipWithCount(FirmwareUpgradeDetailsStrings.tooltip.notStarted, rows.assetUpgradeStatus.notStarted) }
                    placement='top'
                    arrow
                  >
                  <div
                    className={'idle-provision-outlined'}
                    style={{
                      padding: '4px 8px',
                      height: '24px',
                      borderRadius: '16px'
                    }}
                  >
                    <AdjustOutlinedIcon
                      sx={{
                        fontSize: 'medium',
                        paddingRight: '3px'
                      }}
                    />
                    <span>{rows.assetUpgradeStatus.notStarted}</span>
                  </div>
                  </LightTooltip>
                  <LightTooltip
                    title={ tooltipWithCount(FirmwareUpgradeDetailsStrings.tooltip.progress, rows.assetUpgradeStatus.inProgress) }
                    placement='top'
                    arrow
                  >
                  <div
                    className={'success-provision-outlined'}
                    style={{
                      padding: '4px 8px',
                      height: '24px',
                      borderRadius: '16px'
                    }}
                  >
                    <MoreHorizOutlinedIcon
                      sx={{
                        fontSize: 'medium',
                        border: 'solid 1px',
                        borderRadius: '50%',
                        marginRight: '4px',
                        width: '0.7rem',
                        height: '0.7rem'
                      }}
                    />
                    <span>{rows.assetUpgradeStatus.inProgress}</span>
                  </div>
                  </LightTooltip>
                  <LightTooltip
                    title={ tooltipWithCount(FirmwareUpgradeDetailsStrings.tooltip.notApplicable, rows.assetUpgradeStatus.notApplicable) }
                    placement='top'
                    arrow
                  >
                  <div
                    className={'idle-provision-outlined'}
                    style={{
                      padding: '4px 8px',
                      height: '24px',
                      borderRadius: '16px'
                    }}
                  >
                    <HorizontalRuleOutlinedIcon
                      sx={{
                        fontSize: 'medium',
                        border: 'solid 1px',
                        borderRadius: '50%',
                        marginRight: '4px',
                        width: '0.7rem',
                        height: '0.7rem'
                      }}
                    />
                    <span>{rows.assetUpgradeStatus.notApplicable}</span>
                  </div>
                  </LightTooltip>
                </Grid>
                <Stack sx={{ color: '#64C3FF', cursor: 'pointer' }}><span onClick={() => { exportData() }}>{FirmwareUpgradeDetailsStrings.exportData}</span></Stack>
              </Grid>
            </Grid>
          </Grid>
          }
        </div>
      </div>
    )
  } else if (props.firmwareCampaignDetailsState?.isLoading) {
    return (
    <div className='CircularprogressAmi'>
      <CircularProgress />
    </div>
    )
  } else if (props.firmwareCampaignDetailsState?.httpStatus === 401) {
    return (
    <div className='authError'><AuthError errorMessage='Unauthorized' /></div>
    )
  } else if (props.firmwareCampaignDetailsState?.httpStatus === 403) {
    return (
    <div className='authError'><AuthError errorMessage='accessForbidden' /></div>
    )
  } else {
    return (
    <div className='authError'>
      <AuthError errorMessage='cannotFetch' retry={retryApi} />
    </div>
    )
  }
}
interface DispatchToProps {
  getFirmwareUpgradeDetails: (firmwareCampaignId: string, tenantId: string) => void
  exportFirmwareCampaignDetails: (firmwareCampaignId: string) => void
}

// istanbul ignore next
const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getFirmwareUpgradeDetails: (firmwareCampaignId: string, tenantId: string) =>
    dispatch(getFirmwareUpgradeDetails(firmwareCampaignId, tenantId)),
  exportFirmwareCampaignDetails: (firmwareCampaignId: string) =>
    dispatch(exportFirmwareCampaignDetails(firmwareCampaignId))
})

interface StateToProps {
  firmwareCampaignDetailsState: firmwareCampaignDetailsState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  firmwareCampaignDetailsState: state.firmwareCampaignDetailsState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(FirmwareUpgradeDetails)
