import React from 'react'
import './GenXSelectableList.css'
import { type KpiKey } from '../features/ami-meter-data-collection/redux/changeKpiType'

interface GenXSelectableListProps {
  datalist: KpiKey[]
  selected?: KpiKey[]
  onClick?: (item: KpiKey) => void
  ulStyle?: React.CSSProperties
  liStyle?: React.CSSProperties
  searchValue?: string
}

export const GenXSelectableList = (props: GenXSelectableListProps): JSX.Element => {
  let liStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }

  // istanbul ignore next
  if (props.liStyle !== undefined) {
    liStyle = { ...liStyle, ...props.liStyle }
  }

  // istanbul ignore next
  const ulStyle: React.CSSProperties = props.ulStyle !== undefined ? props.ulStyle : {}

  return (
    <ul className='custom-selectable-list-ul' style={ulStyle}>
      {props.datalist.map((item, index) => (
        <li data-testid={item} style={liStyle} key={index}>
          <span>{item}</span>
          <span onClick={() => {
            // istanbul ignore next
            if (props.onClick !== undefined) {
              props.onClick(item)
            }
          }} className='plus-button' data-testid='plus-button' style={{ padding: '2px', fontSize: 24 }}>+</span>
        </li>
      ))}
      { props.searchValue !== undefined && props.searchValue.length > 0 && props.datalist.length === 0 && <li style={{ textAlign: 'center', color: '#888888', border: 'none' }}>NO DATA PRESENT</li>}
    </ul>
  )
}
