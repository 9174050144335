/* eslint-disable @typescript-eslint/no-unused-vars */
import BackButton from '../../components/BackButton'
import LanguageStrings from '../../i18n/locales'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { type RootState } from '../../store'
import ConfigurationTable from './components/configTable'
import { type ConnectedProps, connect, useDispatch, useSelector } from 'react-redux'
import { createNewConfig, fetchAllConfigs, fetchSingleConfig, updateConfig, deleteConfig } from './redux/actionCreators'
import { useAuth } from 'react-oidc-context'
import { CircularProgress, Grid } from '@mui/material'
import { Button } from '../../components/Button'
import { type AllConfigData, type ConfigState } from './redux/allConfigSlice'
import { type CreateConfigurationState } from './redux/addNewConfigSlice'
import { type SingleConfigState } from './redux/singleConfigSlice'
import { type UpdateConfigurationState } from './redux/updateConfigSlice'
import { type DeleteConfigurationState } from './redux/deleteConfigSlice'
import { Modal } from '@scuf/common'
import { changeConfigId } from './redux/configIdSlice'

const AmiMeterCollectionDP = (props: PropsFromRedux): JSX.Element => {
  const AMIMeterCollectionStrings = LanguageStrings().AMIDataCollectionStrings.LandingPage
  const DeviceDetailsStrings = LanguageStrings().DeviceDetailsStrings
  const dispatch = useDispatch()
  const auth = useAuth()
  const navigate = useNavigate()
  const ConfigurationListStrings = LanguageStrings().AssetConfigurationStrings.ConfigurationListString

  // const numConfigurations = useSelector((state: RootState) => state.allConfigData. !== null ? state.configurationList.configurationList.total_count : 0)

  useEffect(() => {
    props.fetchAllConfigs(0, 10, auth.user?.profile.customer as string)
  }, [])

  useEffect(() => {
    props.fetchAllConfigs(0, 10, auth.user?.profile.customer as string)
  }, [props.createDatapubConfig, props.singleConfigData, props.updateConfigData, props.deleteConfigData])

  return (
        <div>
          <div style={{ marginTop: '20px' }}>
            <div className='configurations-table-heading'>
              <h5>Data Publisher List <span className='config-list-box'>{props.allConfigData.configData?.configurations?.length} Data Publisher</span></h5>
            </div>
              <ConfigurationTable />
          </div>
          <br />
        </div>
  )
}

interface DispatchToProps {
  fetchAllConfigs: (page: number, size: number, tenantId: string) => void
  createNewConfig: (configTemplate: AllConfigData) => void
  fetchSingleConfig: (configId: string) => void
  updateConfig: (configId: string, configTemplate: AllConfigData) => void
  deleteConfig: (configId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAllConfigs: (page: number, size: number, tenantId: string) => dispatch(fetchAllConfigs(page, size, tenantId)),
  createNewConfig: (configTemplate: AllConfigData) => dispatch(createNewConfig(configTemplate)),
  fetchSingleConfig: (configId: string) => dispatch(fetchSingleConfig(configId)),
  updateConfig: (configId: string, configTemplate: AllConfigData) => dispatch(updateConfig(configId, configTemplate)),
  deleteConfig: (configId: string) => dispatch(deleteConfig(configId))
})
interface StateToProps {
  allConfigData: ConfigState
  createDatapubConfig: CreateConfigurationState
  singleConfigData: SingleConfigState
  updateConfigData: UpdateConfigurationState
  deleteConfigData: DeleteConfigurationState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  allConfigData: state.allConfigData,
  createDatapubConfig: state.createDatapubConfig,
  singleConfigData: state.singleConfigData,
  updateConfigData: state.updateConfigData,
  deleteConfigData: state.deleteConfigData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AmiMeterCollectionDP)
