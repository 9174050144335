import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZAlarm = z.object({
  alarmDefinitionId: z.string(),
  assetId: z.string(),
  assetName: z.string(),
  alarmName: z.string(),
  severity: z.string(),
  alarmStatus: z.string(),
  readingTime: z.string(),
  longitude: z.number().nullable(),
  latitude: z.number().nullable()
})

const ZAlarmData = z.object({
  totalCount: z.number(),
  alarms: z.array(ZAlarm)
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZAlarmData),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type AllAlarmData = z.infer<typeof ZAlarmData>
export async function getAllAlarms (
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  tenantId: string,
  unpaged: boolean,
  onSuccess: (deviceList: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  if (sortfield === 'alarmDefinitionId') {
    sortfield = 'alarmTypeId'
  } else if (sortfield === 'alarmName') {
    sortfield = 'alarmTypeName'
  } else if (sortfield === 'alarmStatus') {
    sortfield = 'Status'
  }
  const url = `/alarm-service/api/v1/udp/assets/alarms/severity?severities=critical,High&tenantId=${tenantId}&page=${page}&size=${size}&sort=${sortfield},${sortdir}&unPaged=${
    unpaged ? 'true' : 'false'
  }`

  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          // istanbul ignore else
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, response.status)
          } else {
            onFailure(parseResponse.data.error, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      if (error.response?.data !== undefined) {
        onFailure(error.response.data.error_message, error.response.status)
      } else {
        onFailure(
          error.response !== undefined
            ? error.response.statusText
            : 'Network Error',
          error.response !== undefined ? error.response.status : 404
        )
      }
    })
}
