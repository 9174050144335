import React from 'react'
import { GenXDropdown, type Option } from '../../../../components/GenXDropdown'
import { Card, CardContent, FormHelperText, Grid, TextField } from '@mui/material'
import { isValidText } from '../../../../utils/validator'
import LanguageStrings from '../../../../i18n/locales'

const CreateReportFormStrings = LanguageStrings().DataPublisherStrings.CreateNewReportTemplateComponents.CreateReportForm

const textStyle = {
  root: {
    backgroundColor: '#272727',
    '& .MuiInputBase-input': {
      color: '#F0F0F0',
      backgroundColor: '#404040'
    },
    '& .MuiInputLabel-root': {
      color: '#F0F0F0',
      '&.Mui-focused': {
        color: '#F0F0F0'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00000000'
      },
      '&:hover fieldset': {
        borderColor: '#00000000'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00000000'
      }
    }
  }
}

interface ParentProps {
  reportName: string
  setReportName: React.Dispatch<React.SetStateAction<string>>
  reportTypes: Option[]
  report: Option | null
  setReport: React.Dispatch<React.SetStateAction<Option | null>>
  nameErrorInit: boolean
  setNameErrorInit: React.Dispatch<React.SetStateAction<boolean>>
}

const CreateReportForm = (props: ParentProps): JSX.Element => {
  const handleReportSelect = (selectedValue: Option): void => {
    props.setReport(selectedValue)
  }

  const isNameNotValid = (): boolean => {
    return (!isValidText(props.reportName) || (props.reportName.length > 60))
  }

  return (
    <div id='new-reports-template-info'>
      <Card sx={{ borderRadius: '10px', backgroundColor: '#272727', color: '#F0F0F0 !important', overflow: 'visible' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{CreateReportFormStrings.Report_Name}</span>
              <TextField
                id='report-name'
                variant="outlined"
                data-testid='report-name'
                placeholder='Enter Name'
                InputLabelProps={{
                  disabled: true
                }}
                value={props.reportName}
                onChange={(e) => {
                  props.setNameErrorInit(true)
                  props.setReportName(e.target.value)
                }}
                size='small'
                autoComplete='off'
                className='custom-textfield'
                sx={{
                  ...textStyle.root,
                  width: '60%'
                  // border: (isParameterInvalid(row) && parameterListErrorInit.includes(row.parameterName)) ? '1px solid #EE3426' : 'none'
                }}
              />
              {(isNameNotValid() && props.nameErrorInit) && (
                <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', width: '70%' }} className="errorColor">
                  {CreateReportFormStrings.Enter_a_valid_name}
                </FormHelperText>
              )}
            </Grid>
            <Grid item md={4}>
              <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>{CreateReportFormStrings.Report_Type}</span>
              <GenXDropdown placeholder={CreateReportFormStrings.Select_Report_Type} value={props.report} options={props.reportTypes} onSelect={handleReportSelect} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  )
}

export default CreateReportForm
