import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type SchdulerData } from '../services/getReportSchedulerByIdService'

export interface ISchdulerDetailsState {
  isLoading: boolean
  loadingError: string | undefined
  SchdulerDetails: SchdulerData | undefined
  httpStatus: number | undefined
  totalCount: number
}

// Initial state of the slice
const initialState: ISchdulerDetailsState = {
  isLoading: true,
  loadingError: undefined,
  SchdulerDetails: undefined,
  httpStatus: undefined,
  totalCount: 0
}

// Slice to return from action
export const SchdulerDetailsState = createSlice({
  name: 'SchdulerDetailsState',
  initialState,
  reducers: {
    SchdulerDetailsLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: undefined,
        SchdulerDetails: undefined,
        httpStatus: 0,
        totalCount: 0
      }
    },
    SchdulerDetailsUpdateAction: (state, action: PayloadAction<any>) => {
      console.log('action payload in update', action)
      return {
        isLoading: false,
        loadingError: undefined,
        SchdulerDetails: action.payload.data,
        httpStatus: action.payload.httpStatus,
        totalCount: action.payload.total_count
      }
    },
    SchdulerDetailsErrorAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        httpStatus: action.payload.httpStatus,
        SchdulerDetails: undefined,
        totalCount: 0
      }
    }
  }
})

// Actions to export
export const {
  SchdulerDetailsLoadingAction,
  SchdulerDetailsUpdateAction,
  SchdulerDetailsErrorAction
} = SchdulerDetailsState.actions

export default SchdulerDetailsState.reducer
