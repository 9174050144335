import { createSlice } from '@reduxjs/toolkit'

export interface deviceEvent {
  event: string
  eventTime: string
  collectedTime: string
  calculatedValue: string
}

export interface eventsInterface {
  events: deviceEvent[]
  errorMessage: string | null
  deviceCount: number
  isLoading: boolean
  httpStatus: number
}

const initialState: eventsInterface = {
  events: [],
  errorMessage: null,
  deviceCount: 0,
  isLoading: false,
  httpStatus: 200
}

export const eventsSlice = createSlice({
  name: 'eventsTableData',
  initialState,
  reducers: {
    addEventsTableData: (state, action: any) => {
      return {
        events: action.payload.data,
        isLoading: false,
        errorMessage: null,
        deviceCount: action.payload.count,
        httpStatus: action.payload.httpStatus
      }
    },
    EventsTableDataLoading: () => {
      return {
        events: [],
        isLoading: true,
        errorMessage: null,
        deviceCount: 0,
        httpStatus: 0
      }
    },
    EventsTableDataError: (state, action: any) => {
      return {
        events: [],
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        deviceCount: 0,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const { addEventsTableData, EventsTableDataLoading, EventsTableDataError } = eventsSlice.actions
export default eventsSlice.reducer
