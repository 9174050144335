/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef, useState } from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import LanguageStrings from '../../../i18n/locales'
import { useAuth } from 'react-oidc-context'
import { useNavigate } from 'react-router-dom'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { Button as GenericButton } from '../../../components/Button'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import FileExport from '../../../assets/exportIcon.svg'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { useSelector } from 'react-redux'
import Pagination from '../../../components/TablePagination'
import { type RootState } from '../../../store'
import { type ConnectedProps, connect } from 'react-redux'
import FilterIcon from '../../../assets/filterIcon.png'
import {
  getFirmwareCampaignList,
  firmwareDeleteCampaign,
  exportFirmwareUpgradeStatus,
  firmwareCampaignFilterMetaData,
  postFirmwareCampaignFilterList,
  getFirmwareCampaignListBySearch
} from '../redux/actionCreators'
import Button from '@mui/material/Button'
import './firmwareList.css'
import BackButton from '../../../components/BackButton'
import SearchBox from '../../../components/SearchBox'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
// import { type GroupParaObj } from '../services/firmwareList'
import {
  type FirmwareCampaignDetails,
  type FirmwareCampaignFilterRequest
} from '../services/firmwareCampaignListService'
import { CircularProgress } from '@mui/material'
import { getGroupTemplateModal } from './groupTemplate'
import { type FirmwareCampaignListState } from '../redux/firmwareCampaignListSlice'
import Modal from '../../../components/Modal'
import UnAssignGroupPopup from './UnassignGroupPopup'
import AssignGroupPopup from './AssignGroupPopup'
import { type exportFirmwareUpgradeStatusListState } from '../redux/exportFirmwareUpgradeStatusSlice'
import { type FirmwareCampaignFilterMetaDataState } from '../redux/getFirmwareCampaignFilterMetaDataSlice'
// import { GenXDropdown, type Option } from '../../../components/GenXDropdown'

import Drawer from '@mui/material/Drawer'
import ReplayIcon from '@mui/icons-material/Replay'
import CloseIcon from '@mui/icons-material/Close'
import FormControl from '@mui/material/FormControl'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import EditCampaignPopup from './EditCampaignPopup'
import { convertDateIntoAohStandardUsingDayjs } from '../../../customHooks/parseDate'
import _ from 'lodash'

const FirmwareUpgradeStatusStrings =
  LanguageStrings().FirmwareUpgradeStatusStrings

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

interface Data {
  rowID: string
  campaignName: string
  assetType: string
  firmwareType: string
  groups: {
    group_id: string
    group_name: string
    status: string
  }[]
  version: string
  status: string
  scheduleTypeAndDate: string
  startedOn: string
  action: string
}

const style = {
  width: '100%',
  bgcolor: '#272727',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

type Order = 'asc' | 'desc'

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
  sortable: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'firmwareCampaignName',
    numeric: false,
    disablePadding: false,
    sortable: true,
    label: FirmwareUpgradeStatusStrings.FirmwareUpgradeStatusTable.campaignName
  },
  {
    id: 'assetType',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: FirmwareUpgradeStatusStrings.FirmwareUpgradeStatusTable.assetType
  },
  {
    id: 'firmwareType',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: FirmwareUpgradeStatusStrings.FirmwareUpgradeStatusTable.firmwareType
  },
  {
    id: 'assetGroup',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: FirmwareUpgradeStatusStrings.FirmwareUpgradeStatusTable.group
  },
  {
    id: 'version',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: FirmwareUpgradeStatusStrings.FirmwareUpgradeStatusTable.version
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: FirmwareUpgradeStatusStrings.FirmwareUpgradeStatusTable.status
  },
  {
    id: 'scheduleTypeAndDate',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label:
      FirmwareUpgradeStatusStrings.FirmwareUpgradeStatusTable
        .scheduleTypeAndDate
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    sortable: false,
    label: FirmwareUpgradeStatusStrings.FirmwareUpgradeStatusTable.action
  }
]

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => void
  order: Order
  orderBy: string
  rowCount: any
}

function EnhancedTableHead (props: EnhancedTableProps): JSX.Element {
  const { order, orderBy, onRequestSort } = props
  // istanbul ignore next
  const createSortHandler =
    (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy)
    }
  return (
    <TableHead data-testid="firmware-upgrade-status-table-header">
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            className="firmware-table-header"
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label !== FirmwareUpgradeStatusStrings.action ? (
              <TableSortLabel
                hideSortIcon={!headCell.sortable}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={
                  // istanbul ignore next
                  headCell.sortable ? createSortHandler(headCell.id) : undefined
                }
                aria-label={headCell.label}
                data-testid="test-sort"
                className="firmware-table-header"
                sx={{ cursor: headCell.sortable ? 'pointer' : 'text' }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  // istanbul ignore next
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <div>{FirmwareUpgradeStatusStrings.action.toUpperCase()}</div>
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

interface Sort {
  order: Order
  orderBy: string
}

interface GroupParaObj {
  group_id: string
  group_name: string
  status: string
}

type ApiType = 'default' | 'search' | 'filter'

function FirmwareUpgradeStatus (props: PropsFromRedux): JSX.Element {
  const navigate = useNavigate()
  const rowsPerPage = 10
  const auth = useAuth()
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({
    order: 'desc',
    orderBy: 'createdAt'
  })
  const [searchValue, setSearchValue] = React.useState<string | null>(null)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedRow, setSelectedRow] = React.useState<any>(null)
  const [showAssignGroupPopUp, setShowAssignGroupPopUp] = React.useState(false)
  const [showUnAssignGroupPopUp, setshowUnAssignGroupPopUp] =
    React.useState(false)
  const [showTemplates, setShowTemplates] = React.useState<GroupParaObj[]>([])
  const [confirmDeletePopup, setConfirmDeletePopup] = React.useState(false)
  const [showDeleteStatus, setShowDeleteStatus] = React.useState(false)
  const [selectedCampaignId, setSelectedCampaignId] = React.useState('')

  const [anchorAssetType, setAnchorAssetType] = React.useState('')
  const [anchorFileId, setAnchorFileId] = React.useState('')
  const [unassignGroupRow, setUnassignGroupRow] = React.useState<
  GroupParaObj[]
  >([])
  const [selectedGroups, setSelectedGroups] = React.useState<string[]>([])
  const [openFilter, setOpenFilter] = React.useState(false)
  const search = ''

  const rows = useSelector(
    (state: any) => state.firmwareCampaignListState?.campaignListData
  )
  const count = useSelector(
    (state: any) => state.firmwareCampaignListState?.totalCount
  )
  const firmwareCampaignDeleteStatus = useSelector(
    (state: RootState) => state.firmwareCampaignDeleteState
  )
  const firmwareCampaignFilterGroup = useSelector(
    (state: RootState) =>
      state.FirmwareCampaignFilterMetaData.FirmwareCampaignFilterMetaData
        ?.groups
  )
  const [selectedGroup, setSelectedGroup] = React.useState<string[]>([])
  const [showfilter, setshowfilter] = React.useState(false)
  const [showEditCampaignPopup, setShowEditCampaignPopup] =
    React.useState(false)
  const [dataType, setDataType] = React.useState<ApiType>('default')
  const [init, setInit] = React.useState(false)
  const [tempFilters, setTempFilters] = useState<string[]>([])

  React.useEffect(() => {
    props.firmwareCampaignFilterMetaData()
  }, [])

  useEffect(() => {
    if (dataType === 'default') {
      callGetFirmwareCampaignListApi()
    } else if (dataType === 'search') {
      handleSearch()
    } else {
      callGetFirmwareCampaignListApiWithFilter()
    }
  }, [page, sort])

  useEffect(() => {
    if (init) {
      if (page !== 1) {
        setPage(1)
      } else {
        if (dataType === 'default') {
          callGetFirmwareCampaignListApi()
        } else if (dataType === 'search') {
          handleSearch()
        } else {
          callGetFirmwareCampaignListApiWithFilter()
        }
      }
    }
    setInit(true)
  }, [dataType])

  // istanbul ignore next
  const retry = (): void => {
    props.getFirmwareCampaignList(
      Number(page - 1),
      rowsPerPage,
      sort.order,
      'createdAt',
      search,
      auth.user?.profile.customer as string
    )
  }

  const callGetFirmwareCampaignListApi = (): void => {
    props.getFirmwareCampaignList(
      Number(page - 1),
      rowsPerPage,
      sort.order,
      sort.orderBy,
      search,
      auth.user?.profile.customer as string
    )
  }

  // istanbul ignore next
  const callGetFirmwareCampaignListApiWithFilter = (): void => {
    if (tempFilters.length > 0) {
      props.postFirmwareCampaignFilterList(
        Number(page - 1),
        rowsPerPage,
        sort.order,
        sort.orderBy,
        search,
        auth.user?.profile.customer as string,
        { groupIds: tempFilters }
      )
    } else {
      callGetFirmwareCampaignListApi()
    }
  }

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  function useOutsideAlerter (ref: any): void {
    React.useEffect(() => {
      function handleClickOutside (event: any): void {
        // istanbul ignore next
        if (ref.current && !ref.current.contains(event.target)) {
          setAnchorEl(null)
          setSelectedRow(null)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }
  // istanbul ignore next
  const onAssetGroupPopUpClose = (): void => {
    setShowAssignGroupPopUp(false)
    setshowUnAssignGroupPopUp(false)
    setShowEditCampaignPopup(false)
    callGetFirmwareCampaignListApi()
  }

  // istanbul ignore next
  const handleAssignGroup = (row: any): void => {
    setSelectedGroups(row.assetGroup.map((group: any) => group.group_id))
    setAnchorAssetType(row.assetType ?? '')
    // setUnassignGroupRow(row.assetGroup || [])
    setAnchorFileId(row.firmwareCampaignId ?? '')
    setShowAssignGroupPopUp(true)
    setSelectedRow(null)
  }

  // istanbul ignore next
  const handleUnAssignGroup = (row: any): void => {
    setAnchorAssetType(row.assetType ?? '')
    setUnassignGroupRow(row.assetGroup || [])
    setAnchorFileId(row.firmwareCampaignId ?? '')
    setshowUnAssignGroupPopUp(true)
    setSelectedRow(null)
  }

  // istanbul ignore next
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ): void => {
    const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
    const toggledOrder = isAsc ? 'desc' : 'asc'
    setSort({ order: toggledOrder, orderBy: newOrderBy })
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& th': {
      border: '1px solid #202020'
    }
  }))

  const handleClick = (): void => {
    navigate('/firmware-upload')
    localStorage.removeItem('Search')
  }

  // istanbul ignore next
  const handleEllipsisClick = (event: any, row: string): void => {
    setAnchorEl(event.currentTarget)
    setSelectedRow(row)
  }

  // istanbul ignore next
  const handleFetchFirmwareList = (): void => {
    console.log('fff')
  }

  // istanbul ignore next
  const clearAllFilters = (): void => {
    setshowfilter(false)
    setSelectedGroup([])
  }

  // istanbul ignore next
  const operationForFilter = (): void => {
    setSelectedGroup(tempFilters)
    setshowfilter(true)
    setOpenFilter(false)
    if (dataType === 'filter') {
      callGetFirmwareCampaignListApiWithFilter()
    }
  }

  const handleGroupSelection = (event: any): void => {
    console.log('val', event.target.value)
    const {
      target: { value }
    } = event
    setTempFilters(typeof value === 'string' ? value.split(',') : value)
    console.log('temp', tempFilters)
  }

  // istanbul ignore next
  const handleSearch = (): void => {
    if (dataType !== 'search') {
      setTempFilters([])
      setDataType('search')
    } else {
      console.log('page', page)
      clearAllFilters()
      props.getFirmwareCampaignListBySearch(
        page - 1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        searchValue ?? '',
        auth.user?.profile.customer as string
      )
    }
  }

  // istanbul ignore next
  const handleTable = (): JSX.Element => {
    if (props.firmwareCampaignListData?.isLoading) {
      return (
        <div className="CircularprogressAmi">
          <CircularProgress />
        </div>
      )
    } else if (props.firmwareCampaignListData?.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (props.firmwareCampaignListData?.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else if (
      props.firmwareCampaignListData?.httpStatus === 404 ||
      (props.firmwareCampaignListData.httpStatus === 200 &&
        props.firmwareCampaignListData.campaignListData?.length === 0)
    ) {
      return (
        <div className="authError">
          <AuthError errorMessage="NoDataPresent" />
        </div>
      )
    } else if (
      rows !== undefined &&
      props.firmwareCampaignListData?.httpStatus === 200
    ) {
      return (
        <>
          <TableContainer
            className="firmware-table"
            sx={{ overflow: 'visible' }}
          >
            <Table
              sx={{ minWidth: 750, color: '#f0f0f0' }}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <EnhancedTableHead
                order={sort.order}
                orderBy={sort.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length}
              />
              <TableBody>
                {rows?.map((row: FirmwareCampaignDetails, index: any) => {
                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.firmwareCampaignId}
                      aria-label="devices-history-row"
                      sx={{ cursor: 'pointer' }}
                    >
                      <StyledTableCell
                        sx={{
                          color: '#64C3FF !important',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          handleCampaignSelection(
                            row.firmwareCampaignId != null
                              ? row.firmwareCampaignId
                              : '',
                            row.firmwareCampaignName != null
                              ? row.firmwareCampaignName
                              : ''
                          )
                        }}
                      >
                        {row.firmwareCampaignName}
                      </StyledTableCell>
                      <StyledTableCell>{row.assetType}</StyledTableCell>
                      <StyledTableCell>{row.firmwareType}</StyledTableCell>
                      <StyledTableCell data-testid="asset-group">
                        {row.assetGroup === null ? (
                          // istanbul ignore next
                          ''
                        ) : Object.keys(row.assetGroup).length === 0 ? (
                          ''
                        ) : row.assetGroup.length === 1 ? (
                          // istanbul ignore next
                          row.assetGroup[0].group_name
                        ) : (
                          <span
                            style={{ color: '#64C3FF' }}
                            onClick={() => {
                              setShowTemplates(
                                row.assetGroup === null ? [] : row.assetGroup
                              )
                            }}
                          >
                            {row.assetGroup[0]?.group_name}+
                            {row.assetGroup.length - 1}
                          </span>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>{row.version}</StyledTableCell>
                      <StyledTableCell>{row.status}</StyledTableCell>
                      <StyledTableCell>
                        {row.schedulerType} |{' '}
                        {convertDateIntoAohStandardUsingDayjs(
                          row.scheduledDate
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color: '#64C3FF !important',
                          cursor: 'pointer'
                        }}
                      >
                        {row.status !== 'In Progress' ? (
                          <>
                            <div
                              aria-label="delete"
                              style={{
                                display: 'inline',
                                paddingRight: '10px'
                              }}
                              data-testid="delete-campaign-btn"
                              onClick={() => {
                                setConfirmDeletePopup(true)
                                setSelectedCampaignId(
                                  row.firmwareCampaignId
                                    ? row.firmwareCampaignId
                                    : ''
                                )
                              }}
                            >
                              Delete
                            </div>
                            <div
                              style={{
                                display: 'inline-flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                position: 'relative',
                                paddingBottom: '3px'
                              }}
                            >
                              {Object.keys(row.assetGroup).length !== 0 ? (
                                <IconButton
                                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                  aria-controls={`menu-${index}`}
                                  aria-haspopup="true"
                                  data-testid="device-ellipsis"
                                  sx={{
                                    padding: '0px !important',
                                    position: 'absolute'
                                  }}
                                  onClick={(event) => {
                                    handleEllipsisClick(
                                      event,
                                      row.firmwareCampaignId !== null
                                        ? row.firmwareCampaignId
                                        : ''
                                    )
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              ) : (
                                ''
                              )}
                              {Boolean(anchorEl) &&
                              // istanbul ignore next
                              selectedRow === row.firmwareCampaignId ? (
                                // istanbul ignore next
                                <ul
                                  ref={wrapperRef}
                                  className=""
                                  style={{
                                    position: 'absolute',
                                    right: '0px',
                                    backgroundColor: '#303030',
                                    color: '#f0f0f0',
                                    width: 'max-content',
                                    padding: '8px 12px',
                                    listStyleType: 'none',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '5px'
                                  }}
                                >
                                  <li
                                    data-testid="assign-group-btn"
                                    style={{
                                      color: '#64c3ff',
                                      textDecoration: 'none'
                                    }}
                                    onClick={() => {
                                      handleAssignGroup(row)
                                    }}
                                  >
                                    Assign Group
                                  </li>
                                  <li
                                    data-testid="unassign-btn"
                                    style={{
                                      color: '#64c3ff',
                                      textDecoration: 'none',
                                      paddingTop: '2px'
                                    }}
                                    onClick={() => {
                                      handleUnAssignGroup(row)
                                    }}
                                  >
                                    Unassign Group
                                  </li>
                                  <li
                                    data-testid="edit-campaign-btn"
                                    onClick={() => {
                                      setSelectedCampaignId(
                                        row.firmwareCampaignId
                                          ? row.firmwareCampaignId
                                          : ''
                                      )
                                      setShowEditCampaignPopup(true)
                                    }}
                                    style={{
                                      color: '#64c3ff',
                                      textDecoration: 'none'
                                    }}
                                  >
                                    Edit
                                  </li>
                                </ul>
                                  ) : (
                                    ''
                                  )}
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {count > 10 && (<Pagination
            count={count}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            siblingCount={1}
            boundaryCount={1}
          />)}
          {showAssignGroupPopUp && (
            <AssignGroupPopup
              assignedGroups={selectedGroups}
              // refreshFirmwareList={handleRetry}
              onAssetGroupPopUpClose={onAssetGroupPopUpClose}
              selected={anchorFileId}
              assetType={anchorAssetType}
            />
          )}
          {showUnAssignGroupPopUp && (
            <UnAssignGroupPopup
              onAssetGroupPopUpClose={onAssetGroupPopUpClose}
              selectedFirmware={anchorFileId}
              UnassigGroups={unassignGroupRow}
              assetType={anchorAssetType}
              handleFetchFirmwareList={handleFetchFirmwareList}
            />
          )}
          {showEditCampaignPopup && (
            <EditCampaignPopup
              selectedCampaignID={selectedCampaignId}
              refreshFirmwareList={retry}
              onEditCampaignPopUpClose={onAssetGroupPopUpClose}
              aria-label="edit-camp-comp"
            />
          )}
        </>
      )
    } else if (props.firmwareCampaignListData.httpStatus === 400) {
      return (
        <div className="authError">
          <AuthError errorMessage="invalidSearchInput" />
        </div>
      )
    } else {
      return (
        <div className="authError">
          <AuthError errorMessage="cannotFetch" retry={retry} />
        </div>
      )
    }
  }

  // istanbul ignore next
  const handleDeleteCampaignWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (firmwareCampaignDeleteStatus.httpStatus === 200) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={'Success'}
          LabelPositive="Ok"
          onClickPositive={() => {
            setShowDeleteStatus(false)
            callGetFirmwareCampaignListApi()
          }}
          onClose={() => {
            setShowDeleteStatus(false)
            callGetFirmwareCampaignListApi()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {' '}
            {FirmwareUpgradeStatusStrings.deletedSuccessfully}{' '}
          </p>
        </Modal>
      )
    } else if (firmwareCampaignDeleteStatus.isLoading) {
      return (
        <Modal title={'Loading...'}>
          <div className="CircularprogressAmi">
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (firmwareCampaignDeleteStatus.httpStatus === 400) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={'Failed'}
          LabelPositive="Ok"
          onClickPositive={() => {
            setShowDeleteStatus(false)
          }}
          onClose={() => {
            setShowDeleteStatus(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {' '}
            {firmwareCampaignDeleteStatus.error}{' '}
          </p>
        </Modal>
      )
    } else if (firmwareCampaignDeleteStatus.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (firmwareCampaignDeleteStatus.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={'Failed'}
          LabelPositive="Ok"
          onClickPositive={() => {
            setShowDeleteStatus(false)
          }}
          onClose={() => {
            setShowDeleteStatus(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {' '}
            {firmwareCampaignDeleteStatus.error}{' '}
          </p>
        </Modal>
      )
    }
  }
  // istanbul ignore next
  const handleCampaignSelection = (
    campaignId: string,
    campaignName: string
  ): void => {
    sessionStorage.setItem('selectedCampaignId', campaignId)
    sessionStorage.setItem('selectedCampaignName', campaignName)
    navigate('/firmware-upgrade-details')
  }
  return (
    <div style={{ width: 'calc(100vw - 3.5rem)', paddingRight: '2rem' }}>
      <div
        role="presentation"
        data-testid="breadcrumbs"
        style={{ width: '100%' }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            color: '#f0f0f0',
            fontSize: '12px',
            marginBottom: '14px',
            fontFamily: 'Honeywell Sans Web',
            marginTop: '12px'
          }}
        >
          <Link
            color="inherit"
            className="breadcrumbAssetMgmt"
            style={{ textDecoration: 'none', fontSize: '.875rem' }}
            data-testid="device-management-breadcrumb"
            onClick={() => {
              // istanbul ignore next
              navigate('/card')
            }}
          >
            {LanguageStrings().AssetFirmwareStrings.assetManagement}
          </Link>
          <Link
            data-testid="asset-provisioning-history"
            style={{
              cursor: 'text',
              color: '#F0F0F0',
              textDecoration: 'none',
              fontSize: '.875rem'
            }}
            color="inherit"
          >
            {FirmwareUpgradeStatusStrings.Title}
          </Link>
        </Breadcrumbs>
      </div>
      <div
        className="topUtilityBar"
        style={{ marginBottom: '30px', marginRight: '0px' }}
      >
        <BackButton
          dataTestId="back-arrow"
          onclick={() => {
            navigate('/card')
          }}
        />
        <span id="componentTitle" data-testid="firmwaretitle">
          {FirmwareUpgradeStatusStrings.Title}
        </span>
      </div>
      <div className="filterDownloadSection">
        <div>
          <span data-testid="" className="firmware-Component-list-title">
            {FirmwareUpgradeStatusStrings.Title} |{' '}
          </span>
          <span className="total-firmware"> {count} Firmware Upgrade </span>
        </div>
      </div>
      <Box sx={style} className="devices">
        <div
          className="import-section"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div className="searchSection" style={{ width: '100%' }}>
            <Paper
              className="searchBoxComponent"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'left' }}
            >
              <Button
                data-testid="filter-data"
                className="filter-btn"
                sx={{
                  background: '#707070',
                  cursor: 'pointer',
                  color: '#D0D0D0',
                  height: 'auto'
                }}
                onClick={() => {
                  setOpenFilter(true)
                  // populateValues()
                }}
              >
                <img
                  src={FilterIcon}
                  height={16}
                  width={16}
                  style={{ marginLeft: '7px' }}
                />
                {FirmwareUpgradeStatusStrings.filterTitle}
              </Button>
              <SearchBox
                immediate={true}
                debounce={true}
                placeholder={FirmwareUpgradeStatusStrings.searchPlaceholder}
                sx={{
                  width: '17rem;',
                  margin: '-7px -5px',
                  height: '37px'
                }}
                emptyEnterFallback={() => {
                  setDataType('default')
                }}
                searchVal={searchValue}
                setSearchVal={setSearchValue}
                performAction={() => {
                  handleSearch()
                }}
              />
            </Paper>
          </div>
          {props.firmwareCampaignListData?.httpStatus === 200 && (
            <div style={{ padding: '5px' }}>
              <img
                className="fileExport"
                src={FileExport}
                height={25}
                width={25}
                onClick={() => {
                  props.exportFirmwareUpgradeStatus()
                }}
              />
            </div>
          )}
          <span id="top-right-ami-utilities">
            <span id="top-right-btn-span">
              <GenericButton
                sx={{ margin: 0 }}
                width="max-content"
                onClick={() => {
                  handleClick()
                }}
                dataTestId="goto-add-firmware"
              >
                {LanguageStrings().AssetFirmwareStrings.addFirmware}
              </GenericButton>
            </span>
          </span>
        </div>
        <div>
          {showfilter && (
            // istanbul ignore next
            <div
              style={{
                padding: '12px 24px',
                borderTop: '2px solid #202020',
                color: '#d0d0d0'
              }}
            >
              {selectedGroup.length < 1
                ? (
                <>
                  {' '}
                  <span>No filters applied</span>{' '}
                  <em
                    style={{
                      cursor: 'pointer',
                      color: '#1792e5',
                      paddingLeft: '10px'
                    }}
                    onClick={() => {
                      setTempFilters([])
                      clearAllFilters()
                    }}
                  >
                    Clear{' '}
                  </em>{' '}
                </>
                  )
                : (
                <div className="classNAme" style={{ alignItems: 'center' }}>
                  {' '}
                  {'Results | Filters :'}
                  <div className="box">
                    {firmwareCampaignFilterGroup
                      ?.filter((item) => selectedGroup.includes(item.group_id))
                      .map((item) => item.group_name)
                      .join(', ')}
                  </div>
                  <em
                    onClick={() => {
                      clearAllFilters()
                      setTempFilters([])
                      callGetFirmwareCampaignListApi()
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Clear Filters
                  </em>
                </div>
                  )}
            </div>
          )}
        </div>
        <Drawer
          anchor="right"
          open={openFilter}
          className="filter-drawer"
          onClose={() => {
            setOpenFilter(false)
            setTempFilters(selectedGroup)
          }}
        >
          <div className="filter-headar">
            <div className="filter-title">Filter</div>
            <div>
              <Button
                aria-label="clear-all-filter"
                className="cancel"
                style={{ marginTop: '-2px', marginLeft: '80px' }}
                onClick={() => {
                  setTempFilters([])
                }}
              >
                <ReplayIcon
                  sx={{ width: '16px', height: '16px', marginRight: '8px' }}
                />
                Clear All
              </Button>
            </div>
            <div>
              <CloseIcon
                aria-label="close-drawer"
                sx={{ color: '#f0f0f0' }}
                onClick={() => {
                  setOpenFilter(false)
                  setTempFilters(selectedGroup)
                }}
              />
            </div>
          </div>
          {tempFilters && (
            <>
              <div className="type-name" style={{ marginTop: '25px' }}>
                {FirmwareUpgradeStatusStrings.group}
              </div>

              <FormControl>
                <InputLabel className="select-title">Select Group</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  className="uploaded-by-select"
                  multiple
                  value={tempFilters}
                  onChange={handleGroupSelection}
                  sx={{
                    height: '40px',
                    backgroundColor: '#5c5b5bdd',
                    borderRadius: '4px',
                    color: '#d0d0d0'
                  }}
                  renderValue={(selected: string[]) =>
                    `${selected.length} selected`
                  }
                  MenuProps={MenuProps}
                  aria-label="user-change"
                >
                  {firmwareCampaignFilterGroup?.map(
                    (item: any, index: number) => (
                      <MenuItem
                        key={item.group_id}
                        value={item.group_id}
                        data-testid="filter-checkbox"
                      >
                        <Checkbox
                          inputProps={{ id: `select-item-${index}` }}
                          checked={tempFilters.includes(item.group_id)}
                          sx={{
                            color: '#37a3f8',
                            '&.Mui-checked': {
                              color: '#37a3f8'
                            }
                          }}
                        />
                        <ListItemText primary={item.group_name} />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </>
          )}

          <div className="divider" />
          <div className="buttons-container">
            <Button
              className="cancel"
              aria-label="cancel-drawer"
              onClick={() => {
                setOpenFilter(false)
                setTempFilters(selectedGroup)
              }}
            >
              {FirmwareUpgradeStatusStrings.Cancel}
            </Button>
            <Button
              className="confirm"
              aria-label="confirm-filter"
              disabled={_.isEqual(tempFilters, selectedGroup)}
              sx={{ background: _.isEqual(tempFilters, selectedGroup) ? '#B0B0B0 !important' : '#64C3FF !important' }}
              onClick={() => {
                operationForFilter()
                setDataType('filter')
                setSearchValue(null)
              }}
            >
              {FirmwareUpgradeStatusStrings.showResultButton}
            </Button>
          </div>
        </Drawer>
        {handleTable()}
      </Box>
      {
        // istanbul ignore next
        showTemplates.length > 0
          ? (
              getGroupTemplateModal(showTemplates, () => {
                setShowTemplates([])
              })
            )
          : (
          <></>
            )
      }
      {confirmDeletePopup && (
        // istanbul ignore next
        <Modal
          buttonTypeNegative="hollow"
          title={FirmwareUpgradeStatusStrings.Delete_firmware}
          LabelPositive="Confirm"
          LabelNegative="Cancel"
          onClickPositive={() => {
            setConfirmDeletePopup(false)
            setShowDeleteStatus(true)
            props.firmwareDeleteCampaign(selectedCampaignId)
          }}
          onClose={() => {
            setConfirmDeletePopup(false)
          }}
          onClickNegative={() => {
            setConfirmDeletePopup(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {FirmwareUpgradeStatusStrings.Do_you_want_to_delete_this_firmware}
          </p>
        </Modal>
      )}
      {showDeleteStatus && handleDeleteCampaignWithFailure()}
    </div>
  )
}

interface DispatchToProps {
  getFirmwareCampaignList: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    search: string,
    tenantId: string
  ) => void
  postFirmwareCampaignFilterList: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    search: string,
    tenantId: string,
    filterParams: FirmwareCampaignFilterRequest
  ) => void
  firmwareDeleteCampaign: (firmwareCampaignId: string) => void
  exportFirmwareUpgradeStatus: () => void
  firmwareCampaignFilterMetaData: () => void
  getFirmwareCampaignListBySearch: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    input: string,
    tenantId: string
  ) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getFirmwareCampaignList: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    search: string,
    tenantId: string
  ) =>
    dispatch(
      getFirmwareCampaignList(page, size, sortdir, sortfield, search, tenantId)
    ),
  postFirmwareCampaignFilterList: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    search: string,
    tenantId: string,
    filterParams: FirmwareCampaignFilterRequest
  ) =>
    dispatch(
      postFirmwareCampaignFilterList(
        page,
        size,
        sortdir,
        sortfield,
        search,
        tenantId,
        filterParams
      )
    ),
  firmwareDeleteCampaign: (firmwareCampaignId: string) =>
    dispatch(firmwareDeleteCampaign(firmwareCampaignId)),
  exportFirmwareUpgradeStatus: () => dispatch(exportFirmwareUpgradeStatus()),
  firmwareCampaignFilterMetaData: () =>
    dispatch(firmwareCampaignFilterMetaData()),
  getFirmwareCampaignListBySearch: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    input: string,
    tenantId: string
  ) =>
    dispatch(
      getFirmwareCampaignListBySearch(
        page,
        size,
        sortdir,
        sortfield,
        input,
        tenantId
      )
    )
})

interface StateToProps {
  firmwareCampaignListData: FirmwareCampaignListState
  FirmwareUpgradeStatusList: exportFirmwareUpgradeStatusListState
  FirmwareCampaignFilterMetaData: FirmwareCampaignFilterMetaDataState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  firmwareCampaignListData: state.firmwareCampaignListState,
  FirmwareUpgradeStatusList: state.FirmwareUpgradeStatusList,
  FirmwareCampaignFilterMetaData: state.FirmwareCampaignFilterMetaData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(FirmwareUpgradeStatus)
