import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'

interface BackButtonProps {
  onclick?: () => void
  dataTestId?: string
}

const BackButton = (props: BackButtonProps): any => {
  const [color, setColor] = useState('#F0F0F0')
  const navigate = useNavigate()
  const whiteColor = '#F0F0F0'
  const blueColor = '#64C3FF'

  const defaultOnClick = (): void => {
    if (window.location.pathname === '/isense') {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  const handleMouseEnter = (): void => {
    setColor(blueColor)
  }

  // istanbul ignore next
  const handleMouseLeave = (): void => {
    setColor(whiteColor)
  }

  return (
        <i id="backButton" data-testid={props.dataTestId !== undefined ? props.dataTestId : 'backbutton'} onClick={() => { props.onclick !== undefined ? props.onclick() : defaultOnClick() }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 1L1 8L8 15" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </i>
  )
}

export default BackButton
