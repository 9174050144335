import React from 'react'
import { type Params, type ConfigurationDetails } from '../../services/configDetailsService'
import LanguageStrings from '../../../../i18n/locales'

const ParameterListComponentStrings = LanguageStrings().AssetConfigurationStrings.components.AssetDetailsComponents.ParameterListComponent

const ParameterListComponent = (props: { configDetails: ConfigurationDetails }): JSX.Element => {
  const tdrStyle: React.CSSProperties = {
    padding: '1em'
  }

  function parseParameter (paramValue: string): JSX.Element {
    return <abbr style={{ textDecoration: 'none' }} title={paramValue}>{paramValue}</abbr>
  }

  return (
    <div>
      <div style={{ fontWeight: 600, fontSize: '18px', lineHeight: '24px' }}>{ParameterListComponentStrings.Parameter_List} ({ props.configDetails.params !== undefined ? props.configDetails.params.length : 0 })</div>
        <br />
        <div className='parameter-table' style={{ maxHeight: '40vh', overflowY: 'auto', borderRadius: '10px' }}>
          <table style={{ width: '100%', paddingRight: '5px', textAlign: 'left', borderCollapse: 'collapse' }}>
            <thead style={{ position: 'sticky', top: 0, backgroundColor: '#313030' }}>
              <tr>
                <th style={tdrStyle}><span>{ParameterListComponentStrings.PARAMETER_NAME}</span></th>
                <th style={tdrStyle}><span>{ParameterListComponentStrings.PARAMETER_VALUE}</span></th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: '#272727' }}>
              {
                (props.configDetails.params !== undefined && props.configDetails.params.length > 0)
                  ? props.configDetails.params.map((param: Params, index: number) => (
                      <tr key={index}>
                        <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000' }}>{param.param_name}</td>
                        <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000', maxWidth: 500, overflowX: 'hidden', textOverflow: 'ellipsis' }}>{(param.param_value !== null && param.param_value !== '') ? parseParameter(param.param_value) : ParameterListComponentStrings.Not_Applicable}</td>
                      </tr>
                  ))
                  : ''
              }
            </tbody>
          </table>
        </div>
    </div>
  )
}

export default ParameterListComponent
