/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios'
import { oidcConfig } from '../forgeiam/authConfig'
const interceptedAxios = axios.create()

interceptedAxios.interceptors.request.use(
  (config) => {
    const itemKey = `oidc.user:${oidcConfig.authority ?? ''}:${oidcConfig.client_id ?? ''}`
    const itemValue = sessionStorage.getItem(itemKey)
    const token = (itemValue != null) ? JSON.parse(itemValue).id_token : ''
    const username = (itemValue != null) ? JSON.parse(itemValue).profile.name : ''
    const tenantId = (itemValue != null) ? JSON.parse(itemValue).profile.customer : ''
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    if (username) {
      config.headers['X-Username'] = username
    }
    if (tenantId) {
      config.headers['X-TenantId'] = tenantId
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default interceptedAxios
