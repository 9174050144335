import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
export interface testConnectionState {
  isLoading: boolean
  message: string | undefined
  httpStatus: number
}

const initialState: testConnectionState = {
  isLoading: false,
  message: undefined,
  httpStatus: 0
}

const testConnectionSlice = createSlice({
  name: 'testConnection-Create',
  initialState,
  reducers: {
    testConnectionLoadingAction: (state) => {
      return {
        isLoading: true,
        message: undefined,
        httpStatus: 0
      }
    },
    testConnectionUpdateAction: (state, action: PayloadAction<{ message: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        message: action.payload.message,
        httpStatus: action.payload.httpStatus
      }
    },
    testConnectionErrorAction: (state, action: PayloadAction<{ message: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        message: action.payload.message,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  testConnectionLoadingAction,
  testConnectionUpdateAction,
  testConnectionErrorAction
} = testConnectionSlice.actions

export default testConnectionSlice.reducer
