/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface provisioningHistoryFilterUploadedUsersState {
  isLoading: boolean
  loadingError: string | null
  provisioningHistoryFilterUploadedUsers: string[] | []
  httpStatus: number | null
}

// Initial state of the slice
const initialState: provisioningHistoryFilterUploadedUsersState = {
  isLoading: true,
  loadingError: null,
  provisioningHistoryFilterUploadedUsers: [],
  httpStatus: null
}

// Slice to return from action
export const provisioningHistoryFilterUploadedUsersSlice = createSlice({
  name: 'provisioningHistoryFilterUploadedUsers',
  initialState,
  reducers: {
    provisioningHistoryFilterUploadedUsersLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        provisioningHistoryFilterUploadedUsers: [],
        httpStatus: 0
      }
    },
    provisioningHistoryFilterUploadedUsersUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: null,
        provisioningHistoryFilterUploadedUsers: action.payload.data,
        httpStatus: action.payload.httpStatus,
      }
    },
    provisioningHistoryFilterUploadedUsersErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        provisioningHistoryFilterUploadedUsers: []
      }
    }
  }
})

// Actions to export
export const {
  provisioningHistoryFilterUploadedUsersLoadingAction,
  provisioningHistoryFilterUploadedUsersUpdateAction,
  provisioningHistoryFilterUploadedUsersErrorAction
} = provisioningHistoryFilterUploadedUsersSlice.actions

export default provisioningHistoryFilterUploadedUsersSlice.reducer
