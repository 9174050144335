import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'
import { type ReportData, ZReportData } from '../types'

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.array(ZReportData).optional(),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type ApiResponse = z.infer<typeof ZApiResponse>

export async function getTemplateDetailsList (
  onSuccess: (reportTemplateData: ReportData[], httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = '/report-service/api/v1/udp/reportService/reportTemplates'

  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        if (
          parseResponse.data.data !== null &&
          parseResponse.data.data !== undefined
        ) {
          const reportList = parseResponse.data.data.map(item => ({ ...item, reportType: item.reportType.toLowerCase() }))
          onSuccess(reportList, parseResponse.data.http_status)
        } else {
          onFailure(
            parseResponse.data.error_message,
            parseResponse.data.http_status
          )
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.message, error.response.status)
        }
      } else {
        onFailure(error.message, error.status)
      }
    })
}
