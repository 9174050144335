import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export const ZProtocolsDetails = z.object({
  protocolId: z.string(),
  connectionName: z.string(),
  description: z.string().nullable(),
  protocolTypeId: z.string(),
  protocolTypeName: z.string(),
  protocolName: z.string(),
  ipAddress: z.string().nullable(),
  port: z.number().nullable(),
  userName: z.string().nullable(),
  password: z.string().nullable(),
  folderPath: z.string().nullable(),
  tenantId: z.string().nullable(),
  sshKey: z.string().nullable(),
  publicKey: z.string().nullable(),
  modifiedAt: z.string().nullable(),
  baseUrl: z.string().nullable(),
  apiToken: z.string().nullable(),
  authType: z.string().nullable(),
  fileTypeName: z.string().nullable(),
  fileEncryptFormat: z.string().nullable()
})

export const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(ZProtocolsDetails)
})

export type ProtocolsDetails = z.infer<typeof ZProtocolsDetails>
export type ZProtocolsDetailsApiResponse = z.infer<typeof ZApiResponse>

export async function getProtocolDetailsById (
  protocolId: string,
  onSuccess: (protocolsDetailsData: ProtocolsDetails, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/app-config-service/api/v1/udp/configure-protocol/${protocolId}`
  interceptedAxios
    .get(url, { timeout: 10000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (parseResponse.data.data !== undefined && parseResponse.data.data !== null) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.response.statusText, error.response.status)
        }
      } else {
        onFailure('Network Failure', error.status)
      }
    })
}
