import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type reportSchedulerDataObj } from '../services/getSchedulerHistoryByIdService'

export interface SchdulerHistoryListDataState {
  isLoading: boolean
  loadingError: string | undefined
  SchdulerHistoryListData: reportSchedulerDataObj[] | undefined
  httpStatus: number | undefined
  totalCount: number
  successCount: number
  errorCount: number
}

// Initial state of the slice
const initialState: SchdulerHistoryListDataState = {
  isLoading: true,
  loadingError: undefined,
  SchdulerHistoryListData: undefined,
  httpStatus: undefined,
  totalCount: 0,
  successCount: 0,
  errorCount: 0
}

// Slice to return from action
export const SchdulerHistoryListingDataState = createSlice({
  name: 'SchdulerHistoryListData',
  initialState,
  reducers: {
    SchdulerHistoryListLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: undefined,
        SchdulerHistoryListData: undefined,
        httpStatus: 0,
        totalCount: 0,
        successCount: 0,
        errorCount: 0
      }
    },
    SchdulerHistoryListUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: undefined,
        SchdulerHistoryListData: action.payload.data,
        httpStatus: action.payload.httpStatus,
        totalCount: action.payload.total_count,
        successCount: action.payload.successCount,
        errorCount: action.payload.errorCount
      }
    },
    SchdulerHistoryListErrorAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        httpStatus: action.payload.httpStatus,
        SchdulerHistoryListData: undefined,
        totalCount: 0,
        successCount: 0,
        errorCount: 0
      }
    }
  }
})

// Actions to export
export const {
  SchdulerHistoryListLoadingAction,
  SchdulerHistoryListUpdateAction,
  SchdulerHistoryListErrorAction
} = SchdulerHistoryListingDataState.actions

export default SchdulerHistoryListingDataState.reducer
