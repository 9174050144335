import { createSlice } from '@reduxjs/toolkit'

interface regInterface {
  tableData: any[]
  isLoading: boolean
  errorMessage: string | null
  deviceCount: number
  httpStatus: number | null
}

const initialState: regInterface = {
  tableData: [],
  isLoading: true,
  errorMessage: null,
  deviceCount: 0,
  httpStatus: null
}

export const regTableDataSlice = createSlice({
  name: 'registerTableData',
  initialState,
  reducers: {
    addRegTableData: (state, action: any) => {
      return {
        tableData: action.payload.data,
        isLoading: false,
        errorMessage: null,
        deviceCount: action.payload.count,
        httpStatus: action.payload.httpStatus
      }
    },
    RegTableDataLoading: () => {
      return {
        tableData: [],
        isLoading: true,
        errorMessage: null,
        deviceCount: 0,
        httpStatus: 0
      }
    },
    RegTableDataError: (state, action: any) => {
      return {
        tableData: [],
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        deviceCount: 0,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const { addRegTableData, RegTableDataLoading, RegTableDataError } = regTableDataSlice.actions
export default regTableDataSlice.reducer
