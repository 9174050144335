import React from 'react'
import xlsIcon from '../../../../assets/xlsIcon.svg'
import { type ReportContentColumn, type CreateColumnContent } from '../../types'
import { FormHelperText, TextField } from '@mui/material'
import { GenXDropdown, type Option } from '../../../../components/GenXDropdown'
import LanguageStrings from '../../../../i18n/locales'
import { isValidText } from '../../../../utils/validator'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../../store'

const ModifyReportContentFormStrings = LanguageStrings().DataPublisherStrings.CreateNewReportTemplateComponents.ModifyReportContentForm

const textStyle = {
  root: {
    backgroundColor: '#272727',
    '& .MuiInputBase-input': {
      color: '#F0F0F0',
      backgroundColor: '#404040'
    },
    '& .MuiInputLabel-root': {
      color: '#F0F0F0',
      '&.Mui-focused': {
        color: '#F0F0F0'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00000000'
      },
      '&:hover fieldset': {
        borderColor: '#00000000'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00000000'
      }
    }
  }
}

interface ParentProps {
  fieldOptions: ReportContentColumn[]
  reportContent: CreateColumnContent[]
  setReportContent: React.Dispatch<React.SetStateAction<CreateColumnContent[]>>
  width: string
  sx?: React.CSSProperties
  error: boolean
  setError: React.Dispatch<React.SetStateAction<boolean>>
  reportName: string
  source: 'ReportTemplateDetails' | 'CreateNewReportTemplate'
}

const ModifyReportContentForm = (props: ParentProps): JSX.Element => {
  const [colErrorInit, setColErrorInit] = React.useState<boolean[]>([])
  const fieldOptions = props.fieldOptions.map(option => {
    return { label: option.columnDisplayName, value: option.columnId }
  })
  const [fieldAvailableOptions] = React.useState<Option[]>(fieldOptions)
  const reportContentColumns = useSelector((state: RootState) => state.reportContentColumns)
  const handleFieldSelect = (selectedValue: Option, index: number): void => {
    console.log(fieldOptions, 'selectedValue')
    props.setReportContent((prevState) => {
      const temp = [...prevState]
      temp[index].columnName = reportContentColumns.reportContentColumn.filter((column) => column.columnId === selectedValue.value)[0].columnName
      temp[index].columnDisplayName = selectedValue.label
      temp[index].columnId = selectedValue.value
      return temp
    })
  }

  const handleAddField = (): void => {
    if (checkAllOptionsUsed()) {
      return
    }
    setColErrorInit((prevState) => {
      const temp = [...prevState]
      temp.push(false)
      return temp
    })
    props.setReportContent((prevState) => {
      const temp = [...prevState]
      temp.push({ columnHeaderName: '', columnDisplayName: '', columnName: '', columnOrder: props.reportContent.length, columnId: '' })
      return temp
    })
  }

  const handleRemoveField = (index: number): void => {
    const nameError = isNameNotValid(props.reportName)
    for (const report of props.reportContent) {
      if (report.columnOrder !== index) {
        const temp = nameError || (isNameNotValid(report.columnHeaderName) && colErrorInit[index])
        props.setError(temp)
        if (temp) {
          break
        }
      }
    }
    setColErrorInit((prevState) => {
      const temp = [...prevState]
      temp.splice(index, 1)
      return temp
    })
    props.setReportContent((prevState) => {
      const temp = [...prevState]
      temp.splice(index, 1)
      for (let i = index; i < temp.length; i++) {
        temp[i].columnOrder = i
      }
      return temp
    })
  }

  const isNameNotValid = (name: string): boolean => {
    return (!isValidText(name) || (name.length > 60))
  }

  const getOptions = (index: number): Option[] => {
    const temp = [...fieldAvailableOptions]
    for (const report of props.reportContent) {
      if (report.columnOrder !== index) {
        const index = temp.findIndex((option) => option.value === report.columnName)
        if (index !== -1) {
          temp.splice(index, 1)
        }
      }
    }
    const reportColumnDisplayNames = props.reportContent.map(report => report.columnDisplayName)
    const filteredOptions = temp.filter(option => !reportColumnDisplayNames.includes(option.label))
    return filteredOptions
  }

  const getLabelForDropdownList = (value: string): string => {
    const option = fieldOptions.find((item) => item.value === value)
    return option !== undefined ? option.label : ''
  }

  const checkAllOptionsUsed = (): boolean => {
    return props.reportContent.length === fieldOptions.length
  }

  return (
    <div style={{ border: '1px solid #101010', marginTop: '1rem', backgroundColor: '#272727', borderRadius: '10px', overflow: 'visible', ...props.sx }}>
      <div style={{ padding: '10px 1em', backgroundColor: '#313030', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>{ModifyReportContentFormStrings.Report_Contents}</div>
      <div style={{ padding: '1em', borderBottom: '1px solid #101010' }}>
        <span>
          <img style={{ borderRadius: '10px', border: '2px solid #64c3ff', cursor: 'pointer' }} src={xlsIcon} alt='XLS-Icon' />
        </span>
      </div>
      <div style={{ margin: '1rem' }}>
        <div style={{ padding: '10px 1em', backgroundColor: '#313030', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>{ModifyReportContentFormStrings.Report_Contents}</div>
        <table id='report-content-details-table' style={{ display: 'flex', flexDirection: 'column', border: '2px solid #202020', width: props.width, overflowY: 'hidden', overflowX: 'auto', backgroundColor: '#272727', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', tableLayout: 'fixed', height: '260px' }}>
          <tr>
            {
              props.reportContent.map((field: CreateColumnContent, index: number) => {
                return (
                  <th key={index} style={{ color: '#f0f0f0', border: '2px solid #202020', backgroundColor: '#272727', whiteSpace: 'nowrap', width: '280px', minWidth: '280px', maxWidth: '280px', textAlign: 'left', padding: '8px 10px 8px 15px', borderLeft: 'none', lineHeight: 0, position: 'relative' }}>
                    <span>
                      <TextField
                        id={`fieldname-${index}`}
                        variant="outlined"
                        data-testid='group-name'
                        placeholder='Enter Field'
                        InputLabelProps={{
                          disabled: true
                        }}
                        disabled={field.columnId === '6bcd01e3-cb97-40cf-94fa-7ae258edcbfc'}
                        value={field.columnHeaderName}
                        onChange={(e) => {
                          if (!colErrorInit[index]) {
                            setColErrorInit((prevState) => {
                              const temp = [...prevState]
                              temp[index] = true
                              return temp
                            })
                          }
                          props.setReportContent((prevState) => {
                            const temp = [...prevState]
                            temp[index].columnHeaderName = e.target.value
                            if (isNameNotValid(e.target.value)) {
                              props.setError(true)
                            } else {
                              const nameError = isNameNotValid(props.reportName)
                              for (const report of temp) {
                                if (report.columnOrder !== index) {
                                  props.setError(nameError || (isNameNotValid(e.target.value) && colErrorInit[index]))
                                  break
                                }
                              }
                            }
                            return temp
                          })
                        }}
                        size='small'
                        autoComplete='off'
                        className='custom-textfield'
                        sx={{
                          ...textStyle.root,
                          width: '224px',
                          border: (isNameNotValid(field.columnHeaderName) && colErrorInit[index]) ? '1px solid #EE3426' : 'none'
                        }}
                      />
                      {(isNameNotValid(field.columnHeaderName) && colErrorInit[index]) && (
                        <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', width: '70%' }} className="errorColor">
                          Invalid Name
                        </FormHelperText>
                      )}
                    </span>
                    {field.columnId !== '6bcd01e3-cb97-40cf-94fa-7ae258edcbfc' ? <span onClick={() => { handleRemoveField(index) }} className='crossIcon' style={{ fontSize: 50, fontWeight: 100, paddingLeft: (isNameNotValid(field.columnHeaderName) && colErrorInit[index]) ? 230 : 10, cursor: 'pointer', position: 'absolute', top: 27 }} data-testid={`cross-${index}`}></span> : <></>}
                  </th>
                )
              })
            }
            <th></th>
          </tr>
          <tr>
            {
              props.reportContent.map((field: CreateColumnContent, index: number) => {
                return (
                  <td key={index} style={{ color: '#f0f0f0', border: '2px solid #202020', whiteSpace: 'nowrap', width: '280px', minWidth: '280px', maxWidth: '280px', textAlign: 'left', padding: '8px 15px', borderTop: 'none', borderLeft: 'none' }}>
                    <GenXDropdown width={224} disabled={field.columnId === '6bcd01e3-cb97-40cf-94fa-7ae258edcbfc'} placeholder={ModifyReportContentFormStrings.Select_Column} value={ field.columnId !== '' ? { label: getLabelForDropdownList(field.columnId), value: field.columnId } : null}
                      options={getOptions(index)} onSelect={(selectedValue: Option) => { handleFieldSelect(selectedValue, index) }}
                    />
                  </td>
                )
              })
            }
            <td id='plusCell'>
              <span onClick={handleAddField} className='plusIcon' style={{ fontSize: 40, fontWeight: 300, cursor: 'pointer' }} data-testid='plus'></span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  )
}

export default ModifyReportContentForm
