/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react'
import Pagination from '../../../../components/TablePagination'
import { type SnackbarOrigin } from '@mui/material/Snackbar'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import { visuallyHidden } from '@mui/utils'
import LanguageStrings from '../../../../i18n/locales'
import { CircularProgress } from '@mui/material'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import { parseDateInAOHstandard } from '../../../../customHooks/parseDate'
import { type AssetType } from '../../../../utils/NetworkGraphUtils'
import { type eventsInterface, type deviceEvent } from '../../redux/eventsSlice'
export interface State extends SnackbarOrigin {
  open: boolean
}
interface Data {
  register: string
  measurementPeriod: Date | null
  eventType: string
  collected: string
  calculated: string
  deltaValue: string
  lastUpdated: Date | null
}

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

type Order = 'asc' | 'desc'
interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

const TableStrings = LanguageStrings().DatasourceStrings.DeviceTableEvents

function EnhancedTableHead (props: any): any {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, deviceList /*, assetType */ } = props
  const headCells: readonly HeadCell[] = [
    {
      id: 'event',
      numeric: false,
      disablePadding: false,
      label: TableStrings.event
    },
    {
      id: 'eventTime',
      numeric: false,
      disablePadding: false,
      label: TableStrings.eventTime
    },
    {
      id: 'collectedTime',
      numeric: false,
      disablePadding: false,
      label: TableStrings.collectedTime
    }
  ]
  const createSortHandler =
        (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
          onRequestSort(event, newOrderBy)
        }
        // istanbul ignore next
  if (deviceList !== null && deviceList !== undefined) {
    return (
        <TableHead data-testid="table-header">
            <TableRow>
                <StyledTableCell padding="checkbox" style={{ display: 'none' }} >
                    <Checkbox
                        sx={{ color: '#909090' }}
                        data-testid="device-table-header-checkbox"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                          'aria-label': 'select all devices', id: 'select-all-items'
                        }}
                    />
                </StyledTableCell>
                {headCells.map((headCell: HeadCell, index) => (
                    <StyledTableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {index < 0
                        ? (
                            headCell.label
                          )
                        : (
                        <TableSortLabel
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            aria-label={headCell.label}
                            data-testid="test-sort"

                        >
                            {headCell.label}
                            {orderBy === headCell.id
                              ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                                )
                              : null}
                        </TableSortLabel>
                          )}
                    </StyledTableCell>
                ))}
            </TableRow>
        </TableHead>
    )
  }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

interface Sort {
  order: Order
  orderBy: string
}

interface DeviceEventsParentProps {
  fetchEventsTableData: (assetId: string, page: number, rowsPerPage: number, tenantId: string, sortdir: string, sortfield: string) => void
  tenantId: string
  assetId: string
  assetType: AssetType
  tableData: eventsInterface
}

const EventsList = (props: DeviceEventsParentProps): JSX.Element => {
  const rowsPerPage = 50
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({ order: 'desc', orderBy: 'recordedAt' })
  const [deviceList, setEventsList] = React.useState<deviceEvent[]>([])

  React.useEffect(() => {
    props.fetchEventsTableData(props.assetId, page, rowsPerPage, props.tenantId, sort.order, sort.orderBy)
  }, [page, sort])

  const handleRetry = (): any => {
    props.fetchEventsTableData(props.assetId, page, rowsPerPage, props.tenantId, sort.order, sort.orderBy)
  }

  React.useEffect(() => {
    setEventsList(props.tableData.events || [])
  }, [props.tableData])

  // istanbul ignore next
  const handleRequestSort =
        (event: React.MouseEvent<unknown>, newOrderBy: keyof Data): any => {
          const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
          const toggledOrder = isAsc ? 'desc' : 'asc'
          setSort({ order: toggledOrder, orderBy: newOrderBy })
        }

  // istanbul ignore next
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): any => {
    if (event.target.checked) {
      const newSelected: any = deviceList.map((n: any) => n.deviceId)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& th': {
      border: '1px solid #202020'
    }
  }))

  const isSelected = (name: string): any => selected.includes(name)

  if (props.tableData !== undefined && props.tableData.isLoading) {
    return (
      <div className="CircularprogressAmi">
        <CircularProgress />
      </div>
    )
    // istanbul ignore next
  } else if (props.tableData !== undefined && props.tableData.httpStatus === 401) {
    return (
      <div className="authError">
        <AuthError errorMessage="Unauthorized" />
      </div>
    )
    // istanbul ignore next
  } else if (props.tableData !== undefined && props.tableData.httpStatus === 403) {
    return (
      <div className="authError">
        <AuthError errorMessage="accessForbidden" />
      </div>
    )
    // istanbul ignore next
  } else if (props.tableData !== undefined && props.tableData.httpStatus === 200 && props.tableData.errorMessage === null && props.tableData.deviceCount === 0) {
    return (
      <div className="authError">
        <AuthError errorMessage="NoDataPresent" />
      </div>
    )
    // istanbul ignore next
  } else if (props.tableData !== undefined && props.tableData.httpStatus === 404 && props.tableData.errorMessage === 'No data found') {
    return (
      <div className="authError">
        <AuthError errorMessage="NoDataPresent" />
      </div>
    )
    // istanbul ignore next
  } else if (
    props.tableData !== undefined &&
    props.tableData.events !== null &&
    props.tableData.events !== undefined &&
    props.tableData.httpStatus === 200
  ) {
    return (
      <>
        <Box sx={style} className="devices">
          <Paper
            sx={{
              width: '100%',
              mb: 2,
              backgroundColor: '#272727',
              boxShadow: 'unset',
              color: '#F0F0F0',
              marginBottom: '0'
            }}
          >
            <TableContainer className="device-table" data-testid="deviceEventsTable" >
              <Table
                sx={{ minWidth: 750, color: '#f0f0f0' }}
                aria-labelledby="tableTitle"
                size={'small'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={sort.order}
                  orderBy={sort.orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={5}
                  deviceList={props.tableData.events}
                  assetType={props.assetType}
                />
                <TableBody>
                  {props.tableData.events &&
                    props.tableData.events.map((row: any, index: any) => {
                      const isItemSelected = isSelected(row.id)
                      const labelId = `enhanced-table-checkbox-${index}`

                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.key}
                          aria-label="devices-list-row"
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <StyledTableCell
                            padding="checkbox"
                            style={{ display: 'none' }}
                          >
                            <Checkbox
                              sx={{ color: '#909090' }}
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                                'aria-label': 'test-checkbox',
                                id: `select-item-${index}`
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            aria-label="device-name"
                            data-testid="device-name"
                            sx={{ color: '#64C3FF !important' }}
                          >
                            {row.event}
                          </StyledTableCell>
                          <StyledTableCell>
                            {parseDateInAOHstandard(row.eventTime)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {parseDateInAOHstandard(row.collectedTime)}
                          </StyledTableCell>
                          <StyledTableCell>{row.calculatedValue}</StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {props.tableData.deviceCount > 10 && (<Pagination
              count={props.tableData.deviceCount}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              siblingCount={1}
              boundaryCount={1}
            />)}
          </Paper>
        </Box>
      </>
    )
  } else {
    return (
      <div className="authError">
        <AuthError errorMessage="cannotFetch" retry={handleRetry} />
      </div>
    )
  }
}

export default EventsList
