import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZData = z.object({
  seqnum: z.number(),
  last_seqnum: z.number(),
  first_seqnum: z.number(),
  file_name: z.string(),
  file_publishing_status: z.number(),
  file_created: z.string(),
  file_transferred: z.string().nullable(),
  retries: z.number().nullable(),
  file_type: z.string(),
  publisher_id: z.number(),
  tenant_id: z.string()
})

const ZConfigurationTemplate = z.object({
  total_count: z.number(),
  statusfiles: z.array(ZData)
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZConfigurationTemplate.nullable()),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type FileStatus = z.infer<typeof ZConfigurationTemplate>
export type FileStatusData = z.infer<typeof ZData>

export async function getAllFiles (
  page: number,
  size: number,
  tenantId: string,
  onSuccess: (deviceList: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/datapublisher/api/v1/udp/datapublisher/statusfiles?tenantId=${tenantId}&page=${page}&size=${size}`
  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, parseResponse.data.http_status)
          } else {
            onFailure(parseResponse.data.error, parseResponse.data.http_status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      onFailure(error.response !== undefined ? error.response.statusText : 'Network Error', error.response !== undefined ? error.response.status : 404)
    })
}
