import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type FileStatus } from '../services/fileStatusService'

export interface AllFileStatus {
  seqnum: number
  last_seqnum: number
  first_seqnum: number
  file_name: string
  file_publishing_status: number
  file_created: string
  file_transferred: string
  retries: number
  file_type: string
  publisher_id: number
  tenant_id: string
}

export interface FileStatusState {
  isLoading: boolean
  error: string | null
  fileStatusData: FileStatus | null
  httpStatus: number | null
}

const initialState: FileStatusState = {
  isLoading: true,
  error: null,
  fileStatusData: null,
  httpStatus: null
}

const allConfigSlice = createSlice({
  name: 'allConfig',
  initialState,
  reducers: {
    fileStatusDataLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        fileStatusData: null,
        httpStatus: 0
      }
    },
    fileStatusDateUpdateAction: (state, action: PayloadAction<{ data: FileStatus, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        fileStatusData: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    fileStatusDataErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        fileStatusData: null,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  fileStatusDataLoadingAction,
  fileStatusDateUpdateAction,
  fileStatusDataErrorAction
} = allConfigSlice.actions

export default allConfigSlice.reducer
