import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export const templateIdSlice = createSlice({
  name: 'templateIdSlice',
  initialState: '',
  reducers: {
    changeTemplateId: (state, action: PayloadAction<string>) => {
      return action.payload
    }
  }
})

export const { changeTemplateId } = templateIdSlice.actions
export default templateIdSlice.reducer
