import { Box, Card, CardContent, CircularProgress, Grid, Tab } from '@mui/material'
import React, { useEffect } from 'react'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { fetchAssetAttribute, fetchAssetAttrParameter } from '../../redux/actionCreator'
import { type RootState } from '../../../../store'
import { type AssetAttrParameterState } from '../../redux/assetAttrParameterSlice'
import { connect, type ConnectedProps } from 'react-redux'
import { type AssetByIdState } from '../../redux/assetByIdSlice'
import { type AssetAttributeState } from '../../redux/assetAttributeSlice'
import AssetAttrParameter from './AssetAttrParameter'

const AssetAttribute = (props: AllProps): JSX.Element => {
  const [configurationId, setConfigurationId] = React.useState<string>('')
  const paramAssetId = sessionStorage.getItem('paramAssetId')
  const [page, setPage] = React.useState(1)
  const [rowsPerPage] = React.useState(10)
  const paramAssetName = sessionStorage.getItem('paramAssetName')

  // Ensure value is initialized properly based on asset attributes
  useEffect(() => {
    if (props.assetAttribute.assetAttribute != null && props.assetAttribute.assetAttribute.length > 0) {
      setConfigurationId(props.assetAttribute.assetAttribute[0].configurationId)
    }
  }, [props.assetAttribute.assetAttribute])

  // istanbul ignore next
  const handleChange = (event: React.SyntheticEvent, newValue: string): void => {
    setConfigurationId(newValue)
  }

  // istanbul ignore next
  useEffect(() => {
    if (paramAssetId !== null && (props.assetAttribute.assetAttribute != null)) {
      if (configurationId !== '' && page >= 1) {
        props.fetchAssetAttrParameter(paramAssetId, configurationId, page - 1, rowsPerPage)
      }
    }
  }, [page])

  // istanbul ignore next
  useEffect(() => {
    if (page === 1) {
      if (paramAssetId !== null && (props.assetAttribute.assetAttribute != null)) {
        if (configurationId !== '' && page >= 1) {
          props.fetchAssetAttrParameter(paramAssetId, configurationId, 0, rowsPerPage)
        }
      }
    } else {
      setPage(1)
    }
  }, [configurationId])

  // istanbul ignore next
  function handleAssetAttributeParam (): JSX.Element {
    if (props.assetAttrParameterData.isLoading) {
      return (
        <div className='deviceDetailsProgress'>
          <CircularProgress data-testid='deviceDetailsProgress' size={'3rem'} />
        </div>
      )
    } else if (props.assetAttrParameterData.httpStatus === 400) {
      return <div className='authError'><AuthError errorMessage="NoDataPresent" /></div>
    } else if (props.assetAttrParameterData.httpStatus === 401) {
      return <div className='authError'><AuthError errorMessage="Unauthorized" /></div>
    } else if (props.assetAttrParameterData.httpStatus === 403) {
      return <div className='authError'><AuthError errorMessage="Access Forbidden" /></div>
    } else if (props.assetAttrParameterData.httpStatus === 200) {
      return (
        <AssetAttrParameter
          configurationId={configurationId}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
        />
      )
    } else {
      return <div className='authError'><AuthError retry={undefined} errorMessage="Cannot fetch data" /></div>
    }
  }

  // istanbul ignore next
  function handleAssetAttribute (): JSX.Element {
    if (props.assetAttribute.isLoading) {
      return (
        <div className='deviceDetailsProgress'>
          <CircularProgress data-testid='deviceDetailsProgress' size={'3rem'} />
        </div>
      )
    } else if (props.assetAttribute.httpStatus === 401) {
      return <div className='authError'><AuthError errorMessage="Unauthorized" /></div>
    } else if (props.assetAttribute.httpStatus === 200 && props.assetAttribute.assetAttribute?.length === 0) {
      return <div className='authError'><AuthError className={'errorComp'} customAssetConfigError={`No Data Present for ${paramAssetName ?? ''}`}/></div>
    } else if (props.assetAttribute.httpStatus === 403) {
      return <div className='authError'><AuthError errorMessage="Access Forbidden" /></div>
    } else if (props.assetAttribute.httpStatus === 200) {
      return (
        <TabList onChange={handleChange} aria-label="asset attribute tabs">
              {props.assetAttribute.assetAttribute?.map((attribute) => (
                <Tab
                  data-testId='attrValue'
                  key={attribute.configurationId}
                  sx={{ color: 'gray', fontSize: '12px', textTransform: 'none' }}
                  label={attribute.configurationCategory}
                  value={attribute.configurationId}
                />
              ))}
          </TabList>
      )
    } else {
      return <div className='authError'><AuthError retry={undefined} errorMessage="Cannot fetch data" /></div>
    }
  }

  return (
    <div>
      <Box sx={{ width: '100%', backgroundColor: '#272727', padding: '16px', borderRadius: '8px', boxShadow: 'none', margin: '0px', border: 'px solid #101010' }}>
        {props.assetAttribute.httpStatus === 200 && props.assetAttribute.assetAttribute?.length !== 0 && (
        <h2 style={{ fontSize: '16px', marginBottom: '16px' }}>Asset Attributes</h2>
        )}
        <TabContext value={configurationId}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            {handleAssetAttribute()}
          </Box>

          {props.assetAttribute.assetAttribute?.map((attribute) => (
            <TabPanel sx={{ padding: '0px 0px' }} key={attribute.configurationId} value={attribute.configurationId}>
              <Grid item xs={12} sm={12} className='active-issues'>
                <Card sx={{ backgroundColor: '#00000000', boxShadow: 'none' }}>
                  <CardContent sx={{ padding: '0' }}>
                    {handleAssetAttributeParam()}
                  </CardContent>
                </Card>
              </Grid>
            </TabPanel>
          ))}
        </TabContext>
      </Box>
      <br />
    </div>
  )
}

interface DispatchToProps {
  fetchAssetAttrParameter: (assetId: string, configurationId: string, page: number, size: number) => void
  fetchAssetAttribute: (assetId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAssetAttrParameter: (assetId: string, configurationId: string, page: number, size: number) => dispatch(fetchAssetAttrParameter(assetId, configurationId, page, size)),
  fetchAssetAttribute: (assetId: string) => dispatch(fetchAssetAttribute(assetId))
})

interface StateToProps {
  assetAttrParameterData: AssetAttrParameterState
  assetData: AssetByIdState
  assetAttribute: AssetAttributeState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assetAttrParameterData: state.assetAttrParameter,
  assetData: state.assetById,
  assetAttribute: state.assetAttributes
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = PropsFromRedux

export default connector(AssetAttribute)
