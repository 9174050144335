import { Grid } from '@mui/material'
import React from 'react'
import { type ConfigurationDetails } from '../../services/configDetailsService'
import LanguageStrings from '../../../../i18n/locales'

const AssetConfigurationSummaryStrings = LanguageStrings().AssetConfigurationStrings.components.AssetDetailsComponents.AssetConfigurationSummary

const headingStyle: React.CSSProperties = {
  fontWeight: 600,
  fontSize: '20px',
  lineHeight: '30px'
}

const valueStyle: React.CSSProperties = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px'
}

const AssetConfigurationSummary = (props: { configDetails: ConfigurationDetails }): JSX.Element => {
  return (
    <Grid container style={{ paddingTop: '10px', paddingLeft: '0px' }}>
      <Grid container>
        <Grid container className='assetSummaryRow'>
          <Grid item xs={12} sm={3}>
            <Grid style={headingStyle}>{AssetConfigurationSummaryStrings.Name}</Grid>
            <Grid style={valueStyle}>
              { props.configDetails.template_name }
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid style={headingStyle}>{AssetConfigurationSummaryStrings.Status}</Grid>
            <Grid style={valueStyle}>{props.configDetails.status !== null ? props.configDetails.status : AssetConfigurationSummaryStrings.Not_Available}</Grid>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Grid style={headingStyle}>{AssetConfigurationSummaryStrings.Asset_Type}</Grid>
            <Grid style={valueStyle}>{props.configDetails.asset_type_name !== undefined ? props.configDetails.asset_type_name.toUpperCase() : ''}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AssetConfigurationSummary
