import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'
import LanguageStrings from '../../../i18n/locales'

const ZUploadAssetProfile = z.object({
  message: z.string()
})

export interface uploadAssetProfileReq {
  deviceType: string
  meterSize: string
  uploadedBy: string
  uploadType: string
}

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: ZUploadAssetProfile
})
const ZApiFailResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  error_message: z.string()
})

const APIErrorStrings = LanguageStrings().ErrorStrings

export type UploadAssetProfile = z.infer<typeof ZUploadAssetProfile>

export async function uploadAssetProfile (
  assetProfileRequestParams: uploadAssetProfileReq,
  file: File,
  onSuccess: (message: string, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const formData = new FormData()
  const blob = new Blob([file], { type: 'application' })
  formData.append('file', blob, file.name)
  formData.append('fileUpload', '' + JSON.stringify(assetProfileRequestParams))

  const url = '/fileuploadservice/api/v1/udp/asset-profile/uploadProfile'
  interceptedAxios.post(url, formData)
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        const parsedData: string = parseResponse.data.data.message
        onSuccess(parsedData, response.status)
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== null && error.response !== undefined) {
        if (error.response.status === 502) {
          onFailure(APIErrorStrings.BadGateway, error.response.status)
        } else if (error.response.status === 404) {
          onFailure(error.message, error.response.status)
        } else {
          if (error.response.data !== null && Object.prototype.toString.call(error.response.data) === '[object String]') {
            onFailure(error.response.data, error.response.status)
          } else {
            const parseResponse = ZApiFailResponse.safeParse(error.response.data)
            if (parseResponse.success) {
              const parsedData = parseResponse.data.error_message
              onFailure(parsedData, error.response.status)
            } else {
              onFailure(parseResponse.error.message, error.response.status)
            }
          }
        }
      } else {
        onFailure(error.message, error.response.status)
      }
    })
}
