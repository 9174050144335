/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface provisioningHistoryDetailsStateFilterState {
  isLoading: boolean
  loadingError: string | null
  provisioningHistoryDetailsStateFilter: string[] | []
  httpStatus: number | null
}

// Initial state of the slice
const initialState: provisioningHistoryDetailsStateFilterState = {
  isLoading: true,
  loadingError: null,
  provisioningHistoryDetailsStateFilter: [],
  httpStatus: null
}

// Slice to return from action
export const provisioningHistoryDetailsStateFilterSlice = createSlice({
  name: 'provisioningHistoryDetailsStateFilter',
  initialState,
  reducers: {
    provisioningHistoryDetailsStateFilterLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        provisioningHistoryDetailsStateFilter: [],
        httpStatus: 0
      }
    },
    provisioningHistoryDetailsStateFilterUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: null,
        provisioningHistoryDetailsStateFilter: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    provisioningHistoryDetailsStateFilterErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        provisioningHistoryDetailsStateFilter: []
      }
    }
  }
})

// Actions to export
export const {
  provisioningHistoryDetailsStateFilterLoadingAction,
  provisioningHistoryDetailsStateFilterUpdateAction,
  provisioningHistoryDetailsStateFilterErrorAction
} = provisioningHistoryDetailsStateFilterSlice.actions

export default provisioningHistoryDetailsStateFilterSlice.reducer
