import React from 'react'
import SearchBox from '../../../../components/SearchBox'
import { GenxList } from '../../../../components/GenXList'
import { type ReportData } from '../../types'
import LanguageStrings from '../../../../i18n/locales'

const ReportTemplateListStrings = LanguageStrings().DataPublisherStrings.ReportTemplateComponents.ReportTemplateList

interface ParentProps {
  selectedTemplate: ReportData | undefined
  templateDetailsList: ReportData[]
  setSelectedTemplate: React.Dispatch<React.SetStateAction<ReportData | undefined>>
  reportTemplateDetailsRef: React.RefObject<HTMLDivElement>
  editEnabled: boolean
}

const ReportTemplateList = (props: ParentProps): JSX.Element => {
  const [searchVal, setSearchVal] = React.useState<string>('')
  const [filteredTemplateList, setFilteredTemplateList] = React.useState<ReportData[]>(props.templateDetailsList)
  const [height, setHeight] = React.useState<string>('60vh')
  const handleSearch = (val: string): void => {
    setSearchVal(val)
  }

  React.useEffect(() => {
    if (props.reportTemplateDetailsRef.current !== null) {
      if (props.editEnabled) {
        setHeight(`calc(${props.reportTemplateDetailsRef.current.clientHeight - 100}px)`)
      } else {
        setHeight('60vh')
      }
    }
  }, [props.reportTemplateDetailsRef, props.editEnabled])

  const handleSearchAction = (): void => {
    const newTemplateList = props.templateDetailsList.filter((item) => item.reportName.toLowerCase().includes(searchVal.toLowerCase()))
    setFilteredTemplateList(newTemplateList)
    props.setSelectedTemplate(newTemplateList[0])
  }

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ fontSize: '16px', fontWeight: '550', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%', paddingTop: '0.8rem' }}>
          <span>{ReportTemplateListStrings.REPORT_TEMPLATE_LIST} ({props.templateDetailsList.length})</span>
        </div>
        <SearchBox immediate={true} sx={{ width: '100%' }} containerStyle={{ marginLeft: 0, paddingLeft: '2rem', width: '100%' }} placeholder={ReportTemplateListStrings.Search} searchVal={searchVal} setSearchVal={handleSearch} performAction={handleSearchAction} emptyEnterFallback={handleSearchAction} />
      </div>
      <div style={{ height, overflowY: 'scroll' }}>
        <GenxList ulStyle={{ marginLeft: '10px' }} selected={props.selectedTemplate} onClick={(templateName: string) => { props.setSelectedTemplate(props.templateDetailsList.filter((item) => item.reportName === templateName)[0]) }} datalist={filteredTemplateList.map((item) => {
          return { name: item.reportName, value: item.reportName }
        })} />
      </div>
    </div>
  )
}

export default ReportTemplateList
