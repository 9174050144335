import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export const activeIssuesCountSlice = createSlice({
  name: 'activeIssuesCountSlice',
  initialState: 0,
  reducers: {
    changeActiveIssuesCount: (state, action: PayloadAction<number>) => {
      return action.payload
    }
  }
})

export const { changeActiveIssuesCount } = activeIssuesCountSlice.actions
export default activeIssuesCountSlice.reducer
