// istanbul ignore file
import React, { useEffect, useState } from 'react'
import { type RootState } from '../../store'
import { type ConnectedProps, connect, useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import LanguageStrings from '../../i18n/locales'
import HistoryBarGraph from './components/HistoryBarGraph'
import dayjs, { type Dayjs } from 'dayjs'
import './KpiDetails.css'
import { type ToggleOption } from '../../components/GenericToggleButton'
import HistoryTable from './components/KpiComponents/HistoryTable'
import { getDetailKeyword, getDonutType, getToggleOptions } from './components/KpiComponents/kpiUtils'
import { type KpiKeyDonut, doesKpiHaveDonutChart } from './redux/changeKpiType'
import HistoryDonutKpiBarGraph from './components/HistoryDonutKpiBarGraph'
import exportIcon from '../../assets/exportIcon.svg'
import { type ExportADSKpiDetailsState } from './redux/exportADSKpiDetailsSlice'
import { type ExportARKpiDetailsState } from './redux/exportARKpiDetailsSlice'
import { type ExportASKpiDetailsState } from './redux/exportASKpiDetailsSlice'
import { exportADSKpiHistoryTable, exportASKpiHistoryTable, exportARKpiHistoryTable, exportDonutKpiHistoryTable } from './redux/exportActionCreator'
import { type ExportDonutKpiDetailsState } from './redux/exportDonutKpiDetailsSlice'
import { exportCSV } from '../../components/exportFile'
import { getColumnsByKpi } from './components/KpiComponents/HistoryTableCustomColumns'
import { type DonutKpiState } from './types'
import { exportDonutKpiDetailsResetAction } from './redux/exportDonutKpiDetailsSlice'
import { exportADSKpiDetailsResetAction } from './redux/exportADSKpiDetailsSlice'
import { exportASKpiDetailsResetAction } from './redux/exportASKpiDetailsSlice'
import { exportARKpiDetailsResetAction } from './redux/exportARKpiDetailsSlice'
import { convertDateIntoAohStandardUnSeperated } from '../../customHooks/parseDate'
import { resetKpiDetails } from './redux/actionCreators'
const KpiDetailsStrings = LanguageStrings().AMIDataCollectionStrings.KpiDetails

const defaultDate = {
  end: dayjs(new Date()),
  start: dayjs(new Date((new Date()).setMonth(new Date().getMonth() - 1)))
}

export interface BarGraphParameter {
  date: Dayjs
  bar: ToggleOption
}

const KpiDetails = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const selectedKpi = useSelector((state: RootState) => state.kpiType)
  const [selectedDonut, setSelectedDonut] = useState<number>(0)
  const [barGraphParameter, setBarGraphParameter] = useState<BarGraphParameter>({ date: defaultDate.end, bar: getToggleOptions(selectedKpi)[0] })
  const [startDate, setStartDate] = useState<Dayjs>(defaultDate.start)
  const [endDate, setEndDate] = useState<Dayjs>(defaultDate.end)
  const [initModification, setInitModification] = useState<boolean>(false)
  const [detailKeyword, detailService] = getDetailKeyword(selectedKpi, barGraphParameter.bar.value)
  const [page, setPage] = React.useState(1)
  const rowsPerPage = 10
  const dispatch = useDispatch()

  const getExportState = (): ExportADSKpiDetailsState | ExportASKpiDetailsState | ExportARKpiDetailsState | ExportDonutKpiDetailsState => {
    if (doesKpiHaveDonutChart(selectedKpi)) {
      return props.exportDonutKpiDetailData
    } else {
      if (detailService === 'assetrest') {
        return props.exportARKpiDetailData
      } else if (detailService === 'alarm-service') {
        return props.exportASKpiDetailData
      } else {
        return props.exportADSKpiDetailData
      }
    }
  }

  useEffect(() => {
    if (getExportState().httpStatus === 200) {
      const data: string[][] = []
      const headCells = getColumnsByKpi(detailKeyword)
      const headers = headCells.map(column => column.label)
      data.push(headers)
      if (doesKpiHaveDonutChart(selectedKpi)) {
        props.exportDonutKpiDetailData.exportDonutKpiDetails?.assetDetails.forEach(asset => {
          const row = headCells.map(col => {
            return (col.isDate ? convertDateIntoAohStandardUnSeperated(asset[col.id as keyof typeof asset]) : String(asset[col.id as keyof typeof asset]))
          })
          data.push(row)
        })
        dispatch(exportDonutKpiDetailsResetAction())
      } else {
        if (detailService === 'assetrest') {
          props.exportARKpiDetailData.exportARKpiDetails?.assets.forEach(asset => {
            const row = headCells.map(col => {
              return (col.isDate ? convertDateIntoAohStandardUnSeperated(asset[col.id as keyof typeof asset]) : String(asset[col.id as keyof typeof asset]))
            }).filter((item): item is string => item !== null)
            data.push(row)
          })
          dispatch(exportARKpiDetailsResetAction())
        } else if (detailService === 'alarm-service') {
          props.exportASKpiDetailData.exportASKpiDetails?.assetDetails.forEach(asset => {
            const row = headCells.map(col => {
              return (col.isDate ? convertDateIntoAohStandardUnSeperated(asset[col.id as keyof typeof asset]) : String(asset[col.id as keyof typeof asset]))
            }).filter((item): item is string => item !== null)
            data.push(row)
          })
          dispatch(exportASKpiDetailsResetAction())
        } else {
          props.exportADSKpiDetailData.exportADSKpiDetails?.assetDetails.forEach(asset => {
            const row = headCells.map(col => {
              return (col.isDate ? convertDateIntoAohStandardUnSeperated(asset[col.id as keyof typeof asset]) : String(asset[col.id as keyof typeof asset]))
            }).filter((item): item is string => item !== null)
            data.push(row)
          })
          dispatch(exportADSKpiDetailsResetAction())
        }
      }
      console.log('exporting csv', data)
      exportCSV(data, `${selectedKpi.replaceAll(' ', '-')}_${dayjs(barGraphParameter.date).format('YYYY-MM-DD')}`)
    }

    return () => {
      props.resetKpiDetails()
    }
  }, [props.exportADSKpiDetailData, props.exportASKpiDetailData, props.exportARKpiDetailData, props.exportDonutKpiDetailData])

  const exportAssets = (): void => {
    if (doesKpiHaveDonutChart(selectedKpi)) {
      console.log('exporting donut kpi history table')
      props.exportDonutKpiHistoryTable(detailKeyword, dayjs(barGraphParameter.date).format('YYYY-MM-DD'), getDonutType(selectedKpi as KpiKeyDonut, selectedDonut, props.historyDonutState), page - 1, rowsPerPage, detailService)
    } else {
      if (detailService === 'assetrest') {
        props.exportARKpiHistoryTable(detailKeyword, dayjs(barGraphParameter.date).format('YYYY-MM-DD'), page - 1, rowsPerPage, detailService)
      } else if (detailService === 'alarm-service') {
        props.exportASKpiHistoryTable(detailKeyword, dayjs(barGraphParameter.date).format('YYYY-MM-DD'), page - 1, rowsPerPage, detailService)
      } else {
        props.exportADSKpiHistoryTable(detailKeyword, dayjs(barGraphParameter.date).format('YYYY-MM-DD'), page - 1, rowsPerPage, detailService)
      }
    }
  }

  return (
    <div style={{ width: 'calc(100vw - 5.5rem)' }}>
      <div className='link-breadcrumb'>
        <span data-testid='assetManagementbreadcrumb' onClick={ () => { navigate('/isense') }} className='assetmgmtli'>{KpiDetailsStrings.Honeywell_Heading_Breadcrumb}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span>{selectedKpi}</span>
      </div>
      <div className='topUtilityBar' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <BackButton/>
          <span id='componentTitle' data-testid='kpi-details'>{selectedKpi}</span>
        </div>
        <div>
          <img onClick={() => { exportAssets() }} width={25} height={25} alt='exportIcon' src={exportIcon} id='kpi-detail-download' style={{ cursor: 'pointer' }} />
        </div>
      </div>
      <div>
        {
          doesKpiHaveDonutChart(selectedKpi)
            ? <HistoryDonutKpiBarGraph selectedDonut={selectedDonut} setSelectedDonut={setSelectedDonut} barGraphParameter={barGraphParameter} setBarGraphParameter={setBarGraphParameter} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
            : <HistoryBarGraph barGraphParameter={barGraphParameter} setBarGraphParameter={setBarGraphParameter} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
        }
      </div>
      <div style={{ backgroundColor: '#272727', border: '1px solid black', borderBottomLeftRadius: 10, borderBottomRightRadius: 10, marginBottom: 10 }}>
        <HistoryTable page={page} setPage={setPage} selectedDonut={selectedDonut} barGraphParameter={barGraphParameter} setBarGraphParameter={setBarGraphParameter} initModification={initModification} setInitModification={setInitModification} />
      </div>
    </div>
  )
}
interface DispatchToProps {
  exportADSKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => void
  exportASKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => void
  exportARKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => void
  exportDonutKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    donutType: string,
    page: number,
    size: number, service: string) => void
  resetKpiDetails: () => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  exportADSKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => dispatch(exportADSKpiHistoryTable(kpiType, inputDateRead, page, size, service)),
  exportASKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => dispatch(exportASKpiHistoryTable(kpiType, inputDateRead, page, size, service)),
  exportARKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    page: number,
    size: number, service: string) => dispatch(exportARKpiHistoryTable(kpiType, inputDateRead, page, size, service)),
  exportDonutKpiHistoryTable: (
    kpiType: string,
    inputDateRead: string,
    donutType: string,
    page: number,
    size: number, service: string) => dispatch(exportDonutKpiHistoryTable(kpiType, inputDateRead, donutType, page, size, service)),
  resetKpiDetails: () => dispatch(resetKpiDetails())
})

interface StateToProps {
  exportADSKpiDetailData: ExportADSKpiDetailsState
  exportASKpiDetailData: ExportASKpiDetailsState
  exportARKpiDetailData: ExportARKpiDetailsState
  exportDonutKpiDetailData: ExportDonutKpiDetailsState
  historyDonutState: DonutKpiState

}

const mapStateToProps = (state: RootState): StateToProps => ({
  exportADSKpiDetailData: state.exportADSKpiDetailsData,
  exportASKpiDetailData: state.exportASKpiDetailsData,
  exportARKpiDetailData: state.exportARKpiDetailsData,
  exportDonutKpiDetailData: state.exportDonutKpiDetailsData,
  historyDonutState: state.historyDonutState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(KpiDetails)
