import React, { type FC } from 'react'
import Dashboard from './Dashboard'
import AmiMeterCollection from '../../ami-meter-data-collection/AmiMeterCollection'
import DataPublisherMainComponent from '../../data-publisher/DataPublisherMainComponent'
import FileStatus from '../../data-publisher/components/fileStatus'
import AddNewConfig from '../../data-publisher/components/addNewConfig'
import DashboardLayout from './DashboardLayout'
import { Navigate, Route, Routes } from 'react-router-dom'
import axiosRetry from 'axios-retry'
import axios from 'axios'
import CardDashboard from './CardDashboard'
import Login from '../../login/Login'
import { useAuth } from 'react-oidc-context'
import { Loader } from '@scuf/common'
import { oidcConfig } from '../../../forgeiam/authConfig'
import DeviceDetails from '../../device-details/DeviceDetails'
import DeviceHistory from '../../device-provisioning/components/AssetProvisioningHistory'
import DeviceList from '../../ami-meter-data-collection/components/DeviceTable/DeviceListComponent'
import CreateConfiguration from '../../asset-configuration/CreateConfiguration'
import ConfigurationList from '../../asset-configuration/ConfigurationList'
import ConfigurationUploadStatus from '../../configuration-upload-status/ConfigurationUploadStatus'
import ConfigurationDetails from '../../configuration-upload-status/ConfigurationDetails'
import FirmwareList from '../../firmware/components/FirmwareList'
import AddFirmwareVersion from '../../firmware/components/UploadFirmware'
import { escapeHTML } from '../../../utils/escapeHTML'
import AssetConfigurationDetails from '../../asset-configuration/components/AssetConfigurationDetails'
import EditAssetSummary from '../../device-details/components/EditAssetSummary'
import CreateGroupComponent from '../../asset-group/components/CreateGroupComponent'
import AssetGroupMainComponent from '../../asset-group/AssetGroupMainComponent'
import GroupDetailsMainComponent from '../../asset-group/components/GroupDetailsMainComponent'
import CreateNewReportTemplate from '../../data-publisher/components/CreateNewReportTemplate'
import CreateNewReportScheduler from '../../data-publisher/components/ReportSchedulerComponents/CreateReportScheduler'
import EditReportScheduler from '../../data-publisher/components/ReportSchedulerComponents/EditReportScheduler'
import ConfigurationDashboard from './configurationDashboard'
import CreateSftpConfiguration from '../../data-publisher/components/SFTPConfigurationComponents/CreateSftpConfiguration'
import ConfigurationProtocol from '../../data-publisher/components/SFTPConfigurationComponents/configurationProtocol'
import EditConfigurationProtocol from '../../data-publisher/components/SFTPConfigurationComponents/editConfigurationProtocol'
import FirmwareUpgradeDetails from '../../firmware/components/FirmwareUpgradeDetails'
import FirmwareUpgradeStatus from '../../firmware/components/FirmwareUpgradeStatus'
import KpiDetails from '../../ami-meter-data-collection/KpiDetails'
import AssetProfileUpload from '../../asset-configuration/components/UploadAssetProfile'

axiosRetry(axios, { retries: 3 })

const OidcSecure: FC<any> = ({ children }) => {
  const auth = useAuth()
  const oidcStorage = localStorage.getItem(`oidc.user:${oidcConfig.authority ?? ''}:${oidcConfig.client_id ?? ''}`)
  if (auth.isLoading) {
    return <Loader><div className='placeholder'/></Loader>
  } else if (oidcStorage != null || auth.isAuthenticated) {
    return <>{children}</>
  } else {
    localStorage.setItem('redirectUri', escapeHTML(window.location.pathname))
    return (<Navigate to='/login'/>)
  }
}

const Main = (props: any): any => {
  return (
        <>
            <Routes>
                <Route path='/login' element={<Login/>} />
                <Route path='/logout' element={<Navigate to='/login'/>} />
                <Route path="/" element={<DashboardLayout />}>
                    <Route index element={<OidcSecure><Dashboard /></OidcSecure> } />
                    <Route path="isense" element={<OidcSecure><AmiMeterCollection /></OidcSecure>} />
                    <Route path="datapub" element={<OidcSecure><DataPublisherMainComponent /></OidcSecure>} />
                    <Route path="filestatus" element={<OidcSecure><FileStatus /></OidcSecure>} />
                    <Route path="addnewconfig" element={<OidcSecure><AddNewConfig /></OidcSecure>} />
                    <Route path="card" element={<OidcSecure><CardDashboard /></OidcSecure>} />
                    <Route path="devicedetails" element={<OidcSecure><DeviceDetails /></OidcSecure>} />
                    <Route path="device-history" element={<OidcSecure><DeviceHistory /></OidcSecure>} />
                    <Route path="/asset-list" element={<OidcSecure><DeviceList /></OidcSecure>} />
                    <Route path="createConfig" element={<OidcSecure><CreateConfiguration /></OidcSecure>} />
                    <Route path="/configuration" element={<OidcSecure><ConfigurationList /></OidcSecure>} />
                    <Route path="/configuration-upload-status" element={<OidcSecure><ConfigurationUploadStatus /></OidcSecure>} />
                    <Route path="/configuration-details" element={<OidcSecure><ConfigurationDetails /></OidcSecure>} />
                    <Route path="/firmware-list" element={<OidcSecure><FirmwareList /></OidcSecure>} />
                    <Route path="/firmware-upload" element={<OidcSecure><AddFirmwareVersion /></OidcSecure>} />
                    <Route path="/asset-config-details" element={<OidcSecure><AssetConfigurationDetails /></OidcSecure>} />
                    <Route path="/asset-group-list" element={<OidcSecure><AssetGroupMainComponent /></OidcSecure>} />
                    <Route path="/create-asset-group" element={<OidcSecure><CreateGroupComponent /></OidcSecure>} />
                    <Route path="/edit-asset-summary" element={<OidcSecure><EditAssetSummary /></OidcSecure>} />
                    <Route path="/asset-group-details" element={<OidcSecure><GroupDetailsMainComponent /></OidcSecure>} />
                    <Route path="/create-report-template" element={<OidcSecure><CreateNewReportTemplate /></OidcSecure>} />
                    <Route path="/create-report-scheduler" element={<OidcSecure><CreateNewReportScheduler /></OidcSecure>} />
                    <Route path="/edit-report-scheduler" element={<OidcSecure><EditReportScheduler /></OidcSecure>} />
                    <Route path="/configuration-dashboard" element={<OidcSecure><ConfigurationDashboard /></OidcSecure>} />
                    <Route path="/create-sftp-configuration" element={<OidcSecure><CreateSftpConfiguration /></OidcSecure>} />
                    <Route path="/edit-configuration-protocol" element={<OidcSecure><EditConfigurationProtocol /></OidcSecure>} />
                    <Route path="/configuration-protocol" element={<OidcSecure><ConfigurationProtocol /></OidcSecure>} />
                    <Route path="/firmware-upgrade-status" element={<OidcSecure><FirmwareUpgradeStatus /></OidcSecure>} />
                    <Route path="/kpi-details" element={<OidcSecure><KpiDetails /></OidcSecure>} />
                    <Route path="/firmware-upgrade-details" element={<OidcSecure><FirmwareUpgradeDetails /></OidcSecure>} />
                    <Route path="/asset-profile-upload" element={<OidcSecure><AssetProfileUpload /></OidcSecure>} />
                    </Route>
            </Routes>
        </>
  )
}

export default Main
