import React, { useEffect } from 'react'
import BackButton from '../../components/BackButton'
import { Card, CardContent, CircularProgress } from '@mui/material'
import { type Option } from '../../components/GenericDropdown'
import AssetConfiguration, { type parametersData } from './components/AssetConfiguration'
import { CautionWarining } from './components/warnings'
import { useNavigate } from 'react-router-dom'
import { fetchAssetTypes, fetchParameterList, postConfigurationTemplate } from './redux/actionCreator'
import { type ParameterListState } from './redux/parameterListSlice'
import { type RootState } from '../../store'
import { type ConnectedProps, connect } from 'react-redux'
import AuthError from '../../components/ErrorComponents/AuthError'
import { Button } from '../../components/Button'
import Modal from '../../components/Modal'
import { type AssetTypesState } from './redux/assetTypeSlice'
import { type ConfigurationTemplateRequest } from './services/createConfigurationService'
import { type CreateConfigurationState } from './redux/createConfigurationSlice'
import LanguageStrings from '../../i18n/locales'
import { isValidName, validateParametersData } from '../../utils/validator'
import { useAuth } from 'react-oidc-context'
import { GenXDropdown } from '../../components/GenXDropdown'

const CreateConfigurationStrings = LanguageStrings().AssetConfigurationStrings.CreateConfigurationStrings

const CreateConfiguration = (props: PropsFromRedux): JSX.Element => {
  const [categoryOptions, setCategoryOptions] = React.useState<Option[]>([])
  const [assetTypeOptions, setAssetTypeOptions] = React.useState<Option[]>([])
  const [category, setCategory] = React.useState<Option | null>(null)
  const [assetType, setAssetType] = React.useState<Option | null>(null)
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [showCreatePopup, setShowCreatePopup] = React.useState(false)
  const [parameters, setParameters] = React.useState<parametersData[]>([])
  const [name, setName] = React.useState('')
  const [error, setError] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const auth = useAuth()

  useEffect(() => {
    props.fetchAssetTypes()
  }, [])

  useEffect(() => {
    // istanbul ignore next
    if (parameters.length !== 0 && name.length <= 30 && isValidName(name) && validateParametersData(parameters)) {
      setError(false)
    } else {
      setError(true)
    }
  }, [name, parameters])

  useEffect(() => {
    setParameters([])
    setName('')
  }, [category, assetType])

  const handleChooseAssetType = (selectedOption: Option | null): void => {
    setAssetType(selectedOption)
  }

  const handleChooseCategory = (selectedOption: Option | null): void => {
    if (selectedOption !== null && selectedOption !== undefined) {
      setCategory(selectedOption)
      setAssetType(null)
      setAssetTypeOptions([])
      const tempAssetTypeOptions: Option[] = []
      if (props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0) {
        props.assetTypes.assetTypes.forEach((assetType) => {
          if (assetType.category_type_id === selectedOption.value) {
            tempAssetTypeOptions.push({
              value: assetType.asset_type_id,
              label: assetType.asset_type_name
            })
          }
        })
        setAssetTypeOptions(tempAssetTypeOptions)
      }
    }
  }

  const checkForIncompleteForm = (): boolean => {
    return category === null || assetType === null || name === '' || parameters.length === 0 || error
  }

  useEffect(() => {
    if (props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0) {
      const categories: Option[] = []
      const uniqueCategories: Record<string, boolean> = {}

      props.assetTypes.assetTypes.forEach((assetType) => {
        if (!uniqueCategories[assetType.category_type_id]) {
          uniqueCategories[assetType.category_type_id] = true
          categories.push({
            value: assetType.category_type_id,
            label: assetType.category_type_name
          })
        }
      })

      setCategoryOptions(categories)
    }
  }, [props.assetTypes.assetTypes])

  // istanbul ignore next
  const handleAssetTypeRetry = (): void => {
    // istanbul ignore next
    props.fetchAssetTypes()
  }

  // istanbul ignore next
  const handleRetry = (): void => {
    // istanbul ignore next
    if (category !== null && assetType !== null) {
      props.fetchParameterList(assetType.value)
    }
  }

  // istanbul ignore next
  const onAssetCreationConfirmClose = (): void => {
    // istanbul ignore next
    setShowConfirmation(false)
  }

  const handleAssetTypeWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.assetTypes.assetTypes !== null && props.assetTypes.assetTypes.length > 0) {
      return (
        <>
          <Card sx={{ borderRadius: '10px', backgroundColor: '#272727', color: '#F0F0F0 !important', padding: '10px', display: 'flex', overflow: 'visible' }}>
            <CardContent>
              <GenXDropdown data-testid='category' placeholder='Select Category' value={category} options={categoryOptions} onSelect={handleChooseCategory} label={'Category'} />
            </CardContent>
            <CardContent>
              <GenXDropdown data-testid='assettype' placeholder='Select Asset Type' value={assetType} options={assetTypeOptions} onSelect={handleChooseAssetType} label={'Asset Type'}/>
            </CardContent>
          </Card>
          {
            (category !== null && assetType !== null)
              ? handleAssetConfigurationrWithFailure()
              : (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '6em' }}>
                  <CautionWarining
                    assetType={assetType?.value ?? ''}
                    category={category?.value ?? ''}
                  />
                </div>
                )
          }
        </>
      )
    } else if (props.assetTypes.isLoading) {
      return (
          <div className='CircularprogressAmi'>
              <CircularProgress />
          </div>
      )
    } else if (props.assetTypes.httpStatus === 401) {
      return (
                  <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (props.assetTypes.httpStatus === 403) {
      return (
              <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
      )
    } else {
      return (
              <div className='authError'><AuthError errorMessage="cannotFetch" retry={handleAssetTypeRetry}/></div>
      )
    }
  }

  const handleAssetConfigurationrWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.parameterList.parameterList !== null && props.parameterList.parameterList !== undefined && props.parameterList.httpStatus === 200) {
      return props.parameterList.parameterList.length > 0 ? <AssetConfiguration parameterList={props.parameterList} parameters={parameters} name={name} setParameters={setParameters} setName={setName} /> : <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
    } else if (props.parameterList.isLoading) {
      return (
          <div className='CircularprogressAmi'>
              <CircularProgress />
          </div>
      )
    } else if (props.parameterList.httpStatus === 401) {
      return (
                  <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (props.parameterList.httpStatus === 403) {
      return (
              <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
      )
    } else {
      return (
              <div className='authError'><AuthError errorMessage="cannotFetch" retry={handleRetry}/></div>
      )
    }
  }

  const handleCreateConfigurationWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.createConfiguration.httpStatus === 200) {
      return (
        <Modal buttonTypeNegative='hollow' title='Successfully created' LabelPositive='Ok' onClickPositive={() => { navigate('/configuration') }}
          onClose={() => { navigate('/configuration') }}>
          <p style={{ paddingTop: '1.2em' }}>{CreateConfigurationStrings.Configuration_has_been_created_successfully}</p>
        </Modal>
      )
    } else if (props.createConfiguration.isLoading) {
      return (
        <Modal title='Creating Configuration'>
          <div className='CircularprogressAmi'>
              <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.createConfiguration.httpStatus === 401) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { setShowCreatePopup(false) }}
                    onClose={() => { setShowCreatePopup(false) }} title='Creating Configuration'>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="Unauthorized"/>
                    </div>
                </Modal>
      )
    } else if (props.createConfiguration.httpStatus === 403) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { setShowCreatePopup(false) }}
                    onClose={() => { setShowCreatePopup(false) }} title='Creating Configuration'>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="accessForbidden"/>
                    </div>
                </Modal>
      )
    } else if (props.createConfiguration.httpStatus === 400) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { setShowCreatePopup(false) }}
                    onClose={() => { setShowCreatePopup(false) }} title='Creating Configuration'>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} customError="Configuration Name already exists"/>
                    </div>
                </Modal>
      )
    } else {
      return (
        <Modal width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close' LabelPositive='Retry'
          onClickNegative={() => { setShowCreatePopup(false) }}
          onClose={() => { setShowCreatePopup(false) }} title='Creating Configuration'
          onClickPositive={() => {
            props.postConfigurationTemplate(auth.user?.profile.customer as string, {
              configuration_template: {
                template_name: name, asset_type_id: assetType?.value as string
              },
              param_values: parameters.map((parameter) => {
                return {
                  param_mapping_id: parameter.param_mapping_id,
                  value: parameter.value
                }
              })
            })
          }}>
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} errorMessage="cannotCreate"/></div>
        </Modal>
      )
    }
  }

  useEffect(() => {
    if (category !== null && assetType !== null) {
      props.fetchParameterList(assetType.value)
    }
  }, [category, assetType])

  return (
    <div>
      <div className='link-breadcrumb'>
        <span onClick={ () => { navigate('/card') }} data-testid='assetmgmtli' className='assetmgmtli'>{CreateConfigurationStrings.Asset_Management}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span data-testid='configurationbreadcrumb' onClick={ () => { navigate('/configuration') }} className='assetmgmtli'>{CreateConfigurationStrings.Configurations}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span>{CreateConfigurationStrings.Create_Configuration}</span>
      </div>
      <div className="topUtilityBar">
        <BackButton />
        <span id="componentTitle">{CreateConfigurationStrings.Create_Configuration}</span>
      </div>
      {
        handleAssetTypeWithFailure()
      }
      <div style={{ width: '100%', height: '80px', backgroundColor: '#272727', margin: '50px 0 10px 0', borderRadius: 10 }}>
        <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 1em' }}>
          <Button dataTestId='ConfigCancel' type='hollow' onClick={() => { navigate('/configuration') }}>Cancel</Button>
          <Button dataTestId='ConfigCreate' primaryColor={checkForIncompleteForm() ? '#B0B0B0' : undefined} onClick={() => { setShowConfirmation(true) }} disabled={ checkForIncompleteForm() }>{CreateConfigurationStrings.Create}</Button>
        </div>
      </div>
      { showConfirmation &&
        <Modal buttonTypeNegative='hollow' title='Create Configuration' LabelNegative='Cancel' LabelPositive='Create' onClickPositive={() => {
          setShowConfirmation(false)
          setShowCreatePopup(true)
          props.postConfigurationTemplate(auth.user?.profile.customer as string, {
            configuration_template: {
              template_name: name, asset_type_id: assetType?.value as string
            },
            param_values: parameters.map((parameter) => {
              return {
                param_mapping_id: parameter.param_mapping_id,
                value: parameter.value
              }
            })
          })
        }} onClose={onAssetCreationConfirmClose} onClickNegative={onAssetCreationConfirmClose}>
          <p style={{ paddingTop: '1.2em' }}>{CreateConfigurationStrings.Do_you_want_to_create_configuration}</p>
        </Modal>
      }
      {
        showCreatePopup &&
        handleCreateConfigurationWithFailure()
      }
    </div>
  )
}

interface DispatchToProps {
  fetchParameterList: (assetTypeId: string) => void
  fetchAssetTypes: () => void
  postConfigurationTemplate: (tenantId: string, configurationTemplate: ConfigurationTemplateRequest) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchParameterList: (assetTypeId: string) => dispatch(fetchParameterList(assetTypeId)),
  fetchAssetTypes: () => dispatch(fetchAssetTypes()),
  postConfigurationTemplate: (tenantId: string, configurationTemplate: ConfigurationTemplateRequest) => dispatch(postConfigurationTemplate(tenantId, configurationTemplate))
})

interface StateToProps {
  parameterList: ParameterListState
  assetTypes: AssetTypesState
  createConfiguration: CreateConfigurationState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  parameterList: state.parameterList,
  assetTypes: state.assetTypes,
  createConfiguration: state.createConfiguration
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CreateConfiguration)
