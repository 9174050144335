import { CircularProgress, Grid } from '@mui/material'
import React, { useEffect } from 'react'
import ReportTemplateList from './ReportTemplateComponents/ReportTemplateList'
import ReportTemplateDetails from './ReportTemplateComponents/ReportTemplateDetails'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../../store'
import { fetchTemplateDetailsList } from '../redux/actionCreators'
import { type TemplateDetailsListState } from '../redux/templateDetailsListSlice'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { type ReportData } from '../types'

const ReportTemplate = (props: PropsFromRedux): JSX.Element => {
  const [selectedTemplate, setSelectedTemplate] = React.useState<ReportData | undefined>(undefined)
  const reportTemplateDetailsRef = React.useRef<HTMLDivElement>(null)
  const [editEnabled, setEditEnabled] = React.useState(false)

  const handleFetchTemplateDetailsList = (): void => {
    props.fetchTemplateDetailsList()
  }

  useEffect(() => {
    handleFetchTemplateDetailsList()
  }, [])

  useEffect(() => {
    setSelectedTemplate(props.templateDetailsListState.templateDetailsList !== undefined ? props.templateDetailsListState.templateDetailsList[0] : undefined)
  }, [props.templateDetailsListState.templateDetailsList])

  const handleReportTemplateList = (): JSX.Element => {
    if (props.templateDetailsListState.httpStatus === 200 && props.templateDetailsListState.templateDetailsList !== undefined && props.templateDetailsListState.templateDetailsList.length > 0) {
      return (
        <>
          <Grid item sm={3}>
            <ReportTemplateList editEnabled={editEnabled} reportTemplateDetailsRef={reportTemplateDetailsRef} selectedTemplate={selectedTemplate} setSelectedTemplate={setSelectedTemplate} templateDetailsList={props.templateDetailsListState.templateDetailsList} />
          </Grid>
          <Grid item sm={9}>
            <ReportTemplateDetails editEnabled={editEnabled} setEditEnabled={setEditEnabled} reportTemplateDetailsRef={reportTemplateDetailsRef} handleFetchTemplateDetailsList={handleFetchTemplateDetailsList} selectedTemplate={selectedTemplate} templateDetailsList={props.templateDetailsListState.templateDetailsList} />
          </Grid>
        </>
      )
    } else if ((props.templateDetailsListState.httpStatus === 404 && props.templateDetailsListState.error === 'No Report Template Found') || (props.templateDetailsListState.httpStatus === 200 && props.templateDetailsListState.templateDetailsList !== undefined && props.templateDetailsListState.templateDetailsList.length === 0)) {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="NoDataPresent" /></div>
      )
    } else if (props.templateDetailsListState.isLoading) {
      return (
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
      )
    } else if (props.templateDetailsListState.httpStatus === 401) {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="Unauthorized" /></div>
      )
    } else if (props.templateDetailsListState.httpStatus === 403) {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="accessForbidden" /></div>
      )
    } else {
      return (
        <div className='authError' style={{ width: '100%' }}><AuthError errorMessage="cannotFetch" retry={handleFetchTemplateDetailsList} /></div>
      )
    }
  }
  return (
    <Grid container sx={{ backgroundColor: '#191818', borderTopLeftRadius: '10px' }}>
      {
        handleReportTemplateList()
      }
    </Grid>
  )
}

interface DispatchToProps {
  fetchTemplateDetailsList: () => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchTemplateDetailsList: () => dispatch(fetchTemplateDetailsList())
})

interface StateToProps {
  templateDetailsListState: TemplateDetailsListState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  templateDetailsListState: state.templateDetailsListState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ReportTemplate)
