import React from 'react'
import Modal from '../../../../components/Modal'
import { type CreateColumnContent } from '../../types'
import { reportContentValues } from '../ReportTemplateComponents/dummyData'
import LanguageStrings from '../../../../i18n/locales'

const PreviewModalStrings = LanguageStrings().DataPublisherStrings.CreateNewReportTemplateComponents.PreviewModal

interface ParentProps {
  reportContent: CreateColumnContent[]
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>
  reportName: string
}

const PreviewModal = (props: ParentProps): JSX.Element => {
  return (
    <Modal title={PreviewModalStrings.PREVIEW} titleStyle={{ fontWeight: 500 }} LabelPositive='Done' onClickPositive={() => { props.setShowPreview(false) }} width='70vw' height='50vh' >
      <div style={{ marginTop: '1em', position: 'relative', border: '1px solid #101010', borderRadius: '10px' }}>
        <div style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
          <div style={{ padding: '10px 1em', backgroundColor: '#313030', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>{props.reportName}</div>
          <div style={{ padding: '10px 1em', backgroundColor: '#272727', borderTop: '2px solid #303030' }}>{props.reportContent.map((item) => item.columnHeaderName).join(', ').toString()}</div>
        </div>
        <div style={{ overflow: 'auto', height: 'calc(50vh - 225px)' }}>
          {reportContentValues.map((item, index) => {
            return (
              <div key={index} style={{ padding: '10px 1em', backgroundColor: '#272727', borderTop: '2px solid #303030' }}>
                {item.join(', ').toString()}
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

export default PreviewModal
