/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// istanbul ignore file
import React, { useEffect, useState } from 'react'
import { Grid, Button, Typography, Box, TextField, LinearProgress, Modal, CircularProgress } from '@mui/material'
import { GenericDropdown, type Option } from '../../../components/GenericDropdown'
import LanguageStrings from '../../../i18n/locales'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import BackButton from '../../../components/BackButton'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { type SelectChangeEvent } from '@mui/material/Select'
import { type RootState } from '../../../store'
import { connect, type ConnectedProps } from 'react-redux'
import { uploadData } from '../redux/actionCreator'
import { type uploadAssetProfileReq } from '../services/uploadAssetProfile'
import { type UploadAssetProfileInfoState } from '../redux/updateAssetProfileSlice'
import { useAuth } from 'react-oidc-context'
import GenericModal from '../../../components/Modal'
import AuthError from '../../../components/ErrorComponents/AuthError'
import WarningIcon from '@mui/icons-material/WarningAmber'

const AssetProfileUploadStrings = LanguageStrings().AssetConfigurationStrings.components.AssetProfileUpload

const modalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: '#272727',
  fontFamily: 'Honeywell Sans Web',
  p: 4
}

const successModalStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 399,
  bgcolor: '#272727',
  fontFamily: 'Honeywell Sans Web',
  p: 4
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const AssetProfileUpload = (props: PropsFromRedux) => {
  const navigate = useNavigate()
  const auth = useAuth()
  const [file, setFile] = useState<File | null>(null)
  const [showProgressBar, setShowProgressBar] = React.useState(false)
  const [selectedMeterSize, setSelectedMeterSize] = useState<string>('')
  const [selectedDeviceType, setSelectedDeviceType] = useState<string>('')
  const [progress, setProgress] = React.useState(0)
  const [showAddModal, setShowAddModal] = React.useState<boolean>(false)
  const [isDisabled, setIsDisabled] = React.useState(false)
  const [showAddSuccessModal, setShowAddSuccessModal] = React.useState<boolean>(false)
  const [showCreatePopup, setShowCreatePopup] = React.useState(false)
  const [meterSizeOptions, setMeterSizeOptions] = useState<Option[]>([])

  const deviceTypeOptions: Option[] = [
    { label: 'Merlin', value: 'Merlin' },
    { label: 'Q600', value: 'Q600' },
    { label: 'V200H', value: 'V200H' }
  ]

  const stripDNPrefix = (meterSize: string): string => {
    return meterSize.replace(/^DN/, '')
  }

  useEffect(() => {
    switch (selectedDeviceType) {
      case 'Q600':
        setMeterSizeOptions(['DN15', 'DN20'].map((item) => ({ label: `${item}`, value: `${item}` })))
        break
      case 'V200H':
        setMeterSizeOptions(['DN15', 'DN20', 'DN25', 'DN40'].map((item) => ({ label: `${item}`, value: `${item}` })))
        break
      case 'Merlin':
        setMeterSizeOptions(['DN15', 'DN20', 'DN25', 'DN30', 'DN40'].map((item) => ({ label: `${item}`, value: `${item}` })))
        break
      default:
        setMeterSizeOptions([])
    }
  }, [selectedDeviceType])

  // Handle change function for the dropdown
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleMeterSizeChange = (event: SelectChangeEvent<string>) => {
    setSelectedMeterSize(event.target.value)
  }

  const handleDeviceType = (event: SelectChangeEvent<string>) => {
    setSelectedDeviceType(event.target.value)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onRemoveSelectedFile = () => {
    setFile(null)
    setShowProgressBar(false)
  }

  const isFileSizeLessThan10MB = (fileSize: number): boolean => {
    return fileSize > 0 && (fileSize / (1024 * 1024)) < 1
  }
  const isValidFileUploadedXml = () => {
    if (file !== null) {
      return file.name.toLowerCase().endsWith('.xml')
    }
    return false
  }

  function isValidName (value: string): boolean {
    const regexValidator = /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)?$/
    return regexValidator.test(value)
  }

  useEffect(() => {
    setIsDisabled(file === null || selectedMeterSize === '' || selectedDeviceType === '' || !isFileSizeLessThan10MB(file.size) || !isValidFileUploadedXml())
  }, [file, selectedMeterSize, selectedDeviceType])

  const styles = {

    button: {
      padding: '10px 30px',
      cursor: 'pointer'
    },
    buttonDisabled: {
      padding: '10px 30px',
      cursor: 'not-allowed',
      background: '#625d5d'
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSubmit = () => {
    const meterSizeWithoutPrefix = stripDNPrefix(selectedMeterSize)

    props.uploadData({
      deviceType: selectedDeviceType,
      meterSize: meterSizeWithoutPrefix,
      uploadedBy: auth.user?.profile.name as string,
      uploadType: 'profile'
    }, file as File)
    setShowAddSuccessModal(true)
    setShowAddModal(false)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleDrag = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDragOver = (e: any) => {
    const event = e as Event
    event.stopPropagation()
    event.preventDefault()
  }

  const handleDrop = function (e: any) {
    e.preventDefault()
    e.stopPropagation()
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (e.dataTransfer?.files && e.dataTransfer?.files[0]) {
      setFile(e.dataTransfer.files[0])
    }
  }

  const onFileChange = (e: any): void => {
    setFile(e.target.files[0])
  }

  const closeAddSuccessModal = () => {
    setShowAddSuccessModal(false)
    setFile(null)
    setSelectedMeterSize('')
    setShowAddModal(false)
    setIsDisabled(false)
  }

  const closeAddModal = () => {
    setShowAddModal(false)
    setIsDisabled(false)
  }

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         return 0
  //       }
  //       const diff = Math.random() * 10
  //       return Math.min(oldProgress + diff, 100)
  //     })
  //   }, 500)

  //   return () => {
  //     clearInterval(timer)
  //   }
  // }, [])

  const handleFileUploadWithFailure = (): JSX.Element => {
    if (props.uploadAssetProfileInfoState.httpStatus === 200) {
      return (
        <GenericModal buttonTypeNegative='hollow' title='Successfully created' LabelPositive='Ok' onClickPositive={() => {
          setFile(null)
          setSelectedMeterSize('')
          setSelectedDeviceType('')
          setShowAddSuccessModal(false)
          navigate('/card')
        }}
          onClose={() => {
            setFile(null)
            setSelectedMeterSize('')
            setShowAddSuccessModal(false)
            navigate('/card')
          }}>
          <p style={{ paddingTop: '1.2em' }}>{AssetProfileUploadStrings.uploadSuccessfull}</p>
        </GenericModal>
      )
    } else if (props.uploadAssetProfileInfoState.isLoading) {
      return (
        <GenericModal title='Upload Asset Profile'>
          <div className='CircularprogressAmi'>
              <CircularProgress />
          </div>
        </GenericModal>
      )
    } else if (props.uploadAssetProfileInfoState.httpStatus === 401) {
      return (
                <GenericModal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { setShowAddSuccessModal(false) }}
                    onClose={() => { setShowAddSuccessModal(false) }} title='Upload Asset Profile'>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="Unauthorized"/>
                    </div>
                </GenericModal>
      )
    } else if (props.uploadAssetProfileInfoState.httpStatus === 403) {
      return (
                <GenericModal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { setShowAddSuccessModal(false) }}
                    onClose={() => { setShowAddSuccessModal(false) }} title='Upload Asset Profile'>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="accessForbidden"/>
                    </div>
                </GenericModal>
      )
    } else {
      return (
        <GenericModal width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close' LabelPositive='Retry'
          onClickNegative={() => { setShowAddSuccessModal(false) }}
          onClickPositive={() => { handleSubmit() }}
          onClose={() => { setShowAddSuccessModal(false) }} title='Upload Asset Profile'
          >
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} errorMessage='customError' customError={props.uploadAssetProfileInfoState.errorMessage}/></div>
        </GenericModal>
      )
    }
  }

  return (
    <>
      <div className='link-breadcrumb'>
        <span data-testid='uploadAssetProfileBreadCrumb' onClick={() => { navigate('/card') }} className='assetmgmtli'>{AssetProfileUploadStrings.assetManagement}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span data-testid='uploadAssetProfileBreadcrumb'>{AssetProfileUploadStrings.assetProfileUpload}</span>
      </div>
      <div className='topUtilityBar'>
        <BackButton/>
        <span id='componentTitle' data-testid='assetProfileUploadName'>{AssetProfileUploadStrings.assetProfileUpload}</span>
      </div>
      <Box sx={{ width: '99%', borderRadius: '10px', border: '1px solid #101010', background: '#272727', boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0.15)', fontSize: '16px', marginBottom: '16px', padding: '24px' }} className='add-firmware-container'>
        <div className='title'>Add Details</div>
        <span>Asset Config File</span><sup style={{ color: '#EE3426', left: '0.3em' }}>*</sup>
        <div className='drag-drop-container'>
          <Box className='modal-box-firmware' onDragEnter={handleDrag} onDragOver={handleDragOver} onDrop={handleDrop}>
            <DescriptionOutlinedIcon className='DescriptionOutlinedIcon' />
            <Typography className='draganddroptext-firmware'>
              {AssetProfileUploadStrings.droptitle} {' '}
              {AssetProfileUploadStrings.onText}
            </Typography>
            <label className='file-label-firmware' htmlFor='file-input'>
              <input type='file' id='file-input' className='input-file' data-testid='file-input'
                onChange={(e: any) => {
                  onFileChange(e)
                }}
                onClick={(event: any) => {
                  event.target.value = null
                }}
                multiple
              />
              {AssetProfileUploadStrings.browsefile}{' '}
            </label>
          </Box>
          {file !== null && <div className='upload-file-name'>{file.name} ({(file.size / (1024 * 1024)).toFixed(4)}MB / 1MB)  <CloseIcon
            className='upload-file-close'
            data-testid='close-selected-file'
            onClick={() => { onRemoveSelectedFile() }}
          /></div>}
          {file !== null && !isFileSizeLessThan10MB(file.size) && <div className='failure-upload-response'><WarningIcon className='error-upload-icon' /><span className='error-upload-message'> {AssetProfileUploadStrings.errormsg1MB}</span></div>}
          {file !== null && !isValidFileUploadedXml() && <div className='failure-upload-response'><WarningIcon className='error-upload-icon' /><span className='error-upload-message'>{AssetProfileUploadStrings.errormsgXML}</span></div>}
          {file !== null && !isValidName(file.name) && <div className='failure-upload-response'><WarningIcon className='error-upload-icon' /><span className='error-upload-message'>{AssetProfileUploadStrings.filenameError}</span></div>}
          {showProgressBar && (
            <LinearProgress
              variant='determinate'
              value={progress}
              className='linearprogressbar'
            />
          )}
        </div>
        <Typography className='subtext-firmware'>
          {AssetProfileUploadStrings.validation}
        </Typography>
        <Grid style={{ display: 'flex', flexDirection: 'row', paddingRight: '2px' }}>
          <Grid item style={{ display: 'flex', flexDirection: 'column', paddingRight: '50px' }}>
            <div style={{ marginTop: '10px' }}><span>{AssetProfileUploadStrings.deviceType}<sup style={{ color: '#EE3426', left: '0.3em' }}>*</sup> </span></div>
            <GenericDropdown
              options={deviceTypeOptions}
              value={selectedDeviceType}
              onChange={handleDeviceType}
              label={''}
              dataTestId={'device-type-dropdown'}
              width={undefined}
            />
          </Grid>
          <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginTop: '10px' }}><span>{AssetProfileUploadStrings.meterSize}<sup style={{ color: '#EE3426', left: '0.3em' }}>*</sup> </span></div>
            <GenericDropdown
              options={meterSizeOptions}
              value={selectedMeterSize}
              onChange={handleMeterSizeChange}
              label={''}
              dataTestId={'meter-size-dropdown'}
              width={undefined}
            />
          </Grid>
        </Grid>
      </Box>
      <div className='footer'><Button onClick={() => { navigate('/card') }}>Cancel</Button><Button data-testid='validate-req' disabled={isDisabled} style={isDisabled ? styles.buttonDisabled : styles.button} onClick={() => { setShowAddModal(true) }}>Upload</Button></div>
      {
        showAddSuccessModal &&
        handleFileUploadWithFailure()
      }

      <Modal className='add-firmware-modal' open={showAddModal} onClose={() => { closeAddModal() }}><Box sx={modalStyle}>
        <div className='title'>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {AssetProfileUploadStrings.addAssetProfile}
          </Typography>
          <CloseIcon data-testid='close-button' style={{ cursor: 'pointer' }} onClick={() => { closeAddModal() }} />
        </div>
        <Typography id='modal-modal-description' sx={{ mt: 2 }}>
          {AssetProfileUploadStrings.doYouWantadd}
        </Typography>
        {/* {addFirmwareResponse.errorMessage !== null && <div className='failure-upload-response'><WarningIcon className='error-upload-icon' /><span className='error-upload-message'> {addFirmwareResponse.errorMessage}</span></div>} */}
        <div className='add-modal-button-container'>
          <Button data-testid='cancel-upload-profile' onClick={() => { closeAddModal() }}>Cancel</Button><Button data-testid='upload-profile-confirm' onClick={() => { handleSubmit() }}>Confirm</Button></div>
      </Box>
      </Modal>
    </>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: any) => ({
  uploadData: (uploadAssetProfileRequestParams: uploadAssetProfileReq, file: File) => dispatch(uploadData(uploadAssetProfileRequestParams, file))
})

interface StateToProps {
  uploadAssetProfileInfoState: UploadAssetProfileInfoState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  uploadAssetProfileInfoState: state.uploadAssetProfileInfoState
})
type PropsFromRedux = ConnectedProps<typeof connector>

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(AssetProfileUpload)
