// istanbul ignore file
import { type AxiosResponse } from 'axios'

export function handleFileName (response: AxiosResponse<any, any>): void {
  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: 'text/csv' })
  )
  const link = document.createElement('a')
  link.href = url
  const today = new Date()
  const time = today.toLocaleTimeString([], { hourCycle: 'h23', minute: '2-digit' })
  link.setAttribute(
    'download',
    `ProvisioningHistory_${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()} ${today.getHours()}_${time}.csv`
  )
  document.body.appendChild(link)
  link.click()
  link.parentNode?.removeChild(link)
}
