import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ReportContentColumn } from '../types'

export interface ReportContentColumnState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
  reportContentColumn: ReportContentColumn[]
}

const initialState: ReportContentColumnState = {
  isLoading: false,
  error: undefined,
  httpStatus: 0,
  reportContentColumn: []
}

const ReportContentColumnSlice = createSlice({
  name: 'ReportContentColumn state',
  initialState,
  reducers: {
    ReportContentColumnLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0,
        reportContentColumn: []
      }
    },
    ReportContentColumnUpdateAction: (state, action: PayloadAction<{ data: ReportContentColumn[], httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload.httpStatus,
        reportContentColumn: action.payload.data
      }
    },
    ReportContentColumnErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        reportContentColumn: []
      }
    }
  }
})

export const {
  ReportContentColumnLoadingAction,
  ReportContentColumnUpdateAction,
  ReportContentColumnErrorAction
} = ReportContentColumnSlice.actions

export default ReportContentColumnSlice.reducer
