/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZRead = z.object({
  readingTime: z.string(),
  value: z.number().nullable(),
  indexValue: z.number().nullable()
})

const ZIntervalReading = z.object({
  assetId: z.string(),
  total_count: z.number(),
  assetReadingsData: z.array(ZRead)
})

export const ZIntervalReadingResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: ZIntervalReading
})

export type IntervalReading = z.infer<typeof ZIntervalReading>
export async function getIntervalReadings (
  assetId: string,
  fromDate: string,
  toDate: string,
  selectedChannel: string,
  tenantId: string,
  onSuccess: (intervalReadings: IntervalReading, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-data-service/api/v1/udp/assets/data/interval/channel/range?assetId=${assetId}&tenantId=${tenantId}&channelId=${selectedChannel}&fromDate=${fromDate}&toDate=${toDate}`
  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200 && !response.data.error) {
        const parseResponse = ZIntervalReadingResponse.safeParse(response.data)
        if (parseResponse.success) {
          onSuccess(parseResponse.data.data, response.status)
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.data.error, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        // istanbul ignore else
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.message, error.response.status)
        }
      } else {
        onFailure(error.message, error.status)
      }
    })
}
