import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export interface AssignGroupRequest {
  template_id: string
  group_id: string[]
}

const ZGroupAssignConfigObj = z.object({
  status: z.string(),
  groupId: z.string()
})

const ZAssignGroupApiResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  data: z.array(ZGroupAssignConfigObj),
  error_message: z.optional(z.string())
})

export type GroupAssignConfigObj = z.infer<typeof ZGroupAssignConfigObj>

export type AssignGroupApiResponse = z.infer<typeof ZAssignGroupApiResponse>

export async function postModifyConfigurationGroup (
  assignGroupBody: AssignGroupRequest,
  tenantId: string,
  assign: boolean,
  onSuccess: (data: GroupAssignConfigObj[], httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-config-service/api/v1/udp/groups/${!assign ? 'un' : ''}assign?tenantId=${tenantId}`

  interceptedAxios
    .post(url, { ...assignGroupBody, status: 'active' }, { timeout: 15000 })
    .then((response: { data: any, status: number }) => {
      const parseResponse = ZAssignGroupApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        onSuccess(parseResponse.data.data, parseResponse.data.http_status)
      } else {
        onFailure('Error occurred while processing response', response.status)
      }
    })
    .catch(
      // istanbul ignore next
      (error) => {
      // istanbul ignore next
        if (error.response?.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.data.http_status)
        } else {
          onFailure(
            'Network Error', 404
          )
        }
      })
}
