/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface firmwareListFilterAssetTypeState {
  isLoading: boolean
  loadingError: string | null
  firmwareListFilterAssetType: string[] | []
}

// Initial state of the slice
const initialState: firmwareListFilterAssetTypeState = {
  isLoading: true,
  loadingError: null,
  firmwareListFilterAssetType: [],
}

// Slice to return from action
export const firmwareListFilterAssetTypeSlice = createSlice({
  name: 'firmwareListFilterAssetType',
  initialState,
  reducers: {
    firmwareListFilterAssetTypeLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        firmwareListFilterAssetType: []
      }
    },
    firmwareListFilterAssetTypeUpdateAction: (state, action: PayloadAction<string[]|[]>) => {
      return {
        isLoading: false,
        loadingError: null,
        firmwareListFilterAssetType: action.payload
      }
    },
    firmwareListFilterAssetTypeErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        firmwareListFilterAssetType: []
      }
    }
  }
})

// Actions to export
export const {
  firmwareListFilterAssetTypeLoadingAction,
  firmwareListFilterAssetTypeUpdateAction,
  firmwareListFilterAssetTypeErrorAction
} = firmwareListFilterAssetTypeSlice.actions

export default firmwareListFilterAssetTypeSlice.reducer