import interceptedAxios from '../../../utils/interceptedAxios'
import { type HistoryARDataObject, ZHistoryARApiResponse, type HistoryADSDataObject, ZHistoryADSApiResponse, ZHistoryDonutTableApiResponse, type HistoryDonutTableDataObject, type HistoryASDataObject, ZHistoryASApiResponse } from '../types'

export async function getHistoryARTable (
  kpiType: string,
  inputDateRead: string,
  page: number,
  size: number,
  service: string,
  unPaged: boolean,
  onSuccess: (dataObj: HistoryARDataObject, httpStatus: number) => any,
  onFailure: (error_message: string, httpStatus: number) => any
): Promise<void> {
  const url = `/${service}/api/v1/udp/liveData/${kpiType}?inputDateRead=${inputDateRead}&page=${page}&size=${size}&unPaged=${String(unPaged)}`

  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZHistoryARApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        // istanbul ignore else
        if (parseResponse.data.data !== undefined) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        onFailure(error.response.data.error_message, error.response.data.http_status)
      } else {
        onFailure('Network Error', error.status)
      }
    })
}

export async function getHistoryADSTable (
  kpiType: string,
  inputDateRead: string,
  page: number,
  size: number,
  service: string,
  unPaged: boolean,
  onSuccess: (dataObj: HistoryADSDataObject, httpStatus: number) => any,
  onFailure: (error_message: string, httpStatus: number) => any
): Promise<void> {
  const url = `/${service}/api/v1/udp/liveData/${kpiType}?inputDateRead=${inputDateRead}&page=${page + 1}&size=${size}&unPaged=${String(unPaged)}`

  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZHistoryADSApiResponse.safeParse(response.data)
      // istanbul ignore else
      if (parseResponse.success) {
        if (parseResponse.data.data !== undefined) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        onFailure(error.response.data.error_message, error.response.data.http_status)
      } else {
        onFailure('Network Error', error.status)
      }
    })
}

export async function getHistoryASTable (
  kpiType: string,
  inputDateRead: string,
  page: number,
  size: number,
  service: string,
  unPaged: boolean,
  onSuccess: (dataObj: HistoryASDataObject, httpStatus: number) => any,
  onFailure: (error_message: string, httpStatus: number) => any
): Promise<void> {
  const url = `/${service}/api/v1/udp/liveData/${kpiType}?inputDate=${inputDateRead}&page=${page}&size=${size}&unPaged=${String(unPaged)}`

  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZHistoryASApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        // istanbul ignore else
        if (parseResponse.data.data !== undefined) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        onFailure(error.response.data.error_message, error.response.data.http_status)
      } else {
        onFailure('Network Error', error.status)
      }
    })
}

export async function getHistoryDonutTable (
  kpiType: string,
  inputDateRead: string,
  donutType: string,
  page: number,
  size: number,
  service: string,
  unPaged: boolean,
  onSuccess: (dataObj: HistoryDonutTableDataObject, httpStatus: number) => any,
  onFailure: (error_message: string, httpStatus: number) => any
): Promise<void> {
  const url = `/${service}/api/v1/udp/liveData/${kpiType}?barType=${donutType}&inputDate=${inputDateRead}&page=${page}&size=${size}&unPaged=${String(unPaged)}`

  console.log('url', url)

  interceptedAxios
    .get(url)
    .then((response) => {
      const parseResponse = ZHistoryDonutTableApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        // istanbul ignore else
        if (parseResponse.data.data !== undefined) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        onFailure(error.response.data.error_message, error.response.data.http_status)
      } else {
        onFailure('Network Error', error.status)
      }
    })
}
