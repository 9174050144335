import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ConfigurationDetails } from '../services/configDetailsService'

export interface ConfigurationDetailsState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null
  configurationDetails: ConfigurationDetails | null
}

const initialState: ConfigurationDetailsState = {
  isLoading: true,
  error: null,
  httpStatus: null,
  configurationDetails: null
}

const configurationDetailsSlice = createSlice({
  name: 'configurationDetails',
  initialState,
  reducers: {
    configurationDetailsLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0,
        configurationDetails: null
      }
    },
    configurationDetailsUpdateAction: (state, action: PayloadAction<{ data: ConfigurationDetails, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.httpStatus,
        configurationDetails: action.payload.data
      }
    },
    configurationDetailsErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        configurationDetails: null
      }
    }
  }
})

export const {
  configurationDetailsLoadingAction,
  configurationDetailsUpdateAction,
  configurationDetailsErrorAction
} = configurationDetailsSlice.actions

export default configurationDetailsSlice.reducer
