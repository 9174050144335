/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState } from 'react'
import MapComponent, { type AMIMarkerData } from '../../Maps/Maps'
import BasicTabs from './DeviceTable/DeviceListAmi'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ListIcon from '@mui/icons-material/List'
import MapIcon from '@mui/icons-material/Map'
import { type RootState } from '../../../store'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import { fetchMapData } from '../redux/actionCreators'
import { useAuth } from 'react-oidc-context'
import { type MapDataState } from '../redux/mapDataSlice'

function generateOutputArray (markers: any[]): any {
  return markers.map((obj) => {
    return {
      assetName: obj.assetName,
      location: {
        lat: obj.latitude,
        lng: obj.longitude
      },
      severity: obj.severity,
      assetId: obj.assetId,
      alarmName: obj.alarmName
    }
  })
}

const Alarm = (props: AllProps): JSX.Element => {
  const totalIssueCount = useSelector(
    (state: RootState) => state.totalIssuesCount
  )
  const auth = useAuth()
  const [alignment, setAlignment] = props.alignmentObj
  const [mapMarkers, setMapMarkers] = useState<AMIMarkerData[]>([])

  useEffect(() => {
    props.fetchMapData(auth.user?.profile.customer as string)
  }, [])

  useEffect(() => {
    if (props.mapData.alarmData != null && props.mapData.alarmData !== undefined) {
      let outputArray: AMIMarkerData[] = []
      outputArray = generateOutputArray(props.mapData.alarmData)
      setMapMarkers(outputArray)
    }
  }, [props.mapData])

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ): any => {
    // istanbul ignore next
    if (newAlignment !== alignment) {
      setAlignment(newAlignment)
    }
  }

  const toggleviews = (): any => {
    // istanbul ignore else
    if (alignment === 'list') {
      return (
        <BasicTabs />
      )
    } else {
      return (
        <div style={ { height: '100%', padding: '10px' } }>
        <div id='MapCardAmi' style={{ height: `calc(${props.height}px - 35px)` }}>
          <MapComponent AMIMarkers={mapMarkers} />
          {/* {MapMemoized} */}
        </div>
      </div>
      )
    }
  }
  return (
    <Grid
      container
      sx={{
        borderRadius: '10px',
        backgroundColor: '#272727',
        color: '#f0f0f0'
      }}
    >
      <div className="top-issues-header">
        <div style={{ fontSize: '18px' }}>
          TOP ISSUES LIST ({totalIssueCount})
        </div>
        <div style={{ backgroundColor: '#505050', borderRadius: '4px' }}>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            sx={{
              padding: '2px',
              backgroundColor: '#505050',
              border: 'none'
            }}
          >
            <ToggleButton
              sx={{ border: 'none', borderRadius: '4px' }}
              value="list"
              aria-label="left aligned"
              data-testid="left aligned"
              disabled={alignment === 'list'}
            >
              <ListIcon />
            </ToggleButton>
            <ToggleButton
              sx={{ border: 'none', borderRadius: '4px' }}
              value="map"
              aria-label="right aligned"
              data-testid="right aligned"
              disabled={alignment === 'map'}
            >
              <MapIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div style={{ width: '100%' }}>{toggleviews()}</div>
    </Grid>
  )
}

interface DispatchToProps {
  fetchMapData: (
    tenantId: string
  ) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchMapData: (
    tenantId: string
  ) => dispatch(fetchMapData(tenantId))
})

interface StateToProps {
  mapData: MapDataState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  mapData: state.mapData
})

const connector = connect(mapStateToProps, mapDispatchToProps)

interface ParentProps {
  height: number
  alignmentObj: any
}

type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = ParentProps & PropsFromRedux

export default connector(Alarm)
