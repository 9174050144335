/* eslint-disable */
// istanbul ignore file
import * as React from "react";
import { alpha } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import "./assetHistory.css";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import DoNotDisturb from "@mui/icons-material/DoNotDisturb";
import CloseIcon from "@mui/icons-material/Close";
import Search from "@mui/icons-material/Search";
import ReplayIcon from "@mui/icons-material/Replay";
import FileExport from "../../../assets/exportIcon.svg";
import FilterIcon from "../../../assets/filterIcon.png";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Pagination from "../../../components/TablePagination";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Info from "@mui/icons-material/InfoOutlined";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import { useAuth } from "react-oidc-context";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { visuallyHidden } from "@mui/utils";
import ListItemText from "@mui/material/ListItemText";
import DeviceData from "./ProvisioningHistorySummaryDetails";
import LanguageStrings from "../../../i18n/locales";
import { connect, type ConnectedProps } from "react-redux";
import { type RootState } from "../../../store";
import { type assetProvisioningHistoryDetailState } from "../redux/assetProvisioingHistoryInfoSlice";
import {
  getDeviceHistoryData,
  exportProvisioningHistory,
  exportProvisioningHistoryById,
  getAssetProvisioningFilterData,
  getProvisioningHistoryFilterStatus,
  getProvisioningHistoryFilterUploadedUsers,
} from "../redux/actionCreators";
import { useSelector } from "react-redux";
import { Button, CircularProgress } from "@mui/material";
import AuthError from "../../../components/ErrorComponents/AuthError";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { type Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import OutlinedInput from "@mui/material/OutlinedInput";
import { parseDateInAOHstandard } from "../../../customHooks/parseDate";
import successBadge from "../../../assets/successBadge.svg";
import errorBadge from "../../../assets/errorBadge.svg";
import notApplicableBadge from "../../../assets/notApplicableBadge.svg";

const WhiteDatePicker = styled(DatePicker)({
  "& input": {
    color: "#b8b6b6",
  },
});
const CustomLocalizationProviderWrapper = styled("div")({
  backgroundColor: "#5c5b5bdd",
  borderRadius: "4px",
});
const provisioningHistoryStrings =
  LanguageStrings().DeviceProvisioningStrings.DeviceProvisioningTable;
let totalCount = 0;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface State extends SnackbarOrigin {
  open: boolean;
}

interface Data {
  provisioningHistoryId: string;
  uploadedBy: string;
  uploadDate: string;
  totalAsset: string;
  failedAsset: string;
  provisioningHistoryStatus: string;
}

const style = {
  width: "99%",
  bgcolor: "#313030",
  border: "1px solid #101010",
  borderRadius: "10px",
  boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.15)",
  marginBottom: "16px",
  padding: 0,
};

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "provisioningHistoryId",
    numeric: false,
    disablePadding: true,
    label: provisioningHistoryStrings.provisioningHistoryId,
  },
  {
    id: "uploadedBy",
    numeric: false,
    disablePadding: false,
    label: provisioningHistoryStrings.uploadedBy,
  },
  {
    id: "uploadDate",
    numeric: false,
    disablePadding: false,
    label: provisioningHistoryStrings.uploadDate,
  },
  {
    id: "totalAsset",
    numeric: false,
    disablePadding: false,
    label: provisioningHistoryStrings.totalDevice,
  },
  {
    id: "failedAsset",
    numeric: false,
    disablePadding: false,
    label: provisioningHistoryStrings.failed,
  },
  {
    id: "provisioningHistoryStatus",
    numeric: false,
    disablePadding: false,
    label: provisioningHistoryStrings.provisioningHistoryStatus,
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };
  return (
    <TableHead data-testid="device-table-header">
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              aria-label={headCell.label}
              data-testid="test-sort"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  data-testid="sort-table"
                  component="span"
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#F0F0F0",
    fontWeight: "700",
    fontSize: 12,
    border: "2px solid #202020",
    padding: "5px 16px",
    fontFamily: "Honeywell Sans Web",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#d0d0d0",
    paddingLeft: "17px",
    borderBottom: "1px solid #202020",
    borderRight: 0,
    fontFamily: "Honeywell Sans Web",
  },
}));

function EnhancedTableToolbar(props: any) {
  const {
    searchVal,
    setSearchVal,
    setFilterParams,
    checkName,
    setCheckName,
    clearAllClicked,
    setClearAllClicked,
    filterParams,
    setFilterCalled,
    filterCalled,
    rows,
    dateRadio,
    setDateRadio,
    start,
    setStart,
    end,
    clearAllFilters,
    setEnd,
    day,
    setDay,
    selectedUsers,
    setSelectedUsers,
    status,
    statusState,
    setStatusState,
    uploadedUsers,
    filterData,
  } = props;
  const [openFilter, setOpenFilter] = React.useState(false);

  const defaultDate = {
    end: dayjs(new Date()),
    start: dayjs(new Date(new Date().setMonth(new Date().getMonth() - 1))),
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateRadio((event.target as HTMLInputElement).value);
  };

  const onchangehandleSearch = (e: any) => {
    setSearchVal(e.target.value);
  };

  const handleCloseSearch = () => {
    props.inputSearchFunction(null);
    setSearchVal(null);
  };

  const handleUserChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedUsers(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleStatusChange = (e: any) => {
    setStatusState({ ...statusState, [e.target.name]: e.target.checked });
  };
  const [startError, setStartError] = React.useState(false);

  const [endError, setEndError] = React.useState(false);
  const populateValues = () => {
    const tmp: any = {};
    for (let i = 0; i < status.length; i++) {
      if (
        filterParams.filter(
          (par: any) => par.field === "provisioningHistoryStatus"
        ).length &&
        filterParams
          .filter((par: any) => par.field === "provisioningHistoryStatus")[0]
          .values.includes(status[i])
      ) {
        tmp[status[i]] = true;
      } else {
        tmp[status[i]] = false;
      }
    }
    setStatusState(tmp);
    // istanbul ignore next
    setSelectedUsers(
      filterParams.filter((par: any) => par.field === "uploadedBy").length
        ? filterParams.filter((par: any) => par.field === "uploadedBy")[0]
            .values
        : []
    );
    // istanbul ignore next
    if (
      filterParams.filter(
        (par: any) =>
          par.field === "uploadDate" && par.operator === "DATE_EQUAL"
      ).length &&
      filterParams.filter(
        (par: any) =>
          par.field === "uploadDate" && par.operator === "DATE_EQUAL"
      )[0].value.length !== 0
    ) {
      setDateRadio("day");

      setDay(
        dayjs(
          new Date(
            filterParams.filter(
              (par: any) =>
                par.field === "uploadDate" && par.operator === "DATE_EQUAL"
            )[0].value
          )
        )
      );
    } else {
      setDay(null);
    }
    // istanbul ignore next
    if (
      filterParams.filter(
        (par: any) =>
          par.field === "uploadDate" && par.operator === "GREATER_THAN_EQUAL_TO"
      ).length !== 0
    ) {
      setDateRadio("in-between");
      setEnd(
        dayjs(
          new Date(
            filterParams.filter(
              (par: any) =>
                par.field === "uploadDate" &&
                par.operator === "LESS_THAN_EQUAL_TO"
            )[0].value
          )
        )
      );
      setStart(
        dayjs(
          new Date(
            filterParams.filter(
              (par: any) =>
                par.field === "uploadDate" &&
                par.operator === "GREATER_THAN_EQUAL_TO"
            )[0].value
          )
        )
      );
    } else {
      setDateRadio("day");
      setStart(null);
      setEnd(null);
    }
  };
  const validateDate = () => {
    if (dateRadio === "in-between") {
      if (!start) {
        setStartError(true);
      }
      if (!end) {
        setEndError(true);
      } else {
        filterData();
        setOpenFilter(false);
      }
    } else {
      filterData();
      setOpenFilter(false);
    }
  };
  return (
    <Toolbar
      sx={{
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
      }}
    >
      <div style={{ width: "100%" }}>
        <Typography
          sx={{
            flex: "1 1 100%",
            fontFamily: "Honeywell Sans Web",
            fontSize: "16px",
            fontWeight: 600,
            padding: "12px 24px",
            borderBottom: "2px solid #202020",
            borderRadius: "4px",
          }}
          id="tableTitle"
          component="div"
        >
          <span
            data-testid="provision-History-Details"
            style={{
              fontSize: "16px",
              color: "#f0f0f0",
              borderRight: "2px solid #e0e0e0",
              paddingRight: "16px",
              marginRight: "10px",
            }}
          >
            {LanguageStrings().DeviceProvisioningStrings.title}
          </span>
          <span style={{ fontSize: "16px", color: "#d0d0d0" }}>
            {totalCount}{" "}
            {LanguageStrings().DeviceProvisioningStrings.provisioning}
          </span>
        </Typography>
        <div className="searchSection" style={{ width: "100%" }}>
          <Paper
            className="searchBoxComponent"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "left",
              width: 375,
            }}
          >
            <Button
              data-testid="filter-data"
              className="filter-btn"
              sx={{ background: "#707070", color: "#D0D0D0" }}
              onClick={() => {
                setOpenFilter(true);
                populateValues();
              }}
            >
              <img
                src={FilterIcon}
                height={16}
                width={16}
                style={{ marginLeft: "7px" }}
              />
              Filter
            </Button>

            <InputBase
              className="searchBox"
              sx={{ ml: 1, p: 1 }}
              placeholder="Enter search value"
              inputProps={{ "aria-label": "searchInput" }}
              value={searchVal ?? ""}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  clearAllFilters();
                  setFilterParams([]);
                  props.inputSearchFunction(searchVal);
                }
              }}
              onChange={onchangehandleSearch}
            />
            {searchVal != null && searchVal !== "" ? (
              <CloseIcon
                onClick={handleCloseSearch}
                sx={{ backgroundColor: "transparent" }}
                className="search-close"
                aria-label="close-drawer"
              />
            ) : (
              <></>
            )}
            <IconButton
              onClick={() => {
                setFilterParams([]);
                clearAllFilters();
                props.inputSearchFunction(searchVal);
              }}
              className="searchBtn"
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <Drawer
            anchor="right"
            open={openFilter}
            className="filter-drawer"
            variant="temporary"
            onClose={() => {
              setOpenFilter(false);
            }}
          >
            <div className="filter-headar">
              <div className="title">Filter</div>
              <div>
                <Button
                  aria-label="clear-all-filter"
                  className="cancel"
                  style={{ marginTop: "-2px", marginLeft: "80px" }}
                  onClick={() => {
                    clearAllFilters();
                    setClearAllClicked(true);
                  }}
                >
                  <ReplayIcon
                    sx={{ width: "16px", height: "16px", marginRight: "8px" }}
                  />
                  Clear All
                </Button>
              </div>
              <div>
                <CloseIcon
                  aria-label="close-drawer"
                  sx={{ color: "#f0f0f0" }}
                  onClick={() => setOpenFilter(false)}
                />
              </div>
            </div>
            {status && status.length !== 0 && (
              <>
                <div className="type-name status">Status</div>
                <FormGroup>
                  {Object.keys(statusState).length &&
                    status &&
                    status.length !== 0 &&
                    status.map((val: string) => (
                      <FormControlLabel
                        name={val}
                        aria-label="status-change"
                        onChange={(e) => {
                          handleStatusChange(e);
                        }}
                        checked={statusState[val]}
                        control={
                          <Checkbox inputProps={{ id: "select-item" }} />
                        }
                        label={val}
                      />
                    ))}
                </FormGroup>
              </>
            )}
            <div className="divider" />
            <div className="type-name">Uploaded By</div>
            <FormControl>
              <InputLabel className="select-title">
                Select uploaded by
              </InputLabel>

              <Select
                labelId="demo-multiple-checkbox-label"
                className="uploaded-by-select"
                multiple
                value={selectedUsers}
                onChange={handleUserChange}
                sx={{
                  height: "40px",
                  backgroundColor: "#5c5b5bdd",
                  borderRadius: "4px",
                  color: "#d0d0d0",
                }}
                renderValue={(selected: any) => selected.length + "selected"}
                MenuProps={MenuProps}
                aria-label="user-change"
              >
                {uploadedUsers?.map((name: string) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      inputProps={{ id: "select-item" }}
                      checked={selectedUsers.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="divider" />

            <div className="type-name">Date</div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="day"
                name="radio-buttons-group"
                value={dateRadio}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="day"
                  control={<Radio />}
                  label="Select a day"
                />
                <span
                  className="date-picker"
                  style={{ marginTop: "12px", marginBottom: "20px" }}
                >
                  <CustomLocalizationProviderWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <WhiteDatePicker
                        disableFuture
                        disabled={dateRadio !== "day" ? true : false}
                        value={day}
                        onChange={(val: any) => {
                          setDay(dayjs(val));
                        }}
                      />
                    </LocalizationProvider>
                  </CustomLocalizationProviderWrapper>
                </span>
                <FormControlLabel
                  value="in-between"
                  control={<Radio />}
                  label="In between"
                  sx={{ marginBottom: "12px" }}
                />
                <span className="type-name" style={{ marginLeft: "30px" }}>
                  Start date<span style={{ color: "red" }}>*</span>
                </span>
                <span className="date-picker" style={{ marginBottom: "24px" }}>
                  <CustomLocalizationProviderWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <WhiteDatePicker
                        disableFuture
                        disabled={dateRadio === "day" ? true : false}
                        value={start}
                        onChange={(val: any) => {
                          setStart(dayjs(val));
                          setStartError(false);
                        }}
                      />
                    </LocalizationProvider>
                  </CustomLocalizationProviderWrapper>
                </span>
                {startError && (
                  <span
                    data-testId={"start-Error"}
                    className="type-name"
                    style={{
                      marginLeft: "30px",
                      color: "red",
                      marginTop: "-18px",
                      marginBottom: "15px",
                    }}
                  >
                    Required
                  </span>
                )}
                <span className="type-name" style={{ marginLeft: "30px" }}>
                  End date<span style={{ color: "red" }}>*</span>
                </span>
                <span className="date-picker" style={{ marginBottom: "24px" }}>
                  <CustomLocalizationProviderWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <WhiteDatePicker
                        disableFuture
                        disabled={dateRadio === "day" ? true : false}
                        defaultValue={defaultDate.end}
                        value={end}
                        onChange={(val: any) => {
                          setEnd(dayjs(val));
                          setEndError(false);
                        }}
                        sx={{ color: "#D0D0D0" }}
                      />
                    </LocalizationProvider>
                  </CustomLocalizationProviderWrapper>
                </span>
                {endError && (
                  <span
                    className="type-name"
                    style={{
                      marginLeft: "30px",
                      color: "red",
                      marginTop: "-18px",
                    }}
                  >
                    Required
                  </span>
                )}
              </RadioGroup>
            </FormControl>
            <div className="divider" />
            <div className="buttons-container">
              <Button
                className="cancel"
                aria-label="cancel-drawer"
                onClick={() => {
                  setOpenFilter(false);
                }}
              >
                {provisioningHistoryStrings.cancelButton}
              </Button>
              <Button
                className="confirm"
                aria-label="confirm-filter"
                data-testid="show-results"
                onClick={() => {
                  setSearchVal(null);
                  validateDate();
                }}
              >
                {provisioningHistoryStrings.showResultButton}
              </Button>
            </div>
          </Drawer>
        </div>
      </div>
    </Toolbar>
  );
}

interface Sort {
  order: Order;
  orderBy: string;
}

interface EnhancedTableToolbarProps {
  checkName: string[];
  setCheckName: (val: any) => any;
  rows: Data[] | [];
  inputSearchFunction: any;
}

function AssetProvisioningHistory(props: PropsFromRedux): any {
  const navigate = useNavigate();
  const rowsPerPage = 10;
  const rows = useSelector(
    (state: any) =>
      state.assetProvisioningHistoryData.assetProvisioningHistoryData
  );
  const [page, setPage] = React.useState(1);
  const [sort, setSort] = React.useState<Sort>({
    order: "asc",
    orderBy: "provisioningHistoryId",
  });
  const count = (totalCount = useSelector(
    (state: any) => state.assetProvisioningHistoryData.totalCount
  ));
  const [checkName, setCheckName] = React.useState<string[]>([]);
  const [start, setStart] = React.useState<Dayjs | null | undefined>(null);
  const [end, setEnd] = React.useState<Dayjs | null | undefined>(null);
  const [day, setDay] = React.useState<Dayjs | null | undefined>(null);
  const [selectedUsers, setSelectedUsers] = React.useState<any>([]);
  const [statusState, setStatusState] = React.useState<any>({});
  const [filterCalled, setFilterCalled] = React.useState<string>("");
  const [dateRadio, setDateRadio] = React.useState("day");
  const [clearAllClicked, setClearAllClicked] = React.useState(false);
  const [searchVal, setSearchVal] = React.useState<string | null>(null);

  const status = useSelector(
    (state: any) =>
      state.provisioningHistoryFilterStatus.provisioningHistoryFilterStatus
  );
  const uploadedUsers = useSelector(
    (state: any) =>
      state.provisioningHistoryFilterUploadedUsers
        .provisioningHistoryFilterUploadedUsers
  );

  const resetStatus = () => {
    const tmp: any = {};
    for (let i = 0; i < status.length; i++) {
      tmp[status[i]] = false;
    }
    setStatusState(tmp);
  };
  React.useEffect(() => {
    resetStatus();
  }, [status]);

  const clearAllFilters = () => {
    setStart(null);
    setEnd(null);
    setDay(null);
    setSelectedUsers([]);
    setDateRadio("day");
    resetStatus();
  };

  const [filterParams, setFilterParams] = React.useState<any>([]);
  const [selectedStatus, setSelectedStatus] = React.useState<any>([]);

  React.useEffect(() => {
    const extractedTrueValues = Object.keys(statusState).filter(
      (key) => statusState[key] === true
    );
    setSelectedStatus(extractedTrueValues);
  }, [statusState]);
  React.useEffect(() => {
    if (filterCalled === "start") {
      const arr = [];
      if (selectedStatus.length) {
        arr.push({
          field: "provisioningHistoryStatus",
          operator: "IN",
          values: selectedStatus,
        });
      }

      if (dateRadio === "in-between") {
        if (start) {
          arr.push({
            field: "uploadDate",
            operator: "GREATER_THAN_EQUAL_TO",
            value: start.format("MM/DD/YYYY"),
          });
        }
        if (end) {
          arr.push({
            field: "uploadDate",
            operator: "LESS_THAN_EQUAL_TO",
            value: end.format("MM/DD/YYYY"),
          });
        }
      }
      if (dateRadio === "day" && day) {
        arr.push({
          field: "uploadDate",
          operator: "DATE_EQUAL",
          value: day.format("MM/DD/YYYY"),
        });
      }
      if (selectedUsers.length !== 0) {
        arr.push({
          field: "uploadedBy",
          operator: "IN",
          values: selectedUsers,
        });
      }
      if (!arr.length && clearAllClicked) {
        props.getDeviceHistoryData(
          page,
          rowsPerPage,
          sort.order,
          sort.orderBy,
          searchVal ?? "",
          auth.user?.profile.customer as string
        );
      }

      setFilterParams(arr);
    }
  }, [filterCalled]);

  const callSearchValue = (val: string | null) => {
    setPage(1);
    props.getDeviceHistoryData(
      1,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      val ?? "",
      auth.user?.profile.customer as string
    );
  };

  React.useEffect(() => {
    if (filterParams.length !== 0) {
      props.getAssetProvisioningFilterData(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        filterParams,
        auth.user?.profile.customer as string
      );
    } else {
      props.getDeviceHistoryData(
        page,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        searchVal ?? "",
        auth.user?.profile.customer as string
      );
    }
  }, [page, sort]);
  React.useEffect(() => {
    props.getProvisioningHistoryFilterStatus(
      auth.user?.profile.customer as string
    );
    props.getProvisioningHistoryFilterUploadedUsers(
      auth.user?.profile.customer as string
    );
  }, []);
  const auth = useAuth();

  const filterData = () => {
    setFilterCalled("start");
  };
  React.useEffect(() => {
    setPage(1);
    if (filterParams.length !== 0) {
      props.getAssetProvisioningFilterData(
        1,
        rowsPerPage,
        sort.order,
        sort.orderBy,
        filterParams,
        auth.user?.profile.customer as string
      );
      setFilterCalled("end");
    } else {
      if (filterCalled === "start") {
        props.getDeviceHistoryData(
          1,
          rowsPerPage,
          sort.order,
          sort.orderBy,
          searchVal ?? "",
          auth.user?.profile.customer as string
        );
        setFilterCalled("end");
      }
    }
  }, [filterParams]);

  const closeSummryPopup = () => {
    setShowDetails(false);
    setFileId(null);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => {
    const isAsc = sort.orderBy === newOrderBy && sort.order === "asc";
    const toggledOrder = isAsc ? "desc" : "asc";
    setSort({ order: toggledOrder, orderBy: newOrderBy });
  };

  // istanbul ignore next
  const getCompletionStatusBadge = (status: string): JSX.Element => {
    switch (status) {
      case "SUCCESS":
        return <img data-testid="successBadge" src={successBadge} />;
      case "PROGRESS":
        return <img data-testid="progressBadge" src={successBadge} />;
      case "ERROR":
        return <img data-testid="errorBadge" src={errorBadge} />;
      default:
        return (
          <img data-testid="notApplicableBadge" src={notApplicableBadge} />
        );
    }
  };

  const handleRetry = () => {
    props.getDeviceHistoryData(
      page,
      rowsPerPage,
      sort.order,
      sort.orderBy,
      searchVal ?? "",
      auth.user?.profile.customer as string
    );
  };

  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "bottom",
    horizontal: "right",
  });

  const { vertical, horizontal } = state;

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "& th": {
      border: "1px solid #202020",
    },
  }));

  function handleBreadCrumbsClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.preventDefault();
  }
  const [showDetails, setShowDetails] = React.useState(false);

  const [fileId, setFileId] = React.useState<string | null>(null);

  function renderContent(): JSX.Element {
    if (props.assetProvisioningHistoryData.isLoading === true) {
      return (
        <div className="CircularprogressAmi">
          <CircularProgress />
        </div>
      );
    } else if (props.assetProvisioningHistoryData.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      );
    } else if (props.assetProvisioningHistoryData.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      );
    } else if (
      (props.assetProvisioningHistoryData !== null &&
        props.assetProvisioningHistoryData !== undefined &&
        props.assetProvisioningHistoryData.httpStatus === 200) ||
      props.assetProvisioningHistoryData.httpStatus === 500
    ) {
      return (
        <>
          {props.assetProvisioningHistoryData.httpStatus === 200 &&
          rows?.length !== 0 ? (
            <>
              {" "}
              <TableContainer className="device-table">
                <Table
                  sx={{ minWidth: 750, color: "#f0f0f0" }}
                  aria-labelledby="tableTitle"
                  size={"small"}
                >
                  <EnhancedTableHead
                    order={sort.order}
                    orderBy={sort.orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {rows &&
                      rows.map((row: any, index: any) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <StyledTableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.provisioningHistoryId}
                            aria-label="devices-history-row"
                            sx={{ cursor: "pointer" }}
                          >
                            <StyledTableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              aria-label="provisioning-id"
                              padding="none"
                              data-testid="provisioning-id"
                              onClick={() => {
                                setShowDetails(true);
                                setFileId(row.provisioningHistoryId);
                              }}
                              sx={{ color: "#64C3FF !important" }}
                            >
                              {row.provisioningHistoryId}
                            </StyledTableCell>
                            <StyledTableCell>{row.uploadedBy}</StyledTableCell>
                            <StyledTableCell>
                              {row.uploadDate !== null
                                ? parseDateInAOHstandard(row.uploadDate)
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>{row.totalAsset}</StyledTableCell>
                            <StyledTableCell>
                              {row.failedAsset === 0 ? (
                                <div
                                  className="failed-count"
                                  style={{ color: "#ffffff" }}
                                >
                                  {row.failedAsset}
                                </div>
                              ) : (
                                <div
                                  className="failed-count"
                                  style={{ color: "#ff6f67" }}
                                >
                                  {row.failedAsset}
                                </div>
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              <div style={{ height: "20px" }}>
                                {getCompletionStatusBadge(
                                  row.provisioningHistoryStatus
                                )}
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {count > 10 && (<Pagination
                count={count}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                siblingCount={1}
                boundaryCount={1}
              />)}
            </>
          ) : (
            <div className="authError">
              <AuthError errorMessage="NoDataPresent" />
            </div>
          )}
        </>
      );
    } else if (props.assetProvisioningHistoryData.httpStatus === 400) {
      return <div className="authError"><AuthError errorMessage="invalidSearchInput" /></div>
    } else {
      return (
        <div className="authError">
          <AuthError errorMessage="cannotFetch" retry={handleRetry} />
        </div>
      );
    }
  }

  return (
    <>
      <div
        role="presentation"
        data-testid="breadcrumbs"
        onClick={handleBreadCrumbsClick}
        style={{ width: "100%" }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            color: "#f0f0f0",
            fontSize: "12px",
            marginBottom: "14px",
            fontFamily: "Honeywell Sans Web",
            marginTop: "12px",
          }}
        >
          <Link
            underline="hover"
            color="inherit"
            data-testid="device-management-breadcrumb"
            onClick={() => {
              navigate("/card");
            }}
          >
            {LanguageStrings().DeviceProvisioningStrings.deviceManagement}
          </Link>
          {fileId && (
            <Link
              underline="hover"
              data-testid="asset-provisioning-history"
              color="inherit"
              onClick={closeSummryPopup}
            >
              {LanguageStrings().DeviceProvisioningStrings.title}
            </Link>
          )}{" "}
          {fileId && (
            <Typography
              color="inherit"
              sx={{ fontSize: "12px", fontFamily: "Honeywell Sans Web" }}
            >
              {fileId}
            </Typography>
          )}
          {!fileId && (
            <Typography
              color="inherit"
              sx={{ fontSize: "12px", fontFamily: "Honeywell Sans Web" }}
            >
              {LanguageStrings().DeviceProvisioningStrings.title}
            </Typography>
          )}
        </Breadcrumbs>
      </div>{" "}
      <div style={{ display: "flex" }}>
        {showDetails ? (
          <ArrowBackIos
            sx={{ cursor: "pointer" }}
            data-testid="back-arrow"
            onClick={closeSummryPopup}
          />
        ) : (
          <ArrowBackIos
            sx={{ cursor: "pointer" }}
            data-testid="back-arrow"
            onClick={() => {
              setShowDetails(false);
              setFileId(null);
              navigate("/card");
            }}
          />
        )}
        <div style={{ marginBottom: "32px" }}>
          <span
            data-testid="assetProvisioningHistoryDetails"
            style={{
              color: "#f0f0f0",
              fontSize: "24px",
              fontWeight: "900",
              paddingRight: "16px",
              marginRight: "10px",
            }}
          >
            {fileId
              ? fileId
              : LanguageStrings().DeviceProvisioningStrings.deviceDetail}
          </span>
          <Tooltip title="Download">
            <IconButton
              data-testid="exportFile"
              onClick={() => {
                fileId
                  ? props.exportProvisioningHistoryById(
                      fileId,
                      auth.user?.profile.customer as string
                    )
                  : props.exportProvisioningHistory(
                      auth.user?.profile.customer as string
                    );
              }}
              sx={{ position: "absolute", right: "25px" }}
            >
              <img src={FileExport} height={25} width={25} />{" "}
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Box sx={style} className="devices">
        {!showDetails ? (
          <Paper
            sx={{
              width: "100%",
              mb: 2,
              backgroundColor: "#272727",
              boxShadow: "unset",
              color: "#F0F0F0",
              marginBottom: "0",
            }}
          >
            <EnhancedTableToolbar
              searchVal={searchVal}
              setSearchVal={setSearchVal}
              setFilterParams={setFilterParams}
              inputSearchFunction={callSearchValue}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              statusState={statusState}
              filterCalled={filterCalled}
              setFilterCalled={setFilterCalled}
              status={status}
              uploadedUsers={uploadedUsers}
              setStatusState={setStatusState}
              clearAllFilters={clearAllFilters}
              dateRadio={dateRadio}
              setDateRadio={setDateRadio}
              clearAllClicked={clearAllClicked}
              setClearAllClicked={setClearAllClicked}
              filterParams={filterParams}
              checkName={checkName}
              setCheckName={setCheckName}
              rows={rows}
              start={start}
              setStart={setStart}
              end={end}
              setEnd={setEnd}
              day={day}
              setDay={setDay}
              filterData={filterData}
            />
            {filterParams.length !== 0 && (
              <div
                style={{
                  padding: "12px 24px",
                  borderTop: "2px solid #202020",
                  color: "#d0d0d0",
                }}
              >
                {filterParams.length === 0 ? (
                  "No filters applied"
                ) : (
                  <div className="classNAme">
                    {count} Results | Filters :
                    <>
                      {filterParams.filter(
                        (par: any) => par.field === "provisioningHistoryStatus"
                      ).length !== 0 &&
                        filterParams.filter(
                          (par: any) =>
                            par.field === "provisioningHistoryStatus"
                        )[0].values.length !== 0 && (
                          <div className="box">
                            {" "}
                            Status:{" "}
                            {filterParams.filter(
                              (par: any) =>
                                par.field === "provisioningHistoryStatus"
                            )[0].values.length + " selected"}
                          </div>
                        )}
                      {filterParams.filter(
                        (par: any) => par.field === "uploadedBy"
                      ).length !== 0 &&
                        filterParams.filter(
                          (par: any) => par.field === "uploadedBy"
                        )[0].values.length !== 0 && (
                          <div className="box">
                            {" "}
                            Uploaded by:{" "}
                            {
                              filterParams.filter(
                                (par: any) => par.field === "uploadedBy"
                              )[0].values.length
                            }{" "}
                            selected
                          </div>
                        )}
                      {filterParams.filter(
                        (par: any) =>
                          par.field === "uploadDate" &&
                          par.operator === "DATE_EQUAL"
                      ).length !== 0 && (
                        <div className="box">
                          {" "}
                          Uploaded date:{" "}
                          {dayjs(
                            new Date(
                              filterParams.filter(
                                (par: any) =>
                                  par.field === "uploadDate" &&
                                  par.operator === "DATE_EQUAL"
                              )[0].value
                            )
                          ).format("MM/DD/YYYY")}
                        </div>
                      )}
                      {filterParams.filter(
                        (par: any) =>
                          par.field === "uploadDate" &&
                          par.operator === "GREATER_THAN_EQUAL_TO"
                      ).length !== 0 &&
                        filterParams.filter(
                          (par: any) =>
                            par.field === "uploadDate" &&
                            par.operator === "LESS_THAN_EQUAL_TO"
                        ).length !== 0 && (
                          <div className="box">
                            {" "}
                            {"Uploaded date between " +
                              dayjs(
                                new Date(
                                  filterParams.filter(
                                    (par: any) =>
                                      par.field === "uploadDate" &&
                                      par.operator === "GREATER_THAN_EQUAL_TO"
                                  )[0].value
                                )
                              ).format("MM/DD/YYYY") +
                              " and " +
                              dayjs(
                                new Date(
                                  filterParams.filter(
                                    (par: any) =>
                                      par.field === "uploadDate" &&
                                      par.operator === "LESS_THAN_EQUAL_TO"
                                  )[0].value
                                )
                              ).format("MM/DD/YYYY")}
                          </div>
                        )}
                    </>
                    <em
                      onClick={() => {
                        clearAllFilters();
                        setFilterParams([]);
                        props.getDeviceHistoryData(
                          page,
                          rowsPerPage,
                          sort.order,
                          sort.orderBy,
                          searchVal ?? "",
                          auth.user?.profile.customer as string
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Clear Filter{" "}
                    </em>
                  </div>
                )}
              </div>
            )}
            {renderContent()}
          </Paper>
        ) : (
          <DeviceData
            handleClose={() => {
              setShowDetails(false);
              setFileId(null);
            }}
            fileId={fileId}
          />
        )}
      </Box>
    </>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  getDeviceHistoryData: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    search: any,
    tenantId: string
  ) =>
    dispatch(
      getDeviceHistoryData(page, size, sortdir, sortfield, search, tenantId)
    ),
  getAssetProvisioningFilterData: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    filterParams: any,
    tenantId: string
  ) =>
    dispatch(
      getAssetProvisioningFilterData(
        page,
        size,
        sortdir,
        sortfield,
        filterParams,
        tenantId
      )
    ),
  getProvisioningHistoryFilterStatus: (tenantId: string) =>
    dispatch(getProvisioningHistoryFilterStatus(tenantId)),
  getProvisioningHistoryFilterUploadedUsers: (tenantId: string) =>
    dispatch(getProvisioningHistoryFilterUploadedUsers(tenantId)),
  exportProvisioningHistory: (tenantId: string) =>
    dispatch(exportProvisioningHistory(tenantId)),
  exportProvisioningHistoryById: (fileId: string, tenantId: string) =>
    dispatch(exportProvisioningHistoryById(fileId, tenantId)),
});

interface StateToProps {
  assetProvisioningHistoryData: assetProvisioningHistoryDetailState;
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assetProvisioningHistoryData: state.assetProvisioningHistoryData,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AssetProvisioningHistory);
