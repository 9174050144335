import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZFirmwareDeleteCampaignApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(z.string()),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

const ZFirmwareDeleteCampaignErrorApiResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  error_message: z.optional(z.string())
})

export type FirmwareDeleteCampaignApiResponse = z.infer<typeof ZFirmwareDeleteCampaignApiResponse>
export type FirmwareDeleteCampaignErrorApiResponse = z.infer<typeof ZFirmwareDeleteCampaignErrorApiResponse>

export async function firmwareDeleteCampaign (
  firmwareCampaignId: string,
  onSuccess: (deleteStatusData: string, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/asset-config-service/api/v1/udp/firmwareCampaign/delete/${firmwareCampaignId}`
  interceptedAxios
    .delete(url, { timeout: 15000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZFirmwareDeleteCampaignApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, response.status)
          } else {
            onFailure('Error occurred while processing response', response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        const parseResponse = ZFirmwareDeleteCampaignErrorApiResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          // istanbul ignore next
          onFailure(parseResponse.data.error_message !== undefined ? parseResponse.data.error_message : '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
