import React from 'react'
import './GenericToggleButton.css'

export interface ToggleOption {
  label: string
  value: string
}

interface GenericToggleButtonProps {
  selected: ToggleOption
  onChange: (option: ToggleOption) => void
  options: ToggleOption[]
}

const GenericToggleButton = (props: GenericToggleButtonProps): JSX.Element => {
  const handleToggle = (option: ToggleOption): void => {
    props.onChange(option)
  }

  return (
    <div className="toggle-container">
      {
        props.options.map((option, index) => {
          return (
            <div onClick={() => { handleToggle(option) }} key={index} className={`dialog-button ${(props.selected.label === option.label) ? 'selected' : 'unselected'}`}>
              {option.label}
            </div>
          )
        })
      }
    </div>
  )
}

export default GenericToggleButton
