import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZreportSchedulerHistoryTableDetails = z.object({
  reportSchedulerId: z.string(),
  schedulerStatus: z.string(),
  createdAt: z.string(),
  createdBy: z.string(),
  jobHistoryId: z.string(),
  remarks: z.string(),
  scheduledDate: z.string()
})

const ZData = z.object({
  totalCount: z.number(),
  successCount: z.number(),
  errorCount: z.number(),
  reportSchedulerDetails: ZreportSchedulerHistoryTableDetails.array()
})

const ZSchedulerHistoryApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(ZData),
  error_message: z.optional(z.string())
})

export type SchedulerHistoryApiDetails = z.infer<typeof ZSchedulerHistoryApiResponse>
export type reportSchedulerObj = z.infer<typeof ZData>
export type reportSchedulerDataObj = z.infer<typeof ZreportSchedulerHistoryTableDetails>

export async function getSchedulerHistoryByIdService (
  schedulerId: string,
  searchParam: string,
  onSuccess: (SchdulerHistoryListData: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/report-service/api/v1/udp/scheduler/job/history/search/${schedulerId}?parameter=${searchParam}`
  interceptedAxios.get(url, { timeout: 10000 })
    .then((response) => {
      const parseResponse = ZSchedulerHistoryApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        // istanbul ignore else
        if (response.data.data !== null && response.data.data !== undefined) {
          onSuccess(parseResponse.data.data, response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
  // istanbul ignore next
    .catch((error) => {
      // istanbul ignore next
      if (error.response !== undefined) {
        const parseResponse = ZSchedulerHistoryApiResponse.safeParse(error.response.data)
        // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
        if (parseResponse.success) {
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          onFailure(parseResponse.data?.error_message ?? '', error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
