import React from 'react'
import './GenericTextarea.css'

export interface TextareaProps {
  value: string
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  sx?: React.CSSProperties
}

export const GenericTextarea = ({ value, onChange, placeholder, sx }: TextareaProps): JSX.Element => {
  return (
    <div className='GenericTextarea'>
      <textarea
        id='custom-textarea'
        style={sx}
        value={value}
        onChange={onChange}
        placeholder={placeholder !== undefined ? placeholder : ''}
      />
    </div>
  )
}
