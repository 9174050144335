import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface firmwareCampaignDeleteState {
  isLoading: boolean
  error: string | undefined
  campaignDeleteData: string | undefined
  httpStatus: number | undefined
}

export const initialState: firmwareCampaignDeleteState = {
  isLoading: true,
  error: undefined,
  campaignDeleteData: undefined,
  httpStatus: 0
}

const firmwareCampaignDeleteSlice = createSlice({
  name: 'firmwareCampaignDelete',
  initialState,
  reducers: {
    firmwareCampaignDeleteLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        campaignDeleteData: undefined,
        httpStatus: 0
      }
    },
    firmwareCampaignDeleteUpdateAction: (state, action: PayloadAction<{ data: any, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        campaignDeleteData: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    firmwareCampaignDeleteErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        campaignDeleteData: undefined,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  firmwareCampaignDeleteLoadingAction,
  firmwareCampaignDeleteUpdateAction,
  firmwareCampaignDeleteErrorAction
} = firmwareCampaignDeleteSlice.actions

export default firmwareCampaignDeleteSlice.reducer
