import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface DeviceList {
  id: number
  name: string
  serial_number: string
  latitude: number
  longitude: number
  install_date: string
  removal_date: string
}
export interface DeviceListState {
  isLoading: boolean
  loadingError: string | null
  deviceList: DeviceList[] | null
  deviceCount: number
}

const initialState: DeviceListState = {
  isLoading: true,
  loadingError: null,
  deviceList: null,
  deviceCount: 0
}

const deviceListSlice = createSlice({
  name: 'deviceDetailsList',
  initialState,
  reducers: {
    deviceListLoadingAction: (state) => {
      return {
        isLoading: true,
        loadingError: null,
        deviceList: null,
        deviceCount: 0
      }
    },
    deviceListUpdateAction: (state, action: PayloadAction<{ data: DeviceList[], count: number }>) => {
      return {
        isLoading: false,
        loadingError: null,
        deviceList: action.payload.data,
        deviceCount: action.payload.count
      }
    },
    deviceListErrorAction: (state, action: PayloadAction<string>) => {
      return {
        isLoading: false,
        loadingError: action.payload,
        deviceList: null,
        deviceCount: 0
      }
    }
  }
})

export const {
  deviceListLoadingAction,
  deviceListUpdateAction,
  deviceListErrorAction
} = deviceListSlice.actions

export default deviceListSlice.reducer
