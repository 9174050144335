import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Asset } from '../services/assignedAssetListService'
export interface AsignedAssetListState {
  isLoading: boolean
  loadingError: string | null
  assetList: Asset[] | null
  assetCount: number
  httpStatus: number | null
}

const initialState: AsignedAssetListState = {
  isLoading: true,
  loadingError: null,
  assetList: null,
  assetCount: 0,
  httpStatus: null
}

const assignedAssetListSlice = createSlice({
  name: 'Asset-List-for-unassigned-button',
  initialState,
  reducers: {
    assignedAssetListLoadingAction: (state) => {
      return {
        isLoading: true,
        loadingError: null,
        assetList: null,
        assetCount: 0,
        httpStatus: 0
      }
    },
    assignedAssetListUpdateAction: (state, action: PayloadAction<{ data: Asset[], count: number, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: null,
        assetList: action.payload.data,
        assetCount: action.payload.count,
        httpStatus: action.payload.httpStatus
      }
    },
    assignedAssetListErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: action.payload.errorMessage,
        assetList: null,
        assetCount: 0,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  assignedAssetListLoadingAction,
  assignedAssetListUpdateAction,
  assignedAssetListErrorAction
} = assignedAssetListSlice.actions

export default assignedAssetListSlice.reducer
