/* eslint-disable @typescript-eslint/consistent-type-imports */
import React, { useState } from 'react'
import Modal from '../../../components/Modal'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import { unAssignFirmwareGroup } from '../redux/actionCreators'
import { type FirmwareGroupUnassignRequest } from '../services/firmwareUnassignGroupService'
import './UnassignGroup.css'
import {
  TableContainer,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
  Checkbox,
  CircularProgress
} from '@mui/material'
import { Table } from '@scuf/common'
import TableRow from '@mui/material/TableRow'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { FirmwareGroupUnassignState } from '../redux/firmwareUnassignGroupSlice'
// import { GroupParaObj } from '../services/firmwareList'
import { AssetGroupState } from '../../device-provisioning/redux/groupListSlice'
import LanguageStrings from '../../../i18n/locales'
interface GroupParaObj {
  group_id: string
  group_name: string
  status: string
}

const UnassignGroupStrings = LanguageStrings().AssetFirmwareStrings.UnassignGroupPopup
const FirmwareUpgradeStatusStrings = LanguageStrings().FirmwareUpgradeStatusStrings

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

const UnAssignGroupPopup = (props: AllProps): JSX.Element => {
  const [showSuccessPopUp, setshowSuccessPopUp] = useState(false)
  const [selected, setSelected] = React.useState<string[]>([])
  const [rows, setRows] = useState<GroupParaObj[]>(props.UnassigGroups)
  const [created, setCreated] = useState(false)
  const [showHelper, setShowHelper] = useState(false)
  const FirmwareUnAssignGroup = useSelector(
    (state: RootState) => state.firmwareUnassignGroup
  )

  React.useEffect(() => {
    setRows(props.UnassigGroups)
  }, [])

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& th': {
      border: '1px solid #202020'
    }
  }))

  // istanbul ignore next
  const handleRowClick = (id: string): void => {
    const isIdSelected = selected.includes(id)
    if (!isIdSelected) {
      // istanbul ignore next
      if (!((rows.length - 1) === selected.length)) {
        setSelected([...selected, id])
        setShowHelper(false)
      } else {
        setShowHelper(true)
      }
    } else {
      setShowHelper(false)
      setSelected(selected.filter((selectedId) => selectedId !== id))
    }
  }

  // istanbul ignore next
  const handleClickPositive = (): void => {
    setshowSuccessPopUp(true)
  }

  // istanbul ignore next
  const handlePopupclose = (): void => {
    setCreated(false)
    setshowSuccessPopUp(false)
    props.onAssetGroupPopUpClose()
  }

  const isSelected = (id: string): boolean => selected.includes(id)

  const handleClickPositiveCreateAndUpdate = (): void => {
    props.unAssignFirmwareGroup({
      groupIds: selected,
      firmwareCampaignId: props.selectedFirmware
    })
  }

  const handleSuccessCreate = (): JSX.Element => {
    // istanbul ignore next
    const handlePositive = (): void => {
      props.onAssetGroupPopUpClose()
      props.handleFetchFirmwareList()
      if (props.refreshFirmwareList !== undefined) {
        props.refreshFirmwareList()
      }
    }

    // istanbul ignore next
    const handleClose = (): void => {
      props.onAssetGroupPopUpClose()
      props.handleFetchFirmwareList()
    }
    // istanbul ignore else
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (FirmwareUnAssignGroup.isLoading) {
      return (
        <Modal title={UnassignGroupStrings.Title}>
          <div className="CircularprogressAmi">
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.firmwareGroupUnassign?.httpStatus === 200) {
      return (
        <Modal buttonTypeNegative='hollow' title={UnassignGroupStrings.SuccessfullyUnassigned} LabelPositive='Ok' onClickPositive={handlePositive}
          onClose={handleClose}>
          <p data-testid='success-test' style={{ paddingTop: '1.2em' }}>{UnassignGroupStrings.Grouphasbeenunassignedsuccessfully}</p>
        </Modal>
      )
    } else if ((props.firmwareGroupUnassign?.httpStatus === 400) || (props.firmwareGroupUnassign?.httpStatus === 404)) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={UnassignGroupStrings.FailedtoUnassignGroup}
          LabelPositive="Ok"
          onClickPositive={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {UnassignGroupStrings.ErrorOccurredUnassigning}
          </p>
        </Modal>
      )
    } else if (props.firmwareGroupUnassign?.httpStatus === 401) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative="hollow"
          LabelNegative="Close"
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }}
          title={UnassignGroupStrings.Title}
        >
          <div className="authError">
            <AuthError
              className={'errorCompInsideGroupModal'}
              errorMessage="Unauthorized"
            />
          </div>
        </Modal>
      )
    } else if (props.firmwareGroupUnassign.httpStatus === 403) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative="hollow"
          LabelNegative="Close"
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }}
          title={UnassignGroupStrings.Title}
        >
          <div className="authError">
            <AuthError
              className={'errorCompInsideGroupModal'}
              errorMessage="accessForbidden"
            />
          </div>
        </Modal>
      )
    } else {
      return (
        <Modal
          title={'Error!!!'}
          onClose={() => {
            handlePopupclose()
          }}
        >
          <div className="errorCompInsideGroupModal">
            <AuthError errorMessage="cannotUnAssginGroup" />
          </div>
        </Modal>
      )
    }
  }

  const disableUnAssignButton = (): boolean => {
    if (selected.length > 0) {
      return false
    } else {
      return true
    }
  }

  // istanbul ignore next
  const handleSuccessModalPositive = (): void => {
    setshowSuccessPopUp(false)
    setCreated(true)
    handleClickPositiveCreateAndUpdate()
  }

  // istanbul ignore next
  const handleSuccessModalClose = (): void => {
    setshowSuccessPopUp(false)
  }

  const isDisabled = (status: string): boolean => {
    if (status === 'Not Started') {
      return false
    } else {
      return true
    }
  }

  return (
    <>
      <div>
        <Modal
          buttonTypeNegative="hollow"
          title={UnassignGroupStrings.Title}
          LabelPositive="Unassign"
          positiveDisabled={disableUnAssignButton()}
          height={'fit-content'}
          onClickPositive={() => {
            handleClickPositive()
          }}
          onClose={() => {
            props.onAssetGroupPopUpClose()
          }}
        >
          <TableContainer className="unassign-group-table">
            <Table
              className="unassign-table-group"
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <TableBody>
                { rows.length > 0
                  ? rows.map((row: GroupParaObj, index: number) => {
                    const isItemSelected = isSelected(row.group_id)
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <>
                        <StyledTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.group_id}
                          aria-label="devices-firmware-row"
                          sx={{
                            cursor: 'pointer',
                            borderBottom: '1px solid #ffffff'
                          }}
                        >
                          <StyledTableCell
                          padding="checkbox"
                          sx={{
                            cursor: 'pointer',
                            borderBottom: '2px solid #181818 !important'
                          }}
                          >
                            <Checkbox
                              disabled={isDisabled(row.status)}
                              sx={{ color: '#909090' }}
                              data-testid={`row-checkbox-${index}`}
                              checked={isItemSelected}
                              onChange={() => {
                                handleRowClick(row.group_id)
                              }}
                              inputProps={{
                                'aria-labelledby': labelId,
                                'aria-label': 'test-checkbox',
                                id: `select-item-${index}`
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell
                            id={labelId}
                            scope="row"
                            aria-label="assetType"
                            padding="none"
                            data-testid="assetType"
                            sx={{
                              cursor: 'pointer',
                              borderBottom: '2px solid #181818 !important'
                            }}
                          >
                            {row.group_name}
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{
                              fontSize: '10px',
                              cursor: 'pointer',
                              borderBottom: '2px solid #181818 !important'
                            }}
                          >
                            {row.status}
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    )
                  })
                  : <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <AuthError errorMessage="NoDataPresentForSearch">
                        <div className="errorCompInsideGroupModal" />
                      </AuthError>
                    </div>
                }
              </TableBody>
            </Table>
            {showHelper && <p>{FirmwareUpgradeStatusStrings.All_groups_cannot_be_unassigned}</p>}
          </TableContainer>
        </Modal>
        {showSuccessPopUp && (
          <Modal
            buttonTypeNegative="hollow"
            title={UnassignGroupStrings.Title}
            LabelPositive="Confirm"
            LabelNegative="Cancel"
            onClickPositive={handleSuccessModalPositive}
            onClose={handleSuccessModalClose}
            onClickNegative={handleSuccessModalClose}
          >
            <p style={{ paddingTop: '1.2em' }}>
              {FirmwareUpgradeStatusStrings.UnassigngroupoftheselectedFirmwarecampaign}
            </p>
          </Modal>
        )}
        {created && handleSuccessCreate()}
      </div>
    </>
  )
}

interface DispatchToProps {
  unAssignFirmwareGroup: (firmwareGroupUnassignBody: FirmwareGroupUnassignRequest) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  unAssignFirmwareGroup: (
    firmwareGroupUnassignBody: FirmwareGroupUnassignRequest
  ) => dispatch(unAssignFirmwareGroup(firmwareGroupUnassignBody))
})

interface StateToProps {
  groupListData: AssetGroupState
  firmwareGroupUnassign: FirmwareGroupUnassignState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  groupListData: state.groupListData,
  firmwareGroupUnassign: state.firmwareUnassignGroup
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export interface ParentProps {
  onAssetGroupPopUpClose: () => void
  selectedFirmware: string
  assetType: string
  UnassigGroups: GroupParaObj[]
  useCustomPositveFn?: boolean
  customPositiveFn?: () => void
  refreshFirmwareList?: () => void
  handleFetchFirmwareList: () => void
}
type AllProps = PropsFromRedux & ParentProps

export default connector(UnAssignGroupPopup)
