import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZDeviceHistory = z.object({
  state_to: z.string(),
  state_from: z.string(),
  state_create_date: z.string(),
  state_end_date: z.string().nullable(),
  transition_time: z.string().nullable(),
  user_details: z.string()
})

const ZData = z.object({
  total_count: z.number(),
  state_details: ZDeviceHistory.array()

})

const ZApiResponse = z.object({
  httpStatus: z.any(),
  data: z.optional(ZData),
  error: z.boolean()
})

export type DeviceHistory = z.infer<typeof ZDeviceHistory>

export async function getDeviceHistory (
  assetId: string,
  tenantId: string,
  onSuccess: (deviceHistory: any) => any,
  onFailure: (errorMessage: any) => any
): Promise<void> {
  const url = `/assetrest/api/v1/udp/assets/assetStateHistory/${assetId}?tenantId=${tenantId}`

  interceptedAxios.get(url, { timeout: 15000 })
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        // istanbul ignore else
        if (parseResponse.success) {
          // istanbul ignore else
          if (parseResponse.data.data !== null && parseResponse.data.data !== undefined) {
            onSuccess(parseResponse.data.data)
          } else {
            onFailure(parseResponse.data.error)
          }
        } else {
          onFailure(parseResponse.error.message)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message)
    })
}
