import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZAsset = z.object({
  id: z.string(),
  name: z.string(),
  serial_number: z.string(),
  firmware_version: z.string(),
  state: z.string(),
  tag: z.string().nullable()
})

const ZData = z.object({
  total_count: z.number(),
  assets: ZAsset.array()
})

const ZApiResponse = z.object({
  http_status: z.any(),
  data: z.optional(ZData),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type Asset = z.infer<typeof ZAsset>
export type AssetList = z.infer<typeof ZData>

export async function getUnassignedAssetList (
  assetTypeId: string,
  templateId: string,
  page: number,
  size: number,
  sortdir: string,
  sortfield: string,
  onSuccess: (assetList: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/assetrest/api/v1/udp/assets/configuration/unassigned/asset?deviceTypeId=${assetTypeId}&templateId=${templateId}&page=${page}&size=${size}&sort=${sortfield},${sortdir}`

  interceptedAxios.get(url, { timeout: 40000 })
    .then((response) => {
      const parseResponse = ZApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        // istanbul ignore else
        if (parseResponse.data.data !== null && parseResponse.data.data !== undefined) {
          onSuccess(parseResponse.data.data, parseResponse.data.http_status)
        } else {
          onFailure(parseResponse.data.error, parseResponse.data.http_status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        // istanbul ignore next
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.message, error.response.status)
        }
      } else {
        onFailure(error.message, error.status)
      }
    })
}
