import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type AssetTypes } from '../services/assetTypeService'

export interface AssetTypesState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null
  assetTypes: AssetTypes[]
}

const initialState: AssetTypesState = {
  isLoading: true,
  error: null,
  httpStatus: null,
  assetTypes: []
}

const assetTypesSlice = createSlice({
  name: 'AssetAndCategoryTypes',
  initialState,
  reducers: {
    assetTypesLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0,
        assetTypes: []
      }
    },
    assetTypesUpdateAction: (state, action: PayloadAction<{ data: AssetTypes[], httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.httpStatus,
        assetTypes: action.payload.data
      }
    },
    assetTypesErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        assetTypes: []
      }
    }
  }
})

export const {
  assetTypesLoadingAction,
  assetTypesUpdateAction,
  assetTypesErrorAction
} = assetTypesSlice.actions

export default assetTypesSlice.reducer
