import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
export interface PublicKeyDataState {
  isLoading: boolean
  loadingError: string | undefined
  PublicKeyData: string | undefined
  httpStatus: number
}

// Initial state of the slice
const initialState: PublicKeyDataState = {
  isLoading: true,
  loadingError: undefined,
  PublicKeyData: undefined,
  httpStatus: 0
}

// Slice to return from action
export const getPublicKeyDataState = createSlice({
  name: 'PublicKeyData',
  initialState,
  reducers: {
    PublicKeyDataLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: undefined,
        PublicKeyData: undefined,
        httpStatus: 0
      }
    },
    PublicKeyDataUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: undefined,
        PublicKeyData: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    PublicKeyDataErrorAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        httpStatus: action.payload.httpStatus,
        PublicKeyData: undefined
      }
    }
  }
})

// Actions to export
export const {
  PublicKeyDataLoadingAction,
  PublicKeyDataUpdateAction,
  PublicKeyDataErrorAction
} = getPublicKeyDataState.actions

export default getPublicKeyDataState.reducer
