import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface UploadFileInfoState {
  isLoading: boolean
  message: any | null
  errorMessage: string | null
}

const initialState: UploadFileInfoState = {
  isLoading: false,
  message: null,
  errorMessage: null
}

export const deviceInfoSlice = createSlice({
  name: 'deviceInfoSlice',
  initialState,
  reducers: {
    resetDeviceUpload: () => {
      return {
        isLoading: false,
        message: null,
        errorMessage: null
      }
    },
    uploadFileInfoDataLoading: (state): UploadFileInfoState => {
      return {
        isLoading: true,
        message: null,
        errorMessage: null
      }
    },
    uploadFileInfoDataError: (state, action: PayloadAction<string>): UploadFileInfoState => {
      return {
        isLoading: false,
        message: null,
        errorMessage: action.payload
      }
    },
    uploadFileInfoData: (state, action: PayloadAction<string>): UploadFileInfoState => {
      return {
        isLoading: false,
        message: action.payload,
        errorMessage: null
      }
    }
  }
})

export const { uploadFileInfoDataLoading, uploadFileInfoDataError, uploadFileInfoData, resetDeviceUpload } = deviceInfoSlice.actions
export default deviceInfoSlice.reducer
