import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ApiResponse } from '../services/updateConfigService'

export interface UpdateConfigurationState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null | undefined
}

const initialState: UpdateConfigurationState = {
  isLoading: true,
  error: null,
  httpStatus: null
}

const updateConfigurationSlice = createSlice({
  name: 'updateConfig',
  initialState,
  reducers: {
    updateConfigurationLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0
      }
    },
    updateConfigurationUpdateAction: (state, action: PayloadAction<{ data: ApiResponse }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.data.http_status
      }
    },
    updateConfigurationErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  updateConfigurationLoadingAction,
  updateConfigurationUpdateAction,
  updateConfigurationErrorAction
} = updateConfigurationSlice.actions

export default updateConfigurationSlice.reducer
