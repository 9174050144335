import './App.css'
import './login.css'
import './features/asset-configuration/assetConfigStyle.css'
import { BrowserRouter as Router } from 'react-router-dom'
import Main from './features/dashboard/components/MainComponent'
import React from 'react'
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#64C3FF'
    }
  }
})

function App (): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Router>
          <Main />
        </Router>
      </div>
    </ThemeProvider>
  )
}

export default App
