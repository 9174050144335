/* eslint-disable @typescript-eslint/consistent-type-imports */
import React, { useEffect, useState } from 'react'
import Modal from '../../../components/Modal'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { useAuth } from 'react-oidc-context'
import LanguageStrings from '../../../i18n/locales'
import { CircularProgress, Stack } from '@mui/material'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { type FirmwareGroupAssignState } from '../redux/firmwareAssignGroupSlice'
import { type FirmwareGroupAssignRequest } from '../services/firmwareAssignGroupService'
import { fetchGroupWithCountListData, assignFirmwareGroup } from '../redux/actionCreators'
import { type AssetGroupWithCountState } from '../redux/groupWithCountListSlice'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

interface Option {
  value: string
  label: string
  count: number
  countName: string
  checkBox: boolean
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #E6E8ED'
    },
    color: 'white'
  }
}))

const textStyle = {
  root: {
    backgroundColor: '#272727',
    width: '100%',
    '& .MuiInputBase-input': {
      color: '#F0F0F0',
      height: '30px',
      paddingLeft: '10px',
      borderRadius: '0px'
    },
    '& .MuiInputLabel-root': {
      color: '#F0F0F0',
      boxShadow: 'none',
      '&.Mui-focused': {
        color: '#F0F0F0'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00000000'
      },
      '&:hover fieldset': {
        borderColor: '#00000000'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00000000'
      }
    }
  }
}

const AssignGroupStrings = LanguageStrings().AssetFirmwareStrings.AssignGroupPopup
const AssignGroupPopUp = (props: AllProps): JSX.Element => {
  const [groupOptions, setGroupOptions] = useState<Option[]>([])
  const [group, setGroup] = useState<string[]>([])
  const [created, setCreated] = useState(false)
  const [showSuccessPopUp, setshowSuccessPopUp] = useState(false)
  const [maximumAssetPopup, setMaximumAssetPopup] = useState(false)
  const [search, setSearch] = React.useState('')
  const [filteredOptions, setFilteredOptions] = React.useState<Option[]>(groupOptions)
  const auth = useAuth()
  const assetType: any = useSelector(
    (state: RootState) => state.deviceDetail.deviceData
  )
  const FirmwareGroupStatus: any = useSelector(
    (state: RootState) => state.createFirmwareGroup
  )

  useEffect(() => {
    // istanbul ignore next
    props.fetchGroupWithCountListData(
      props.assetType === undefined ? assetType[0].deviceType : props.assetType,
      'group',
      auth.user?.profile.customer as string
    )
  }, [])

  useEffect(() => {
    let transformedArray: React.SetStateAction<Option[]> = []
    if (
      props.groupListData.groupListData !== null &&
      props.groupListData.groupListData !== undefined
    ) {
      transformedArray = props.groupListData.groupListData.map((item) => ({
        value: item.assetGroupId,
        label: item.groupName,
        count: item.assetCount,
        countName: item.assetCountDisplayName,
        checkBox: item.groupMapped
      }))
      transformedArray = transformedArray.filter(
        (item) => !props.assignedGroups.includes(item.label)
      )
    }
    setGroupOptions(transformedArray)
  }, [props.groupListData])

  // istanbul ignore next
  const handleChooseGroup = (event: SelectChangeEvent<string[]>): void => {
    const { target: { value } } = event
    if (getSumOfAssets(value, groupOptions) > 500000) {
      setMaximumAssetPopup(true)
    } else {
      setGroup(typeof value === 'string' ? value.split(',') : value)
    }
  }

  // istanbul ignore next
  const getSumOfAssets = (selectedGroup: string | string[], allGroup: Option[]): any => {
    let sum = 0
    allGroup.forEach((item) => {
      if (selectedGroup.includes(item.value)) {
        sum += item.count
      }
    })
    return sum
  }

  const disableAssignButton = (): boolean => {
    if (group.length < 1) {
      // istanbul ignore next
      return true
    } else {
      return false
    }
  }
  // istanbul ignore next
  const tooltipTitle = (label: string): string => {
    return label.length > 15 ? label : ''
  }

  // istanbul ignore next
  const ListItemTextLabel = (label: string): string => {
    return label.length > 15 ? `${label.substring(0, 15)}..` : label
  }

  const handleGenericDropdownWithFailure = (): JSX.Element => {
    if (props.groupListData.isLoading) {
      return <>
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
      </>
    } else if ((props.groupListData.httpStatus === 200 && props.groupListData.groupListData !== undefined)) {
      return (
        <Stack>
          <div>
            <span style={{ fontSize: '15px', padding: '7px 0px', display: 'block' }} >
              Select Group
              <sup style={{ color: '#EE3426' }}> *</sup>
            </span>
            <FormControl style={{ width: '100%' }}>
              <Select
                data-testid='assign-group-dropdown'
                labelId="choose-group-checkbox-label"
                multiple
                value={group}
                onChange={handleChooseGroup}
                sx={{ height: '40px', backgroundColor: '#5c5b5bdd', borderRadius: '4px', color: '#d0d0d0' }}
                // renderValue={(selected: any) => `${selected.length}  selected`}
                MenuProps={MenuProps}
                aria-label='user-change'
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Select an option</em>
                  }
                  return `${selected.length}  selected`
                }}
                displayEmpty
                onKeyDownCapture={(event) => {
                  if (event.key !== 'Escape') {
                    event.stopPropagation()
                  }
                }}
              >
                <TextField
                  variant="standard"
                  id='campaign-assign-group-search'
                  placeholder='Search'
                  value={search}
                  onChange={handleSearch}
                  InputLabelProps={{
                    disabled: true
                  }}
                  size='small'
                  className='custom-textfield'
                  sx={
                    textStyle.root
                  }
                />
                {filteredOptions?.map((item, index) => (
                  <MenuItem key={item.value} value={item.value} disabled={item.checkBox} >
                    <Checkbox
                      inputProps={{ id: `select-item-${index}` }}
                      checked={group.includes(item.value)}
                      sx={{ color: '#909090' }}
                    />
                    <LightTooltip
                      arrow
                      placement="top"
                      // istanbul ignore next
                      title={tooltipTitle(item.label)}
                    >
                      <ListItemText
                        // istanbul ignore next
                        primary={ListItemTextLabel(item.label)}
                      />
                    </LightTooltip>
                    <ListItemText style={{ float: 'right', textAlign: 'right' }} primary={`${item.countName}`}/>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p style={{ marginTop: '0.5rem' }} >Maximum 50K assets allowed</p>
          </div>
          {maximumAssetPopup && (
            <Modal
              buttonTypeNegative='hollow'
              title={'CreateCampaignPopupStrings.maximum_asset_title'}
              LabelPositive='Ok'
              onClickPositive={() => {
                setMaximumAssetPopup(false)
              }}
              onClose={() => {
                setMaximumAssetPopup(false)
              }}
            >
              <p style={{ paddingTop: '1.2em' }}>
                {
                  'CreateCampaignPopupStrings.maximum_asset_body'
                }
              </p>
            </Modal>
          )}
        </Stack>
      )
    } else if (props.groupListData.httpStatus === 401) {
      return (
        <div className='errorCompInsideModal'><AuthError errorMessage="Unauthorized" /></div>
      )
    } else if (props.groupListData.httpStatus === 403) {
      return (
        <div className='errorCompInsideModal'><AuthError errorMessage="accessForbidden" /></div>
      )
    } else {
      return (
        <div className='errorCompInsideModal'><AuthError errorMessage="cannotFetchGroups" /></div>
      )
    }
  }

  useEffect(() => {
    setFilteredOptions(groupOptions)
  }, [groupOptions])

  // istanbul ignore next
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
    setFilteredOptions(groupOptions.filter((option) => option.label.toLowerCase().includes(event.target.value.toLowerCase())))
  }
  const handleClickPositiveCreateAndUpdate = (): void => {
    props.assignFirmwareGroup({
      groupIds: group,
      firmwareCampaignId: props.selected
    })
  }

  const handleSuccessCreate = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (FirmwareGroupStatus.isLoading || props.updateGroup.isLoading) {
      // istanbul ignore else
      return (
        <Modal title={AssignGroupStrings.title}>
          <div className='CircularprogressAmi'>
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (created && ((FirmwareGroupStatus?.httpStatus === 200) || (props.updateGroup.httpStatus === 200))) {
      return (
        <Modal buttonTypeNegative='hollow' title={AssignGroupStrings.successTitle}LabelPositive='Ok' onClickPositive={() => {
          props.onAssetGroupPopUpClose()
          if (props.refreshFirmwareList !== undefined) {
            props.refreshFirmwareList()
          }
        }}
        onClose={() => {
          props.onAssetGroupPopUpClose()
          if (props.refreshFirmwareList !== undefined) {
            props.refreshFirmwareList()
          }
        }}
      >
          <p style={{ paddingTop: '1.2em' }}>{AssignGroupStrings.Grouphasbeenassignedsuccessfully}</p>
        </Modal>
      )
    } else if ((FirmwareGroupStatus?.httpStatus === 401) || (props.updateGroup.httpStatus === 401)) {
      return (
        <Modal ariaLabel='final-close-btn' titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }} title={AssignGroupStrings.Assign_Unsuccessfull_Title}>
          <div className='authError'>
            <AuthError className={'errorInsideGroupModal'} errorMessage="Unauthorized" />
          </div>
        </Modal>
      )
    } else if ((FirmwareGroupStatus.httpStatus === 403) || (props.updateGroup.httpStatus === 403)) {
      return (
        <Modal ariaLabel='final-close-btn' titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }} title={AssignGroupStrings.Assign_Unsuccessfull_Title}>
          <div className='authError'>
            <AuthError className={'errorInsideGroupModal'} errorMessage="accessForbidden" />
          </div>
        </Modal>
      )
    } else if ((props.updateGroup.httpStatus === 400) || (props.updateGroup.httpStatus === 404)) {
      return (
        <Modal ariaLabel='final-close-btn' titleStyle={{ paddingRight: '2em' }} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }} title={AssignGroupStrings.Assign_Unsuccessfull_Title}>
          <div className='authError'>
            <AuthError className={'errorInsideGroupModal'} customError={props.updateGroup.error} />
          </div>
        </Modal>
      )
    } else {
      return (<Modal ariaLabel='final-close-btn' title={'Error!!!'} onClose={() => { props.onAssetGroupPopUpClose() }}>
        <div className='errorInsideGroupModal'><AuthError errorMessage="cannotAssginGroup" /></div>
      </Modal>
      )
    }
  }
  return (
    <div>
      {(!showSuccessPopUp || (showSuccessPopUp)) && <Modal
        buttonTypeNegative="hollow"
        title={AssignGroupStrings.AssignGroup}
        LabelPositive="Assign"
        LabelNegative="Cancel"
        height={'fit-content'}
        positiveDisabled={disableAssignButton()}
        onClickPositive={() => {
          setshowSuccessPopUp(true)
        }}
        onClickNegative={() => {
          props.onAssetGroupPopUpClose()
        }}
        onClose={() => {
          props.onAssetGroupPopUpClose()
        }}
      >
        <div style={{ marginTop: '3em' }}>
          {handleGenericDropdownWithFailure()}
        </div>
        <div
          style={{
            marginTop: '1.5em',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
        </div>
      </Modal>}
      {showSuccessPopUp && (
        <Modal
          buttonTypeNegative="hollow"
          title={AssignGroupStrings.AssignGroup}
          LabelPositive='Confirm'
          LabelNegative='Cancel'
          onClickPositive={() => {
            setshowSuccessPopUp(false)
            setCreated(true)
            handleClickPositiveCreateAndUpdate()
          }}
          onClose={() => {
            setshowSuccessPopUp(false)
          }}
          onClickNegative={() => {
            setshowSuccessPopUp(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {AssignGroupStrings.Assign_Group_To_The_Selected_Firmware}
          </p>
        </Modal>
      )}
      {created && handleSuccessCreate()}
    </div>
  )
}

interface DispatchToProps {
  assignFirmwareGroup: (firmwareGroupAssignBody: FirmwareGroupAssignRequest) => void
  fetchGroupWithCountListData: (
    assetType: string,
    groupName: string,
    tenantId: string
  ) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  assignFirmwareGroup: (firmwareGroupAssignBody: FirmwareGroupAssignRequest) => dispatch(assignFirmwareGroup(firmwareGroupAssignBody)),
  fetchGroupWithCountListData: (
    assetType: string,
    groupName: string,
    tenantId: string
  ) => dispatch(fetchGroupWithCountListData(assetType, groupName, tenantId))
})

interface StateToProps {
  groupListData: AssetGroupWithCountState
  fileName: string
  updateGroup: FirmwareGroupAssignState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  groupListData: state.groupWithCountListState,
  fileName: state.deviceInfo.message?.data.fileName,
  updateGroup: state.firmwareAssignGroup

})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export interface ParentProps {
  onAssetGroupPopUpClose: () => void
  selected: string
  assetType: string
  useCustomPositveFn?: boolean
  customPositiveFn?: () => void
  refreshFirmwareList?: () => void
  assignedGroups: string[]
}
type AllProps = PropsFromRedux & ParentProps

export default connector(AssignGroupPopUp)
