import React from 'react'
import Modal from '../../../../../components/Modal'

export const getTemplateModal = (templates: string[], handleModalClose: () => void, title: string, ariaLabel?: string): JSX.Element => {
  const tdrStyle: React.CSSProperties = {
    padding: '10px 0'
  }

  // istanbul ignore next
  if (templates.length === 0) {
    return <div />
  }

  return (
    <Modal ariaLabel={ariaLabel} sx={{ paddingBottom: '10px' }} height='auto' width='600px' title={`${title} (${templates.length})`} onClose={handleModalClose}>
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <table style={{ width: '100%', padding: '10px 5px 0 0' }}>
          <tbody>
            {
              templates.map((templateName: string, index: number) => (
                <tr key={index}>
                  <td style={{ margin: '1em 0 0 0', fontSize: '14px', ...tdrStyle, borderBottom: '1px solid #000', textAlign: 'center' }}>{templateName}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </Modal>
  )
}
