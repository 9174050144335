import React, { useEffect, useState } from 'react'
import Modal from '../../../components/Modal'
import { CircularProgress, Stack } from '@mui/material'
import Tooltip, { type TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { useAuth } from 'react-oidc-context'
import LanguageStrings from '../../../i18n/locales'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import './CreateCampaignPopup.css'
import { isValidName } from '../../../utils/validator'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { type FirmwareGroupAssignState } from '../redux/firmwareAssignGroupSlice'
import { type FirmwareCreateCampaignState } from '../redux/firmwareCreateCampaignSlice'
import { type FirmwareGroupAssignRequest } from '../services/firmwareAssignGroupService'
import { type FirmwareCreateCampaignRequest } from '../services/firmwareCreateCampaignService'
import { type AssetGroupWithCountState } from '../redux/groupWithCountListSlice'
import { fetchGroupWithCountListData, assignFirmwareGroup, firmwareCreateCampaign } from '../redux/actionCreators'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

interface Option {
  value: string
  label: string
  count: number
  countName: string
  checkBox: boolean
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #E6E8ED'
    },
    color: 'white'
  }
}))

const CreateCampaignPopupStrings = LanguageStrings().FirmwareCampaignPopupStrings
const textStyle = {
  root: {
    backgroundColor: '#272727',
    width: '100%',
    '& .MuiInputBase-input': {
      color: '#F0F0F0',
      height: '30px',
      paddingLeft: '10px',
      borderRadius: '0px'
    },
    '& .MuiInputLabel-root': {
      color: '#F0F0F0',
      boxShadow: 'none',
      '&.Mui-focused': {
        color: '#F0F0F0'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00000000'
      },
      '&:hover fieldset': {
        borderColor: '#00000000'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00000000'
      }
    }
  }
}

const CreateCampaignPopup = (props: AllProps): JSX.Element => {
  const [groupOptions, setGroupOptions] = useState<Option[]>([])
  const [group, setGroup] = useState<string[]>([])
  const [created, setCreated] = useState(false)
  const [showSuccessPopUp, setshowSuccessPopUp] = useState(false)
  const [maximumAssetPopup, setMaximumAssetPopup] = useState(false)
  const [campaignTitle, setCampaignTitle] = useState('')
  const [titleError, setTitleError] = useState(false)
  const [lenghtError, setLenghtError] = useState(false)
  const [search, setSearch] = React.useState('')
  const [filteredOptions, setFilteredOptions] = React.useState<Option[]>(groupOptions)
  const schedulerType: string = CreateCampaignPopupStrings.immediate
  const auth = useAuth()
  const assetType: any = useSelector(
    (state: RootState) => state.deviceDetail.deviceData
  )

  const navigate = useNavigate()

  useEffect(() => {
    props.fetchGroupWithCountListData(
      // istanbul ignore next
      props.assetType === undefined ? assetType[0].deviceType : props.assetType,
      'group',
      auth.user?.profile.customer as string
    )
  }, [])

  // istanbul ignore next
  useEffect(() => {
    let transformedArray: React.SetStateAction<Option[]> = []
    if (
      props.groupListData.groupListData !== null &&
      props.groupListData.groupListData !== undefined
    ) {
      transformedArray = props.groupListData.groupListData.map((item) => ({
        value: item.assetGroupId,
        label: item.groupName,
        count: item.assetCount,
        countName: item.assetCountDisplayName,
        checkBox: item.groupMapped
      }))
      transformedArray = transformedArray.filter(
        (item) => !props.assignedGroups.includes(item.label)
      )
    }
    setGroupOptions(transformedArray)
  }, [props.groupListData])

  // istanbul ignore next
  const handleChooseGroup = (event: SelectChangeEvent<string[]>): void => {
    const { target: { value } } = event
    if (getSumOfAssets(value, groupOptions) > 500000) {
      setMaximumAssetPopup(true)
    } else {
      setGroup(typeof value === 'string' ? value.split(',') : value)
    }
  }

  // istanbul ignore next
  const getSumOfAssets = (selectedGroup: string | string[], allGroup: Option[]): any => {
    let sum = 0
    allGroup.forEach((item) => {
      if (selectedGroup.includes(item.value)) {
        sum += item.count
      }
    })
    return sum
  }

  // istanbul ignore next
  const handleClickPositiveCreateAndUpdate = (): void => {
    props.firmwareCreateCampaign({
      firmwareCampaignName: campaignTitle,
      assetGroupIds: group,
      schedulerType,
      firmwareFileId: props.selected
    })
  }

  useEffect(() => {
    setFilteredOptions(groupOptions)
  }, [groupOptions])

  // istanbul ignore next
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
    setFilteredOptions(groupOptions.filter((option) => option.label.toLowerCase().includes(event.target.value.toLowerCase())))
  }

  // istanbul ignore next
  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = event.target.value
    setCampaignTitle(value)
    setLenghtError(value.length < 5 || value.length > 20)
    if (isValidName(value)) {
      setTitleError(false)
    } else {
      setTitleError(true)
    }
  }

  // istanbul ignore next
  const isPositiveDisabled = (): boolean => {
    if (campaignTitle.length <= 0) {
      return true
    } else if (titleError || lenghtError || group.length === 0) {
      return true
    } else {
      return false
    }
  }

  // istanbul ignore next
  const createCampaignHandleSuccess = (): void => {
    setshowSuccessPopUp(false)
    setCreated(true)
    handleClickPositiveCreateAndUpdate()
  }

  const handleGenericDropdownWithFailure = (): JSX.Element => {
    if (props.groupListData.isLoading) {
      return (
        <>
          <div className='CircularprogressAmi'>
            <CircularProgress />
          </div>
        </>
      )
    } else {
      return (
        <Stack spacing={3}>
          <Stack>
            <Typography
              variant='body2'
              sx={{ fontSize: '15px' }}
              display='block'
              gutterBottom
              data-testid='create-campaign-title'
            >
              {CreateCampaignPopupStrings.title}{' '}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </Typography>
            <TextField
              id='outlined-basic'
              value={campaignTitle}
              onChange={handleTitleChange}
              variant='outlined'
              className='campaign-popup-custom-textfield'
              data-testid='create-campaign-textfield'
              inputProps={{ 'aria-label': 'campaignName' }}
            />
            <div>
              {
                // istanbul ignore next
                titleError && (
                  <p style={{ color: '#EE3426' }}>
                    {CreateCampaignPopupStrings.invalid_text}
                  </p>
                )
              }
              {
                // istanbul ignore next
                lenghtError && (
                  <p style={{ color: '#EE3426' }}>
                    {CreateCampaignPopupStrings.min_max_charecter}
                  </p>
                )
              }
            </div>
          </Stack>
          <div>
            <span style={{ fontSize: '15px', padding: '7px 0px', display: 'block' }} >
              {CreateCampaignPopupStrings.Select_Group}
              <sup style={{ color: '#EE3426' }}> *</sup>
            </span>
            <FormControl style={{ width: '100%' }}>
              <Select
                labelId="choose-group-checkbox-label"
                multiple
                value={group}
                onChange={handleChooseGroup}
                sx={{ height: '40px', backgroundColor: '#5c5b5bdd', borderRadius: '4px', color: '#d0d0d0' }}
                // renderValue={(selected: any) => `${selected.length}  selected`}
                MenuProps={MenuProps}
                aria-label='user-change'
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>{CreateCampaignPopupStrings.Select_an_option}</em>
                  }
                  return `${selected.length}  selected`
                }}
                displayEmpty
                onKeyDownCapture={(event) => {
                  if (event.key !== 'Escape') {
                    event.stopPropagation()
                  }
                }}
              >
                <TextField
                  variant="standard"
                  id='standard-basic'
                  placeholder='Search'
                  value={search}
                  onChange={handleSearch}
                  InputLabelProps={{
                    disabled: true
                  }}
                  size='small'
                  className='custom-textfield'
                  sx={
                    textStyle.root
                  }
                />
                {filteredOptions?.map((item, index) => (
                  <MenuItem key={item.value} value={item.value} disabled={item.checkBox} >
                    <Checkbox
                      inputProps={{ id: `select-item-${index}` }}
                      checked={group.includes(item.value)}
                      sx={{ color: '#909090' }}
                    />
                    <LightTooltip
                      arrow
                      placement="top"
                      title={item.label.length > 15 ? `${item.label}` : ''}
                    >
                      <ListItemText
                        primary={item.label.length > 15 ? `${item.label.substring(0, 15)}..` : item.label}
                      />
                    </LightTooltip>
                    <ListItemText style={{ float: 'right', textAlign: 'right' }} primary={`${item.countName}`}/>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <p style={{ marginTop: '0.5rem' }} >{CreateCampaignPopupStrings.max_50k_assets_allowed}</p>
          </div>
          <FormControl>
            <Typography
              variant='body2'
              sx={{ fontSize: '15px' }}
              display='block'
              gutterBottom
            >
              {CreateCampaignPopupStrings.schedule}{' '}
              <sup style={{ color: '#EE3426' }}>*</sup>
            </Typography>
            <RadioGroup
              defaultValue={CreateCampaignPopupStrings.immediate}
              name='radio-buttons-group'
              data-testid='create-campaign-schedule-radio-btn'
            >
              <FormControlLabel
                value={CreateCampaignPopupStrings.immediate}
                control={<Radio />}
                label={CreateCampaignPopupStrings.immediate}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      )
    }
  }

  // istanbul ignore next
  const handleSuccessCreate = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (props.firmwareCreateCampaignData.isLoading) {
      return (
        <Modal title={CreateCampaignPopupStrings.title}>
          <div className='CircularprogressAmi'>
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.firmwareCreateCampaignData.httpStatus === 200) {
      return (
        <Modal
          buttonTypeNegative='hollow'
          title={CreateCampaignPopupStrings.successTitle}
          LabelPositive='Ok'
          LabelNegative='View Status'
          onClickPositive={() => {
            setCreated(false)
            setshowSuccessPopUp(false)
            props.onCreateCampaignPopUpClose()
          }}
          onClose={() => {
            setCreated(false)
            setshowSuccessPopUp(false)
            props.onCreateCampaignPopUpClose()
          }}
          onClickNegative={() => {
            navigate('/firmware-upgrade-status')
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {CreateCampaignPopupStrings.campaign_has_been_created_successfully}
          </p>
        </Modal>
      )
    } else if (props.firmwareCreateCampaignData.httpStatus === 401) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative='hollow'
          LabelNegative='Close'
          onClickNegative={() => {
            props.onCreateCampaignPopUpClose()
          }}
          onClose={() => {
            props.onCreateCampaignPopUpClose()
          }}
          title={CreateCampaignPopupStrings.create_campaign_unsuccessfull_title}
        >
          <div className='authError'>
            <AuthError
              className={'errorInsideGroupModal'}
              errorMessage='Unauthorized'
            />
          </div>
        </Modal>
      )
    } else if (props.firmwareCreateCampaignData.httpStatus === 403) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative='hollow'
          LabelNegative='Close'
          onClickNegative={() => {
            props.onCreateCampaignPopUpClose()
          }}
          onClose={() => {
            props.onCreateCampaignPopUpClose()
          }}
          title={CreateCampaignPopupStrings.create_campaign_unsuccessfull_title}
        >
          <div className='authError'>
            <AuthError
              className={'errorInsideGroupModal'}
              errorMessage='accessForbidden'
            />
          </div>
        </Modal>
      )
    } else if (props.firmwareCreateCampaignData.httpStatus === 405 && props.firmwareCreateCampaignData.error === 'Firmware Campaign exists. Please provide a different name') {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative='hollow'
          LabelNegative='Close'
          onClickNegative={() => {
            setCreated(false)
            setshowSuccessPopUp(false)
          }}
          onClose={() => {
            setCreated(false)
            setshowSuccessPopUp(false)
          }}
          title={CreateCampaignPopupStrings.create_campaign_unsuccessfull_title}
        >
          <div className='error-msg' style={ { width: '100%', fontSize: '14px', padding: '0px 0px', marginTop: '25px' } } >
            <p>{props.firmwareCreateCampaignData.error}</p>
          </div>
        </Modal>
      )
    } else if (props.firmwareCreateCampaignData.httpStatus === 400) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          height={'fit-content'}
          buttonTypeNegative='hollow'
          LabelNegative='Close'
          onClickNegative={() => {
            props.onCreateCampaignPopUpClose()
          }}
          onClose={() => {
            props.onCreateCampaignPopUpClose()
          }}
          title={CreateCampaignPopupStrings.create_campaign_unsuccessfull_title}
        >
          <div className='authError'>
            <AuthError
              className={'errorInsideGroupModal'}
              customError={props.firmwareCreateCampaignData.error}
            />
          </div>
        </Modal>
      )
    } else {
      return (
        <Modal
          title={'Error'}
          onClose={() => {
            props.onCreateCampaignPopUpClose()
          }}
        >
          <div className={'errorInsideGroupModal'} style={ { width: '100%', fontSize: '14px', padding: '0px 20px' } } >
            <p>{props.firmwareCreateCampaignData.error}</p>
          </div>
        </Modal>
      )
    }
  }
  return (
    <div>
      {(!showSuccessPopUp || showSuccessPopUp) && (
        <Modal
          buttonTypeNegative='hollow'
          title={CreateCampaignPopupStrings.title}
          LabelPositive='Create'
          LabelNegative='Cancel'
          positiveDisabled={isPositiveDisabled()}
          height={'fit-content'}
          onClickPositive={() => {
            // istanbul ignore next
            setshowSuccessPopUp(true)
          }}
          onClickNegative={() => {
            // istanbul ignore next
            props.onCreateCampaignPopUpClose()
          }}
          onClose={() => {
            props.onCreateCampaignPopUpClose()
          }}
        >
          <div style={{ marginTop: '3em' }} >
            {handleGenericDropdownWithFailure()}
          </div>
          <div
            style={{
              marginTop: '1.5em',
              display: 'flex',
              justifyContent: 'center'
            }}
          ></div>
        </Modal>
      )}
      {showSuccessPopUp && (
        // istanbul ignore next
        <Modal
          buttonTypeNegative='hollow'
          title={CreateCampaignPopupStrings.title}
          LabelPositive='Confirm'
          LabelNegative='Cancel'
          onClickPositive={() => {
            createCampaignHandleSuccess()
          }}
          onClose={() => {
            setshowSuccessPopUp(false)
          }}
          onClickNegative={() => {
            setshowSuccessPopUp(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {
              CreateCampaignPopupStrings.create_campaign_for_the_selected_firmware
            }
          </p>
        </Modal>
      )}
      {maximumAssetPopup && (
        // istanbul ignore next
        <Modal
          buttonTypeNegative='hollow'
          title={CreateCampaignPopupStrings.maximum_asset_title}
          LabelPositive='Ok'
          onClickPositive={() => {
            setMaximumAssetPopup(false)
          }}
          onClose={() => {
            setMaximumAssetPopup(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {
              CreateCampaignPopupStrings.maximum_asset_body
            }
          </p>
        </Modal>
      )}
      {created && handleSuccessCreate()}
    </div>
  )
}

interface DispatchToProps {
  fetchGroupWithCountListData: (
    assetType: string,
    groupName: string,
    tenantId: string
  ) => void
  assignFirmwareGroup: (
    firmwareGroupAssignBody: FirmwareGroupAssignRequest
  ) => void
  firmwareCreateCampaign: (
    createCampaignBody: FirmwareCreateCampaignRequest
  ) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchGroupWithCountListData: (
    assetType: string,
    groupName: string,
    tenantId: string
  ) => dispatch(fetchGroupWithCountListData(assetType, groupName, tenantId)),
  assignFirmwareGroup: (firmwareGroupAssignBody: FirmwareGroupAssignRequest) =>
    dispatch(assignFirmwareGroup(firmwareGroupAssignBody)),
  firmwareCreateCampaign: (createCampaignBody: FirmwareCreateCampaignRequest) =>
    dispatch(firmwareCreateCampaign(createCampaignBody))
})

interface StateToProps {
  groupListData: AssetGroupWithCountState
  fileName: string
  updateGroup: FirmwareGroupAssignState
  firmwareCreateCampaignData: FirmwareCreateCampaignState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  groupListData: state.groupWithCountListState,
  fileName: state.deviceInfo.message?.data.fileName,
  updateGroup: state.firmwareAssignGroup,
  firmwareCreateCampaignData: state.FirmwareCreateCampaignState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export interface ParentProps {
  onCreateCampaignPopUpClose: () => void
  selected: string
  assetType: string
  useCustomPositveFn?: boolean
  customPositiveFn?: () => void
  refreshFirmwareList?: () => void
  assignedGroups: string[]
}
type AllProps = PropsFromRedux & ParentProps

export default connector(CreateCampaignPopup)
