import { type KpiKeyDonut, type KpiKeyBar } from '../../redux/changeKpiType'
import { type DonutKpiState, type BarChartState } from '../../types'
import { type ReportData } from './BarCharts'
import { type DonutSeriesData } from './DonutChart'

export const kpiColorMap: Record<KpiKeyBar, string[]> = {
  'Total Registered': ['#1272B2', '#E3D659'],
  'Total Connected': ['#1272B2', '#F1AA3E'],
  'Total Shipped': ['#84D16E'],
  'Total Stale': ['#E3D659'],
  'Last Read Alarms': ['#ED6E56']
}

export const kpiColorMapDonut: Record<KpiKeyDonut, string[]> = {
  'Total Reads': ['#84D16E', '#F19C58', '#ED6E56'],
  'Extended Coverage Level': ['#84D16E', '#F19C58', '#ED6E56'],
  'Signal Breakdown': ['#84D16E', '#F19C58', '#ED6E56'],
  'Alarm Breakdown': ['#ED6E56', '#F19C58', '#E3D659', '#84D16E'],
  'Firmware Breakdown': ['#1272B2', '#F19C58', '#ED6E56', '#E3D659', '#F1AA3E', '#84D16E', '#1272B2', '#F19C58', '#ED6E56', '#E3D659', '#F1AA3E', '#84D16E', '#1272B2', '#F19C58', '#ED6E56', '#E3D659', '#F1AA3E', '#84D16E'],
  'Battery Breakdown': ['#84D16E', '#F19C58', '#ED6E56'],
  'Lifecycle Breakdown': ['#1272B2', '#F19C58', '#ED6E56', '#E3D659']
}

export function buildTotalRegisteredReportData (reportData: ReportData, setReportData: React.Dispatch<React.SetStateAction<ReportData>>, kpiData: BarChartState): void {
  if (kpiData.data !== undefined) {
    const categoryData: string[] = []
    const barOne: number[] = []
    const barTwo: number[] = []
    kpiData.data.liveData.forEach((element) => {
      categoryData.push(element.date)
      barOne.push(element.dataPoint[0].count)
      barTwo.push(element.dataPoint[1].count)
    })
    const seriesData = [
      {
        name: kpiData.data.liveData[0].dataPoint[0].name,
        data: barOne
      }, {
        name: kpiData.data.liveData[0].dataPoint[1].name,
        data: barTwo
      }
    ]
    setReportData({ ...reportData, categories: categoryData, series: seriesData, colors: kpiColorMap['Total Registered'] })
  }
}

export function buildTotalConnectedReportData (reportData: ReportData, setReportData: React.Dispatch<React.SetStateAction<ReportData>>, kpiData: BarChartState): void {
  if (kpiData.data !== undefined) {
    const categoryData: string[] = []
    const barOne: number[] = []
    const barTwo: number[] = []
    kpiData.data.liveData.forEach((element) => {
      categoryData.push(element.date)
      barOne.push(element.dataPoint[0].count)
      barTwo.push(element.dataPoint[1].count)
    })
    const seriesData = [
      {
        name: kpiData.data.liveData[0].dataPoint[0].name,
        data: barOne
      }, {
        name: kpiData.data.liveData[0].dataPoint[1].name,
        data: barTwo
      }
    ]
    setReportData({ ...reportData, categories: categoryData, series: seriesData, colors: kpiColorMap['Total Connected'] })
  }
}

export function buildTotalShippedReportData (reportData: ReportData, setReportData: React.Dispatch<React.SetStateAction<ReportData>>, kpiData: BarChartState): void {
  if (kpiData.data !== undefined) {
    const categoryData: string[] = []
    const barOne: number[] = []
    kpiData.data.liveData.forEach((element) => {
      categoryData.push(element.date)
      barOne.push(element.dataPoint[0].count)
    })
    const seriesData = [
      {
        name: kpiData.data.liveData[0].dataPoint[0].name,
        data: barOne
      }
    ]
    setReportData({ ...reportData, categories: categoryData, series: seriesData, colors: kpiColorMap['Total Shipped'] })
  }
}

export function buildLastReadAlarmsReportData (reportData: ReportData, setReportData: React.Dispatch<React.SetStateAction<ReportData>>, kpiData: BarChartState): void {
  if (kpiData.data !== undefined) {
    const categoryData: string[] = []
    const barOne: number[] = []
    kpiData.data.liveData.forEach((element) => {
      categoryData.push(element.date)
      barOne.push(element.dataPoint[0].count)
    })
    const seriesData = [
      {
        name: kpiData.data.liveData[0].dataPoint[0].name,
        data: barOne
      }
    ]
    setReportData({ ...reportData, categories: categoryData, series: seriesData, colors: kpiColorMap['Last Read Alarms'] })
  }
}

export function buildTotalStaleReportData (reportData: ReportData, setReportData: React.Dispatch<React.SetStateAction<ReportData>>, kpiData: BarChartState): void {
  if (kpiData.data !== undefined) {
    const categoryData: string[] = []
    const barOne: number[] = []
    kpiData.data.liveData.forEach((element) => {
      categoryData.push(element.date)
      barOne.push(element.dataPoint[0].count)
    })
    const seriesData = [
      {
        name: kpiData.data.liveData[0].dataPoint[0].name,
        data: barOne
      }
    ]
    setReportData({ ...reportData, categories: categoryData, series: seriesData, colors: kpiColorMap['Total Stale'] })
  }
}

export function buildDonutChartData (kpiName: KpiKeyDonut, kpiData: DonutSeriesData, setKpiData: React.Dispatch<React.SetStateAction<DonutSeriesData>>, kpiDataState: DonutKpiState): void {
  if (kpiDataState.data !== undefined) {
    const name: string[] = []
    const data: number[] = []
    const color: string[] = kpiColorMapDonut[kpiName]
    const colorsLength = color.length

    kpiDataState.data.liveData[0].dataPoint.forEach((element, index) => {
      if (index < colorsLength) {
        name.push(element.name)
        data.push(element.percentage)
      } else {
        // If index is greater than or equal to 5, repeat colors from the beginning
        const repeatedIndex = (index - colorsLength) % color.length
        name.push(element.name)
        data.push(element.percentage)
        color.push(color[repeatedIndex])
      }
    })

    setKpiData({ ...kpiData, name, data, color })
  }
}
