import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
export interface CreateSFTPConfigurationState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
}

const initialState: CreateSFTPConfigurationState = {
  isLoading: false,
  error: undefined,
  httpStatus: undefined
}

const CreateSFTPConfigurationSlice = createSlice({
  name: 'CreateSFTPConfiguration-Create',
  initialState,
  reducers: {
    createSFTPConfigurationLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0
      }
    },
    createSFTPConfigurationUpdateAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload
      }
    },
    createSFTPConfigurationErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  createSFTPConfigurationLoadingAction,
  createSFTPConfigurationUpdateAction,
  createSFTPConfigurationErrorAction
} = CreateSFTPConfigurationSlice.actions

export default CreateSFTPConfigurationSlice.reducer
