/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface firmwareListFilterVersionState {
  isLoading: boolean
  loadingError: string | null
  firmwareListFilterVersion: string[] | []
}

// Initial state of the slice
const initialState: firmwareListFilterVersionState = {
  isLoading: true,
  loadingError: null,
  firmwareListFilterVersion: [],
}

// Slice to return from action
export const firmwareListFilterVersionSlice = createSlice({
  name: 'firmwareListFilterVersion',
  initialState,
  reducers: {
    firmwareListFilterVersionLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        firmwareListFilterVersion: []
      }
    },
    firmwareListFilterVersionUpdateAction: (state, action: PayloadAction<string[]|[]>) => {
      return {
        isLoading: false,
        loadingError: null,
        firmwareListFilterVersion: action.payload
      }
    },
    firmwareListFilterVersionErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        firmwareListFilterVersion: []
      }
    }
  }
})

// Actions to export
export const {
  firmwareListFilterVersionLoadingAction,
  firmwareListFilterVersionUpdateAction,
  firmwareListFilterVersionErrorAction
} = firmwareListFilterVersionSlice.actions

export default firmwareListFilterVersionSlice.reducer