/* istanbul ignore file */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type HistoryARDataObject } from '../types'

export interface ExportARKpiDetailsState {
  isLoading: boolean
  error: string | undefined
  exportARKpiDetails: HistoryARDataObject | undefined
  httpStatus: number
}

// Initial state of the slice
const initialState: ExportARKpiDetailsState = {
  isLoading: true,
  error: undefined,
  exportARKpiDetails: undefined,
  httpStatus: 0
}

// Slice to return from action
export const exportARKpiDetailsSlice = createSlice({
  name: 'exportARKpiDetails',
  initialState,
  reducers: {
    exportARKpiDetailsLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        exportARKpiDetails: undefined,
        httpStatus: 0
      }
    },
    exportARKpiDetailsUpdateAction: (state, action: PayloadAction<{ data: HistoryARDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        exportARKpiDetails: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    exportARKpiDetailsErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        exportARKpiDetails: undefined,
        httpStatus: action.payload.httpStatus
      }
    },
    // istanbul ignore next
    exportARKpiDetailsResetAction: () => {
      return {
        isLoading: false,
        error: undefined,
        exportARKpiDetails: undefined,
        httpStatus: 0
      }
    }
  }
})

// Actions to export
export const {
  exportARKpiDetailsLoadingAction,
  exportARKpiDetailsUpdateAction,
  exportARKpiDetailsErrorAction,
  exportARKpiDetailsResetAction
} = exportARKpiDetailsSlice.actions

export default exportARKpiDetailsSlice.reducer
