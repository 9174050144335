import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ReportData } from '../types'

export interface TemplateDetailsListState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
  templateDetailsList: ReportData[] | undefined
}

const initialState: TemplateDetailsListState = {
  isLoading: false,
  error: undefined,
  httpStatus: 0,
  templateDetailsList: undefined
}

const TemplateDetailsListSlice = createSlice({
  name: 'TemplateDetailsList state',
  initialState,
  reducers: {
    templateDetailsListLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0,
        templateDetailsList: undefined
      }
    },
    templateDetailsListUpdateAction: (state, action: PayloadAction<{ data: ReportData[], httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload.httpStatus,
        templateDetailsList: action.payload.data
      }
    },
    templateDetailsListErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        templateDetailsList: undefined
      }
    }
  }
})

export const {
  templateDetailsListLoadingAction,
  templateDetailsListUpdateAction,
  templateDetailsListErrorAction
} = TemplateDetailsListSlice.actions

export default TemplateDetailsListSlice.reducer
