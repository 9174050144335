import React, { useEffect } from 'react'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { type RootState } from '../../store'
import BackButton from '../../components/BackButton'
import ConfigurationDetailsComp from './components/ConfigurationDetailsComponent'
import { type TemplateDetailsState, changeTemplate } from './redux/selectedTemplateDetailsSlice'
import { fetchConfigurationDetails } from './redux/actionCreator'
import LanguageStrings from '../../i18n/locales'

const ConfigurationDetailStrings = LanguageStrings().ConfigurationUploadStrings.components.ConfigurationDetails

const ConfigurationDetails = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const numConfigurations = useSelector((state: RootState) => state.configurationDetailsForStatus.configurationDetails !== null ? state.configurationDetailsForStatus.configurationDetails.total_count : 0)
  useEffect(() => {
    if (props.templateDetailsState.template_id !== '' && props.templateDetailsState.template_name !== '') {
      sessionStorage.setItem('templateDetails', JSON.stringify(props.templateDetailsState))
    } else {
      // istanbul ignore next
      const templateDetailsFromStorage = (sessionStorage.getItem('templateDetails') !== null ? JSON.parse(sessionStorage.getItem('templateDetails') ?? '') : '')
      // istanbul ignore next
      if (templateDetailsFromStorage !== '') {
        const templateDetails: TemplateDetailsState = {
          template_id: templateDetailsFromStorage.template_id,
          template_name: templateDetailsFromStorage.template_name
        }
        props.changeTemplate(templateDetails)
        props.fetchConfigurationDetails(templateDetails.template_id, 0, 10, 'asc', 'serial_number')
      }
    }
  }, [])

  return (
    <div style={{ width: 'calc(100vw - 3.5rem)', paddingRight: '1.2rem' }}>
      <div className='link-breadcrumb'>
        <span data-testid='assetManagementbreadcrumb' onClick={ () => { navigate('/card') }} className='assetmgmtli'>{ConfigurationDetailStrings.ASSET_MANAGEMENT}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span className='assetmgmtli' onClick={ () => { navigate('/configuration-upload-status') }}>{ConfigurationDetailStrings.CONFIG_UPLOAD_STATUS}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span>{props.templateDetailsState.template_name}</span>
      </div>
      <div className='topUtilityBar'>
        <BackButton/>
        <span id='componentTitle' data-testid='configurations'>{props.templateDetailsState.template_name}</span>
      </div>
      <div>
        <div className='configurations-table-heading'>
          <h5>{ConfigurationDetailStrings.ASSET_LIST} <span className='config-list-box'>{numConfigurations} {ConfigurationDetailStrings.ASSETS}</span></h5>
        </div>
        <ConfigurationDetailsComp />
      </div>
    </div>
  )
}

interface DispatchToProps {
  changeTemplate: (templateDetails: TemplateDetailsState) => void
  fetchConfigurationDetails: (templateId: string, page: number, size: number, sortdir: string, sortfield: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  changeTemplate: (templateDetails: TemplateDetailsState) => dispatch(changeTemplate(templateDetails)),
  fetchConfigurationDetails: (templateId: string, page: number, size: number, sortdir: string, sortfield: string) => dispatch(fetchConfigurationDetails(templateId, page, size, sortdir, sortfield))
})

interface StateToProps {
  templateDetailsState: TemplateDetailsState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  templateDetailsState: state.templateDetails
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ConfigurationDetails)
