import React from 'react'
import ReportScheduleTableList from './ReportScheduleTableList'
import { useSelector } from 'react-redux'
import { type RootState } from '../../../../store'
import LanguageStrings from '../../../../i18n/locales'

const SchedulerTableStrings = LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings.SchedulerStrings.SchedulerTableStrings

const ReportScheduleMain = (): JSX.Element => {
  const totalCount = useSelector((state: RootState) => state.SchdulerListData.totalCount)

  return (
    <div>
          <div style={{ marginTop: '20px' }}>
            <div className='configurations-table-heading'>
              <h5 data-testid='schedule-list'>{SchedulerTableStrings.tabletitle} <span className='config-list-box'>{totalCount} {SchedulerTableStrings.Schedules}</span></h5>
            </div>
              <ReportScheduleTableList />
          </div>
          <br />
    </div>
  )
}
export default ReportScheduleMain
