import React from 'react'
import BackButton from '../../../components/BackButton'
import LanguageStrings from '../../../i18n/locales'
import { useNavigate } from 'react-router-dom'
import GroupInformation from './createGroupComponents/GroupInformation'
import { Button } from '../../../components/Button'
import SelectAssetComponent from './createGroupComponents/SelectAssets'
import { Card, CardContent, CircularProgress } from '@mui/material'
import timelineSelect from '../../../assets/timelineSelect.svg'
import timelineCreate from '../../../assets/timelineCreate.svg'
import { type Option } from '../../../components/GenXDropdown'
import Modal from '../../../components/Modal'
import { type CreateGroupPostRequest } from '../types'
import { createGroup } from '../redux/actionCreator'
import { type RootState } from '../../../store'
import { type CreateGroupState } from '../redux/createGroupSlice'
import { type ConnectedProps, connect } from 'react-redux'
import AuthError from '../../../components/ErrorComponents/AuthError'

const CreateGroupComponentStrings = LanguageStrings().AssetGroupStrings.CreateGroupComponent

const CreateGroupComponent = (props: PropsFromRedux): JSX.Element => {
  const [isGroupInfoEnabled, setIsGroupInfoEnabled] = React.useState(true)
  const [groupName, setGroupName] = React.useState('')
  const [category, setCategory] = React.useState<Option | null>(null)
  const [assetType, setAssetType] = React.useState<Option | null>(null)
  const [groupType] = React.useState<string>('static')
  const [description, setDescription] = React.useState<string>('')
  const [selectedAssets, setSelectedAssets] = React.useState<string[]>([])
  const [error, setError] = React.useState<boolean>(false)
  const [descError, setDescError] = React.useState<boolean>(false)
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [showCreatePopup, setShowCreatePopup] = React.useState(false)
  const [nameErrorInit, setNameErrorInit] = React.useState(false)
  const [categoryOptions, setCategoryOptions] = React.useState<Option[]>([])
  const [assetTypeOptions, setAssetTypeOptions] = React.useState<Option[]>([])
  const navigate = useNavigate()

  // istanbul ignore next
  const resetGroupState = (): void => {
    setGroupName('')
    setCategory(null)
    setAssetType(null)
    setDescription('')
    setSelectedAssets([])
    setError(false)
    setDescError(false)
    setIsGroupInfoEnabled(true)
    setNameErrorInit(false)
  }

  // istanbul ignore next
  const handeleCancelClick = (): void => {
    resetGroupState()
    navigate(-1)
  }

  // istanbul ignore next
  const moveToGroupInfo = (): void => {
    setSelectedAssets([])
    setIsGroupInfoEnabled(true)
  }

  const handleCreateGroup = (): void => {
    props.createGroup({
      group_name: groupName,
      description,
      type: groupType,
      category_type_id: category !== null ? category.value : '',
      asset_type_id: assetType !== null ? assetType.value : '',
      assets: selectedAssets
    })
  }

  const checkIfGroupInfoValid = (): boolean => {
    return (groupName !== '' && category !== null && assetType !== null && !error && !descError)
  }

  const handleCreateGroupWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (props.createGroupState.httpStatus === 201) {
      return (
        <Modal buttonTypeNegative='hollow' title={CreateGroupComponentStrings.Successfully_created} LabelPositive={CreateGroupComponentStrings.Ok} onClickPositive={() => { navigate('/asset-group-list') }}
          onClose={() => { navigate('/asset-group-list') }}>
          <p style={{ paddingTop: '1.2em' }}>{CreateGroupComponentStrings.Group_has_been_created_successfully}</p>
        </Modal>
      )
    } else if (props.createGroupState.isLoading) {
      return (
        <Modal title={CreateGroupComponentStrings.Creating_Group}>
          <div className='CircularprogressAmi'>
              <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.createGroupState.httpStatus === 401) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative={CreateGroupComponentStrings.Close}
                    onClickNegative={() => { setShowCreatePopup(false) }}
                    onClose={() => { setShowCreatePopup(false) }} title={CreateGroupComponentStrings.Creating_Group}>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="Unauthorized"/>
                    </div>
                </Modal>
      )
    } else if (props.createGroupState.httpStatus === 403) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative={CreateGroupComponentStrings.Close}
                    onClickNegative={() => { setShowCreatePopup(false) }}
                    onClose={() => { setShowCreatePopup(false) }} title={CreateGroupComponentStrings.Creating_Group}>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="accessForbidden"/>
                    </div>
                </Modal>
      )
    } else if (props.createGroupState.httpStatus === 400 && props.createGroupState.error === 'GroupName already exists ') {
      return (
        <Modal width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative={CreateGroupComponentStrings.Close} LabelPositive='Retry'
          onClickNegative={() => { setShowCreatePopup(false) }}
          onClose={() => { setShowCreatePopup(false) }} title={CreateGroupComponentStrings.Creating_Group}
          onClickPositive={handleCreateGroup}>
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} errorMessage="groupNameAlready"/></div>
        </Modal>
      )
    } else {
      return (
        <Modal width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative={CreateGroupComponentStrings.Close} LabelPositive='Retry'
          onClickNegative={() => { setShowCreatePopup(false) }}
          onClose={() => { setShowCreatePopup(false) }} title={CreateGroupComponentStrings.Creating_Group}
          onClickPositive={handleCreateGroup}>
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} errorMessage="cannotCreateGroup"/></div>
        </Modal>
      )
    }
  }

  return (
    <div style={{ width: 'calc(100vw - 3.5rem)', paddingRight: '2rem' }}>
      <div className='link-breadcrumb'>
        <span data-testid='assetManagementbreadcrumb' onClick={ () => { navigate('/card') }} className='assetmgmtli'>{CreateGroupComponentStrings.Asset_Management}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span onClick={() => { navigate('/asset-group-list') }} className='assetmgmtli'>{CreateGroupComponentStrings.Asset_Group_Title}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span>{CreateGroupComponentStrings.Create_Asset_Group}</span>
      </div>
      <div className='topUtilityBar'>
        <BackButton/>
        <span id='componentTitle' data-testid='create-group'>{CreateGroupComponentStrings.Create_Asset_Group}</span>
      </div>
      <br />
      {
        isGroupInfoEnabled
          ? <div>
            <Card sx={{ borderRadius: '10px', backgroundColor: '#272727', color: '#F0F0F0 !important', padding: '25px', overflow: 'visible' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={timelineCreate} />
              </div>
              <div style={{ height: 2, borderTop: '2px solid #707070', width: '100%', margin: '20px 0' }}></div>
              <GroupInformation
                descError={descError}
                setDescError={setDescError}
                error={error}
                setError={setError}
                groupName={groupName}
                setGroupName={setGroupName}
                category={category}
                setCategory={setCategory}
                assetType={assetType}
                setAssetType={setAssetType}
                description={description}
                setDescription={setDescription}
                setNameErrorInit={setNameErrorInit}
                nameErrorInit={nameErrorInit} categoryOptions={categoryOptions} setCategoryOptions={setCategoryOptions} assetTypeOptions={assetTypeOptions} setAssetTypeOptions={setAssetTypeOptions}/>
            </Card>
        </div>
          : <div>
            <Card sx={{ borderRadius: '10px', backgroundColor: '#272727', color: '#F0F0F0 !important', padding: '25px', overflow: 'visible' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={timelineSelect} />
              </div>
              <div style={{ height: 2, borderTop: '2px solid #707070', width: '100%', margin: '20px 0' }}></div>
              <CardContent>
                <SelectAssetComponent assetType={assetType} selectedAssets={selectedAssets} setSelectedAssets={setSelectedAssets} />
              </CardContent>
            </Card>
          </div>
      }
      <div style={{ width: '100%', height: '80px', backgroundColor: '#272727', margin: '50px 0 10px 0', borderRadius: 10 }}>
        <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 1em' }}>
          <div>
            <Button type='hollow' onClick={() => { handeleCancelClick() }}>{CreateGroupComponentStrings.Cancel}</Button>
          </div>
          <div>
            { isGroupInfoEnabled ? <></> : <Button dataTestId='back-btn' onClick={moveToGroupInfo} type='hollow'>{CreateGroupComponentStrings.Back}</Button> }
            {
              isGroupInfoEnabled
                ? <>
                <Button dataTestId='next-btn-grp-info' primaryColor={checkIfGroupInfoValid() ? undefined : '#B0B0B0'}
                  disabled={!checkIfGroupInfoValid()}
                  onClick={() => { setIsGroupInfoEnabled(false) }}>
                    {CreateGroupComponentStrings.Next}
                </Button>
              </>
                : selectedAssets.length > 0
                  ? <>
                <Button dataTestId='assign-btn-select' disabled={!checkIfGroupInfoValid()} onClick={() => { setShowConfirmation(true) }}>{CreateGroupComponentStrings.Assign}</Button>
              </>
                  : <>
                <Button dataTestId='next-btn-select' primaryColor={checkIfGroupInfoValid() ? undefined : '#B0B0B0'}
                  disabled={!checkIfGroupInfoValid()}
                  onClick={() => { setShowConfirmation(true) }}>
                    {CreateGroupComponentStrings.Next}
                </Button>
              </>
            }
          </div>
        </div>
      </div>
      { showConfirmation &&
        <Modal buttonTypeNegative='hollow' title={CreateGroupComponentStrings.Create_Group} LabelNegative={CreateGroupComponentStrings.Cancel} LabelPositive={CreateGroupComponentStrings.Create} onClickPositive={() => {
          setShowConfirmation(false)
          setShowCreatePopup(true)
          handleCreateGroup()
        }} onClose={() => { setShowConfirmation(false) }} onClickNegative={() => { setShowConfirmation(false) }}>
          <p style={{ paddingTop: '1.2em' }}>{CreateGroupComponentStrings.Do_you_want_to_Create_Group}</p>
        </Modal>
      }
      {
        showCreatePopup &&
        handleCreateGroupWithFailure()
      }
    </div>
  )
}

interface DispatchToProps {
  createGroup: (createGroupPostRequest: CreateGroupPostRequest) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  createGroup: (createGroupPostRequest: CreateGroupPostRequest) => dispatch(createGroup(createGroupPostRequest))
})

interface StateToProps {
  createGroupState: CreateGroupState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  createGroupState: state.createGroupState
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CreateGroupComponent)
