import dayjs from 'dayjs'

// use this if timestamp has both date and time
export const parseDateInAOHstandard = (date: string | Date): string => {
  return new Date(date).toUTCString()
}

// use this if timestamp has only date
export function convertDateIntoAohStandardUsingDayjs (date: string | null | Date): string {
  if (date === null) return ''
  return dayjs(date).format('ddd, DD MMM YYYY')
}

export function convertDateIntoAohStandardUnSeperated (date: string | null | Date): string {
  if (date === null) return ''
  return dayjs(date).format('ddd DD MMM YYYY')
}
