import React from 'react'
import errorFourZeroOne from '../../assets/authImg.svg'
import catchFetch from '../../assets/errImg.svg'
import noCampaign from '../../assets/noCampaign.svg'
import LanguageStrings from '../../i18n/locales'

const AuthError = (props: any): any => {
  const ErrorStrings = LanguageStrings().DeviceDetailsStrings.Cellular
  if (props.errorMessage === 'Unauthorized') {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={errorFourZeroOne} className='errorComp1'/>
        <div className='errorCompContent'>{ErrorStrings.UNAUTHORIZED}</div>
      </div>
    )
  } else if (props.errorMessage === 'accessForbidden') {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={errorFourZeroOne} className='errorComp1'/>
        <div className='errorCompContent'>{ErrorStrings.FORBIDDEN}</div>
      </div>
    )
  } else if (props.errorMessage === 'NoDataPresent') {
    return (
      <><div className={props.className !== undefined ? props.className : 'errorComp'}>
      <img src={catchFetch} className='errorComp1'/>
      <div className='errorCompContent'>{ErrorStrings.NODATA}</div>
    </div>
    </>
    )
  } else if (props.errorMessage === 'cannotFetch') {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={catchFetch} className='errorComp1'/>
        <div className='errorCompContent'>{ErrorStrings.CANNOTFETCH}</div>
        <button
        className='errorCompButton'
        onClick={() => props.retry()}>
          Retry
        </button>
      </div>
    )
  } else if (props.errorMessage === 'cannotCreate') {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={catchFetch} className='errorComp1'/>
        <div className='errorCompContent'>{ErrorStrings.CANNOTCREATECONFIGURATION}</div>
      </div>
    )
  } else if (props.errorMessage === 'cannotAssign') {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={catchFetch} className='errorComp1'/>
        <div className='errorCompContent'>{ErrorStrings.CANNOTASSIGNASSETS}</div>
      </div>
    )
  } else if (props.errorMessage === 'cannotUnassign') {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={catchFetch} className='errorComp1'/>
        <div className='errorCompContent'>{ErrorStrings.CANNOTUNASSIGNASSETS}</div>
      </div>
    )
  } else if (props.errorMessage === 'cannotCreateGroup') {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={catchFetch} className='errorComp1'/>
        <div className='errorCompContent'>{ErrorStrings.UNABLETOCREATEGROUP}</div>
        </div>
    )
  } else if (props.errorMessage === 'groupNameAlready') {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={catchFetch} className='errorComp1'/>
        <div className='errorCompContent'>{ErrorStrings.GROUPNAMEALREADYEXISTS}</div>
        </div>
    )
  } else if (props.errorMessage === 'cannotUpdateGroup') {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={catchFetch} className='errorComp1'/>
        <div className='errorCompContent'>{ErrorStrings.UNABLETOUPDATEGROUP}</div>
        </div>
    )
  } else if (props.errorMessage === 'cannotFetchGroups') {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={catchFetch} className='errorComp1Group'/>
        <div className='errorCompGroupContent'>{ErrorStrings.CANNOTFETCHGROUPS}</div>
      </div>
    )
  } else if (props.errorMessage === 'cannotAssginGroup') {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={catchFetch} className='errorComp1Group'/>
        <div className='errorCompGroupContent'>{ErrorStrings.CANNOTASSIGNGROUP}</div>
      </div>
    )
  } else if (props.customError !== undefined) {
    return (
      <div className={props.className !== undefined ? props.className : 'errorComp'}>
        <img src={catchFetch} className='errorComp1Group'/>
        <div className='errorCompGroupContent'>{props.customError}</div>
      </div>
    )
  } else if (props.customAssetConfigError !== undefined) {
    return (
      <><div className={props.className !== undefined ? props.className : 'errorComp'}>
      <img src={catchFetch} className='errorComp1'/>
      <div className='errorCustomGroupContent'>{props.customAssetConfigError}</div>
    </div>
    </>
    )
  } else if (props.errorMessage === 'NoDataPresentForSearch') {
    return (
      <><div className={props.className !== undefined ? props.className : 'errorCompSearch'}>
      <img src={catchFetch} className='errorComp1Search'/>
      <div className='errorCompContentSearch'>{ErrorStrings.NODATAFOUND}</div>
    </div>
    </>
    )
  } else if (props.errorMessage === 'invalidSearchInput') {
    return (
      <><div className={props.className !== undefined ? props.className : 'errorComp'}>
      <img src={catchFetch} className='errorComp1Search'/>
      <div className='errorCompContentSearch'>{ErrorStrings.INVALIDINPUT}</div>
    </div>
    </>
    )
  } else if (props.errorMessage === 'NoDataPresentCampaign') {
    return (
      <><div className={props.className !== undefined ? props.className : 'errorCompCampaign'}>
      <img src={noCampaign} className='errorComp1Search'/>
      <div className='errorCompContentSearch'>{ErrorStrings.no_configuration_Avalable}</div>
      <span>{ErrorStrings.add_configuration_protocol}</span>
      <button
        className='errorCompButton'
        onClick={() => props.retry()}>
          {ErrorStrings.add_btn}
        </button>
    </div>
    </>
    )
  }
}
export default AuthError
