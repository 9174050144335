export const dummyData = [
  {
    reportName: 'Meter Reads',
    reportType: '.csv',
    reportContent: [
      {
        columnName: 'Asset ID',
        columnValue: 'asset_id',
        columnOrder: 1
      },
      {
        columnName: 'Asset Type',
        columnValue: 'asset_type',
        columnOrder: 2
      },
      {
        columnName: 'Reading Time',
        columnValue: 'reading_time',
        columnOrder: 15
      },
      {
        columnName: 'Reading Value',
        columnValue: 'reading_value',
        columnOrder: 16
      }
    ]
  },
  {
    reportName: 'Meters in AMI Mode',
    reportType: '.csv',
    reportContent: [
      {
        columnName: 'Asset ID',
        columnValue: 'asset_id',
        columnOrder: 1
      },
      {
        columnName: 'Asset Type',
        columnValue: 'asset_type',
        columnOrder: 2
      },
      {
        columnName: 'CIM Code',
        columnValue: 'cim_code_id',
        columnOrder: 23
      },
      {
        columnName: 'MDC Collection Time',
        columnValue: 'mdc_collection_time',
        columnOrder: 21
      },
      {
        columnName: 'Reading Value',
        columnValue: 'reading_value',
        columnOrder: 16
      },
      {
        columnName: 'Asset ID',
        columnValue: 'asset_id',
        columnOrder: 1
      },
      {
        columnName: 'Asset Type',
        columnValue: 'asset_type',
        columnOrder: 2
      },
      {
        columnName: 'CIM Code',
        columnValue: 'cim_code_id',
        columnOrder: 23
      },
      {
        columnName: 'MDC Collection Time',
        columnValue: 'mdc_collection_time',
        columnOrder: 21
      },
      {
        columnName: 'Reading Value',
        columnValue: 'reading_value',
        columnOrder: 16
      },
      {
        columnName: 'Asset ID',
        columnValue: 'asset_id',
        columnOrder: 1
      },
      {
        columnName: 'Asset Type',
        columnValue: 'asset_type',
        columnOrder: 2
      },
      {
        columnName: 'CIM Code',
        columnValue: 'cim_code_id',
        columnOrder: 23
      },
      {
        columnName: 'MDC Collection Time For Datat Completeness',
        columnValue: 'mdc_collection_time',
        columnOrder: 21
      },
      {
        columnName: 'Reading Value',
        columnValue: 'reading_value',
        columnOrder: 16
      }
    ]
  }
]

export const fieldOptions = [
  {
    label: 'Asset ID',
    value: 'asset_id'
  },
  {
    label: 'Asset Type',
    value: 'asset_type'
  },
  {
    label: 'Reading Time',
    value: 'reading_time'
  },
  {
    label: 'Reading Value',
    value: 'reading_value'
  },
  {
    label: 'CIM Code',
    value: 'cim_code_id'
  },
  {
    label: 'MDC Collection Time',
    value: 'mdc_collection_time'
  }
]

export const reportContentValues = [
  [12233456, 12345, 2345566, 230],
  [12233456, 12345, 2345566, 230],
  [12233456, 12345, 2345566, 230],
  [12233456, 12345, 2345566, 230],
  [12233456, 12345, 2345566, 230],
  [12233456, 12345, 2345566, 230],
  [12233456, 12345, 2345566, 230],
  [12233456, 12345, 2345566, 230],
  [12233456, 12345, 2345566, 230],
  [12233456, 12345, 2345566, 230],
  [12233456, 12345, 2345566, 230],
  [12233456, 12345, 2345566, 230]
]
