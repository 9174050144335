import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ApiResponse } from '../services/createAssetGroupService'

export interface CreateGroupState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null | undefined
}

export const initialState: CreateGroupState = {
  isLoading: false,
  error: null,
  httpStatus: null
}

const createAssetGroupSlice = createSlice({
  name: 'create-asset-group',
  initialState,
  reducers: {
    createAssetGroupLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0
      }
    },
    createAssetGroupUpdateAction: (state, action: PayloadAction<{ data: ApiResponse }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.data.http_status
      }
    },
    createAssetGroupErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  createAssetGroupLoadingAction,
  createAssetGroupUpdateAction,
  createAssetGroupErrorAction
} = createAssetGroupSlice.actions

export default createAssetGroupSlice.reducer
