import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

// Define the state type
export interface DeleteGroupState {
  isLoading: boolean
  error: boolean
  success: boolean
}

// Initial state of the slice
const initialState: DeleteGroupState = {
  isLoading: false,
  error: false,
  success: false
}

// Create the slice
export const deleteGroupSlice = createSlice({
  name: 'deleteGroup',
  initialState,
  reducers: {
    deleteGroupLoadingAction: (state) => ({
      isLoading: true,
      error: false,
      success: false
    }),
    deleteGroupUpdateAction: (state, action: PayloadAction) => ({
      isLoading: false,
      error: false,
      success: true
    }),
    deleteGroupErrorAction: (state, action: PayloadAction) => ({
      isLoading: false,
      error: true,
      success: false
    })
  }
})

// Actions to export
export const {
  deleteGroupLoadingAction,
  deleteGroupUpdateAction,
  deleteGroupErrorAction
} = deleteGroupSlice.actions

// Export the reducer
export default deleteGroupSlice.reducer
