import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface IPConfigState {
  isLoading: boolean
  error: boolean
  httpStatus: number | null | undefined
}

const initialState: IPConfigState = {
  isLoading: false,
  error: false,
  httpStatus: null
}

export const ipConfigSlice = createSlice({
  name: 'ip-config-update',
  initialState,
  reducers: {
    ipConfigLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        httpStatus: 0
      }
    },
    ipConfigUpdateAction: (state, action: PayloadAction<{ httpStatus: number }>) => {
      return {
        isLoading: false,
        error: false,
        httpStatus: action.payload.httpStatus
      }
    },
    ipConfigErrorAction: (state, action: PayloadAction<{ httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: true,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  ipConfigLoadingAction,
  ipConfigUpdateAction,
  ipConfigErrorAction
} = ipConfigSlice.actions

export default ipConfigSlice.reducer
