import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export type KpiKey = 'Data Completeness' | '30 Days Trend' | 'Total Registered' | 'Total Connected' | 'Total Shipped' | 'Total Stale' | 'Total Reads' | 'Extended Coverage Level' | 'Signal Breakdown' | 'Alarm Breakdown' | 'Firmware Breakdown' | 'Last Read Alarms' | 'Battery Breakdown' | 'Lifecycle Breakdown'
export const AvailableKpiTypes: KpiKey[] = ['Data Completeness', '30 Days Trend', 'Total Registered', 'Total Connected', 'Total Shipped', 'Total Stale', 'Total Reads', 'Extended Coverage Level', 'Signal Breakdown', 'Alarm Breakdown', 'Firmware Breakdown', 'Last Read Alarms', 'Battery Breakdown', 'Lifecycle Breakdown']
export type KpiKeyBar = 'Total Shipped' | 'Total Stale' | 'Total Registered' | 'Total Connected' | 'Last Read Alarms'
export type KpiKeyDonut = 'Total Reads' | 'Extended Coverage Level' | 'Signal Breakdown' | 'Alarm Breakdown' | 'Firmware Breakdown' | 'Battery Breakdown' | 'Lifecycle Breakdown'
export const KpiWithDonutChart: KpiKeyDonut[] = ['Total Reads', 'Extended Coverage Level', 'Signal Breakdown', 'Alarm Breakdown', 'Firmware Breakdown', 'Battery Breakdown', 'Lifecycle Breakdown']
export const ClickableKpiKey = ['Total Registered', 'Total Connected', 'Total Shipped', 'Total Stale', 'Total Reads', 'Extended Coverage Level', 'Signal Breakdown', 'Alarm Breakdown', 'Firmware Breakdown', 'Last Read Alarms', 'Battery Breakdown', 'Lifecycle Breakdown']

export type KpiKeyWithDynamicColumn = 'Total Reads' | 'Extended Coverage Level' | 'Signal Breakdown' | 'Alarm Breakdown' | 'Firmware Breakdown' | 'Battery Breakdown' | 'Lifecycle Breakdown' | 'Last Read Alarms'

export const isDonutKpiWihtBar = (kpiName: KpiKeyDonut): boolean => {
  return ['Total Reads', 'Extended Coverage Level', 'Signal Breakdown', 'Alarm Breakdown', 'Battery Breakdown', 'Lifecycle Breakdown'].includes(kpiName)
}

export const isSingleBarKpi = (kpiName: KpiKey): boolean => {
  return ['Total Shipped', 'Total Stale', 'Last Read Alarms'].includes(kpiName)
}

export function isClickableKpiKey (kpiName: string): boolean {
  return ClickableKpiKey.includes(kpiName)
}

export function doesKpiHaveDonutChart (kpiName: string): boolean {
  return KpiWithDonutChart.includes(kpiName as KpiKeyDonut)
}

const getKpiKeyFromLocalStorage = (): KpiKey => {
  const kpiTypeVal = localStorage.getItem('kpiType')
  if (kpiTypeVal === null) {
    return 'Total Connected'
  } else {
    // check here if the kpiType is a valid KpiKey
    return kpiTypeVal as KpiKey
  }
}

const initialState: KpiKey = getKpiKeyFromLocalStorage()

export const kpiTypeSlice = createSlice({
  name: 'kpiTypeSlice',
  initialState,
  reducers: {
    changeKpiType: (state, action: PayloadAction<KpiKey>) => {
      localStorage.setItem('kpiType', action.payload)
      return action.payload
    }
  }
})

export const { changeKpiType } = kpiTypeSlice.actions
export default kpiTypeSlice.reducer
