import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZThirtyData = z.object(
  {
    timestamp: z.string(),
    kpi_value: z.number()
  })

export type thirtyDay = z.infer<typeof ZThirtyData>

export async function getThirtyDayData (
  tenantId: string,
  from: string,
  to: string,
  onSuccess: (thirtyDayData: any, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  // Move the KPIList out soon
  const encodedFrom = from.replace('+', '%2B')
  const encodedTo = to.replace('+', '%2B')
  const kpiList = '17d3138f-e42a-411a-95e9-4f64e2296c25'
  const url = `/kpirest/api/v1/udp/kpis?kpiList=${kpiList}&from=${encodedFrom}&to=${encodedTo}&tenantId=${tenantId}`
  interceptedAxios.get(url, { timeout: 20000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZThirtyData.array().safeParse(response.data.data)
        // istanbul ignore else
        if (parseResponse.success) {
          // istanbul ignore else
          if (parseResponse.data !== null && parseResponse.data !== undefined && parseResponse.data.length > 0) {
            onSuccess(parseResponse.data, response.status)
          } else {
            onFailure('No Data Present', response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      const statusCode = error.response ? error.response.status : 500
      onFailure(error.message, statusCode)
    })
}
