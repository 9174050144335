import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZAssetAttribute = z.object({
  configurationId: z.string(),
  configurationCategory: z.string()
})

const ZAssetAttributeResponse = z.object({
  http_status: z.number(),
  data: z.array(ZAssetAttribute),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type AssetAttribute = z.infer<typeof ZAssetAttribute>
export type AssetAttributeResponse = z.infer<typeof ZAssetAttributeResponse>

export async function getAssetAttribute (
  assetId: string,
  onSuccess: (assetAttributeData: AssetAttribute[], httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/assetrest/api/v1/udp/assets/asset-configuration/${assetId}`
  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      const parseResponse = ZAssetAttributeResponse.safeParse(response.data)
      if (parseResponse.success) {
        if (
          parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.data, response.status)
        } else {
          onFailure('Error occurred while processing response', response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      // istanbul ignore else
      if (error.response !== undefined) {
        onFailure(error.response.data.error_message, error.response.data.http_status)
      } else {
        onFailure('Network Error', error.status)
      }
    })
}
