import React from 'react'
import ReactApexChart, { type Props } from 'react-apexcharts'
import { kpiColorMapDonut } from './BuildKpiData'
import { type KpiKey, type KpiKeyDonut } from '../../redux/changeKpiType'

export interface DonutSeriesData {
  name: string[]
  data: number[]
  color: string[]
}

export function getReactApexDonutChart (kpiData: DonutSeriesData, height: string, centerFont: string, setSelectedDonut?: React.Dispatch<React.SetStateAction<number>>, setBarColor?: React.Dispatch<React.SetStateAction<string>>, selectedKpi?: KpiKey): JSX.Element {
  // calculate the center average
  const chartOptions: Props = {
    series: kpiData.data.map(value => Number(value.toFixed(2))),
    options: {
      grid: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      chart: {
        type: 'radialBar',
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (setSelectedDonut !== undefined) {
              setSelectedDonut(config.dataPointIndex)
            }
            if (setBarColor !== undefined && selectedKpi !== undefined) {
              setBarColor(kpiColorMapDonut[selectedKpi as KpiKeyDonut][config.dataPointIndex])
            }
          }
        }
      },
      stroke: {
        // This puts a little circle in the chart when it's zero so the
        // chart doesn't look like it's missing data.
        lineCap: 'butt'
      },
      fill: {
        // If there is more series data than colors it repeats
        colors: kpiData.color
      },
      legend: {
        show: false,
        position: 'right'
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              show: false
            },
            value: {
              show: true,
              fontSize: centerFont,
              fontWeight: 400,
              color: 'white'
            },
            total: {
              show: false,
              label: 'Total',
              fontSize: centerFont,
              formatter: function (e) {
                // All of this because the default center avgs the series data
                // but produces a string tha doesn't fit on the screen for cases
                // like 66.3333333 etc.
                // The documentation leaves a lot to be desired. I set a breakpoint to
                // capture the event. I'm assuming here that the config is always present.
                const seriesData = e.config.series
                if (seriesData.length === 0) {
                  return 'No Data'
                } else {
                  const avg = ((seriesData.reduce((partialSum: number, a: number) => partialSum + a, 0)) / seriesData.length)
                  return avg.toFixed(2) + '%'
                }
              }
            }
          },
          track: {
            background: '#202020AA',
            strokeWidth: '45px',
            show: true
          }
        }
      }
    }
  }
  return (
    <ReactApexChart
      options={chartOptions.options}
      series={chartOptions.series}
      type='radialBar' height={height}
    />
  )
}
