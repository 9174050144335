import React, { useEffect } from 'react'
import UnassignedAssetList from './UnassignedAssetList'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import UnAssignAssetList from './AssignedAssetList'
import LanguageStrings from '../../../i18n/locales'

const AssignAssetWindowStrings = LanguageStrings().AssetConfigurationStrings.components.AssignAssetWindow

const AssignAssetWindow = ({ handleFetchConfigList, onClose, assetTypeId, templateId, assetListOption }: { handleFetchConfigList: () => void, onClose: () => void, assetTypeId: string, templateId: string, assetListOption: string }): JSX.Element => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])
  return (
    <div style={{ backgroundColor: '#000000a4', height: '100vh', width: '100vw', position: 'fixed', zIndex: 1000, top: 0, backdropFilter: 'blur(5px)', left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: '80vw', backgroundColor: '#272727' }}>
        <div className='configurations-table-heading' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <span style={{ fontSize: 24, fontWeight: 500 }}>{assetListOption} {AssignAssetWindowStrings.Configuration}</span>
          <IconButton data-testid={'close-cross-btn'} onClick={onClose}>
            <CloseIcon />
        </IconButton>
        </div>
        {
          assetListOption === 'Assign'
            ? <UnassignedAssetList handleFetchConfigList={handleFetchConfigList} assetTypeId={assetTypeId} templateId={templateId} closeAssetAssignWindow={onClose} />
            : <UnAssignAssetList handleFetchConfigList={handleFetchConfigList} assetTypeId={assetTypeId} templateId={templateId} closeAssetAssignWindow={onClose} />
        }
      </div>
    </div>
  )
}

export default AssignAssetWindow
