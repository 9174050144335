import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type IAlarmHistoryData } from '../services/alarmHistoryService'

export interface IAlarmHistoryState {
  isLoading: boolean
  error: string | undefined
  alarmHistoryData: IAlarmHistoryData | undefined
  httpStatus: number | undefined
}

export const initialState: IAlarmHistoryState = {
  isLoading: true,
  error: undefined,
  alarmHistoryData: undefined,
  httpStatus: 0
}

const alarmHistorySlice = createSlice({
  name: 'alarmHistorySlice',
  initialState,
  reducers: {
    alarmHistoryLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        alarmHistoryData: undefined,
        httpStatus: 0
      }
    },
    alarmHistoryUpdateAction: (state, action: PayloadAction<{ data: IAlarmHistoryData, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        alarmHistoryData: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    alarmHistoryErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        alarmHistoryData: undefined,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  alarmHistoryLoadingAction,
  alarmHistoryUpdateAction,
  alarmHistoryErrorAction
} = alarmHistorySlice.actions

export default alarmHistorySlice.reducer
