/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface exportProvisioningHistoryState {
  isLoading: boolean
  loadingError: string | null
  exportProvisioningHistory: any
}

// Initial state of the slice
const initialState: exportProvisioningHistoryState = {
  isLoading: true,
  loadingError: null,
  exportProvisioningHistory: null
}

// Slice to return from action
export const exportProvisioningHistorySlice = createSlice({
  name: 'exportProvisioningHistory',
  initialState,
  reducers: {
    exportProvisioningHistoryLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        exportProvisioningHistory: []
      }
    },
    exportProvisioningHistoryUpdateAction: (state, action: PayloadAction< any >) => {
      return {
        isLoading: false,
        loadingError: null,
        exportProvisioningHistory: action.payload
      }
    },
    exportProvisioningHistoryErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        exportProvisioningHistory: null
      }
    }
  }
})

// Actions to export
export const {
  exportProvisioningHistoryLoadingAction,
  exportProvisioningHistoryUpdateAction,
  exportProvisioningHistoryErrorAction
} = exportProvisioningHistorySlice.actions

export default exportProvisioningHistorySlice.reducer
