/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable quote-props */

export default class DeviceUtil {
  static deviceTypes = Object.freeze({
    'Gas-NXCM':
            Object.freeze({
              type: 'GASNXCM',
              style: 1702,
              isGas: true,
              isGasNxcm: true
            }),
    'Water-Merlin':
            Object.freeze({
              name: 'WATER_MERLIN',
              style: 1710,
              isWater: true
            }),
    'Water-UNE-Y290':
           Object.freeze({
             name: 'WATER_Y290',
             style: 1711,
             isWater: true,
             isY290: true,
             hasTopology: true
           }),
    'Water-UNE-Contazara':
          Object.freeze({
            name: 'WATER_CZ',
            style: 1712,
            isWater: true,
            isCz: true,
            hasTopology: true
          }),
    'Water-TSherpa-Bridge':
          Object.freeze({
            name: 'WATER_TSHERPA_BRIDGE',
            style: 1713,
            isWater: true,
            isTSherpaBridge: true,
            hasTopology: true
          }),
    'Water-NXCM':
            Object.freeze({
              name: 'WATERNXCM',
              style: 1703,
              isWater: true
            }),
    'WaterNode-SynergyNet':
            Object.freeze({
              name: 'WATER_SYNERGYNET',
              style: 1506,
              isWater: true,
              isSynergyNet: true
            }),
    'Water-Q600':
            Object.freeze({
              name: 'WATER_Q600',
              style: 1506,
              isWater: true,
              isQ600: true
            }),
    'Water-HBG3':
            Object.freeze({
              name: 'HBG3',
              style: 1506,
              isWater: true,
              isHBG3: true
            }),
    'Agua-Merlin':
            Object.freeze({
              name: 'AGUA_MERLIN',
              style: 1710,
              isWater: true
            }),
    'Agua-UNE-Y290':
      Object.freeze({
        name: 'WATER_Y290',
        style: 1711,
        isWater: true,
        isY290: true,
        hasTopology: true
      }),
    'Agua-UNE-Contazara':
      Object.freeze({
        name: 'WATER_CZ',
        style: 1712,
        isWater: true,
        isCz: true,
        hasTopology: true
      }),
    'Agua-TSherpa-Bridge':
      Object.freeze({
        name: 'WATER_TSHERPA_BRIDGE',
        style: 1713,
        isWater: true,
        isTSherpaBridge: true,
        hasTopology: true
      }),
    'Agua-NXCM':
            Object.freeze({
              name: 'AGUANXCM',
              style: 1703,
              isWater: true
            }),
    'Agua-Q600':
            Object.freeze({
              name: 'AGUA_Q600',
              style: 1506,
              isWater: true,
              isQ600: true
            }),
    'Agua-HBG3':
            Object.freeze({
              name: 'HBG3',
              style: 1506,
              isWater: true,
              isHBG3: true
            }),
    'Water-V200H-NBIOT':
            Object.freeze({
              name: 'Water-V200H-NBIOT',
              style: 1506,
              isV200H: true,
              isWater: true
            }),
    'Gas-ThemisUno-A2-EI6-EI7':
          Object.freeze({
            name: 'Gas-ThemisUno-A2-EI6-EI7',
            style: 1507,
            isThemisDuo: true,
            isGas: true
          }),
    'Agua-V200H-NBIOT':
            Object.freeze({
              name: 'Water-V200H-NBIOT',
              style: 1506,
              isV200H: true,
              isWater: true
            })
  })

  static getDeviceTypeByName (typeName: string): any {
    // Test if key is present with "in". Need to do this to get around
    // restriction of Typescript that this method be called with a
    // type of "keyof typeof DeviceUtil.deviceTypes"
    if (typeName in DeviceUtil.deviceTypes) {
      return DeviceUtil.deviceTypes[typeName as keyof typeof DeviceUtil.deviceTypes]
    } else {
      return null
    }
  }

  static getDeviceTypeByMeterStyle (meterstyle: number): any {
    let k: keyof typeof DeviceUtil.deviceTypes
    for (k in DeviceUtil.deviceTypes) {
      if (DeviceUtil.deviceTypes[k].style === meterstyle) {
        return DeviceUtil.deviceTypes[k]
      }
    }
    return null
  }
}
