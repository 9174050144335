import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type ChannelDropdownData } from '../services/channelDropDownService'

export interface channelDropdownInterface {
  isLoading: boolean
  errorMessage: string | null
  httpStatus: number
  channelDropdownOptionData: ChannelDropdownData[] | null
}

const initialState: channelDropdownInterface = {
  isLoading: true,
  errorMessage: null,
  httpStatus: 0,
  channelDropdownOptionData: null
}

export const channelDropdownDataSlice = createSlice({
  name: 'channelDropdownData',
  initialState,
  reducers: {
    addChannelDropdownData: (state, action: PayloadAction<{ data: ChannelDropdownData[], httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: null,
        httpStatus: action.payload.httpStatus,
        channelDropdownOptionData: action.payload.data
      }
    },
    channelDropdownLoading: (state) => {
      return {
        isLoading: true,
        errorMessage: null,
        httpStatus: 0,
        channelDropdownOptionData: null
      }
    },
    channelDropdownError: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        channelDropdownOptionData: null
      }
    }
  }
})

export const { addChannelDropdownData, channelDropdownLoading, channelDropdownError } = channelDropdownDataSlice.actions
export default channelDropdownDataSlice.reducer
