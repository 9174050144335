import { z } from 'zod'

const ZReportContent = z.object({
  columnDisplayName: z.string(),
  columnName: z.string(),
  columnOrder: z.number(),
  columnHeaderName: z.string(),
  columnId: z.string()
})

export const ZReportData = z.object({
  reportName: z.string(),
  reportType: z.string(),
  reportTemplateId: z.string(),
  reportContent: z.array(ZReportContent),
  editable: z.string()
})

export const ZCreateColumnContent = z.object({
  columnName: z.string(),
  columnOrder: z.number(),
  columnDisplayName: z.string(),
  columnHeaderName: z.string(),
  columnId: z.string()
})

export const ZCreateReportData = z.object({
  reportName: z.string(),
  reportType: z.string(),
  reportContent: z.array(ZCreateColumnContent)
})

export const ZReportContentColumn = z.object({
  columnDisplayName: z.string(),
  columnName: z.string(),
  columnTable: z.string(),
  columnId: z.string()
})

export const ZReportSchedulerData = z.object({
  schedulerName: z.string(),
  reportTemplateId: z.string(),
  schedulerIntervalId: z.string(),
  schedulerLocationId: z.string(),
  recurrencePattern: z.string(),
  reportMonth: z.number(),
  dayOfTheMonth: z.number(),
  dayOfTheWeek: z.string(),
  time: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  schedulerAssignmentId: z.string(),
  assignmentValues: z.any()
})

export const ZEditReportSchedulerData = z.object({
  schedulerName: z.string(),
  reportTemplateId: z.string(),
  schedulerIntervalId: z.string(),
  schedulerLocationId: z.string(),
  recurrencePattern: z.string(),
  reportMonth: z.number(),
  dayOfTheMonth: z.number(),
  dayOfTheWeek: z.string(),
  time: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  schedulerAssignmentId: z.string(),
  assignmentValues: z.any(),
  connectionName: z.string(),
  protocolId: z.string(),
  frequency: z.number()
})

export const ZSFTPConfigurationData = z.object({
  connectionName: z.string(), // Connection Name
  description: z.string(), // Description
  protocolTypeId: z.string(), // Protocol Type HTTPS, SFTP
  protocolName: z.string(), // Protocol Name
  ipAddress: z.string(), // IP Address
  port: z.number(), // Port
  userName: z.string(), // User Name
  folderPath: z.string(), // Folder Path
  sshKey: z.string(), // SSH Key (sftp only)
  password: z.string(), // Password (sftp only)
  fileType: z.string(), // File Type (Zipped or Encrypted)
  fileEncryptFormat: z.string(), // File Encrypt Format ('' or gpg)
  publicKey: z.string(), // Public Key (file type gpg only)
  baseUrl: z.string(), // Base URL (https only)
  authType: z.string(), // gpg (encrypted only)
  apiToken: z.string() // API Token (https only)
})

export const ZReportList = z.array(ZReportData)
export type ReportContentColumn = z.infer<typeof ZReportContentColumn>
export type ReportData = z.infer<typeof ZReportData>
export type ReportContent = z.infer<typeof ZReportContent>
export type ReportList = z.infer<typeof ZReportList>
export type CreateReportData = z.infer<typeof ZCreateReportData>
export type CreateColumnContent = z.infer<typeof ZCreateColumnContent>
export type ReportSchedulerData = z.infer<typeof ZReportSchedulerData>
export type EditReportSchedulerData = z.infer<typeof ZEditReportSchedulerData>
export type SFTPConfigurationData = z.infer<typeof ZSFTPConfigurationData>
