import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export interface GroupCreationRequest {
  group_name: string
  description: string
  asset_type: string
  assets: string[]
}

const ZData = z.object({
  asset_group_id: z.string(),
  group_name: z.string()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: ZData,
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type ApiResponse = z.infer<typeof ZApiResponse>

export async function createAssetGroup (
  groupCreationBody: GroupCreationRequest,
  onSuccess: (successResponse: ApiResponse) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = '/assetrest/api/v1/udp/groupManagement/group'

  interceptedAxios
    .post(url, groupCreationBody, { timeout: 15000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          onSuccess(parseResponse.data)
        } else {
          onFailure('Error occurred while processing response', response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      onFailure(error.response !== undefined ? error.response.statusText : 'Network Error', error.response !== undefined ? error.response.status : 404)
    })
}
