import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type KpiPreferenceObject } from '../types'

export interface KpiUserPreferenceState {
  isLoading: boolean
  errorMessage: string | undefined
  data: KpiPreferenceObject | undefined
  httpStatus: number
}

const initialState: KpiUserPreferenceState = {
  isLoading: false,
  errorMessage: undefined,
  data: undefined,
  httpStatus: 0
}

const kpiUserPreferenceSlice = createSlice({
  name: 'KpiUserPreference',
  initialState,
  reducers: {
    kpiUserPreferenceLoadingAction: (state) => {
      return {
        isLoading: true,
        errorMessage: undefined,
        data: undefined,
        httpStatus: 0
      }
    },
    kpiUserPreferenceUpdateAction: (state, action: PayloadAction<{ data: KpiPreferenceObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: undefined,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    kpiUserPreferenceErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        data: undefined,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  kpiUserPreferenceLoadingAction,
  kpiUserPreferenceUpdateAction,
  kpiUserPreferenceErrorAction
} = kpiUserPreferenceSlice.actions

export default kpiUserPreferenceSlice.reducer
