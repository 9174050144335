import { Grid, Card } from '@scuf/common'
import { useNavigate } from 'react-router-dom'
import './Dashboard.css'
import React from 'react'
import LanguageStrings from '../../../i18n/locales'
import { getOperationPermissions } from '../redux/actionCreator'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { useAuth } from 'react-oidc-context'
import { type OperationPermissionState } from '../redux/operationPermissionSlice'
import { type RootState } from '../../../store'

const Dashboard = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const DashboardStrings = LanguageStrings().Dashboard
  const operationPermissions: OperationPermissionState = useSelector((state: RootState) => state.operationPermissions)
  const auth = useAuth()

  React.useEffect(() => {
    if (!operationPermissions.updated) {
      props.getOperationPermissions(auth.user?.profile.sub as string)
    }
  }, [])

  const onclickPublisher = (): void => {
    sessionStorage.setItem('currentSessionTab', '0')
    navigate('/datapub')
  }

  return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={12}>
                    <h1 style={{ fontWeight: 700, marginTop: '0.5em', marginBottom: '0.4em' }}>{DashboardStrings.UDP}</h1>
                </Grid.Column>
                <Grid.Row>
                    <Grid.Column width={4} mWidth={6} sWidth={12}>
                        <Card className="dashboard-card ami" onClick={() => { navigate('/isense') }}>
                            <Card.Content className=' admin-card-titles'>{DashboardStrings.Honeywell_Heading}</Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={4} mWidth={6} sWidth={12}>
                        <Card className="dashboard-card data-publisher" onClick={() => { onclickPublisher() }} data-testid='publisherTab'>
                            <Card.Content className=' admin-card-titles'>{'DATA PUBLISHER'}</Card.Content>
                        </Card>
                    </Grid.Column>
                    <Grid.Column width={4} mWidth={6} sWidth={12}>
                    <a
                        href={typeof window._env_?.REACT_APP_FORGE_IAM_ADMIN_CARD === 'string' ? window._env_.REACT_APP_FORGE_IAM_ADMIN_CARD : ''}
                        data-testid='admin_link'
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <Card className="dashboard-card admin">
                            <Card.Content className=' admin-card-titles'>{DashboardStrings.Admin}</Card.Content>
                        </Card>
                    </a>
                    </Grid.Column>
                </Grid.Row>
            </Grid.Row>
        </Grid>
  )
}

interface DispatchToProps {
  getOperationPermissions: (sub: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getOperationPermissions: (sub: string) => dispatch(getOperationPermissions(sub))
})

const connector = connect(null, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(Dashboard)
