import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZAlarmHistoryList = z.object({
  alarmDefinitionId: z.string(),
  alarmActivity: z.string(),
  severity: z.string(),
  alarmStatus: z.string(),
  alarmRaisedAt: z.string(),
  alarmClearedAt: z.string().nullable()
})

const ZAlarmHistoryData = z.object({
  totalCount: z.number(),
  alarmHistoryDetails: ZAlarmHistoryList.array()
})

const ZAlarmHistoryApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZAlarmHistoryData),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

const ZAlarmHistoryErrorApiResponse = z.object({
  error: z.boolean(),
  http_status: z.number(),
  error_message: z.string()
})

export type IAlarmHistoryList = z.infer<typeof ZAlarmHistoryList>
export type IAlarmHistoryData = z.infer<typeof ZAlarmHistoryData>
export type IAlarmHistoryApiResponse = z.infer<typeof ZAlarmHistoryApiResponse>
export type IAlarmHistoryErrorApiResponse = z.infer<typeof ZAlarmHistoryErrorApiResponse>

export async function getAlarmHistory (
  assetId: string,
  page: number,
  size: number,
  onSuccess: (alarmHistoryData: IAlarmHistoryData, httpStatus: number) => any,
  onFailure: (errorMessage: string, httpStatus: number) => any
): Promise<void> {
  const url = `/alarm-service/api/v1/udp/assets/alarms/history/${assetId}?&page=${page}&size=${size}`
  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      const parseResponse = ZAlarmHistoryApiResponse.safeParse(response.data)
      if (parseResponse.success) {
        if (
          parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
        ) {
          onSuccess(parseResponse.data.data, response.status)
        } else {
          onFailure('Error occurred while processing response', response.status)
        }
      } else {
        onFailure(parseResponse.error.message, response.status)
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        const parseResponse = ZAlarmHistoryErrorApiResponse.safeParse(error.response.data)
        if (parseResponse.success) {
          onFailure(parseResponse.data.error_message, error.response.status)
        } else {
          onFailure('Parsing Error', error.response.status)
        }
      } else {
        onFailure('Network Error', 0)
      }
    })
}
