import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZData = z.object({
  id: z.number(),
  description: z.string(),
  spec_version: z.string(),
  name: z.string(),
  public_key: z.string(),
  file_created: z.string(),
  encryption_enabled_state: z.boolean(),
  protocol: z.string(),
  sftp_host_name: z.string(),
  url: z.string(),
  username: z.string(),
  password: z.string(),
  sftp_port: z.number(),
  sftp_public_key: z.string(),
  sftp_encryption_string: z.string(),
  tenant_id: z.string(),
  file_format: z.string(),
  enabled: z.boolean(),
  minutes_between_publishing: z.number(),
  hour_of_day_to_start: z.number(),
  minute_of_day_to_start: z.number(),
  minutes_after_hour: z.number(),
  frequency_type: z.string()
})

const ZConfigurationTemplate = z.object({
  total_count: z.number(),
  configurations: z.array(ZData)
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.optional(ZConfigurationTemplate.nullable()),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export type Configuration = z.infer<typeof ZConfigurationTemplate>
export type ConfigurationData = z.infer<typeof ZData>

export async function getAllConfigData (
  page: number,
  size: number,
  tenantId: string,
  onSuccess: (deviceList: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/datapublisher/api/v1/udp/datapublisher/configdata?tenantId=${tenantId}&page=${page}&size=${size}`
  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, parseResponse.data.http_status)
          } else {
            onFailure(parseResponse.data.error, parseResponse.data.http_status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      onFailure(error.response !== undefined ? error.response.statusText : 'Network Error', error.response !== undefined ? error.response.status : 404)
    })
}
