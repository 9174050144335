import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type SchdulerListDetails } from '../services/getAllSchdulerList'

export interface SchdulerListData {
  schedule_name: string
  report_template: string
  group: string
  device_type: string
  groups: string[]
  group_name: string
  last_schedule_date: string
  next_schedule_date: string
  last_schedule_status: string
  action: string
}

export interface SchdulerListDataState {
  isLoading: boolean
  loadingError: string | undefined
  SchdulerListData: SchdulerListDetails[] | undefined
  httpStatus: number | undefined
  totalCount: number
}

// Initial state of the slice
const initialState: SchdulerListDataState = {
  isLoading: true,
  loadingError: undefined,
  SchdulerListData: undefined,
  httpStatus: undefined,
  totalCount: 0
}

// Slice to return from action
export const SchdulerListingDataState = createSlice({
  name: 'SchdulerListData',
  initialState,
  reducers: {
    SchdulerListLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: undefined,
        SchdulerListData: undefined,
        httpStatus: 0,
        totalCount: 0
      }
    },
    SchdulerListUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: undefined,
        SchdulerListData: action.payload.data,
        httpStatus: action.payload.httpStatus,
        totalCount: action.payload.total_count
      }
    },
    SchdulerListErrorAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        httpStatus: action.payload.httpStatus,
        SchdulerListData: undefined,
        totalCount: 0
      }
    }
  }
})

// Actions to export
export const {
  SchdulerListLoadingAction,
  SchdulerListUpdateAction,
  SchdulerListErrorAction
} = SchdulerListingDataState.actions

export default SchdulerListingDataState.reducer
