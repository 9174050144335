/* istanbul ignore file */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type HistoryDonutTableDataObject } from '../types'

export interface ExportDonutKpiDetailsState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number
  exportDonutKpiDetails: HistoryDonutTableDataObject | undefined
}

// Initial state of the slice
const initialState: ExportDonutKpiDetailsState = {
  isLoading: true,
  error: undefined,
  httpStatus: 0,
  exportDonutKpiDetails: undefined
}

// Slice to return from action
export const exportDonutKpiDetailsSlice = createSlice({
  name: 'exportDonutKpiDetails',
  initialState,
  reducers: {
    exportDonutKpiDetailsLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        exportDonutKpiDetails: undefined,
        httpStatus: 0
      }
    },
    exportDonutKpiDetailsUpdateAction: (state, action: PayloadAction<{ data: HistoryDonutTableDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        exportDonutKpiDetails: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    exportDonutKpiDetailsErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        exportDonutKpiDetails: undefined,
        httpStatus: action.payload.httpStatus
      }
    },
    // istanbul ignore next
    exportDonutKpiDetailsResetAction: () => {
      return {
        isLoading: false,
        error: undefined,
        exportDonutKpiDetails: undefined,
        httpStatus: 0
      }
    }
  }
})

// Actions to export
export const {
  exportDonutKpiDetailsLoadingAction,
  exportDonutKpiDetailsUpdateAction,
  exportDonutKpiDetailsErrorAction,
  exportDonutKpiDetailsResetAction
} = exportDonutKpiDetailsSlice.actions

export default exportDonutKpiDetailsSlice.reducer
