import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ApiResponse } from '../services/disassociateAssetService'

export interface DisassociateAssetState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null | undefined
}

const initialState: DisassociateAssetState = {
  isLoading: true,
  error: null,
  httpStatus: null
}

const disassociateAssetsSlice = createSlice({
  name: 'disassociate-assets',
  initialState,
  reducers: {
    disassociateAssetsLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0
      }
    },
    disassociateAssetsUpdateAction: (state, action: PayloadAction<{ data: ApiResponse }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.data.http_status
      }
    },
    disassociateAssetsErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  disassociateAssetsLoadingAction,
  disassociateAssetsUpdateAction,
  disassociateAssetsErrorAction
} = disassociateAssetsSlice.actions

export default disassociateAssetsSlice.reducer
