import { Box, CircularProgress, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material'
import Pagination from '../../../components/TablePagination'
import React, { useEffect } from 'react'
import { type ConnectedProps, connect, useDispatch } from 'react-redux'
import { type RootState } from '../../../store'
import { type AllConfigData, type ConfigState } from '../redux/allConfigSlice'

import AuthError from '../../../components/ErrorComponents/AuthError'
import { fetchAllConfigs, updateConfig } from '../redux/actionCreators'
import { useAuth } from 'react-oidc-context'
import { type ConfigurationData } from '../services/allConfigService'
import { useNavigate } from 'react-router-dom'
import { changeConfigId } from '../redux/configIdSlice'

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

function EnhancedTableHead (props: any): any {
  return (
          <TableHead data-testid='table-header'>
              <TableRow>
                  {headCells.map((headCell: HeadCell) => (
                      <StyledTableCell
                          key={headCell.id}
                          align={'left'}
                          padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                          <>{headCell.label}</>
                      </StyledTableCell>
                  ))}
              </TableRow>
          </TableHead>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'NAME'
  },
  {
    id: 'enabled',
    numeric: false,
    disablePadding: false,
    label: 'ENABLED'
  },
  {
    id: 'tenant_id',
    numeric: true,
    disablePadding: false,
    label: 'ORGANIZATION'
  },
  {
    id: 'protocol',
    numeric: true,
    disablePadding: false,
    label: 'PROTOCOL'
  },
  {
    id: 'file_format',
    numeric: true,
    disablePadding: false,
    label: 'TYPE'
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'CREATED ON'
  },
  {
    id: 'description',
    numeric: true,
    disablePadding: false,
    label: 'DESCRIPTION'
  }
]

type Order = 'asc' | 'desc'

interface Sort {
  order: Order
  orderBy: string
}

const ConfigurationTable = (props: PropsFromRedux): JSX.Element => {
  const [configList, setConfigList] = React.useState<ConfigurationData[]>([])
  const [selected, setSelected] = React.useState<readonly number[]>([])
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'template_id' })
  const rowsPerPage = 10
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const auth = useAuth()

  const handleFetchConfigList = (): void => {
    if (page >= 1) {
      props.fetchAllConfigs(page - 1, rowsPerPage, auth.user?.profile.customer as string)
    }
  }

  useEffect(() => {
    handleFetchConfigList()
  }, [page, sort])

  useEffect(() => {
    if (props.allConfigData.configData !== null && props.allConfigData.configData.configurations.length > 0) {
      setConfigList(props.allConfigData.configData.configurations)
    }
  }, [props.allConfigData.configData])

  const handleRequestSort =
        (event: React.MouseEvent<unknown>, newOrderBy: keyof AllConfigData): void => {
          const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
          const toggledOrder = isAsc ? 'desc' : 'asc'
          setSort({ order: toggledOrder, orderBy: newOrderBy })
        }

  const handleRowClick = (configId: string): void => {
    dispatch(changeConfigId(configId))
    sessionStorage.setItem('configId', configId)
    navigate('/addnewconfig')
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked && configList.length > 0) {
      const newSelected: any = configList.map((n: any) => n.name)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const isSelected = (index: number): boolean => selected.includes(index)

  const handleEnabled = (currentRow: ConfigurationData): void => {
    const handleFrequencyState = (input: string): number => {
      const frequencyType = ['CONTINUOUS', 'MULTIPLE_PER_HOUR', 'ONCE_PER_HOUR', 'ONCE_PER_DAY']
      return frequencyType.indexOf(input)
    }

    props.updateConfig(
      currentRow.id.toString(),
      {
        description: currentRow.description,
        spec_version: currentRow.spec_version,
        name: currentRow.name,
        public_key: '',
        file_created: currentRow.file_created,
        encryption_enabled_state: currentRow.encryption_enabled_state,
        protocol: currentRow.protocol,
        sftp_host_name: currentRow.sftp_host_name,
        url: currentRow.url,
        username: currentRow.username,
        password: currentRow.password,
        sftp_port: currentRow.sftp_port,
        sftp_public_key: currentRow.sftp_public_key,
        sftp_encryption_string: currentRow.sftp_encryption_string,
        tenant_id: currentRow.tenant_id,
        file_format: currentRow.file_format,
        enabled: !(currentRow.enabled),
        minutes_between_publishing: currentRow.minutes_between_publishing,
        hour_of_day_to_start: currentRow.hour_of_day_to_start,
        minute_of_day_to_start: currentRow.minute_of_day_to_start,
        minutes_after_hour: currentRow.minutes_after_hour,
        frequency_type: handleFrequencyState(currentRow.frequency_type)
      })
  }

  if (props.allConfigData.configData !== null && props.allConfigData.configData.configurations.length > 0 && props.allConfigData.httpStatus === 200) {
    return (
      <>
        <Box sx={style} className="devices">
          <Paper
            sx={{
              width: '100%',
              mb: 2,
              backgroundColor: '#272727',
              boxShadow: 'unset',
              color: '#F0F0F0',
              marginBottom: '0'
            }}
          >
            <TableContainer className="device-table">
              <Table
                sx={{ minWidth: 750, color: '#f0f0f0' }}
                aria-labelledby="tableTitle"
                size={'small'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={sort.order}
                  orderBy={sort.orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={configList.length}
                />
                <TableBody>
                  {configList.length > 0 &&
                    configList.map((row: ConfigurationData, index: number) => {
                      const isItemSelected = isSelected(index)
                      const labelId = `enhanced-table-checkbox-${row.id}`

                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          aria-label="devices-list-row"
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            aria-label="device-name"
                            onClick={() => { handleRowClick(row.id.toString()) }}
                            data-testid="device-name"
                            sx={{
                              color: '#64C3FF !important',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '25vw'
                            }}
                          >
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Switch checked={row.enabled}
                            onChange={() => { handleEnabled(row) }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.tenant_id.toUpperCase()}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.protocol}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.file_format}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.file_created.split('.')[0]}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.description}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {props.allConfigData.configData.total_count > 10 && (<Pagination count={props.allConfigData.configData.total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} siblingCount={1}
                        boundaryCount={1} />)}
          </Paper>
        </Box>
      </>
    )
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  } else if (props.allConfigData.isLoading) {
    return (
      <div className='CircularprogressAmi'>
        <CircularProgress />
      </div>
    )
  } else if (props.allConfigData.httpStatus === 401) {
    return (
            <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
    )
  } else if (props.allConfigData.httpStatus === 403) {
    return (
        <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
    )
  } else if ((props.allConfigData.configData === null && props.allConfigData.httpStatus === 200)) {
    return (
        <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
    )
  } else {
    return (
        <div className='authError'><AuthError errorMessage="cannotFetch" retry={handleFetchConfigList}/></div>
    )
  }
}

interface DispatchToProps {
  fetchAllConfigs: (page: number, size: number, tenantId: string) => void
  updateConfig: (configId: string, configTemplate: AllConfigData) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAllConfigs: (page: number, size: number, tenantId: string) => dispatch(fetchAllConfigs(page, size, tenantId)),
  updateConfig: (configId: string, configTemplate: AllConfigData) => dispatch(updateConfig(configId, configTemplate))
})

interface StateToProps {
  allConfigData: ConfigState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  allConfigData: state.allConfigData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ConfigurationTable)
