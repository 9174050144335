/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type AssetProvisioningHistoryDetails } from '../services/getAssetProvisioningHistory'

export interface assetProvisioningHistoryDetailState {
  isLoading: boolean
  loadingError: string | null
  assetProvisioningHistoryData: AssetProvisioningHistoryDetails | null
  httpStatus: number | null
  totalCount:number
}

// Initial state of the slice
const initialState: assetProvisioningHistoryDetailState = {
  isLoading: true,
  loadingError: null,
  assetProvisioningHistoryData: null,
  httpStatus: null,
  totalCount:0
}

// Slice to return from action
export const assetProvisioningHistoryDetailSlice = createSlice({
  name: 'assetProvisioningHistoryData',
  initialState,
  reducers: {
    assetProvisioningHistoryDataLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        assetProvisioningHistoryData: null,
        httpStatus: 0,
        totalCount:0
      }
    },
    assetProvisioningHistoryDataUpdateAction: (state, action: PayloadAction< any >) => {
      return {
        isLoading: false,
        loadingError: null,
        assetProvisioningHistoryData: action.payload.data,
        httpStatus: action.payload.httpStatus,
        totalCount:action.payload.total_count
      }
    },
    assetProvisioningHistoryDataErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload.errorMessage,
        assetProvisioningHistoryData: null,
        httpStatus: action.payload.httpStatus,
        totalcount:0
      }
    }
  }
})

// Actions to export
export const {
  assetProvisioningHistoryDataLoadingAction,
  assetProvisioningHistoryDataUpdateAction,
  assetProvisioningHistoryDataErrorAction
} = assetProvisioningHistoryDetailSlice.actions

export default assetProvisioningHistoryDetailSlice.reducer
