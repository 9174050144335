// istanbul ignore file
import { useState, useEffect } from 'react'

interface WindowSize {
  width: number
  height: number
}

function useWindowSize (): WindowSize {
  // State to store window dimensions
  const [windowSize, setWindowSize] = useState<WindowSize>({ width: window.innerWidth, height: window.innerHeight })

  // Helper function to get window size
  const getSize = (): WindowSize => {
    return { width: window.innerWidth, height: window.innerHeight }
  }

  // Update window size on mount and resize
  useEffect(() => {
    setWindowSize(getSize())

    const handleResize = (): void => { setWindowSize(getSize()) }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove event listener on unmount
    return () => { window.removeEventListener('resize', handleResize) }
  }, [])

  return windowSize
}

export default useWindowSize
