import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { type RootState } from '../../store'
import BackButton from '../../components/BackButton'
import ConfigurationList from './components/ConfigurationList'
import LanguageStrings from '../../i18n/locales'

const ConfigurationListStrings = LanguageStrings().ConfigurationUploadStrings.ConfigurationUploadStatus

const ConfigurationUploadStatus = (): JSX.Element => {
  const navigate = useNavigate()
  const numConfigurations = useSelector((state: RootState) => state.configurationsForStatus.configurationsWithStatus !== null ? state.configurationsForStatus.configurationsWithStatus.total_count : 0)
  return (
    <div style={{ width: 'calc(100vw - 3.5rem)', paddingRight: '1.2rem' }}>
      <div className='link-breadcrumb'>
        <span data-testid='assetManagementbreadcrumb' onClick={ () => { navigate('/card') }} className='assetmgmtli'>{ConfigurationListStrings.Asset_Management}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span>{ConfigurationListStrings.Configuration_Upload_Status}</span>
      </div>
      <div className='topUtilityBar'>
        <BackButton/>
        <span id='componentTitle' data-testid='configurations'>{ConfigurationListStrings.Configuration_Upload_Status}</span>
      </div>
      <div>
        <div className='configurations-table-heading'>
          <h5>{ConfigurationListStrings.Configuration_List} <span className='config-list-box'>{numConfigurations} {ConfigurationListStrings.Configurations}</span></h5>
        </div>
        <ConfigurationList />
      </div>
    </div>
  )
}

export default ConfigurationUploadStatus
