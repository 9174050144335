import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

export interface DisassociateAssetsRequest {
  template_id: string
  asset_type_id: string
  assets: string[]
}

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.string(),
  error: z.boolean()
})

export type ApiResponse = z.infer<typeof ZApiResponse>

export async function unAssignAssetsFromConfiguration (
  assetsRequest: DisassociateAssetsRequest,
  onSuccess: (successResponse: ApiResponse) => any,
  onFailure: (errorMessage: string, httpStatus: number | undefined) => any
): Promise<void> {
  const unassignedAssets = {
    assets: assetsRequest.assets
  }
  const url = `/asset-config-service/api/v1/udp/commands/configuration/templates/${assetsRequest.template_id}/assets`
  interceptedAxios
    .put(url, unassignedAssets, { timeout: 15000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        // istanbul ignore else
        if (parseResponse.success) {
          onSuccess(parseResponse.data)
        } else {
          onFailure('Error occurred while processing response', response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      onFailure(error.response !== undefined ? error.response.statusText : 'Network Error', error.response !== undefined ? error.response.status : 404)
    })
}
