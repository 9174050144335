/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface provisioningHistoryDetailsFilterDeviceTypeState {
  isLoading: boolean
  loadingError: string | null
  provisioningHistoryDetailsFilterDeviceType: string[] | []
  httpStatus: number | null
}

// Initial state of the slice
const initialState: provisioningHistoryDetailsFilterDeviceTypeState = {
  isLoading: true,
  loadingError: null,
  provisioningHistoryDetailsFilterDeviceType: [],
  httpStatus: null
}

// Slice to return from action
export const provisioningHistoryDetailsFilterDeviceTypeSlice = createSlice({
  name: 'provisioningHistoryDetailsFilterDeviceType',
  initialState,
  reducers: {
    provisioningHistoryDetailsFilterDeviceTypeLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        provisioningHistoryDetailsFilterDeviceType: [],
        httpStatus: 0
      }
    },
    provisioningHistoryDetailsFilterDeviceTypeUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: null,
        provisioningHistoryDetailsFilterDeviceType: action.payload.data,
        httpStatus: action.payload.httpStatus,
      }
    },
    provisioningHistoryDetailsFilterDeviceTypeErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        provisioningHistoryDetailsFilterDeviceType: []
      }
    }
  }
})

// Actions to export
export const {
  provisioningHistoryDetailsFilterDeviceTypeLoadingAction,
  provisioningHistoryDetailsFilterDeviceTypeUpdateAction,
  provisioningHistoryDetailsFilterDeviceTypeErrorAction
} = provisioningHistoryDetailsFilterDeviceTypeSlice.actions

export default provisioningHistoryDetailsFilterDeviceTypeSlice.reducer
