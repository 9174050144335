import Modal from 'react-modal'
import IdleTimer from 'react-idle-timer'
import './IdleModal.css'
import React, { useRef, useState } from 'react'

const IdleModal = (props: { onLogout: () => void }): JSX.Element => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const idleTimerRef = useRef<IdleTimer | null>(null)
  const sessionTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const onIdle = (): void => {
    setModalIsOpen(true)
    sessionTimeoutRef.current = setTimeout(() => {
      props.onLogout() // call onLogout function passed from DashboardLayout
      setModalIsOpen(false)
    }, 5000)
  }

  const stayActive = (): void => {
    setModalIsOpen(false)
    if (sessionTimeoutRef.current != null) {
      clearTimeout(sessionTimeoutRef.current)
    }
  }

  return (
        <div>
            <Modal data-testid='Idle' isOpen={modalIsOpen} style={{ overlay: { background: 'none' }, content: { background: 'none', height: '100vh', width: '100vw' } }}>
                <div style={{ backgroundColor: '#000000a4', height: '100vh', width: '100vw', position: 'fixed', zIndex: 1000, top: 0, backdropFilter: 'blur(5px)', left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ width: '500px', height: '224px', backgroundColor: '#202020', padding: '30px', borderRadius: '8px', boxShadow: '28px 28px 32px 12px rgba(0,0,0,0.66)' }}>
                        <h2 id='autologout-h2'>You have been Idle for a while</h2>
                        <p id='autologout-p'>You will be logged out soon, Do you want to stay?</p>
                        <div style={{ display: 'flex', justifyContent: 'right', padding: '1em' }}>
                            <button onClick={stayActive} className="button_active" data-testid='button_active'> Keep me signed in</button>
                            <button onClick={() => {
                              props.onLogout() // call onLogout function passed from DashboardLayout
                              setModalIsOpen(false)
                              if (sessionTimeoutRef.current != null) {
                                clearTimeout(sessionTimeoutRef.current)
                              }
                            }} className="button_logout" data-testid='button_logout'> Log out </button>
                        </div>
                    </div>
                </div>
            </Modal>
            <IdleTimer data-testid='Idle_timer'
                       ref={(ref: IdleTimer | null) => {
                         return (idleTimerRef.current = ref)
                       }}
                       timeout={1200000} onIdle={onIdle}></IdleTimer>
        </div>
  )
}
export default IdleModal
