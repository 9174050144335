import React from 'react'
import AuthError from '../../../../../components/ErrorComponents/AuthError'
import { CircularProgress } from '@mui/material'

interface ParentProps {
  isLoading: boolean
  unauthorized: boolean
  forbidden: boolean
  noDataPresent: boolean
  retryHandler?: () => void
}

export const HandleError = (props: ParentProps): JSX.Element => {
  if (props.isLoading) {
    return (
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
    )
  } else if (props.unauthorized) {
    return (
              <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
    )
  } else if (props.forbidden) {
    return (
          <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
    )
  } else if (props.noDataPresent) {
    return (
          <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
    )
  } else {
    return (
          <div className='authError'><AuthError errorMessage="cannotFetch" retry={props.retryHandler}/></div>
    )
  }
}
