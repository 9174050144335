import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface Alarm {
  alarmDefinitionId: string
  alarmName: string
  severity: string
  alarmStatus: string
  readingTime: string
}

export interface AlarmByIdState {
  isLoading: boolean
  alarmData: Alarm[] | null
  errorMessage: string | null
  httpStatus: number | null
}

const initialState: AlarmByIdState = {
  isLoading: true,
  alarmData: null,
  errorMessage: null,
  httpStatus: null
}

export const alarmByIdSlice = createSlice({
  name: 'alarmById',
  initialState,
  reducers: {
    alarmByIdDataLoading: (state): AlarmByIdState => {
      return {
        isLoading: true,
        alarmData: null,
        errorMessage: null,
        httpStatus: 0
      }
    },
    alarmByIdDataError: (
      state,
      action: PayloadAction<{ errorMessage: string, httpStatus: number }>
    ): AlarmByIdState => {
      return {
        isLoading: false,
        alarmData: null,
        errorMessage: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    },
    addAlarmByIdData: (state, action: PayloadAction<{ AlarmData: Alarm[], httpStatus: number }>): AlarmByIdState => {
      return {
        isLoading: false,
        alarmData: action.payload.AlarmData,
        errorMessage: null,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const { alarmByIdDataLoading, alarmByIdDataError, addAlarmByIdData } =
  alarmByIdSlice.actions
export default alarmByIdSlice.reducer
