/* eslint-disable */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type DeviceProvisioningData } from '../services/getDeviceProvisioningData'

export interface assetDetail {
  provisioningDetailsId: string
  assetId: string
  name: string
  serialNumber: string
  configuration: string
  state: string
  assetGroup: string
  provisioningDetailsStatus: string
  provisioningErrorDescription: string
  deviceType: string
  provisioningHistoryId: number
}

export interface deviceProvisioningDataState {
  isLoading: boolean
  loadingError: string | null
  deviceProvisioningData: DeviceProvisioningData | null
  httpStatus: number | null
  totalCount: number
}

// Initial state of the slice
const initialState: deviceProvisioningDataState = {
  isLoading: true,
  loadingError: null,
  deviceProvisioningData: null,
  httpStatus: null,
  totalCount: 0
}

// Slice to return from action
export const deviceProvisioningDataSlice = createSlice({
  name: 'deviceProvisioningData',
  initialState,
  reducers: {
    deviceProvisioningDataLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        deviceProvisioningData: null,
        httpStatus: 0,
        totalCount: 0
      }
    },
    deviceProvisioningDataUpdateAction: (state, action: PayloadAction< any >) => {
      return {
        isLoading: false,
        loadingError: null,
        deviceProvisioningData: action.payload.data,
        httpStatus: action.payload.httpStatus,
        totalCount: action.payload.total_count
      }
    },
    deviceProvisioningDataErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        deviceProvisioningData: null
      }
    }
  }
})

// Actions to export
export const {
  deviceProvisioningDataLoadingAction,
  deviceProvisioningDataUpdateAction,
  deviceProvisioningDataErrorAction
} = deviceProvisioningDataSlice.actions

export default deviceProvisioningDataSlice.reducer
