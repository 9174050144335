/* eslint-disable @typescript-eslint/ban-types */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface ThirtyDay {
  timestamp: string
  kpi_value: number
}

export interface thirtyDayState {
  isLoading: boolean
  loadingError: string | null
  thirtyDayData: {} | null
  httpStatus: number | null
}

// Initial state of the slice
const initialState: thirtyDayState = {
  isLoading: true,
  loadingError: null,
  thirtyDayData: {},
  httpStatus: null
}

// Slice to return from action
export const thirtyDaySlice = createSlice({
  name: 'thirtyDayMap',
  initialState,
  reducers: {
    thirtyDayLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        thirtyDayData: null,
        httpStatus: 0
      }
    },
    thirtyDayUpdateAction: (state, action: PayloadAction<{ data: {}, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: null,
        thirtyDayData: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    thirtyDayErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: action.payload.errorMessage,
        thirtyDayData: null,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

// Actions to export
export const {
  thirtyDayLoadingAction,
  thirtyDayUpdateAction,
  thirtyDayErrorAction
} = thirtyDaySlice.actions

export default thirtyDaySlice.reducer
