import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useEffect, useState } from 'react'
import LanguageStrings from '../../i18n/locales'

const HandleConjuctionStrings = LanguageStrings().AssetGroupStrings.GroupDetailsComponent.HandleConjuction

interface SavedFilterTemplateProps {
  onConjuctionChange: (value: string) => void
  currentConjuction: string // Added prop to receive current conjunction value from parent
}

const SavedFilterTemplate = ({ onConjuctionChange, currentConjuction }: SavedFilterTemplateProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<string>('All')

  useEffect(() => {
    setSelectedValue(currentConjuction === 'AND' ? 'All' : 'Any') // Update state when prop changes
  }, [currentConjuction])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value
    setSelectedValue(value)

    // Set conjunction based on the selected radio button
    const conjunctionValue = value === 'All' ? 'AND' : 'OR'
    onConjuctionChange(conjunctionValue)
  }

  return (
    <FormControl style={{ justifyContent: 'space-between' }}>
      <RadioGroup
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
        value={selectedValue}
        onChange={handleChange}
      >
        <h2 data-testid='matches' style={{ fontSize: '16px', fontWeight: '500', padding: '15px 35px 0px 7px' }}>{HandleConjuctionStrings.Matches}</h2>
        <FormControlLabel data-testid='radio-all' style={{ fontSize: '16px' }} value='All' control={<Radio style={{ color: '#B0B0B0' }} />} label='All' />
        <FormControlLabel data-testid='radio-any' style={{ fontSize: '16px', paddingTop: '0' }} value='Any' control={<Radio style={{ color: '#B0B0B0' }} />} label='Any' />
      </RadioGroup>
    </FormControl>
  )
}

export default SavedFilterTemplate
