import React from 'react'
import './CardDashboard.css'
import {
  Card,
  CardContent,
  Toolbar,
  Typography
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import LanguageStrings from '../../../i18n/locales'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import { type OperationPermissionState } from '../redux/operationPermissionSlice'
import { getOperationPermissions } from '../redux/actionCreator'
import { useAuth } from 'react-oidc-context'

function ConfigurationDashboard (props: PropsFromRedux): any {
  const navigate = useNavigate()
  const auth = useAuth()
  const operationPermissions: OperationPermissionState = useSelector((state: RootState) => state.operationPermissions)
  React.useEffect(() => {
    if (!operationPermissions.updated) {
      props.getOperationPermissions(auth.user?.profile.sub as string)
    }
  }, [])

  const CardDashboardStrings = LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings
    .SchedulerStrings.conectionSettingDashbaord

  return (
    <div className='card-body'>
      <div>
        <Toolbar className='dm-backButton'>
          <Typography
            className='header-title'
          >
            {CardDashboardStrings.DataPublisherSettingPageTitle}
          </Typography>
        </Toolbar>
      </div>
      <div className="container">
        <Card className="card" aria-label='cardopen'>
          <CardContent className='cardcontent'>
            <Typography>
              <p className="cardMainTitle"
              >
                {CardDashboardStrings.BoxTitle}
              </p>
              <ul
                className='sub-titleList'
              >
                <li data-testid= 'asset-list-card' className='assetmgmtli' onClick={ () => { navigate('/configuration-protocol') }}>
                  {CardDashboardStrings.BocSubtitle_First}</li>
              </ul>
            </Typography>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

interface DispatchToProps {
  getOperationPermissions: (sub: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getOperationPermissions: (sub: string) => dispatch(getOperationPermissions(sub))
})

const connector = connect(null, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
export default connector(ConfigurationDashboard)
