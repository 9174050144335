import { type TooltipFormatterContextObject } from 'highcharts'
import { parseDateInAOHstandard } from '../customHooks/parseDate'

export function getDataSourceTooltip (ctx: TooltipFormatterContextObject, consumptionData: any = [[null, null, null]], unitText: string, toolTip: string[]): string {
  const xValue = ctx.x
  let extraFieldValue = 'Not Available'
  let consumption = 'Not Available'

  for (let i = 0; i < consumptionData.length; i++) {
    if (xValue === consumptionData[i][0]) {
      extraFieldValue = consumptionData[i][2]
      break
    }
  }

  if (ctx.y !== undefined && ctx.y !== null) {
    consumption = ctx.y.toString()
  }

  const date = ctx.x !== undefined ? parseDateInAOHstandard(ctx.x as string) : ''

  if (toolTip.length < 2) {
    return (
      '<span style="color:' + '">\u25CF</span> ' +
            date + '<br/>' +
          '•&nbsp;&nbsp;' + ctx.series.name.toString() + ': ' + consumption + '</b>'
    )
  } else {
    return (
      '<span style="color:' + '">\u25CF</span> ' +
            date + '<br/>' +
          '•&nbsp;&nbsp;' + ctx.series.name.toString() + ': ' + consumption + '</b><br/>' +
          '•&nbsp;&nbsp;Meter index' + ((unitText.length > 0) ? ' ' + unitText : '') + ': ' + extraFieldValue.toString()
    )
  }
}
