import React, { useEffect, useState } from 'react'
import { CircularProgress, FormHelperText, Grid } from '@mui/material'
import LanguageStrings from '../../../../i18n/locales'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import { type AssetGroupDetailsState } from '../../redux/AssetGroupDetailsSlice'
import { connect } from 'react-redux'
import { type ConnectedProps } from 'react-redux'
import { type RootState } from '../../../../store'
import { fetchAssetGroupDetails } from '../../redux/actionCreator'
import { type AssetGroupDetails } from '../../types'
import { GenericTextarea } from '../../../../components/GenericTextarea'
import { isValidDesc } from '../../../../utils/validator'
import { useAuth } from 'react-oidc-context'

const AssetGroupDetailsString = LanguageStrings().AssetGroupStrings.GroupDetailsComponent.AssetGroupDetails

const AssetGroupDetailsComponent = (props: AllProps): JSX.Element => {
  const [groupDetails, setGroupDetails] = useState<AssetGroupDetails | undefined>(undefined)
  const [descErrorInit, setDescErrorInit] = useState(false)
  const auth = useAuth()

  const handleFetchAssetGroupDetails = (): void => {
    let groupId = sessionStorage.getItem('selectedGroupId')
    if (groupId === null) {
      // istanbul ignore next
      groupId = ''
    }
    props.fetchAssetGroupDetails(groupId, auth.user?.profile.customer as string ?? '')
  }

  useEffect(() => {
    handleFetchAssetGroupDetails()
  }, [props.reset])

  useEffect(() => {
    // istanbul ignore next
    if (props.assetGroupDetailsState.assetGroupDetails !== null && props.assetGroupDetailsState.httpStatus === 200) {
      if (props.assetGroupDetailsState.assetGroupDetails !== undefined) {
        props.setAssetTypeId(props.assetGroupDetailsState.assetGroupDetails.asset_type_id)
      }
      setGroupDetails(props.assetGroupDetailsState.assetGroupDetails)
      sessionStorage.setItem('currGroupDesc', props.assetGroupDetailsState.assetGroupDetails !== undefined ? props.assetGroupDetailsState.assetGroupDetails.description : '')
      props.setDescription(props.assetGroupDetailsState.assetGroupDetails !== undefined ? props.assetGroupDetailsState.assetGroupDetails.description : '')
      props.setInitialDesc(props.assetGroupDetailsState.assetGroupDetails !== undefined ? props.assetGroupDetailsState.assetGroupDetails.description : '')
    }
  }, [props.assetGroupDetailsState.assetGroupDetails])

  const checkDescriptionIsValid = (value: string): void => {
    props.setDescError((!isValidDesc(value) || value.length > 200) && value.length !== 0)
  }

  const gridItemStyle = {
    flex: '0 0 25%'
  }
  // istanbul ignore else
  if (groupDetails !== undefined) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6} style={gridItemStyle}>
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>{AssetGroupDetailsString.GroupName}</div>
            <div>{groupDetails.group_name}</div>
          </div>
        </Grid>
        <Grid item xs={6} style={gridItemStyle}>
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>{AssetGroupDetailsString.GroupType}</div>
            <div>{groupDetails.group_type}</div>
          </div>
        </Grid>
        <Grid item xs={6} style={gridItemStyle}>
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>{AssetGroupDetailsString.Category}</div>
            <div>{groupDetails.category_name}</div>
          </div>
        </Grid>
        <Grid item xs={6} style={gridItemStyle}>
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>{AssetGroupDetailsString.AssetType}</div>
            <div>{groupDetails.asset_type_name}</div>
          </div>
        </Grid>
        <Grid item xs={12} style={gridItemStyle}>
          <div>
            <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>{AssetGroupDetailsString.Description}</div>
            {
              props.isEditEnabled
                ? <>
                <GenericTextarea
                  sx={{ width: 350, height: 64 }}
                  placeholder='Enter Description'
                  value={props.description} onChange={(e) => {
                    props.setDescription(e.target.value)
                    setDescErrorInit(true)
                    checkDescriptionIsValid(e.target.value)
                  }}
                />
                  {
                    // istanbul ignore next
                    (props.descError && descErrorInit) && (
                    <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', width: '70%' }} className="errorColor">
                      {AssetGroupDetailsString.Desc_Error}
                    </FormHelperText>
                    )
                  }
              </>
                : <div style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '15vw',
                  cursor: 'pointer'
                }}><abbr style={{ textDecoration: 'none' }} title={groupDetails.description}>{groupDetails.description}</abbr></div>
            }
          </div>
        </Grid>
      </Grid>
    )
  } else if (props.assetGroupDetailsState.isLoading) {
    return (
      <div className='CircularprogressAmi'>
        <CircularProgress />
      </div>
    )
  } else if (props.assetGroupDetailsState.httpStatus === 401) {
    return (
            <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
    )
  } else if (props.assetGroupDetailsState.httpStatus === 403) {
    return (
        <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
    )
  } else if (((props.assetGroupDetailsState.assetGroupDetails === null || props.assetGroupDetailsState.httpStatus === 404) && props.assetGroupDetailsState.error !== '')) {
    return (
        <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
    )
  } else {
    return (
        <div className='authError'><AuthError errorMessage="cannotFetch" retry={handleFetchAssetGroupDetails}/></div>
    )
  }
}

interface DispatchToProps {
  fetchAssetGroupDetails: (groupId: string, tenantId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAssetGroupDetails: (groupId: string, tenantId: string) => dispatch(fetchAssetGroupDetails(groupId, tenantId))
})

interface StateToProps {
  assetGroupDetailsState: AssetGroupDetailsState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assetGroupDetailsState: state.assetGroupDetails
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

interface ParentProps {
  reset: boolean
  setAssetTypeId: React.Dispatch<React.SetStateAction<string>>
  isEditEnabled: boolean
  description: string
  setDescription: React.Dispatch<React.SetStateAction<string>>
  descError: boolean
  setDescError: React.Dispatch<React.SetStateAction<boolean>>
  setInitialDesc: React.Dispatch<React.SetStateAction<string>>
}

type AllProps = PropsFromRedux & ParentProps

export default connector(AssetGroupDetailsComponent)
