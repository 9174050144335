import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface SeedState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number
  data: boolean | undefined
}

const initialState: SeedState = {
  isLoading: false,
  error: undefined,
  data: undefined,
  httpStatus: 0
}

const seedSlice = createSlice({
  name: 'seed',
  initialState,
  reducers: {
    seedLoadingAction: (state) => {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        data: undefined,
        httpStatus: 0
      }
    },
    seedSuccessUpdateAction: (state, action: PayloadAction<{ data: boolean, httpStatus: number }>) => {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    seedErrorAction: (
      state,
      action: PayloadAction<{ errorMessage: string, httpStatus: number }>
    ) => {
      return {
        ...state,
        isLoading: false,
        data: undefined,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const { seedLoadingAction, seedSuccessUpdateAction, seedErrorAction } = seedSlice.actions

export default seedSlice.reducer
