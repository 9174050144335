import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type BarKpiDataObject } from '../../types'

export interface TotalConnectedState {
  isLoading: boolean
  error: boolean
  data: BarKpiDataObject | undefined
  httpStatus: number
}

const initialState: TotalConnectedState = {
  isLoading: false,
  error: false,
  data: undefined,
  httpStatus: 0
}

const totalConnectedSlice = createSlice({
  name: 'totalConnected',
  initialState,
  reducers: {
    totalConnectedLoadingAction: (state) => {
      return {
        isLoading: true,
        error: false,
        data: undefined,
        httpStatus: 0
      }
    },
    totalConnectedUpdateAction: (state, action: PayloadAction<{ data: BarKpiDataObject, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: false,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    totalConnectedErrorAction: (state, action: PayloadAction<number>) => {
      return {
        isLoading: false,
        error: true,
        data: undefined,
        httpStatus: action.payload
      }
    }
  }
})

export const {
  totalConnectedLoadingAction,
  totalConnectedUpdateAction,
  totalConnectedErrorAction
} = totalConnectedSlice.actions

export default totalConnectedSlice.reducer
