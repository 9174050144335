import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export const tenantSlice = createSlice({
  name: 'tenantSlice',
  initialState: 'dukeenergy-qa',
  reducers: {
    changeActiveTenant: (state, action: PayloadAction<string>) => {
      return action.payload
    }
  }
})

export const { changeActiveTenant } = tenantSlice.actions
export default tenantSlice.reducer
