/* eslint-disable @typescript-eslint/consistent-type-imports */
import React, { useEffect, useState } from 'react'
import Modal from '../../../components/Modal'
import { CircularProgress } from '@mui/material'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../store'
import { fetchGroupListData } from '../../device-provisioning/redux/actionCreators'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { useAuth } from 'react-oidc-context'
import { AssetGroupState } from '../../device-provisioning/redux/groupListSlice'
import { GenXDropdown, type Option } from '../../../components/GenXDropdown'
import { ConfigurationGroupAssignState } from '../redux/configAssignGroupSlice'
import { AssignGroupRequest } from '../services/configAssignGroupService'
import { modifyConfigurationGroup } from '../redux/actionCreator'
import LanguageStrings from '../../../i18n/locales'

const ConfigAssignGroupPopUp = (props: AllProps): JSX.Element => {
  const [groupOptions, setGroupOptions] = useState<Option[]>([])
  const [group, setGroup] = useState<Option | null>(null)
  const [created, setCreated] = useState(false)
  const [showSuccessPopUp, setshowSuccessPopUp] = useState(false)
  const auth = useAuth()
  const assetType: any = useSelector(
    (state: RootState) => state.deviceDetail.deviceData
  )

  const AssignGroupStrings = LanguageStrings().AssetConfigurationStrings.components.ConfigAssignGroupPopup
  useEffect(() => {
    props.fetchGroupListData(props.assetType === undefined ? assetType[0].deviceType : props.assetType, group !== null ? group.value : '', auth.user?.profile.customer as string)
  }, [group])

  useEffect(() => {
    let transformedArray: React.SetStateAction<Option[]> = []
    if (props.groupListData.groupListData !== null && props.groupListData.groupListData !== undefined) {
      transformedArray = props.groupListData.groupListData.map((item) => ({
        value: item.asset_group_id,
        label: item.group_name
      }))
      transformedArray = transformedArray.filter(item => !props.assignedGroups.includes(item.label))
    }
    setGroupOptions(transformedArray)
  }, [props.groupListData])

  const handleChooseGroup = (selectedValue: Option): void => {
    setGroup(groupOptions.filter(grp => grp.value === selectedValue.value)[0])
  }

  const disableAssignButton = (): boolean => {
    if (group === null || group === undefined) {
      return true
    } else {
      return false
    }
  }

  const handleGenericDropdownWithFailure = (): JSX.Element => {
    if (props.groupListData.isLoading) {
      return <>
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
      </>
    } else if ((props.groupListData.httpStatus === 200 && props.groupListData.groupListData !== undefined)) {
      return (
        <>
            <GenXDropdown width={'32em'} label='Select Group' options={groupOptions} value={group} onSelect={handleChooseGroup} />
        </>
      )
    } else if (props.groupListData.httpStatus === 401) {
      return (
        <div className='errorCompInsideModal'><AuthError errorMessage="Unauthorized" /></div>
      )
    } else if (props.groupListData.httpStatus === 403) {
      return (
        <div className='errorCompInsideModal'><AuthError errorMessage="accessForbidden" /></div>
      )
    } else {
      return (
        <div className='errorCompInsideModal'><AuthError errorMessage="cannotFetchGroups" /></div>
      )
    }
  }
  const handleClickPositiveCreateAndUpdate = (): void => {
    props.modifyConfigurationGroup({
      template_id: props.templateId,
      group_id: [group?.value as string]
    }, auth.user?.profile.customer as string, true)
  }

  const handleSuccessCreate = (): JSX.Element => {
    // istanbul ignore next
    const handleClose = (): void => {
      props.onAssetGroupPopUpClose()
      if (props.refreshConfigurationList !== undefined) {
        props.refreshConfigurationList()
      }
    }
    // istanbul ignore else
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if ((props.configurationAssignGroup.httpStatus === 207) && (props.configurationAssignGroup.groupListData !== undefined && props.configurationAssignGroup.groupListData.length > 0 && props.configurationAssignGroup.groupListData[0].status === 'success')) {
      return (
        <Modal buttonTypeNegative='hollow' title={AssignGroupStrings.Assign_Successfull_Title} LabelPositive='Ok' onClickPositive={handleClose}
        onClose={handleClose}
      >
          <p style={{ paddingTop: '1.2em' }}>{AssignGroupStrings.Group_has_been_assigned_successfully}</p>
        </Modal>
      )
    } else if (props.configurationAssignGroup.isLoading) {
      return (
        <Modal title='Assigning Group'>
          <div className='CircularprogressAmi'>
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.configurationAssignGroup.httpStatus === 401) {
      return (
        <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }} title={AssignGroupStrings.Assign_Unsuccessfull_Title}>
          <div className='authError'>
            <AuthError className={'errorCompInsideGroupModal'} errorMessage="Unauthorized" />
          </div>
        </Modal>
      )
    } else if (props.configurationAssignGroup.httpStatus === 403) {
      return (
        <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }} title={AssignGroupStrings.Assign_Unsuccessfull_Title}>
          <div className='authError'>
            <AuthError className={'errorCompInsideGroupModal'} errorMessage="accessForbidden" />
          </div>
        </Modal>
      )
    } else if ((props.configurationAssignGroup.httpStatus === 207) && (props.configurationAssignGroup.groupListData !== undefined && props.configurationAssignGroup.groupListData[0].status === 'Group should be active having at least one asset assigned')) {
      return (
        <Modal titleStyle={{ paddingRight: '2em' }} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }} title={AssignGroupStrings.Assign_Unsuccessfull_Title}>
          <div className='authError'>
            <AuthError className={'errorInsideGroupModal'} customError={props.configurationAssignGroup.groupListData[0].status} />
          </div>
        </Modal>
      )
    } else if ((props.configurationAssignGroup.httpStatus === 404) || (props.configurationAssignGroup.httpStatus === 400)) {
      return (
        <Modal titleStyle={{ paddingRight: '2em' }} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }} title={AssignGroupStrings.Assign_Unsuccessfull_Title}>
          <div className='authError'>
            <AuthError className={'errorInsideGroupModal'} customError={props.configurationAssignGroup.error}/>
          </div>
        </Modal>
      )
    } else {
      return (<Modal title={'Error!!!'} onClose={() => { props.onAssetGroupPopUpClose() }}>
        <div className='errorCompInsideGroupModal'><AuthError errorMessage="cannotAssginGroup" /></div>
      </Modal>
      )
    }
  }
  return (
    <div>
      <Modal
        buttonTypeNegative="hollow"
        title={AssignGroupStrings.Assign_Group}
        LabelPositive="Assign"
        LabelNegative="Cancel"
        height={'fit-content'}
        positiveDisabled={disableAssignButton()}
        onClickPositive={() => {
          setshowSuccessPopUp(true)
        }}
        onClickNegative={() => {
          props.onAssetGroupPopUpClose()
        }}
        onClose={() => {
          props.onAssetGroupPopUpClose()
        }}
      >
        <div style={{ marginTop: '3em' }} className='authError'>
          {handleGenericDropdownWithFailure()}
        </div>
        <div
          style={{
            marginTop: '1.5em',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
        </div>
        <div
          style={{ marginTop: '1.5em', marginBottom: '2.5em', width: '32em' }}
        >
        </div>
      </Modal>

      {showSuccessPopUp && (
        <Modal
          buttonTypeNegative="hollow"
          title={AssignGroupStrings.Assign_Group}
          LabelPositive='Confirm'
          LabelNegative='Cancel'
          onClickPositive={() => {
            setshowSuccessPopUp(false)
            setCreated(true)
            handleClickPositiveCreateAndUpdate()
          }}
          onClose={() => {
            setshowSuccessPopUp(false)
          }}
          onClickNegative={() => {
            setshowSuccessPopUp(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {AssignGroupStrings.Do_you_want_to_assign_group_to_the_selected_asset_configuration}
          </p>
        </Modal>
      )}
      {created && handleSuccessCreate()}
    </div>
  )
}

interface DispatchToProps {
  fetchGroupListData: (
    assetType: string,
    groupName: string,
    tenantId: string,
  ) => void
  modifyConfigurationGroup: (configurationGroupAssignBody: AssignGroupRequest, tenantId: string, assign: boolean) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchGroupListData: (
    assetType: string,
    groupName: string,
    tenantId: string
  ) => dispatch(fetchGroupListData(assetType, groupName, tenantId)),
  modifyConfigurationGroup: (configurationGroupAssignBody: AssignGroupRequest, tenantId: string, assign: boolean) => dispatch(modifyConfigurationGroup(configurationGroupAssignBody, tenantId, assign))

})

interface StateToProps {
  groupListData: AssetGroupState
  configurationAssignGroup: ConfigurationGroupAssignState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  groupListData: state.groupListData,
  configurationAssignGroup: state.configurationAssignGroup

})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export interface ParentProps {
  onAssetGroupPopUpClose: () => void
  assetType: string
  templateId: string
  useCustomPositveFn?: boolean
  customPositiveFn?: () => void
  refreshConfigurationList?: () => void
  assignedGroups: string[]
}
type AllProps = PropsFromRedux & ParentProps

export default connector(ConfigAssignGroupPopUp)
