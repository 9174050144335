import * as React from 'react'
import { CircularProgress } from '@mui/material'
import Pagination from '../../../components/TablePagination'
import { type SnackbarOrigin } from '@mui/material/Snackbar'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import LanguageStrings from '../../../i18n/locales'
import { Button } from '../../../components/Button'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../../store'
import {
  fetchAssignedAssetList,
  postAssetsForRemovalFromConfiguration,
  fetchAssignedAssetListBySearch
} from '../redux/actionCreator'
import { type AsignedAssetListState } from '../redux/assignedAssetListSlice'
import { type Asset } from '../services/unAssignedAssetListService'
import { useEffect } from 'react'
import AuthError from '../../../components/ErrorComponents/AuthError'
import Modal from '../../../components/Modal'
import { type DisassociateAssetsRequest } from '../services/disassociateAssetService'
import { type DisassociateAssetState } from '../redux/disassociateAssetsSlice'
import SearchBox from '../../../components/SearchBox'

export interface State extends SnackbarOrigin {
  open: boolean
}

interface EnhancedTableProps {
  numSelected: number
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  rowCount: number
  dataList: Asset[]
}
interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

const TableStrings = LanguageStrings().AMIDataCollectionStrings.TableComponent
const UnassignAssetListStrings =
  LanguageStrings().AssetConfigurationStrings.components.UnassignAssetList
const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: TableStrings.ASSET_NAME
  },
  {
    id: 'serial_number',
    numeric: false,
    disablePadding: false,
    label: TableStrings.SerialNumber
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: TableStrings.ASSETID
  },
  {
    id: 'firmware_version',
    numeric: false,
    disablePadding: false,
    label: 'Firmware Version'
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: 'State'
  },
  {
    id: 'tag',
    numeric: false,
    disablePadding: false,
    label: 'Tag'
  }
]

function EnhancedTableHead (props: EnhancedTableProps): any {
  const { onSelectAllClick, numSelected, rowCount, dataList } = props
  // istanbul ignore else
  if (dataList !== null && dataList !== undefined) {
    return (
      <TableHead data-testid="table-header">
        <TableRow>
          <StyledTableCell padding="checkbox">
            <Checkbox
              sx={{ color: '#909090' }}
              color="primary"
              data-testid="device-table-header-checkbox"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'Select all Assets',
                id: 'select-all-items'
              }}
            />
          </StyledTableCell>
          {headCells.map((headCell: HeadCell) => (
            <StyledTableCell
              key={headCell.id}
              align={'left'}
              padding={'normal'}
            >
              {headCell.label}
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
}

const style = {
  width: '100%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

function UnassignAssetList (props: AllProps): JSX.Element {
  const rowsPerPage = 10
  const [assets, setAssets] = React.useState<Asset[]>([])
  const [selected, setSelected] = React.useState<string[]>([])
  const [showConfirmation, setShowConfirmation] =
    React.useState<boolean>(false)
  const [showDisassociationResponse, setShowDisassociationResponse] =
    React.useState<boolean>(false)
  const [page, setPage] = React.useState(1)
  const [searchValue, setSearchValue] = React.useState<string>('')

  useEffect(() => {
    handleFetchAssignedAssetList()
  }, [page])

  useEffect(() => {
    // istanbul ignore else
    if (
      props.assignedAssetList.assetList !== null &&
      props.assignedAssetList.assetList !== undefined
    ) {
      setAssets(props.assignedAssetList.assetList)
    }
  }, [props.assignedAssetList.assetList])

  const handleFetchAssignedAssetList = (): void => {
    // istanbul ignore else
    if (page > 0) {
      props.fetchAssignedAssetList(props.templateId, page - 1, rowsPerPage)
    }
  }

  const handleFetchAssignedAssetListBySearch = (): void => {
    // istanbul ignore else
    if (page > 0) {
      props.fetchAssignedAssetListBySearch(
        props.templateId,
        page - 1,
        rowsPerPage,
        searchValue,
        'desc',
        'name'
      )
    }
  }

  const handleRowClick = (id: string): void => {
    const isIdSelected = selected.includes(id)
    // istanbul ignore else
    if (!isIdSelected) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter((selectedId) => selectedId !== id))
    }
  }

  // istanbul ignore next
  const handleSelectAllClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentPageAssets = assets.map((n) => n.id)
    if (event.target.checked) {
      const newSelected: string[] = []
      currentPageAssets.forEach((id) => {
        if (!selected.includes(id)) {
          newSelected.push(id)
        }
      })
      setSelected([...selected, ...newSelected])
    } else {
      const newSelected: string[] = []
      selected.forEach((id) => {
        if (!currentPageAssets.includes(id)) {
          newSelected.push(id)
        }
      })
      setSelected(newSelected)
    }
  }

  const triggerUnassign = (): void => {
    const disassociateAssets: DisassociateAssetsRequest = {
      template_id: props.templateId,
      asset_type_id: props.templateId,
      assets: selected
    }
    props.postAssetsForRemovalFromConfiguration(disassociateAssets)
  }

  const handleUnassign = (): void => {
    // istanbul ignore else
    if (selected.length > 0) {
      setShowConfirmation(false)
      setShowDisassociationResponse(true)
      triggerUnassign()
    }
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& th': {
      border: '1px solid #202020'
    }
  }))
  const isSelected = (id: string): boolean => selected.includes(id)

  /* istanbul ignore next */
  const handleCreateConfigurationWithFailure = (): JSX.Element => {
    const handleSuccessClickPositive = (): void => {
      setShowDisassociationResponse(false)
      props.closeAssetAssignWindow()
      props.handleFetchConfigList()
    }

    const handleRetry = (): void => {
      triggerUnassign()
    }

    if (props.disassociateAssets.httpStatus === 200) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title="Successfully Unassigned"
          LabelPositive="Ok"
          onClickPositive={handleSuccessClickPositive}
          onClose={handleSuccessClickPositive}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {
              UnassignAssetListStrings.Asset_configuration_has_been_unassigned_successfully
            }
          </p>
        </Modal>
      )
    } else if (props.disassociateAssets.isLoading) {
      return (
        <Modal title="Unassign Asset Configuration">
          <div
            className="CircularprogressAmi"
            style={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.disassociateAssets.httpStatus === 401) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative="hollow"
          LabelNegative="Close"
          onClickNegative={() => {
            setShowDisassociationResponse(false)
          }}
          onClose={() => {
            setShowDisassociationResponse(false)
          }}
          title="Unassign Asset Configuration"
        >
          <div className="authError">
            <AuthError
              className={'errorCompInsideModal'}
              errorMessage="Unauthorized"
            />
          </div>
        </Modal>
      )
    } else if (props.disassociateAssets.httpStatus === 403) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative="hollow"
          LabelNegative="Close"
          onClickNegative={() => {
            setShowDisassociationResponse(false)
          }}
          onClose={() => {
            setShowDisassociationResponse(false)
          }}
          title="Unassign Asset Configuration"
        >
          <div className="authError">
            <AuthError
              className={'errorCompInsideModal'}
              errorMessage="accessForbidden"
            />
          </div>
        </Modal>
      )
    } else {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative="hollow"
          LabelNegative="Close"
          LabelPositive="Retry"
          onClickNegative={() => {
            setShowDisassociationResponse(false)
          }}
          onClickPositive={handleRetry}
          onClose={() => {
            setShowDisassociationResponse(false)
          }}
          title="Unassign Asset Configuration"
        >
          <div className="authError">
            <AuthError
              className={'errorCompInsideModal'}
              errorMessage="cannotUnassign"
            />
          </div>
        </Modal>
      )
    }
  }

  const showTableWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (
      assets !== null &&
      assets !== undefined &&
      props.assignedAssetList.httpStatus === 200 &&
      (props.assignedAssetList.assetList?.length ?? 0) > 0
    ) {
      return (
        <>
          <TableContainer className="device-table">
            <Table
              sx={{ minWidth: 750, color: '#f0f0f0' }}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <EnhancedTableHead
                numSelected={
                  selected.filter((id) =>
                    assets.map((asset: Asset) => asset.id).includes(id)
                  ).length
                }
                onSelectAllClick={handleSelectAllClick}
                rowCount={assets.length}
                dataList={assets}
              />
              <TableBody>
                {assets?.map((row: Asset, index: number) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <StyledTableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      aria-label="devices-list-row"
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          sx={{ color: '#909090' }}
                          checked={isItemSelected}
                          onChange={() => {
                            handleRowClick(row.id)
                          }}
                          inputProps={{
                            'aria-labelledby': labelId,
                            'aria-label': 'test-checkbox',
                            id: `select-item-${index}`
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        aria-label="device-name"
                        data-testid="device-name"
                        sx={{ color: '#64C3FF !important' }}
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell>{row.serial_number}</StyledTableCell>
                      <StyledTableCell>{row.id}</StyledTableCell>
                      <StyledTableCell>{row.firmware_version}</StyledTableCell>
                      <StyledTableCell>{row.state}</StyledTableCell>
                      <StyledTableCell>{row.tag}</StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {props.assignedAssetList.assetCount > 10 && (<Pagination
            count={props.assignedAssetList.assetCount}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            siblingCount={1}
            boundaryCount={1}
          />)}
          <div
            className="assign-list-footer"
            style={{
              padding: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row-reverse'
            }}
          >
            <Button
              dataTestId="unassign-btn-from-window"
              disabled={selected.length === 0}
              onClick={() => {
                setShowConfirmation(true)
              }}
              sx={{ float: 'right' }}
            >
              {UnassignAssetListStrings.Unassign}
            </Button>
          </div>
          {showConfirmation && (
            <Modal
              buttonTypeNegative="hollow"
              title="Unassign Asset Configuration"
              LabelNegative="Cancel"
              LabelPositive="Unassign"
              onClickPositive={handleUnassign}
              onClose={() => {
                setShowConfirmation(false)
              }}
              onClickNegative={() => {
                setShowConfirmation(false)
              }}
            >
              <p style={{ paddingTop: '1.2em' }}>
                {
                  UnassignAssetListStrings.Do_you_want_to_unassign_asset_configuration_to_the_selected_devices
                }
              </p>
            </Modal>
          )}
          {showDisassociationResponse && handleCreateConfigurationWithFailure()}
        </>
      )
    } else if (props.assignedAssetList.isLoading) {
      return (
        <div
          className="CircularprogressAmi"
          style={{ display: 'flex', alignItems: 'center', height: '100%' }}
        >
          <CircularProgress />
        </div>
      )
    } else if (props.assignedAssetList.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (props.assignedAssetList.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else if (
      props.assignedAssetList.assetList !== null &&
      props.assignedAssetList.assetList !== undefined &&
      props.assignedAssetList.assetList.length === 0
    ) {
      return (
        <div className="authError">
          <AuthError errorMessage="NoDataPresent" />
        </div>
      )
    } else {
      return (
        <div className="authError">
          <AuthError
            errorMessage="cannotFetch"
            retry={handleFetchAssignedAssetList}
          />
        </div>
      )
    }
  }

  return (
    <>
      <Box sx={style} className="devices">
        <Paper
          sx={{
            width: '100%',
            mb: 2,
            backgroundColor: '#272727',
            boxShadow: 'unset',
            color: '#F0F0F0',
            marginBottom: '0',
            minHeight: '200px'
          }}
        >
          <SearchBox
            immediate={true}
            debounce={true}
            placeholder="Search"
            sx={{ width: '300px', marginLeft: '0px' }}
            emptyEnterFallback={() => {
              handleFetchAssignedAssetList()
            }}
            searchVal={searchValue}
            setSearchVal={setSearchValue}
            performAction={() => {
              handleFetchAssignedAssetListBySearch()
            }}
          />
          {showTableWithFailure()}
        </Paper>
      </Box>
    </>
  )
}

interface DispatchToProps {
  fetchAssignedAssetList: (
    templateId: string,
    page: number,
    size: number
  ) => void
  postAssetsForRemovalFromConfiguration: (
    disassociateAssets: DisassociateAssetsRequest
  ) => void
  fetchAssignedAssetListBySearch: (
    templateId: string,
    page: number,
    size: number,
    parameter: string,
    sortdir: string,
    sortfield: string
  ) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAssignedAssetList: (templateId: string, page: number, size: number) =>
    dispatch(fetchAssignedAssetList(templateId, page, size)),
  fetchAssignedAssetListBySearch: (
    templateId: string,
    page: number,
    size: number,
    parameter: string,
    sortdir: string,
    sortfield: string
  ) =>
    dispatch(
      fetchAssignedAssetListBySearch(
        templateId,
        page,
        size,
        parameter,
        sortdir,
        sortfield
      )
    ),
  postAssetsForRemovalFromConfiguration: (
    disassociateAssets: DisassociateAssetsRequest
  ) => dispatch(postAssetsForRemovalFromConfiguration(disassociateAssets))
})

interface StateToProps {
  assignedAssetList: AsignedAssetListState
  disassociateAssets: DisassociateAssetState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assignedAssetList: state.assignedAssetList,
  disassociateAssets: state.disassociateAssets
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
interface PropsFromParent {
  assetTypeId: string
  templateId: string
  closeAssetAssignWindow: () => void
  handleFetchConfigList: () => void
}
type AllProps = PropsFromRedux & PropsFromParent

export default connector(UnassignAssetList)
