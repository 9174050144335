import React, { useState } from 'react'
import Modal from '../../../components/Modal'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../../store'
import { modifyConfigurationGroup } from '../redux/actionCreator'
import { type AssetGroupState } from '../../device-provisioning/redux/groupListSlice'
import { type ConfigurationGroupAssignState } from '../redux/configAssignGroupSlice'
import { type AssignGroupRequest } from '../services/configAssignGroupService'
import './UnassignGroupPopUp.css'
import { useAuth } from 'react-oidc-context'

import {
  TableContainer,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
  Checkbox,
  CircularProgress
} from '@mui/material'
import { Table } from '@scuf/common'
import TableRow from '@mui/material/TableRow'
import SearchBox from '../../../components/SearchBox'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { BpCheckbox } from '../../../components/CustomCheckbox'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

const UnassignGroupConfigurationPopup = (props: AllProps): JSX.Element => {
  const [showSuccessPopUp, setshowSuccessPopUp] = useState(false)
  const [selected, setSelected] = React.useState<string[]>([])
  const [rows, setRows] = useState(props.UnassigGroups)
  const [searchValue, setSearchValue] = useState('')
  const [created, setCreated] = useState(false)
  const auth = useAuth()

  // istanbul ignore next
  const requestSearch = (searchedVal: string): void => {
    const filteredRows = props.UnassigGroups.filter((row: any) => {
      return row.group_name.toLowerCase().includes(searchedVal.toLowerCase())
    })
    setRows(filteredRows)
  }

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '& th': {
      border: '1px solid #202020'
    }
  }))

  const handleRowClick = (id: string): void => {
    const isIdSelected = selected.includes(id)
    if (!isIdSelected) {
      setSelected([...selected, id])
    } else {
      setSelected(selected.filter((selectedId) => selectedId !== id))
    }
  }

  const handleClickPositive = (): void => {
    setshowSuccessPopUp(true)
  }

  const isSelected = (id: string): boolean => selected.includes(id)

  const handleClickPositiveCreateAndUpdate = (): void => {
    props.modifyConfigurationGroup({
      template_id: props.templateId,
      group_id: selected
    }, auth.user?.profile.customer as string, false)
  }

  // istanbul ignore next
  const handleSuccessCreate = (): JSX.Element => {
    const handlePopupclose = (): void => {
      props.onAssetGroupPopUpClose()
      props.handleFetchConfigList()
      if (props.refreshConfigurationList !== undefined) {
        props.refreshConfigurationList()
      }
    }
    // istanbul ignore else
    if (props.configurationAssignGroup?.httpStatus === 207) {
      return (
        <Modal buttonTypeNegative='hollow' title='Successfully Unassigned' LabelPositive='Ok' onClickPositive={handlePopupclose}
          onClose={handlePopupclose}>
          <p style={{ paddingTop: '1.2em' }}>Group has been unassigned successfully.</p>
        </Modal>
      )
    } else if (props.configurationAssignGroup.isLoading) {
      return (
        <Modal title="Unassign Group">
          <div className="CircularprogressAmi">
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.configurationAssignGroup?.httpStatus === 400) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title="Failed to Unassign Group"
          LabelPositive="Ok"
          onClickPositive={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            Error Occurred while Un-Assigning Group
          </p>
        </Modal>
      )
    } else if (props.configurationAssignGroup?.httpStatus === 401) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative="hollow"
          LabelNegative="Close"
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }}
          title="Unassign Group"
        >
          <div className="authError">
            <AuthError
              className={'errorCompInsideGroupModal'}
              errorMessage="Unauthorized"
            />
          </div>
        </Modal>
      )
    } else if (props.configurationAssignGroup.httpStatus === 403) {
      return (
        <Modal
          titleStyle={{ paddingRight: '2em' }}
          width={'fit-content'}
          height={'fit-content'}
          buttonTypeNegative="hollow"
          LabelNegative="Close"
          onClickNegative={() => { props.onAssetGroupPopUpClose() }}
          onClose={() => { props.onAssetGroupPopUpClose() }}
          title="Unassign Group"
        >
          <div className="authError">
            <AuthError
              className={'errorCompInsideGroupModal'}
              errorMessage="accessForbidden"
            />
          </div>
        </Modal>
      )
    } else {
      return (
        <Modal
          title={'Error!!!'}
          onClose={() => {
            handlePopupclose()
          }}
        >
          <div className="errorCompInsideGroupModal">
            <AuthError errorMessage="cannotUnAssginGroup" />
          </div>
        </Modal>
      )
    }
  }

  const disableUnAssignButton = (): boolean => {
    if (selected.length > 0) {
      return false
    } else {
      return true
    }
  }

  const defaultSearchValue = (e: any): void => {
    setRows(props.UnassigGroups)
  }

  return (
    <>
      <div>
        <Modal
          buttonTypeNegative="hollow"
          title="Unassign Group"
          LabelPositive="Unassign"
          positiveDisabled={disableUnAssignButton()}
          height={'fit-content'}
          onClickPositive={() => {
            handleClickPositive()
          }}
          onClose={() => {
            props.onAssetGroupPopUpClose()
          }}
        >
          <div className="group-search">
            <SearchBox
              placeholder={'Search Group'}
              sx={{ width: '100%' }}
              emptyEnterFallback={() => { defaultSearchValue(searchValue) }}
              searchVal={searchValue}
              setSearchVal={setSearchValue}
              performAction={() => { requestSearch(searchValue) }}
              immediate={true}
              debounce={true}
            />
          </div>
          <TableContainer className="unassign-group-table">
            <Table
              className="unassign-table-group"
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <TableBody>
                { rows.length > 0
                  ? rows.map((row: any, index: number) => {
                    const isItemSelected = isSelected(row.group_id)
                    const labelId = `enhanced-table-checkbox-${index}`
                    return (
                      <StyledTableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.group_id}
                        aria-label="devices-firmware-row"
                        sx={{
                          cursor: 'pointer',
                          borderBottom: '1px solid #ffffff'
                        }}
                      >
                        <StyledTableCell padding="checkbox">
                        {
                          row.status === 'In Progress'
                            ? <BpCheckbox inputProps={{ id: `select-item-${index}` }} disabled/>
                            : <Checkbox
                              sx={{
                                color: row.status === 'In Progress' ? '#707070' : '#909090',
                                pointerEvents: row.status === 'In Progress' ? 'none' : 'auto',
                                cursor: row.status === 'In Progress' ? 'not-allowed' : 'pointer'
                              }}
                              data-testid={`row-checkbox-${index}`}
                              checked={isItemSelected}
                              disabled={row.status === 'In Progress'}
                              onChange={() => {
                                if (row.status !== 'In Progress') {
                                  handleRowClick(row.group_id)
                                }
                              }}
                              inputProps={{
                                'aria-labelledby': labelId,
                                'aria-label': 'test-checkbox',
                                id: `select-item-${index}`
                              }}
                            />
                        }
                        </StyledTableCell>
                        <StyledTableCell
                          id={labelId}
                          scope="row"
                          aria-label="assetType"
                          padding="none"
                          data-testid="assetType"
                          sx={{
                            color: row.status === 'In Progress' ? '#B0B0B0 !important' : '#F0F0F0'
                          }}
                        >
                          {row.group_name}
                        </StyledTableCell>
                        <StyledTableCell>{row.status}</StyledTableCell>
                      </StyledTableRow>
                    )
                  })
                  : <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <AuthError errorMessage="NoDataPresentForSearch">
                        <div className="errorCompInsideGroupModal" />
                      </AuthError>
                    </div>
                  }
              </TableBody>
            </Table>
          </TableContainer>
        </Modal>
        {showSuccessPopUp && (
          <Modal
            buttonTypeNegative="hollow"
            title="Unassign Group"
            LabelPositive="Confirm"
            LabelNegative="Cancel"
            onClickPositive={() => {
              setshowSuccessPopUp(false)
              setCreated(true)
              handleClickPositiveCreateAndUpdate()
            }}
            onClose={() => {
              setshowSuccessPopUp(false)
            }}
            onClickNegative={() => {
              setshowSuccessPopUp(false)
            }}
          >
            <p style={{ paddingTop: '1.2em' }}>
              Do you want to unassign group of the selected Configuration ?
            </p>
          </Modal>
        )}
        {created && handleSuccessCreate()}
      </div>
    </>
  )
}

interface DispatchToProps {
  modifyConfigurationGroup: (configurationGroupAssignBody: AssignGroupRequest, tenantId: string, assign: boolean) => void

}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  modifyConfigurationGroup: (configurationGroupAssignBody: AssignGroupRequest, tenantId: string, assign: boolean) => dispatch(modifyConfigurationGroup(configurationGroupAssignBody, tenantId, assign))

})

interface StateToProps {
  groupListData: AssetGroupState
  configurationAssignGroup: ConfigurationGroupAssignState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  groupListData: state.groupListData,
  configurationAssignGroup: state.configurationAssignGroup
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export interface ParentProps {
  onAssetGroupPopUpClose: () => void
  templateId: string
  assetType: string
  UnassigGroups: any
  useCustomPositveFn?: boolean
  customPositiveFn?: () => void
  refreshConfigurationList?: () => void
  handleFetchConfigList: () => void
}
type AllProps = PropsFromRedux & ParentProps

export default connector(UnassignGroupConfigurationPopup)
