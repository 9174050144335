import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Tag } from '../services/deviceListService'

export interface DeviceList {
  id: number
  name: string
  serial_number: string
  latitude: number
  longitude: number
  install_date: string
  removal_date: string
  tag: Tag[]
  state: string
  device_type: string
  comm_module_serial_number: string
}
export interface DeviceListState {
  isLoading: boolean
  loadingError: string | null
  deviceList: DeviceList[] | null
  deviceCount: number
  httpStatus: number | null
}

const initialState: DeviceListState = {
  isLoading: true,
  loadingError: null,
  deviceList: null,
  deviceCount: 0,
  httpStatus: null
}

const deviceListSlice = createSlice({
  name: 'deviceList',
  initialState,
  reducers: {
    deviceListLoadingAction: (state) => {
      return {
        isLoading: true,
        loadingError: null,
        deviceList: null,
        deviceCount: 0,
        httpStatus: 0
      }
    },
    deviceListUpdateAction: (state, action: PayloadAction<{ data: DeviceList[], count: number, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: null,
        deviceList: action.payload.data,
        deviceCount: action.payload.count,
        httpStatus: action.payload.httpStatus
      }
    },
    deviceListErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: action.payload.errorMessage,
        deviceList: null,
        deviceCount: 0,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  deviceListLoadingAction,
  deviceListUpdateAction,
  deviceListErrorAction
} = deviceListSlice.actions

export default deviceListSlice.reducer
