import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface topIssuesState {
  isLoading: boolean
  loadingError: string | null
  topIssues: number[]
}

const initialState: topIssuesState = {
  isLoading: true,
  loadingError: null,
  topIssues: [0, 0, 0]
}

export const topIssuesSlice = createSlice({
  name: 'topIssues',
  initialState,
  reducers: {
    topIssuesLoadingAction: (state) => {
      return {
        isLoading: true,
        loadingError: null,
        topIssues: [0, 0, 0]
      }
    },
    topIssuesUpdateAction: (state, action: PayloadAction< number[] >) => {
      return {
        isLoading: false,
        loadingError: null,
        topIssues: action.payload
      }
    },
    topIssuesErrorAction: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        isLoading: true,
        loadingError: action.payload,
        topIssues: [0, 0, 0]
      }
    }
  }
})

// Actions to export
export const {
  topIssuesLoadingAction,
  topIssuesUpdateAction,
  topIssuesErrorAction
} = topIssuesSlice.actions

export default topIssuesSlice.reducer
