import React, { useEffect } from 'react'
import BackButton from '../../../components/BackButton'
import { useNavigate } from 'react-router-dom'
import AssetConfigurationSummary from './AssetDetailsComponents/AssetConfigurationSummary'
import { Card, CardContent, CircularProgress, Grid } from '@mui/material'
import ParameterListComponent from './AssetDetailsComponents/ParameterListComponent'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../../store'
import { getConfigurationDetailsById } from '../redux/actionCreator'
import { type ConfigurationDetailsState } from '../redux/configDetailsSlice'
import { changeTemplateId } from '../redux/templateIdSlice'
import { useDispatch } from 'react-redux'
import AuthError from '../../../components/ErrorComponents/AuthError'
import LanguageStrings from '../../../i18n/locales'

const AssetConfigurationDetailsStrings = LanguageStrings().AssetConfigurationStrings.components.AssetConfigurationDetails

const AssetConfigurationDetails = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  function getConfigurationSummary (): any {
    if (props.configurationDetailsState.isLoading) {
      return (
      <div className='deviceDetailsProgress'><CircularProgress data-testid='deviceDetailsProgress' size={'5rem'} /></div>)
    } else if (props.configurationDetailsState.configurationDetails !== null && props.configurationDetailsState.configurationDetails !== undefined && props.configurationDetailsState.httpStatus === 200) {
      return (
        <AssetConfigurationSummary configDetails={props.configurationDetailsState.configurationDetails} />)
    } else if (props.configurationDetailsState.httpStatus === 401) {
      // istanbul ignore next
      return (
              <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (props.configurationDetailsState.httpStatus === 403) {
      // istanbul ignore next
      return (
          <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
      )
    } else {
      return (
        <div className='authError'><AuthError retry={handleFetchData} errorMessage="cannotFetch"/></div>
      )
    }
  }

  function getParameterList (): any {
    if (props.configurationDetailsState.isLoading) {
      return (
      <div className='deviceDetailsProgress'><CircularProgress data-testid='deviceDetailsProgress' size={'5rem'} /></div>)
    } else if (props.configurationDetailsState.configurationDetails !== null && props.configurationDetailsState.configurationDetails !== undefined && props.configurationDetailsState.httpStatus === 200) {
      return (
        <ParameterListComponent configDetails={props.configurationDetailsState.configurationDetails} />)
    } else if (props.configurationDetailsState.httpStatus === 401) {
      // istanbul ignore next
      return (
        <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (props.configurationDetailsState.httpStatus === 403) {
      // istanbul ignore next
      return (
        <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
      )
    } else {
      return (
        <div className='authError'><AuthError retry={handleFetchData} errorMessage="cannotFetch"/></div>
      )
    }
  }

  const handleFetchData = (): void => {
    // istanbul ignore else
    if (props.templateIdForConfigurationDetails !== '') {
      props.getConfigurationDetailsById(props.templateIdForConfigurationDetails)
    } else {
      const templateId = (sessionStorage.getItem('templateId') !== null) ? sessionStorage.getItem('templateId') : ''
      if (templateId !== '' && templateId !== null) {
        dispatch(changeTemplateId(templateId))
        props.getConfigurationDetailsById(templateId)
      }
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [props.templateIdForConfigurationDetails])

  return (
    <div>
      <div className='link-breadcrumb'>
        <span data-testid='assetManagementbreadcrumb' onClick={ () => { navigate('/card') }} className='assetmgmtli'>{AssetConfigurationDetailsStrings.Asset_Management}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span data-testid='assetConfigurationBreadcrumb' onClick={ () => { navigate(-1) }} className='assetmgmtli'>{AssetConfigurationDetailsStrings.Configurations}</span>
        <span style={{ padding: '0 0.5em' }}>/</span>
        <span>{props.configurationDetailsState.configurationDetails !== null ? props.configurationDetailsState.configurationDetails.template_name : ''}</span>
      </div>
      <div className='topUtilityBar'>
        <BackButton/>
        <span id='componentTitle' data-testid='configurationName'>{props.configurationDetailsState.configurationDetails !== null ? props.configurationDetailsState.configurationDetails.template_name : ''}</span>
      </div>
      <div>
        <Grid item xs={12} sm={12}>
          <Card sx={{ borderRadius: '10px', backgroundColor: '#272727', color: '#F0F0F0 !important', padding: '25px' }}>
            <div style={{ fontWeight: 600, fontSize: '16px', lineHeight: '24px' }}>{AssetConfigurationDetailsStrings.ASSET_CONFIGURATION_SUMMARY}</div>
            <CardContent>
              { getConfigurationSummary() }
            </CardContent>
          </Card>
          <div style={{ marginTop: '2em' }}>
            {getParameterList()}
          </div>
        </Grid>
      </div>
    </div>
  )
}

interface DispatchToProps {
  getConfigurationDetailsById: (templateId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getConfigurationDetailsById: (templateId: string) => dispatch(getConfigurationDetailsById(templateId))
})

interface StateToProps {
  configurationDetailsState: ConfigurationDetailsState
  templateIdForConfigurationDetails: string
}

const mapStateToProps = (state: RootState): StateToProps => ({
  configurationDetailsState: state.configurationDetails,
  templateIdForConfigurationDetails: state.templateIdForConfigurationDetails
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(AssetConfigurationDetails)
