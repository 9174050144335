import { createSlice } from '@reduxjs/toolkit'
import { type PayloadAction } from '@reduxjs/toolkit'
import { type GroupsAsset } from '../types'

export interface AssetAssignedListState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null
  assetAssignedList: GroupsAsset | null
}

const initialState: AssetAssignedListState = {
  isLoading: false,
  error: null,
  httpStatus: 0,
  assetAssignedList: null
}

const assetAssignedListSlice = createSlice({
  name: 'assetAssignedList',
  initialState,
  reducers: {
    assetAssignedListLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0,
        assetAssignedList: null
      }
    },
    assetAssignedListUpdateAction: (state, action: PayloadAction<{ data: GroupsAsset, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.httpStatus,
        assetAssignedList: action.payload.data
      }
    },
    assetAssignedListErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        assetAssignedList: null
      }
    }
  }
})

export const {
  assetAssignedListLoadingAction,
  assetAssignedListUpdateAction,
  assetAssignedListErrorAction
} = assetAssignedListSlice.actions

export default assetAssignedListSlice.reducer
