import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Configuration } from '../services/configurationService'

export interface ConfigurationListState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null
  configurationList: Configuration | null
}

const initialState: ConfigurationListState = {
  isLoading: true,
  error: null,
  httpStatus: null,
  configurationList: null
}

const configurationListSlice = createSlice({
  name: 'configurationList',
  initialState,
  reducers: {
    configurationListLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0,
        configurationList: null
      }
    },
    configurationListUpdateAction: (state, action: PayloadAction<{ data: Configuration, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.httpStatus,
        configurationList: action.payload.data
      }
    },
    configurationListErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        configurationList: null
      }
    }
  }
})

export const {
  configurationListLoadingAction,
  configurationListUpdateAction,
  configurationListErrorAction
} = configurationListSlice.actions

export default configurationListSlice.reducer
