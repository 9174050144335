import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type FirmwareCampaignFilterMetadata } from '../services/firmwareCampaignFilterMetadata'

export interface FirmwareCampaignFilterMetaDataState {
  isLoading: boolean
  loadingError: string | undefined
  FirmwareCampaignFilterMetaData: FirmwareCampaignFilterMetadata | undefined
  httpStatus: number | undefined
}

// Initial state of the slice
const initialState: FirmwareCampaignFilterMetaDataState = {
  isLoading: true,
  loadingError: undefined,
  FirmwareCampaignFilterMetaData: undefined,
  httpStatus: undefined
}

// Slice to return from action
export const firmwareCampaignFilterMetaDataSlice = createSlice({
  name: 'getFirmwareCampaignFilterMetaDataSlice',
  initialState,
  reducers: {
    firmwareCampaignFilterMetaDataLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: undefined,
        FirmwareCampaignFilterMetaData: undefined,
        httpStatus: 0
      }
    },
    firmwareCampaignFilterMetaDataUpdateAction: (state, action: PayloadAction<any>) => {
      return {
        isLoading: false,
        loadingError: undefined,
        FirmwareCampaignFilterMetaData: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    firmwareCampaignFilterMetaDataErrorAction: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload,
        httpStatus: action.payload.httpStatus,
        FirmwareCampaignFilterMetaData: undefined
      }
    }
  }
})

// Actions to export
export const {
  firmwareCampaignFilterMetaDataLoadingAction,
  firmwareCampaignFilterMetaDataUpdateAction,
  firmwareCampaignFilterMetaDataErrorAction
} = firmwareCampaignFilterMetaDataSlice.actions

export default firmwareCampaignFilterMetaDataSlice.reducer
