export function validateName (name: string): boolean {
  return !((name.length <= 255)) // 255
}

export function validateDescription (description: string): boolean {
  return !((description.length <= 1024)) // 1024
}

export function isValidIp (value: string): boolean {
  const ipAddressRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  if (value.length > 0) {
    return (ipAddressRegex.test(value))
  } else if (value.length > 0 && typeof value === 'string') {
    return false
  } else {
    return true
  }
}

export function validatePublicKey (publicKey: string): boolean {
  return !((publicKey.length <= 1024)) // 1024
}

export function validatePort (port: string): boolean {
  if (port.length > 0 && !isNaN(Number(port))) {
    return !((Number(port) >= 0 && Number(port) <= 65535)) // 0 to 65535
  } else if (port.length > 0 && typeof port === 'string') {
    return true
  } else {
    return false
  }
}

export function validateUsername (username: string): boolean {
  return !((username.length <= 255)) // 255
}

export function validatePassword (password: string): boolean {
  return !((password.length <= 255)) // 255
}

export function validateUrl (url: string): boolean {
  return !((url.length <= 255)) // 255
}

export function validateHour (hour: string): boolean {
  if (hour.length > 0 && !isNaN(Number(hour))) {
    return !((Number(hour) >= 0 && Number(hour) <= 23))
  } else if (hour.length > 0 && typeof hour === 'string') {
    return true
  } else {
    return false
  }
}

export function validateMinute (minute: string): boolean {
  if (minute.length > 0 && !isNaN(Number(minute))) {
    return !((Number(minute) >= 0 && Number(minute) <= 59))
  } else if (minute.length > 0 && typeof minute === 'string') {
    return true
  } else {
    return false
  }
}
