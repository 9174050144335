import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type UpdateGroupApiResponse } from '../services/updateGroupService'

export interface UpdateGroupState {
  isLoading: boolean
  error: string | undefined
  data: UpdateGroupApiResponse | undefined
  httpStatus: number | undefined
}

const initialState: UpdateGroupState = {
  isLoading: false,
  error: undefined,
  data: undefined,
  httpStatus: 0
}

const updateGroupSlice = createSlice({
  name: 'updateGroupSlice',
  initialState,
  reducers: {
    updateGroupLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        data: undefined,
        httpStatus: 0
      }
    },
    updateGroupUpdateAction: (state, action: PayloadAction<{ data: UpdateGroupApiResponse, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: undefined,
        data: action.payload.data,
        httpStatus: action.payload.httpStatus
      }
    },
    updateGroupErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        data: undefined,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  updateGroupLoadingAction,
  updateGroupUpdateAction,
  updateGroupErrorAction
} = updateGroupSlice.actions

export default updateGroupSlice.reducer
