import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZGroup = z.object({
  asset_group_id: z.string(),
  group_name: z.string()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  data: z.array(ZGroup),
  error: z.boolean(),
  error_message: z.optional(z.string())
})

export async function getAllGroups (
  assetType: string,
  groupName: string,
  tenantId: string,
  onSuccess: (groupList: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  let url = ''
  // if (groupName === '') {
  url = `/assetrest/api/v1/udp/groupManagement/groups?assetType=${assetType}&tenantId=${tenantId}`
  // } else {
  //   url = `/assetrest/api/v1/udp/groupManagement/groups?assetType=${assetType}&tenantId=${tenantId}&search=${groupName}`
  // }
  interceptedAxios
    .get(url, { timeout: 15000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (
            parseResponse.data.data !== null &&
            parseResponse.data.data !== undefined
          ) {
            onSuccess(parseResponse.data.data, response.status)
          } else {
            onFailure(parseResponse.data.error, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      } else {
        onFailure(response.statusText, response.status)
      }
    })
    .catch((error) => {
      if (error.response?.data !== undefined) {
        onFailure(error.response.data.error_message, error.response.status)
      } else {
        onFailure(error.response !== undefined ? error.response.statusText : 'Network Error', error.response !== undefined ? error.response.status : 404)
      }
    })
}
