import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface UploadAssetProfileInfoState {
  isLoading: boolean
  message: string | null
  errorMessage: string | null
  httpStatus: number
}

const initialState: UploadAssetProfileInfoState = {
  isLoading: false,
  message: null,
  errorMessage: null,
  httpStatus: 0
}

export const uploadAssetProfileInfoSlice = createSlice({
  name: 'uploadAssetProfileInfoSlice',
  initialState,
  reducers: {
    resetUploadAssetProfile: () => {
      return {
        isLoading: false,
        message: null,
        errorMessage: null,
        httpStatus: 0
      }
    },
    uploadAssetProfileDataLoading: (state): UploadAssetProfileInfoState => {
      return {
        isLoading: true,
        message: null,
        errorMessage: null,
        httpStatus: 0
      }
    },
    uploadAssetProfileDataError: (state, action: PayloadAction<{ httpStatus: number, message: string }>): UploadAssetProfileInfoState => {
      return {
        isLoading: false,
        message: null,
        errorMessage: action.payload.message,
        httpStatus: action.payload.httpStatus
      }
    },
    uploadAssetProfileDataUpdate: (state, action: PayloadAction<{ httpStatus: number, message: string }>): UploadAssetProfileInfoState => {
      return {
        isLoading: false,
        message: action.payload.message,
        errorMessage: null,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const { uploadAssetProfileDataLoading, uploadAssetProfileDataError, uploadAssetProfileDataUpdate, resetUploadAssetProfile } = uploadAssetProfileInfoSlice.actions
export default uploadAssetProfileInfoSlice.reducer
