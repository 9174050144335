import React from 'react'
import Modal from '../../../components/Modal'
import { CircularProgress, FormHelperText, TextField } from '@mui/material'
import { connect, type ConnectedProps } from 'react-redux'
import { type RootState } from '../../../store'
import { type SeedRequest } from '../services/setC12.22SeedService'
import { postSeed } from '../redux/actionCreators'
import { type SeedState } from '../redux/setC12.22SeedSlice'
import AuthError from '../../../components/ErrorComponents/AuthError'
import LanguageStrings from '../../../i18n/locales'
import { validateSeedValue } from '../../../utils/validator'

type CurrentState = 'initial' | 'confirmation' | 'result'
interface ParentProps {
  setShowConfigSeed: React.Dispatch<React.SetStateAction<boolean>>
}

const setC1222SeedStrings = LanguageStrings().ProvisioningDetailStrings.SetC1222Seed

const textStyle = {
  root: {
    backgroundColor: '#272727',
    '& .MuiInputBase-input': {
      color: '#F0F0F0',
      backgroundColor: '#404040'
    },
    '& .MuiInputLabel-root': {
      color: '#F0F0F0',
      '&.Mui-focused': {
        color: '#F0F0F0'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#00000000'
      },
      '&:hover fieldset': {
        borderColor: '#00000000'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#00000000'
      }
    }
  }
}

const ConfigSeed = (props: AllProps): JSX.Element => {
  const [seed, setSeed] = React.useState<string>('')
  const [reSeed, setReSeed] = React.useState<string>('')
  const [initialState, setInitialState] = React.useState<CurrentState>('initial')
  const [seedErrorInit, setSeedErrorInit] = React.useState<boolean>(false)
  const [reSeedErrorInit, setReSeedErrorInit] = React.useState<boolean>(false)

  const validateSeedVal = (): boolean => {
    return seed === reSeed && validateSeedValue(seed)
  }

  // istanbul ignore next
  const handleResultWithFailure = (): JSX.Element => {
    if (props.seedState.httpStatus === 200) {
      return (
        <Modal buttonTypeNegative='hollow' title={setC1222SeedStrings.successTitle} LabelPositive='Ok' onClickPositive={() => {
          props.setShowConfigSeed(false)
        }}
          onClose={() => {
            props.setShowConfigSeed(false)
          }}>
          <p style={{ paddingTop: '1.2em' }}>{setC1222SeedStrings.successContent}</p>
        </Modal>
      )
    } else if (props.seedState.isLoading) {
      return (
        <Modal title={setC1222SeedStrings.unSuccessTitle}>
          <div className='CircularprogressAmi'>
              <CircularProgress />
          </div>
        </Modal>
      )
    } else if (props.seedState.httpStatus === 401) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { props.setShowConfigSeed(false) }}
                    onClose={() => { props.setShowConfigSeed(false) }} title={setC1222SeedStrings.unSuccessTitle}>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="Unauthorized"/>
                    </div>
                </Modal>
      )
    } else if (props.seedState.httpStatus === 403) {
      return (
                <Modal titleStyle={{ paddingRight: '2em' }} width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close'
                    onClickNegative={() => { props.setShowConfigSeed(false) }}
                    onClose={() => { props.setShowConfigSeed(false) }} title={setC1222SeedStrings.unSuccessTitle}>
                    <div className='authError'>
                        <AuthError className={'errorCompInsideModal'} errorMessage="accessForbidden"/>
                    </div>
                </Modal>
      )
    } else {
      return (
        <Modal width={'fit-content'} height={'fit-content'} buttonTypeNegative='hollow' LabelNegative='Close' LabelPositive='Retry'
          onClickNegative={() => { props.setShowConfigSeed(false) }}
          onClickPositive={() => { props.postSeed({ seed }) }}
          onClose={() => { props.setShowConfigSeed(false) }} title={setC1222SeedStrings.unSuccessTitle}
          >
          <div className='authError'>
            <AuthError className={'errorCompInsideModal'} errorMessage='customError' customError={props.seedState.error}/></div>
        </Modal>
      )
    }
  }

  function handleModal (): JSX.Element {
    if (initialState === 'initial') {
      return (
        <Modal sx={{ height: 'fit-content' }} title={setC1222SeedStrings.unSuccessTitle} LabelPositive='Submit' LabelNegative='Cancel' onClickNegative={() => {
          props.setShowConfigSeed(false)
        }} onClose={() => {
          props.setShowConfigSeed(false)
        }} onClickPositive={() => {
          if (validateSeedVal()) {
            setInitialState('confirmation')
          }
        }} positiveDisabled={!validateSeedVal()}
        buttonTypeNegative='hollow'
        >
            <div style={{ marginTop: '30px', fontSize: '16px', marginBottom: '5px' }}><span>{setC1222SeedStrings.enter_seed}</span></div>
            <TextField
              id='seed-textfield'
              variant="outlined"
              InputLabelProps={{
                disabled: true
              }}
              size='small'
              className='custom-textfield'
              value={seed}
              onChange={(e) => {
                setSeed(e.target.value)
                setSeedErrorInit(true)
              }}
              sx={{
                ...textStyle.root,
                width: '450px'
              }}
            />
            {!validateSeedValue(seed) && (seedErrorInit) && (
                // istanbul ignore next
                <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', width: '70%' }} className="errorColor">
                  {setC1222SeedStrings.validateSeed}
                </FormHelperText>
            )}
            <div style={{ marginTop: '20px', fontSize: '16px', marginBottom: '5px' }}><span>{setC1222SeedStrings.reseed}</span></div>
            <TextField
              id='reseed-textfield'
              variant="outlined"
              InputLabelProps={{
                disabled: true
              }}
              size='small'
              className='custom-textfield'
              value={reSeed}
              onChange={(e) => {
                setReSeed(e.target.value)
                setReSeedErrorInit(true)
              }}
              sx={{
                ...textStyle.root,
                width: '450px'
              }}
            />
            {!validateSeedVal() && (reSeedErrorInit) && (
                // istanbul ignore next
                <FormHelperText sx={{ color: '#EE3426', paddingLeft: '10px', width: '70%' }} className="errorColor">
                  {setC1222SeedStrings.validateReSeed}
                </FormHelperText>
            )}
          </Modal>
      )
    } else if (initialState === 'confirmation') {
      return (
        <Modal title={'Set Seed'} LabelNegative='Cancel' LabelPositive='Confirm' onClickNegative={() => {
          setInitialState('initial')
        }} onClickPositive={() => {
          props.postSeed({ seed })
          setInitialState('result')
        }} onClose={() => {
          setInitialState('initial')
        }} buttonTypeNegative='hollow'>
          <p style={{ marginTop: '20px' }}>{setC1222SeedStrings.confirmContent}</p>
        </Modal>
      )
    } else {
      return (
        handleResultWithFailure()
      )
    }
  }
  return (handleModal())
}

interface DispatchToProps {
  postSeed: (SeedRequestBody: SeedRequest) => void
}
const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  postSeed: (SeedRequestBody: SeedRequest) => dispatch(postSeed(SeedRequestBody))
})

interface StateToProps {
  seedState: SeedState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  seedState: state.seedState
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = PropsFromRedux & ParentProps

export default connector(ConfigSeed)
