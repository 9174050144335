// istanbul ignore file
import * as AddFirmwareService from '../services/uploadFirmware'
import { type firmwareReq } from '../services/uploadFirmware'
import { addFirmwareInfoDataLoading, addFirmwareInfoDataError, addFirmwareInfoData, resetAddFirmware } from './addFirmwareInfoSlice'
import { FirmwareTypeDataLoadingAction, FirmwareTypeDataUpdateAction, FirmwareTypeDataErrorAction } from './getFirmwareTypeDataInfoSlice'
import * as FirmwareDetailsService from '../services/firmwareList'
import { firmwareListFilterFirmwareTypeLoadingAction, firmwareListFilterFirmwareTypeErrorAction, firmwareListFilterFirmwareTypeUpdateAction } from '../redux/getFirmwareFilterFirmwareTypeSlice'
import { firmwareListFilterAssetTypeLoadingAction, firmwareListFilterAssetTypeErrorAction, firmwareListFilterAssetTypeUpdateAction } from '../redux/getFirmwareFilterAssetTypeSlice'
import { firmwareListFilterVersionLoadingAction, firmwareListFilterVersionErrorAction, firmwareListFilterVersionUpdateAction } from '../redux/getFirmwareFilterVersionSlice'
import { FirmwareListLoadingAction, FirmwareListUpdateAction, FirmwareListErrorAction } from './firmwareListInfoSlice'
import { exportFirmwareListLoadingAction, exportFirmwareListUpdateAction, exportFirmwareListErrorAction } from './exportFirmwareListSlice'
import { deleteFirmwareLoadingAction, deleteFirmwareUpdateAction, deleteFirmwareErrorAction, resetDeleteFirmware } from './deleteFirmwareSlice'
import { type CreateGroupRequest, postCreateGroup } from '../services/createGroupService'
import { type AppDispatch } from '../../../store'
import { createGroupErrorAction, createGroupLoadingAction, createGroupUpdateAction } from './createGroupSlice'
import { type FirmwareGroupAssignRequest, postAssignFirmwareGroup } from '../services/firmwareAssignGroupService'
import { firmwareGroupAssignErrorAction, firmwareGroupAssignLoadingAction, firmwareGroupAssignUpdateAction } from './firmwareAssignGroupSlice'
import { type FirmwareGroupUnassignRequest, PostfirmwareUnassignGroup } from '../services/firmwareUnassignGroupService'
import { firmwareGroupUnassignErrorAction, firmwareGroupUnassignLoadingAction, firmwareGroupUnassignUpdateAction } from './firmwareUnassignGroupSlice'
import { type FirmwareCreateCampaignRequest, postFirmwareCreateCampaign, type FirmwareCreateCampaignConfigObj } from '../services/firmwareCreateCampaignService'
import { putFirmwareEditCampaign, type FirmwareEditCampaignRequest } from '../services/firmwareEditCampaignService'
import { createCampaignErrorAction, createCampaignLoadingAction, createCampaignUpdateAction } from './firmwareCreateCampaignSlice'
import { editCampaignLoadingAction, editCampaignUpdateAction, editCampaignErrorAction } from './firmwareEditCampaignSlice'
import { getFirmwareCampaignDetails, postFirmwareCampaignFilterDetails, type FirmwareCampaignListDataObj, type FirmwareCampaignFilterRequest } from '../services/firmwareCampaignListService'
import { getFirmwareCampaignFilterMetadata, type FirmwareCampaignFilterMetadata } from '../services/firmwareCampaignFilterMetadata'
import { firmwareCampaignListLoadingAction, firmwareCampaignListUpdateAction, firmwareCampaignListErrorAction } from './firmwareCampaignListSlice'
import { firmwareCampaignFilterMetaDataLoadingAction, firmwareCampaignFilterMetaDataUpdateAction, firmwareCampaignFilterMetaDataErrorAction } from './getFirmwareCampaignFilterMetaDataSlice'
import { allGroupDataErrorAction, allGroupDataLoadingAction, allGroupDataUpdateAction } from './groupWithCountListSlice'
import * as AllGroupWithCountService from '../services/groupListWithCountService'
import { firmwareCampaignDeleteErrorAction, firmwareCampaignDeleteLoadingAction, firmwareCampaignDeleteUpdateAction } from './firmwareCampaignDeleteSlice'
import * as FirmwareDeleteCampaignService from '../services/firmwareCampaignDeleteService'
import { type DataObj } from '../services/firmwareList'
import * as FirmwareUpgradeDetailsService from '../services/firmwareUpgradeDetailsService'
import { type firmwareCampaignDetailsData } from '../services/firmwareUpgradeDetailsService'
import { firmwareCampaignDetailsLoadingAction, firmwareCampaignDetailsUpdateAction, firmwareCampaignDetailsErrorAction } from './firmwareUpgradeDetailsSlice'
import * as exportFirmwareUpgradeStatusService from '../services/exportFirmwareUpgradeStatus'
import { exportFirmwareUpgradeStatusLoadingAction, exportFirmwareUpgradeStatusUpdateAction, exportFirmwareUpgradeStatusErrorAction } from './exportFirmwareUpgradeStatusSlice'
import * as exportFirmwareCampaignDetailsService from '../services/exportFirmwareCampaignDetails'
import { exportFirmwareCampaignDetailsLoadingAction, exportFirmwareCampaignDetailsUpdateAction, exportFirmwareCampaignDetailsErrorAction } from './exportFirmwareCampaignDetailsSlice'
import * as firmwareCampaignListSearchService from '../services/firmwareCampaignListService'

export const getFirmwareCampaignListBySearch = (page: number, size: number, sortdir: string, sortfield: string, search: string, tenantId: string) => async (dispatch: any) => {
  dispatch(firmwareCampaignListLoadingAction())
  try {
    await firmwareCampaignListSearchService.getFirmwareCampaignSearchDetails(page, size, sortdir, sortfield, search, tenantId, (firmwareCampaignListData: FirmwareCampaignListDataObj, httpStatus: number) => {
      dispatch(firmwareCampaignListUpdateAction({ data: firmwareCampaignListData.campaignDetails, total_count: firmwareCampaignListData.totalCount, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(firmwareCampaignListErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(firmwareCampaignListErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getFirmwareUpgradeDetails = (firmwareCampaignId: string, tenantId: string) => async (dispatch: any) => {
  dispatch(firmwareCampaignDetailsLoadingAction())
  try {
    await FirmwareUpgradeDetailsService.firmwareGridDetailsService(firmwareCampaignId, tenantId, (firmwareCampaignDetails: firmwareCampaignDetailsData, httpStatus) => {
      dispatch(firmwareCampaignDetailsUpdateAction({ data: firmwareCampaignDetails, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(firmwareCampaignDetailsErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(firmwareCampaignDetailsErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const exportFirmwareCampaignDetails = (firmwareCampaignId: string) => async (dispatch: any) => {
  dispatch(exportFirmwareCampaignDetailsLoadingAction())
  try {
    await exportFirmwareCampaignDetailsService.exportFirmwareCampaignDetailsGrid(firmwareCampaignId, (message: any) => {
      dispatch(exportFirmwareCampaignDetailsUpdateAction(message))
    },
    (errorMessage: any) => {
      dispatch(exportFirmwareCampaignDetailsErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(exportFirmwareCampaignDetailsErrorAction(e))
  }
}

export const exportFirmwareUpgradeStatus = () => async (dispatch: any) => {
  dispatch(exportFirmwareUpgradeStatusLoadingAction())
  try {
    await exportFirmwareUpgradeStatusService.exportFirmwareUpgradeStatusGrid((message: any) => {
      dispatch(exportFirmwareUpgradeStatusUpdateAction(message))
    },
    (errorMessage: any) => {
      dispatch(exportFirmwareUpgradeStatusErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(exportFirmwareUpgradeStatusErrorAction(e))
  }
}

export const getFirmwareListingDetails = (page: number, size: number, sortdir: string, sortfield: string, search: string, tenantId: string) => async (dispatch: any) => {
  dispatch(FirmwareListLoadingAction())
  try {
    await FirmwareDetailsService.getFirmwareListingDetails(page, size, sortdir, sortfield, search, tenantId, (firmwareListData: DataObj, httpStatus) => {
      dispatch(FirmwareListUpdateAction({ data: firmwareListData.firmware, total_count: firmwareListData.total_count, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(FirmwareListErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(FirmwareListErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getFirmwareListingDetailsFilteredData = (page: number, size: number, sortdir: string, sortfield: string, filterParams: any, tenantId: string) => async (dispatch: any) => {
  dispatch(FirmwareListLoadingAction())
  try {
    await FirmwareDetailsService.getFirmwareListingFilteredData(page, size, sortdir, sortfield, filterParams, tenantId, (firmwareListData, httpStatus) => {
      dispatch(FirmwareListUpdateAction({ data: firmwareListData.firmware, total_count: firmwareListData.total_count, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(FirmwareListErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(FirmwareListErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}
export const addFirmware = (firmwareRequestParams: firmwareReq, name: string, tenantId: string) => async (dispatch: any) => {
  dispatch(addFirmwareInfoDataLoading())
  try {
    await AddFirmwareService.addFirmware(firmwareRequestParams, name, tenantId, (message) => {
      dispatch(addFirmwareInfoData(message))
    },
    (errorMessage) => {
      dispatch(addFirmwareInfoDataError(errorMessage))
    })
  } catch (e: any) {
    dispatch(addFirmwareInfoDataError(e))
  }
}

export const resetFirmwareParams = () => async (dispatch: any) => {
  dispatch(resetAddFirmware())
}

export const resetDeleteParams = () => async (dispatch: any) => {
  dispatch(resetDeleteFirmware())
}

export const getFirmwareTypeData = () => async (dispatch: any) => {
  dispatch(FirmwareTypeDataLoadingAction())
  try {
    await AddFirmwareService.getFirmwareTypeData((message) => {
      dispatch(FirmwareTypeDataUpdateAction(message))
    },
    (errorMessage) => {
      dispatch(FirmwareTypeDataErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(FirmwareTypeDataErrorAction(e))
  }
}

export const getFirmwareListingFilterFirmwareType = (tenantId: string) => async (dispatch: any) => {
  dispatch(firmwareListFilterFirmwareTypeLoadingAction())
  try {
    await FirmwareDetailsService.getFirmwareListingFilterFirmwareType(tenantId, (message) => {
      dispatch(firmwareListFilterFirmwareTypeUpdateAction(message))
    },
    (errorMessage) => {
      dispatch(firmwareListFilterFirmwareTypeErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(firmwareListFilterFirmwareTypeErrorAction(e))
  }
}

export const getFirmwareListingFilterAssetType = (tenantId: string) => async (dispatch: any) => {
  dispatch(firmwareListFilterAssetTypeLoadingAction())
  try {
    await FirmwareDetailsService.getFirmwareListingFilterAssetType(tenantId, (message) => {
      dispatch(firmwareListFilterAssetTypeUpdateAction(message))
    },
    (errorMessage) => {
      dispatch(firmwareListFilterAssetTypeErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(firmwareListFilterAssetTypeErrorAction(e))
  }
}

export const getFirmwareListingFilterVersion = (tenantId: string) => async (dispatch: any) => {
  dispatch(firmwareListFilterVersionLoadingAction())
  try {
    await FirmwareDetailsService.getFirmwareListingFilterVersion(tenantId, (message) => {
      dispatch(firmwareListFilterVersionUpdateAction(message))
    },
    (errorMessage) => {
      dispatch(firmwareListFilterVersionErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(firmwareListFilterVersionErrorAction(e))
  }
}
export const exportFirmwareList = (tenantId: string) => async (dispatch: any) => {
  dispatch(exportFirmwareListLoadingAction())
  try {
    await FirmwareDetailsService.exportFirmwareList(tenantId, (message: any) => {
      dispatch(exportFirmwareListUpdateAction(message))
    },
    (errorMessage: any) => {
      dispatch(exportFirmwareListErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(exportFirmwareListErrorAction(e))
  }
}

export const deleteFirmware = (firmwareId: string, tenantId: string) => async (dispatch: any) => {
  dispatch(deleteFirmwareLoadingAction())
  try {
    await FirmwareDetailsService.deleteFirmwareById(firmwareId, tenantId, (message: any) => {
      dispatch(deleteFirmwareUpdateAction(message))
    },
    (errorMessage: any) => {
      dispatch(deleteFirmwareErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(deleteFirmwareErrorAction(e))
  }
}

export const createGroup = (createGroupBody: CreateGroupRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(createGroupLoadingAction())

  await postCreateGroup(
    createGroupBody,
    (httpStatus: number) => {
      dispatch(createGroupUpdateAction(httpStatus))
    },
    (errorMessage: string, httpStatus: number) => {
      dispatch(createGroupErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const assignFirmwareGroup = (firmwareGroupAssignBody: FirmwareGroupAssignRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(firmwareGroupAssignLoadingAction())

  await postAssignFirmwareGroup(
    firmwareGroupAssignBody,
    (httpStatus: number) => {
      dispatch(firmwareGroupAssignUpdateAction(httpStatus))
    },
    (errorMessage: string, httpStatus: number) => {
      dispatch(firmwareGroupAssignErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const unAssignFirmwareGroup = (firmwareGroupUnassignBody: FirmwareGroupUnassignRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(firmwareGroupUnassignLoadingAction())

  await PostfirmwareUnassignGroup(
    firmwareGroupUnassignBody,
    (httpStatus: number) => {
      dispatch(firmwareGroupUnassignUpdateAction(httpStatus))
    },
    (errorMessage: string, httpStatus: number) => {
      dispatch(firmwareGroupUnassignErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const firmwareCreateCampaign = (createCampaignBody: FirmwareCreateCampaignRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(createCampaignLoadingAction())
  await postFirmwareCreateCampaign(
    createCampaignBody,
    (createCampaignData: FirmwareCreateCampaignConfigObj, httpStatus: number) => {
      dispatch(createCampaignUpdateAction({ data: createCampaignData, httpStatus }))
    },
    (errorMessage: string, httpStatus: number) => {
      dispatch(createCampaignErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const firmwareEditCampaign = (editCampaignBody: FirmwareEditCampaignRequest) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(editCampaignLoadingAction())
  await putFirmwareEditCampaign(
    editCampaignBody,
    (editCampaignData: string, httpStatus: number) => {
      dispatch(editCampaignUpdateAction({ data: editCampaignData, httpStatus }))
    },
    (errorMessage: string, httpStatus: number) => {
      dispatch(editCampaignErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const getFirmwareCampaignList = (page: number, size: number, sortdir: string, sortfield: string, search: string, tenantId: string) => async (dispatch: any) => {
  dispatch(firmwareCampaignListLoadingAction())
  try {
    await getFirmwareCampaignDetails(page, size, sortdir, sortfield, search, tenantId, (firmwareCampaignListData: FirmwareCampaignListDataObj, httpStatus: number) => {
      dispatch(firmwareCampaignListUpdateAction({ data: firmwareCampaignListData.campaignDetails, total_count: firmwareCampaignListData.totalCount, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(firmwareCampaignListErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(firmwareCampaignListErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const postFirmwareCampaignFilterList = (page: number, size: number, sortdir: string, sortfield: string, search: string, tenantId: string, filterParams: FirmwareCampaignFilterRequest) => async (dispatch: any) => {
  dispatch(firmwareCampaignListLoadingAction())
  try {
    await postFirmwareCampaignFilterDetails(page, size, sortdir, sortfield, search, tenantId, filterParams, (firmwareCampaignListData: FirmwareCampaignListDataObj, httpStatus: number) => {
      dispatch(firmwareCampaignListUpdateAction({ data: firmwareCampaignListData.campaignDetails, total_count: firmwareCampaignListData.totalCount, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(firmwareCampaignListErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(firmwareCampaignListErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const firmwareCampaignFilterMetaData = () => async (dispatch: any) => {
  dispatch(firmwareCampaignFilterMetaDataLoadingAction())
  try {
    await getFirmwareCampaignFilterMetadata((FilterMetadata: FirmwareCampaignFilterMetadata, httpStatus: number) => {
      dispatch(firmwareCampaignFilterMetaDataUpdateAction({ data: FilterMetadata, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(firmwareCampaignFilterMetaDataErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(firmwareCampaignFilterMetaDataErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const fetchGroupWithCountListData = (assetType: string, groupName: string, tenantId: string) => async (dispatch: any): Promise<void> => {
  dispatch(allGroupDataLoadingAction())
  void AllGroupWithCountService.getAllGroupsWithCount(
    assetType, groupName, tenantId,
    (allGroupData, httpStatus) => {
      dispatch(
        allGroupDataUpdateAction({
          data: allGroupData,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(allGroupDataErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const firmwareDeleteCampaign = (firmwareCampaignId: string) => async (dispatch: any): Promise<void> => {
  dispatch(firmwareCampaignDeleteLoadingAction())
  void FirmwareDeleteCampaignService.firmwareDeleteCampaign(firmwareCampaignId,
    (deleteStatusData, httpStatus) => {
      dispatch(
        firmwareCampaignDeleteUpdateAction({
          data: deleteStatusData,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(firmwareCampaignDeleteErrorAction({ errorMessage, httpStatus }))
    }
  )
}
