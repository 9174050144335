import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ApiResponse } from '../services/deleteConfigService'

export interface DeleteConfigurationState {
  isLoading: boolean
  error: string | null
  httpStatus: number | null | undefined
}

const initialState: DeleteConfigurationState = {
  isLoading: true,
  error: null,
  httpStatus: null
}

const deleteConfigurationSlice = createSlice({
  name: 'deleteConfig',
  initialState,
  reducers: {
    deleteConfigurationLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        httpStatus: 0
      }
    },
    deleteConfigurationUpdateAction: (state, action: PayloadAction<{ data: ApiResponse }>) => {
      return {
        isLoading: false,
        error: null,
        httpStatus: action.payload.data.http_status
      }
    },
    deleteConfigurationErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number | undefined }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  deleteConfigurationLoadingAction,
  deleteConfigurationUpdateAction,
  deleteConfigurationErrorAction
} = deleteConfigurationSlice.actions

export default deleteConfigurationSlice.reducer
