import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
export interface FirmwareEditCampaignState {
  isLoading: boolean
  error: string | undefined
  httpStatus: number | undefined
  data: string | undefined
}

export const initialState: FirmwareEditCampaignState = {
  isLoading: false,
  error: undefined,
  httpStatus: undefined,
  data: undefined
}

const FirmwareEditCampaignSlice = createSlice({
  name: 'firmware-edit-campaign',
  initialState,
  reducers: {
    editCampaignLoadingAction: (state) => {
      return {
        isLoading: true,
        error: undefined,
        httpStatus: 0,
        data: undefined
      }
    },
    editCampaignUpdateAction: (
      state,
      action: PayloadAction<{ data: string, httpStatus: number }>
    ) => {
      return {
        isLoading: false,
        error: undefined,
        httpStatus: action.payload.httpStatus,
        data: action.payload.data
      }
    },
    editCampaignErrorAction: (
      state,
      action: PayloadAction<{ errorMessage: string, httpStatus: number }>
    ) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        data: undefined
      }
    }
  }
})

export const {
  editCampaignLoadingAction,
  editCampaignUpdateAction,
  editCampaignErrorAction
} = FirmwareEditCampaignSlice.actions

export default FirmwareEditCampaignSlice.reducer
