import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type AssetGroupDetails } from '../types'

export interface AssetGroupDetailsState {
  isLoading: boolean
  error: string
  httpStatus: number
  assetGroupDetails: AssetGroupDetails | undefined
}

export const initialState: AssetGroupDetailsState = {
  isLoading: false,
  error: '',
  httpStatus: 0,
  assetGroupDetails: undefined
}

const assetGroupDetailsSlice = createSlice({
  name: 'assetGroupDetails',
  initialState,
  reducers: {
    assetGroupDetailsLoadingAction: (state) => {
      return {
        isLoading: true,
        error: '',
        httpStatus: 0,
        assetGroupDetails: undefined
      }
    },
    assetGroupDetailsUpdateAction: (state, action: PayloadAction<{ data: AssetGroupDetails, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: '',
        httpStatus: action.payload.httpStatus,
        assetGroupDetails: action.payload.data
      }
    },
    assetGroupDetailsErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus,
        assetGroupDetails: undefined
      }
    }
  }
})

export const {
  assetGroupDetailsLoadingAction,
  assetGroupDetailsUpdateAction,
  assetGroupDetailsErrorAction
} = assetGroupDetailsSlice.actions

export default assetGroupDetailsSlice.reducer
