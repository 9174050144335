import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface AllAlarmData {
  assetId: string
  assetName: string
  readingTime: string
  alarmDefinitionId: string
  alarmName: string
  severity: string
  alarmStatus: string
  longitude: number
  latitude: number
}

export interface AllAlarmDetails {
  totalCount: number
  alarms: AllAlarmData[]
}

export interface AlarmDataState {
  isLoading: boolean
  error: string | null
  alarmData: AllAlarmData[] | null
  count: number
  httpStatus: number | null
}

const initialState: AlarmDataState = {
  isLoading: true,
  error: null,
  alarmData: null,
  count: 0,
  httpStatus: null
}

const allAlarmsSlice = createSlice({
  name: 'allAlarms',
  initialState,
  reducers: {
    allAlarmDataLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        alarmData: null,
        count: 0,
        httpStatus: 0
      }
    },
    allAlarmDataUpdateAction: (state, action: PayloadAction<{ data: AllAlarmData[], count: number, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: null,
        alarmData: action.payload.data,
        count: action.payload.count,
        httpStatus: action.payload.httpStatus
      }
    },
    allAlarmDataErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        error: action.payload.errorMessage,
        alarmData: null,
        count: 0,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  allAlarmDataLoadingAction,
  allAlarmDataUpdateAction,
  allAlarmDataErrorAction
} = allAlarmsSlice.actions

export default allAlarmsSlice.reducer
