import { type AuthProviderProps } from 'react-oidc-context'
import { escapeHTML } from '../utils/escapeHTML'

export const oidcConfig: AuthProviderProps = {
  client_id: (typeof window._env_?.REACT_APP_FORGE_IAM_CLIENTID === 'string' ? window._env_.REACT_APP_FORGE_IAM_CLIENTID : ''),
  authority: (typeof window._env_?.REACT_APP_FORGE_IAM_AUTHORITY === 'string' ? window._env_.REACT_APP_FORGE_IAM_AUTHORITY : ''),
  redirect_uri: escapeHTML(window.location.origin) + '/',
  redirectMethod: 'replace',
  post_logout_redirect_uri: escapeHTML(window.location.origin) + '/logout',
  scope: 'openid offline',
  onSigninCallback: () => {
    const prevLocation = localStorage.getItem('redirectUri')
    if (prevLocation != null) {
      window.location.replace(escapeHTML(prevLocation))
      localStorage.removeItem('redirectUri')
    } else {
      window.history.replaceState({}, document.title, escapeHTML(window.location.pathname))
    }
  }
}
