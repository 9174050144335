import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, tableCellClasses } from '@mui/material'
import Pagination from '../../../components/TablePagination'
import React, { useEffect } from 'react'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../../store'
import { type AllConfigData } from '../redux/allConfigSlice'

import AuthError from '../../../components/ErrorComponents/AuthError'
import { fetchAllFiles } from '../redux/actionCreators'
import { useAuth } from 'react-oidc-context'
import { type FileStatusState } from '../redux/fileStatusSlice'
import { type FileStatusData } from '../services/fileStatusService'

const style = {
  width: '99%',
  bgcolor: '#313030',
  border: '1px solid #101010',
  borderRadius: '10px',
  boxShadow: '1px 1px 1px rgba(0, 0, 0, 0.15)',
  marginBottom: '16px',
  padding: 0
}

function EnhancedTableHead (props: any): any {
  return (
          <TableHead data-testid='table-header'>
              <TableRow>
                  {headCells.map((headCell: HeadCell) => (
                      <StyledTableCell
                          key={headCell.id}
                          align={'left'}
                          padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                          <>{headCell.label}</>
                      </StyledTableCell>
                  ))}
              </TableRow>
          </TableHead>
  )
}

interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#F0F0F0',
    fontWeight: '700',
    fontSize: 12,
    border: '2px solid #202020',
    padding: '5px 16px',
    fontFamily: 'Honeywell Sans Web'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#d0d0d0',
    paddingLeft: '17px',
    borderBottom: '1px solid #202020',
    borderRight: 0,
    fontFamily: 'Honeywell Sans Web'
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  '& th': {
    border: '1px solid #202020'
  }
}))

const headCells: readonly HeadCell[] = [
  {
    id: 'file_name',
    numeric: false,
    disablePadding: false,
    label: 'FILE NAME'
  },
  {
    id: 'file_type',
    numeric: true,
    disablePadding: false,
    label: 'FILE TYPE'
  },
  {
    id: 'file_created',
    numeric: false,
    disablePadding: false,
    label: 'CREATED DATE'
  },
  {
    id: 'first_seqnum',
    numeric: false,
    disablePadding: false,
    label: 'START RANGE'
  },
  {
    id: 'last_seqnum',
    numeric: false,
    disablePadding: false,
    label: 'END RANGE'
  },
  {
    id: 'tenant_id',
    numeric: true,
    disablePadding: false,
    label: 'ORGANIZATION'
  },
  {
    id: 'file_publishing_status',
    numeric: true,
    disablePadding: false,
    label: 'TRANSFER STATE'
  }
]

type Order = 'asc' | 'desc'

interface Sort {
  order: Order
  orderBy: string
}

const ConfigurationTable = (props: PropsFromRedux): JSX.Element => {
  const [configList, setConfigList] = React.useState<FileStatusData[]>([])
  const [selected, setSelected] = React.useState<readonly number[]>([])
  const [page, setPage] = React.useState(1)
  const [sort, setSort] = React.useState<Sort>({ order: 'asc', orderBy: 'template_id' })
  const rowsPerPage = 10
  const auth = useAuth()

  const handleFetchConfigList = (): void => {
    if (page >= 1) {
      props.fetchAllFiles(page - 1, rowsPerPage, auth.user?.profile.customer as string)
    }
  }

  useEffect(() => {
    handleFetchConfigList()
  }, [page, sort])

  useEffect(() => {
    if (props.allStatusFiles.fileStatusData !== null && props.allStatusFiles.fileStatusData.statusfiles.length > 0) {
      const data = props.allStatusFiles.fileStatusData.statusfiles
      const uniqueObjects: FileStatusData[] = []
      const uniqueObjectIds = new Set<string>()

      data.forEach((obj) => {
        const key = obj.file_name
        if (!uniqueObjectIds.has(key)) {
          uniqueObjectIds.add(key)
          uniqueObjects.push(obj)
        }
      })

      setConfigList(uniqueObjects)
    }
  }, [props.allStatusFiles.fileStatusData])

  const handleRequestSort =
        (event: React.MouseEvent<unknown>, newOrderBy: keyof AllConfigData): void => {
          const isAsc = sort.orderBy === newOrderBy && sort.order === 'asc'
          const toggledOrder = isAsc ? 'desc' : 'asc'
          setSort({ order: toggledOrder, orderBy: newOrderBy })
        }

  const handleRowClick = (templateId: string): void => {
    sessionStorage.setItem('templateId', templateId)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked && configList.length > 0) {
      const newSelected: any = configList.map((n: any) => n.name)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleTransferState = (state: any): any => {
    const fileState = ['Completed', 'Failed', 'Pending', 'In Progress', 'Cancelled', 'Expired', 'Skipped', 'Outstanding']
    return fileState[state]
  }

  const isSelected = (index: number): boolean => selected.includes(index)

  if (props.allStatusFiles.fileStatusData !== null && props.allStatusFiles.fileStatusData.statusfiles.length > 0 && props.allStatusFiles.httpStatus === 200) {
    return (
      <>
        <Box sx={style} className="devices">
          <Paper
            sx={{
              width: '100%',
              mb: 2,
              backgroundColor: '#272727',
              boxShadow: 'unset',
              color: '#F0F0F0',
              marginBottom: '0'
            }}
          >
            <TableContainer className="device-table">
              <Table
                sx={{ minWidth: 750, color: '#f0f0f0' }}
                aria-labelledby="tableTitle"
                size={'small'}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={sort.order}
                  orderBy={sort.orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={configList.length}
                />
                <TableBody>
                  {configList.length > 0 &&
                    configList.map((row: FileStatusData, index: number) => {
                      const isItemSelected = isSelected(index)
                      const labelId = `enhanced-table-checkbox-${row.file_name}`

                      return (
                        <StyledTableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.file_name}
                          aria-label="devices-list-row"
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <StyledTableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            aria-label="device-name"
                            onClick={() => { handleRowClick(row.file_name.toString()) }}
                            data-testid="device-name"
                            sx={{
                              color: '#64C3FF !important',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '25vw'
                            }}
                          >
                            {row.file_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.file_type.toUpperCase()}
                          </StyledTableCell>
                          <StyledTableCell>
                            {
                            row.file_created.split('.')[0]
                            }
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.first_seqnum}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.last_seqnum}
                          </StyledTableCell>
                          <StyledTableCell>
                            {row.tenant_id.toUpperCase()}
                          </StyledTableCell>
                          <StyledTableCell>
                            {handleTransferState(row.file_publishing_status)}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {props.allStatusFiles.fileStatusData.total_count > 10 && (<Pagination count={props.allStatusFiles.fileStatusData.total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} siblingCount={1}
                        boundaryCount={1} />)}
          </Paper>
        </Box>
      </>
    )
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  } else if (props.allStatusFiles.isLoading) {
    return (
      <div className='CircularprogressAmi'>
        <CircularProgress />
      </div>
    )
  } else if (props.allStatusFiles.httpStatus === 401) {
    return (
            <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
    )
  } else if (props.allStatusFiles.httpStatus === 403) {
    return (
        <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
    )
  } else if ((props.allStatusFiles.fileStatusData === null && props.allStatusFiles.httpStatus === 200)) {
    return (
        <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
    )
  } else {
    return (
        <div className='authError'><AuthError errorMessage="cannotFetch" retry={handleFetchConfigList}/></div>
    )
  }
}

interface DispatchToProps {
  fetchAllFiles: (page: number, size: number, tenantId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAllFiles: (page: number, size: number, tenantId: string) => dispatch(fetchAllFiles(page, size, tenantId))

})

interface StateToProps {
  allStatusFiles: FileStatusState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  allStatusFiles: state.allStatusFiles
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ConfigurationTable)
