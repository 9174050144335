import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type CreateGroupApiResponse } from '../services/createGroupService'

export interface CreateGroupState {
  isLoading: boolean
  error: string | null
  httpStatus: number
  GropData: CreateGroupApiResponse | null
}

export const initialState: CreateGroupState = {
  isLoading: false,
  error: null,
  GropData: null,
  httpStatus: 0
}

const createGroupSlice = createSlice({
  name: 'firmware-create-group',
  initialState,
  reducers: {
    createGroupLoadingAction: (state) => {
      return {
        isLoading: true,
        error: null,
        GropData: null,
        httpStatus: 0
      }
    },
    createGroupUpdateAction: (state, action: PayloadAction<any>): CreateGroupState => {
      return {
        isLoading: false,
        error: null,
        GropData: action.payload.data,
        httpStatus: action.payload.http_status
      }
    },
    createGroupErrorAction: (
      state,
      action: PayloadAction<{ errorMessage: string, httpStatus: number }>
    ) => {
      return {
        isLoading: false,
        GropData: null,
        error: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const {
  createGroupLoadingAction,
  createGroupUpdateAction,
  createGroupErrorAction
} = createGroupSlice.actions

export default createGroupSlice.reducer
